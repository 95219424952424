import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center px-[34px] pt-16" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex items-start" }
const _hoisted_4 = { class: "flex items-center justify-center mb-6" }
const _hoisted_5 = { class: "flex flex-col mb-2" }
const _hoisted_6 = { class: "flex flex-col items-start justify-center font-sourcesanspro-regular text-m2" }
const _hoisted_7 = { class: "flex items-center mb-4 font-color-text-50" }
const _hoisted_8 = { class: "flex items-center mb-4 font-color-text-50" }

import { ref } from 'vue';
import { IonContent, IonFooter, IonButton, IonIcon, IonSearchbar } from '@ionic/vue';
import BookingsReservationsItem from '@/modules/bookings-reservations/components/BookingsReservationsItem.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import { close, search } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingsReservationsPage',
  setup(__props) {

const showSearch = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-[20px]" }, null, -1)),
              _createElementVNode("div", null, [
                _createVNode(_unref(IonIcon), {
                  icon: require('@/statics/svg/dashboard/anykrowd-logo.svg'),
                  class: "w-[100px] h-[100px]"
                }, null, 8, ["icon"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(search),
                  class: "w-[20px] h-[20px]",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showSearch.value = true))
                }, null, 8, ["icon"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(IonButton), {
                class: "mr-2 w-[140px] h-[40px] backdrop-blur bg-white/20 rounded-full",
                shape: "round",
                fill: "clear"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("span", { class: "text-white text-base2" }, "All Bookings", -1)
                ])),
                _: 1
              }),
              _createVNode(_unref(IonButton), {
                class: "w-[140px] h-[40px]",
                shape: "round"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "text-base2" }, "My Bookings", -1)
                ])),
                _: 1
              })
            ])
          ], 512), [
            [_vShow, !showSearch.value]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _withDirectives(_createVNode(_unref(IonSearchbar), {
              class: "mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l",
              shape: "round",
              onIonBlur: _cache[1] || (_cache[1] = ($event: any) => (showSearch.value = false)),
              onIonCancel: _cache[2] || (_cache[2] = ($event: any) => (showSearch.value = false))
            }, null, 512), [
              [_vShow, showSearch.value]
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mb-4" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2" }, "Autocomplete Suggestions")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(IonIcon), {
                    class: "mr-2",
                    icon: _unref(close)
                  }, null, 8, ["icon"]),
                  _cache[6] || (_cache[6] = _createElementVNode("div", null, [
                    _createElementVNode("span", { class: "text-white" }, [
                      _createElementVNode("b", null, "Boum")
                    ]),
                    _createTextVNode(),
                    _createElementVNode("span", null, "De Noël 2022")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(IonIcon), {
                    class: "mr-2",
                    icon: _unref(close)
                  }, null, 8, ["icon"]),
                  _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                    _createElementVNode("span", null, "Bart Peeters & De"),
                    _createTextVNode(),
                    _createElementVNode("span", { class: "text-white" }, [
                      _createElementVNode("b", null, "Boum")
                    ]),
                    _createElementVNode("span", null, "s Mannen")
                  ], -1))
                ])
              ])
            ])
          ], 512), [
            [_vShow, showSearch.value]
          ]),
          _createVNode(BookingsReservationsItem, {
            accepted: true,
            image: require('@/statics/svg-delete-after-implement/bookings-reservations-image-1.svg'),
            "price-original": 38.99,
            price: 25.99,
            time: "15 mins ago",
            description: "Quisque pretium malesuada justo, nec maximus lacus efficitur nec.",
            hour: "08:00pm",
            link: "/bookings-reservations/1",
            "price-currency": "€",
            title: "VIP DISPLAY"
          }, null, 8, ["image"]),
          _createVNode(BookingsReservationsItem, {
            image: require('@/statics/svg-delete-after-implement/bookings-reservations-image-2.svg'),
            "price-original": 38.99,
            price: 25.99,
            time: "15 mins ago",
            hour: "08:00pm",
            description: "Quisque pretium malesuada justo, nec maximus lacus efficitur nec.",
            link: "/bookings-reservations/2",
            location: "Ancienne Belgieque, Brussels",
            "price-currency": "€",
            title: "VIP DISPLAY"
          }, null, 8, ["image"]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "bookings-reservations" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full" }
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = { class: "flex w-full items-center px-6" }

import { ref, toRef } from 'vue';
import { IonIcon, IonInput, IonLabel } from '@ionic/vue';
import { useField, useIsFormValid } from 'vee-validate';
import { checkmarkOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'TextInput',
  props: {
	disabled: { type: Boolean, default: false },
	iconLeft: { type: String, default: '' },
	inputClass: { type: String, default: 'font-sourcesanspro-regular text-base' },
	inputMode: {
		type: String as () => 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined,
		default: 'text',
	},
	label: { type: String },
	name: { type: String, required: true },
	manualSubmit: { type: Function },
	placeholder: { type: String, default: '' },
	step: { type: String as () => string | undefined },
	showErrorMessage: { type: Boolean, default: true },
	showInputSuccess: { type: Boolean, default: true },
	shouldHandleBlur: { type: Boolean, default: true },
	shouldValidate: { type: Boolean, default: true },
	successMessage: { type: String, default: '' },
	type: { type: String, default: 'text' },
	value: { type: String as () => string | number | null | undefined, default: null },
	wrapperClass: { type: String, default: '' },
},
  emits: ['inputChanged', 'enterHit'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Handle password visibility
const type = ref(props.type);
const showPassword = ref(false);
const swapPasswordVisibility = () => {
	type.value = type.value === 'password' ? 'text' : 'password';
	showPassword.value = !showPassword.value;
};

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { value, errorMessage, handleChange, meta, setValue } = useField(name, undefined, {
	initialValue: props.value,
});

const isFormValid = useIsFormValid();

const handleInputChange = (event: any, enterHit: boolean) => {
	if (props.shouldValidate) {
		if (props.shouldHandleBlur) {
			handleChange(event);
		} else {
			if (event.detail && event.detail.value) {
				handleChange(event);
				setValue(event.detail.value);
			}
		}
	}
	handleInputUpdate(event);
	if (props.manualSubmit && isFormValid && enterHit) {
		props.manualSubmit();
	}
};

// Validate form
const handleInputUpdate = (event: any) => {
	// Let the parent know when the input change
	emit('inputChanged', isFormValid, name, event);
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-regular" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.label), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["wrapper border-white-15", [
				{
					'border-error': !!_unref(errorMessage) && !!__props.wrapperClass,
					'border-success': props.showInputSuccess && _unref(meta).valid && _unref(meta).dirty,
				},
				__props.wrapperClass,
			]])
    }, [
      _createElementVNode("div", _hoisted_3, [
        (props.iconLeft)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: props.iconLeft,
              class: "mr-3",
              color: "primary",
              slot: "start"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createVNode(_unref(IonInput), {
          class: _normalizeClass(props.inputClass),
          name: props.name,
          type: type.value,
          disabled: props.disabled,
          placeholder: props.placeholder,
          step: props.step,
          inputmode: props.inputMode,
          value: _unref(value),
          onKeydown: _cache[0] || (_cache[0] = _withKeys((event) => handleInputChange(event, true), ["enter"])),
          onIonChange: _cache[1] || (_cache[1] = (event) => handleInputChange(event, false)),
          onIonBlur: _cache[2] || (_cache[2] = (event) => handleInputChange(event, false))
        }, null, 8, ["class", "name", "type", "disabled", "placeholder", "step", "inputmode", "value"]),
        (props.type !== 'password' && props.showInputSuccess && _unref(meta).valid && _unref(meta).dirty)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 1,
              icon: _unref(checkmarkOutline),
              slot: "end"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        (props.type === 'password' && showPassword.value)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 2,
              icon: _unref(eyeOutline),
              slot: "end",
              onClick: swapPasswordVisibility
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        (props.type === 'password' && !showPassword.value)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 3,
              icon: _unref(eyeOffOutline),
              slot: "end",
              onClick: swapPasswordVisibility
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _withDirectives(_createElementVNode("p", { class: "mb-4 font-sourcesanspro-regular text-sm font-color-error" }, _toDisplayString(_unref(errorMessage)), 513), [
      [_vShow, _unref(errorMessage) && __props.showErrorMessage]
    ])
  ], 512)), [
    [_vShow, type.value !== 'hidden']
  ])
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full items-center justify-center mb-4 mt-4 text-white"
}

import { useAuthStore } from '@/stores/auth/authStore';
import { captureMessage } from '@/sentry';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { ValuesEnum } from '@/enums/values.enum';
import { set } from '@/shared/composables/useStorageService';


export default /*@__PURE__*/_defineComponent({
  __name: 'MagicLoginPage',
  setup(__props) {

const route = useRoute();
const token = route.params.token.toString();

onMounted(async () => {
	if (token) {
		const redirectUrl = route.query?.redirect_url;
		const authStore = useAuthStore();

		const user = await authStore.saveToken({ token });

		if (user && user.token) {
			if (redirectUrl) {
				window.location.href = redirectUrl as string;
			} else {
				await router.push('/dashboard');
			}
		} else {
			captureMessage(`Error magic login saving token: ${user}`);
		}
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_unref(token))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " There was an error with the magic link. Please contact support. "))
      : _createCommentVNode("", true)
  ]))
}
}

})
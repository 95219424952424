<script setup lang="ts">
import vue, { watch } from 'vue';
import router from '@/router';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { lockOpenOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useToast from '@/shared/composables/useToast';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const emit = defineEmits(['onFormValidation']);
const { loginUserWithPhone, loginData, isSuccessLogin } = useAuth();
const { presentToast } = useToast();
const route = useRoute();
const uuid = route.params.id;

const verificationCodeRegExp = /^[0-9]{6}$/;

const validationSchema = yup.object({
	verification_code: yup
		.string()
		.matches(verificationCodeRegExp, t('auth.sign_in_phone_page.errors.verification_code'))
		.required(t('auth.sign_in_phone_page.required')),
});

const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

const onSubmit = handleSubmit(async (formData: any) => {
	try {
		await loginUserWithPhone({
			userData: {
				...formData,
				uuid,
			},
			message: t('general.please_wait'),
		});
	} catch (error: any) {
		await presentToast(
			'top',
			error.response?.data?.message ? error.response.data.message.toString() : error.toString(),
			5000,
			'danger',
		);
	}
});

watch(isSuccessLogin, () => {
	if (isSuccessLogin.value && loginData.value) {
		router.push('/profile/additionaldata');
	}
});

const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	emit('onFormValidation', meta.value.valid);
};
</script>

<template>
	<form id="signInPhoneVerificationCodeForm" @submit="onSubmit" :validation-schema="validationSchema">
		<TextInput
			wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
			:iconLeft="lockOpenOutline"
			name="verification_code"
			type="text"
			:placeholder="$t('auth.sign_in_phone_page.verification_code')"
			@inputChanged="onInputChange" />
	</form>
</template>

<style lang="scss" scoped></style>

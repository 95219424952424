import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "avatar-container relative" }
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = {
  key: 0,
  class: "dot"
}
const _hoisted_4 = ["src"]




export default /*@__PURE__*/_defineComponent({
  __name: 'UserAvatar',
  props: {
	alt: { type: String },
	border: { type: String },
	borderClass: { type: String },
	imageClass: { type: String },
	containerClass: { type: String },
	src: { type: String, required: true },
	showStatus: { type: Boolean, default: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative flex items-center justify-center", props.containerClass ? props.containerClass : 'w-[70px] h-[70px]'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: _normalizeClass(["object-cover rounded-full", props.imageClass ? props.imageClass : 'w-[42px] h-[42px]']),
        alt: props.alt,
        src: props.src
      }, null, 10, _hoisted_2),
      (__props.showStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (props.border)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: props.border,
          class: _normalizeClass(["absolute m-auto", props.borderClass ? props.borderClass : 'w-[70px] h-[70px]'])
        }, null, 10, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer"
}
const _hoisted_3 = {
  key: 0,
  class: "flex w-full items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "text-base"
}
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm" }
const _hoisted_7 = { class: "font-sourcesanspro-black text-lg3" }
const _hoisted_8 = { class: "flex justify-between mb-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  id: "popover-button",
  class: "flex items-center justify-center rounded-lg font-sourcesanspro-bold text-anykrowd-dark text-md cursor-pointer"
}
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "mr-2" }
const _hoisted_13 = { class: "flex flex-col px-3 py-2 font-sourcesanspro-regular" }
const _hoisted_14 = { class: "mb-1" }
const _hoisted_15 = {
  key: 3,
  class: "flex flex-col font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm mb-6 mt-4"
}
const _hoisted_16 = { class: "flex items-center justify-between" }
const _hoisted_17 = ["src"]
const _hoisted_18 = {
  key: 1,
  class: "flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer"
}
const _hoisted_19 = {
  key: 0,
  class: "flex px-[34px] mb-3 mt-[2px] justify-end font-sourcesanspro-regular text-xs"
}
const _hoisted_20 = {
  key: 1,
  class: "flex px-[34px] mx-auto items-center justify-between w-full"
}
const _hoisted_21 = ["src"]

import { onMounted, onBeforeUnmount, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { chevronDownOutline, chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { captureMessage } from '@/sentry';
import { useI18n } from 'vue-i18n';

import { useAuthStore } from '@/stores/auth/authStore';
import { Wallet } from '../interfaces/balance';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useStorageService from '@/shared/composables/useStorageService';


export default /*@__PURE__*/_defineComponent({
  __name: 'WalletBox',
  props: {
	user: { type: String, default: '' },
	walletId: { type: String, default: '' },
},
  emits: ['onWalletSet'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

// Init
const { t } = useI18n();
const emit = __emit;
const { presentToast } = useToast();
const authStore = useAuthStore();
const { getWallets, selectedWallet } = useWallet();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { refetchWalletsData, wallets, walletUpdatedAt } = getWallets();
const { convertToCurrency, toRelativeTime } = useUtils();
const unsubscribe = ref();
const isOnline = ref(navigator.onLine);
const startTime = ref(new Date());
const showWarningMessage = ref(false);
const { get, set } = useStorageService();

// Expose the method to the parent component
__expose({
	refetchWalletsData,
});

if (authStore.isAuthenticated()) {
	refetchWalletsData();
}

const handleWalletChange = async () => {
	if (authStore.isAuthenticated() && wallets.value) {
		if (wallets.value) {
			if (wallets?.value?.length && wallets?.value?.length > 0) {
				selectedWallet.value = wallets.value?.find((wallet) => {
					return wallet.currency.default;
				});
				// if not default wallet, then select first
				if (props.walletId) {
					selectedWallet.value = wallets.value?.find((wallet) => {
						return wallet.id == +props.walletId;
					});
				} else if (!selectedWallet.value) {
					selectedWallet.value = wallets.value[0];
				}
			}
			await set('selectedWallet', selectedWallet.value);
			emit('onWalletSet', selectedWallet.value);
		}
	}
};

onMounted(async () => {
	const selectedWalletFromCache = await get('selectedWallet');
	if (selectedWalletFromCache) {
		selectedWallet.value = selectedWalletFromCache;
	}
	await handleWalletChange();
});

watch(wallets, async () => {
	if (wallets.value) {
		await handleWalletChange();
	}
});

const warningMessageInterval = setInterval(() => {
	const seconds = Math.round(DateTime.now().diff(DateTime.fromJSDate(startTime.value)).milliseconds / 1000);
	if (seconds > 5 && !selectedWallet.value) {
		showWarningMessage.value = true;
		clearInterval(warningMessageInterval);
	} else if (seconds > 5) {
		clearInterval(warningMessageInterval);
	} else {
		showWarningMessage.value = false;
	}
}, 1000);

onBeforeUnmount(() => {
	if (unsubscribe.value) {
		unsubscribe.value();
	}
});

const selectWallet = async (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
	await set('selectedWallet', wallet);
	emit('onWalletSet', wallet);
};

const getWalletAtIndex = (index: number) => {
	if (index >= wallets.value!.length) {
		index = 0;
	} else if (index < 0) {
		index = wallets.value!.length - 1;
	}
	return wallets.value?.[index];
};

const currentWalletIndex = () => {
	return wallets.value!.findIndex((wallet) => wallet.id === selectedWallet.value!.id);
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};

const hideOfflineMessage = () => {
	isOnline.value = true;
};

const showOfflineMessage = () => {
	isOnline.value = false;
};

window.addEventListener('offline', (e) => {
	showOfflineMessage();
});

window.addEventListener('online', (e) => {
	hideOfflineMessage();
});

const goTo = async (link: string) => {
	// If the user is not authenticated, we will first ask him to sign in
	if (!authStore.isAuthenticated()) {
		await presentToast('top', t('general.please_login_first'), 5000, 'danger');
		await router.push('/auth/signuphome');
	} else {
		await router.push(link);
	}
};

return (_ctx: any,_cache: any) => {
  return (!_unref(tenantConfig)?.hide_wallet)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
			{
				'mb-2': !_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
				'mb-8': _unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
			},
		])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (isOnline.value && _unref(authStore).isAuthenticated())
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_unref(wallets) && _unref(wallets)?.length > 1)
                  ? (_openBlock(), _createBlock(_unref(IonIcon), {
                      key: 0,
                      icon: _unref(chevronBackOutline),
                      class: "text-white active:bg-white/30 active:rounded-full active:p-1",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (selectPreviousWallet()))
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([[
					{
						'bg-white-image': !_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
						'payconiq': _unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
						'text-white': _unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
						'font-color-dark': !_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
						'mx-[34px]': !isOnline.value || !_unref(authStore).isAuthenticated(),
					},
				], "flex justify-between rounded-md p-4 w-full"])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex flex-col justify-between w-full", [
						{
							'px-4': !_unref(authStore).isAuthenticated(),
						},
					]])
            }, [
              (isOnline.value && _unref(authStore).isAuthenticated() && !_unref(selectedWallet))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_unref(IonSpinner), {
                      name: "dots",
                      class: "text-anykrowd-dark"
                    })
                  ]))
                : _createCommentVNode("", true),
              (_unref(authStore).isAuthenticated() && showWarningMessage.value && !_unref(selectedWallet))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(t)('general.connection.offline_long_message')), 1))
                : _createCommentVNode("", true),
              (_unref(authStore).isAuthenticated())
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["flex flex-row justify-between", { 'mb-6': !_unref(selectedWallet)?.currency?.description }])
                  }, [
                    (_unref(selectedWallet))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('components.wallet_box.your')) + " " + _toDisplayString(_unref(selectedWallet).currency.name) + " " + _toDisplayString(_unref(t)('components.wallet_box.balance')), 1),
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(selectedWallet).currency.prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+_unref(selectedWallet).current_balance)), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
                      (_unref(selectedWallet))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(selectedWallet).currency.prefix), 1),
                              _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(selectedWallet)?.currency.name), 1),
                              (_unref(wallets) && _unref(wallets)?.length > 1)
                                ? (_openBlock(), _createBlock(_unref(IonIcon), {
                                    key: 0,
                                    icon: _unref(chevronDownOutline),
                                    class: "text-anykrowd-dark text-md"
                                  }, null, 8, ["icon"]))
                                : _createCommentVNode("", true)
                            ]),
                            _createVNode(_unref(IonPopover), {
                              trigger: "popover-button",
                              "dismiss-on-select": true
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonContent), null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(IonList), null, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(wallets), (wallet) => {
                                          return (_openBlock(), _createBlock(_unref(IonItem), {
                                            class: "flex items-center justify-center ion-no-padding ion-no-margin",
                                            key: wallet.id,
                                            button: true,
                                            onClick: ($event: any) => (selectWallet(wallet)),
                                            detail: false
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_13, [
                                                _createElementVNode("div", _hoisted_14, [
                                                  _createElementVNode("span", null, _toDisplayString(wallet.currency.prefix) + " " + _toDisplayString(wallet.current_balance) + " " + _toDisplayString(wallet.currency.name), 1)
                                                ])
                                              ])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_unref(authStore).isAuthenticated() && _unref(selectedWallet) && _unref(selectedWallet).currency?.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_unref(selectedWallet).currency?.description), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_16, [
                (
								isOnline.value &&
								_unref(authStore).isAuthenticated() &&
								_unref(selectedWallet) &&
								_unref(selectedWallet).currency.topupable
							)
                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                      key: 0,
                      class: _normalizeClass([[
								{
									'black': !_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode,
								},
							], "w-full h-[40px] font-sourcesanspro-semibold text-sm2 ion-no-margin hover:bg-white hover:rounded"]),
                      shape: !_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode ? 'round' : undefined,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo(`/top-up/${_unref(selectedWallet)!.id}`)))
                    }, {
                      default: _withCtx(() => [
                        (_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: require('@/statics/png/payconiq/payconiq-horizontal.png'),
                              class: "mr-1"
                            }, null, 8, _hoisted_17))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(!_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode
									? _unref(t)('wallet.add')
									: `${_unref(t)('wallet.add')} ${_unref(selectedWallet).currency.name} ${_unref(t)('wallet.pay_with_payconiq')}`), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "shape"]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ], 2),
          (isOnline.value && _unref(authStore).isAuthenticated())
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                (_unref(wallets) && _unref(wallets)?.length > 1)
                  ? (_openBlock(), _createBlock(_unref(IonIcon), {
                      key: 0,
                      icon: _unref(chevronForwardOutline),
                      class: "text-white active:bg-white/30 active:rounded-full active:p-1",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (selectNextWallet()))
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (isOnline.value && _unref(walletUpdatedAt))
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_unref(t)('wallet.updated_at')) + " " + _toDisplayString(_unref(toRelativeTime)(_unref(t), _unref(walletUpdatedAt))), 1))
          : _createCommentVNode("", true),
        (isOnline.value && _unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-sourcesanspro-semibold drop-shadow-customtext-xs" }, "Powered by payconiq", -1)),
              _createElementVNode("img", {
                src: require('@/statics/svg/payconiq/payconiq-horizontal-neg.svg'),
                class: "w-[74px] h-[13px]drop-shadow-custom"
              }, null, 8, _hoisted_21)
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})
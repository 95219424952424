import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-[34px]" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center mb-14" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "flex items-center justify-between mb-8",
  slot: "header"
}
const _hoisted_5 = {
  class: "flex items-start justify-between mb-8",
  slot: "header"
}
const _hoisted_6 = {
  class: "flex items-center justify-between mb-8",
  slot: "header"
}
const _hoisted_7 = {
  class: "flex items-center justify-between mb-8",
  slot: "header"
}

import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';

import TopBar from '@/shared/components/TopBar.vue';
import { callOutline, chevronBackOutline, locationOutline, timeOutline } from 'ionicons/icons';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactInfoPage',
  setup(__props) {

const assetStore = useAssetsStore();

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex items-center justify-start cursor-pointer",
            onClick: goBack
          }, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(chevronBackOutline),
              class: "text-white"
            }, null, 8, ["icon"])
          ])
        ]),
        center: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }, "Contact Info", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(assetStore).logoUrl,
            class: "max-w-[70%]"
          }, null, 8, _hoisted_3),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l" }, "Club Ampere", -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(IonIcon), {
            class: "w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full",
            icon: _unref(locationOutline)
          }, null, 8, ["icon"]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col grow pl-2 justify-center" }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none" }, "Address")
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 leading-none" }, "Ancienne Belgieque, Brussels")
            ])
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(IonIcon), {
            class: "w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full",
            icon: _unref(timeOutline)
          }, null, 8, ["icon"]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col pt-[2px] grow pl-2 justify-center" }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none" }, "Opening hours")
            ]),
            _createElementVNode("div", { class: "flex" }, [
              _createElementVNode("div", { class: "mr-4" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-xs2" }, "MON - FRI")
              ]),
              _createElementVNode("div", { class: "mr-4" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 leading-none" }, "09:30am - 07:30pm")
              ])
            ]),
            _createElementVNode("div", { class: "flex" }, [
              _createElementVNode("div", { class: "mr-4" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-xs2" }, "SAT - SUN")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 leading-none" }, "11:30am - 09:30pm")
              ])
            ])
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(IonIcon), {
            class: "w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full",
            icon: _unref(locationOutline)
          }, null, 8, ["icon"]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col grow pl-2 justify-center" }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none" }, "Email")
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 leading-none" }, "info@bullypoint.com")
            ])
          ], -1)),
          _createVNode(_unref(IonButton), {
            class: "w-[79px] h-[40px]",
            expand: "full",
            size: "large",
            shape: "round",
            fill: "outline"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "flex px-2 items-center justify-center text-sm2 text-white" }, "Send", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(IonIcon), {
            class: "w-[34px] h-[34px] mr-2 p-2 bg-white/20 rounded-full",
            icon: _unref(callOutline)
          }, null, 8, ["icon"]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-col grow pl-2 justify-center" }, [
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-regular text-sm2 font-color-text-50 leading-none" }, "Phone")
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 leading-none" }, "+34 182 49 4829")
            ])
          ], -1)),
          _createVNode(_unref(IonButton), {
            class: "w-[79px] h-[40px]",
            expand: "full",
            size: "large",
            shape: "round",
            fill: "outline"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "flex px-2 items-center justify-center text-sm2 text-white" }, "Call", -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["id"]

import { PropType } from 'vue';
import { timeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';
import { DateTime } from 'luxon';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTableHeader',
  props: {
	timelineDetails: { type: Object as PropType<(DateTime | null)[]>, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.timelineDetails, (date, idx) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
        _createElementVNode("div", {
          id: `day-${date!.day.toString()}`,
          class: "flex justify-center font-sourcesanspro-semibold text-base"
        }, _toDisplayString(date?.hour) + ":" + _toDisplayString(date?.minute.toString().padStart(2, '0')) + "h ", 9, _hoisted_1),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['·', '·', '·'], (dot) => {
          return _createElementVNode("div", {
            key: dot,
            class: "flex justify-center font-sourcesanspro-semibold text-base"
          }, _toDisplayString(dot), 1)
        }), 64))
      ], 64))
    }), 128))
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap w-full border border-solid border-white-15 rounded-full mb-2 px-2 font-sourcesanspro-semibold text-base" }

import { toRefs, PropType } from 'vue';
import { useField, useIsFormValid } from 'vee-validate';
import { IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';

import type { Option } from '@/shared/interfaces/option';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectInput',
  props: {
	label: { type: String },
	name: { type: String },
	value: { type: String },
	values: { type: Array as PropType<Option[]>, required: true },
},
  emits: ['inputChanged'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { value, errorMessage, handleChange, meta } = useField(name, undefined, {
	initialValue: props.value,
});

const isFormValid = useIsFormValid();

const selectItem = (option: string) => {
	handleChange(option);
	// event.preventDefault();
	emit('inputChanged', isFormValid, option);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (props.label)
      ? (_openBlock(), _createBlock(_unref(IonLabel), {
          key: 0,
          class: "font-sourcesanspro-regular text-xs2 text-white/50"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(IonSelect), {
        onIonChange: _cache[0] || (_cache[0] = ($event: any) => (selectItem($event.detail.value))),
        name: _unref(name),
        interface: "popover",
        "aria-label": "select",
        placeholder: "Select",
        value: props.value
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.values, (option, index) => {
            return (_openBlock(), _createBlock(_unref(IonSelectOption), {
              key: index,
              value: option.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(option.label), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["name", "value"])
    ])
  ]))
}
}

})
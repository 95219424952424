import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center px-[34px] pt-14" }
const _hoisted_2 = { class: "flex justify-between w-full mb-8" }
const _hoisted_3 = { class: "flex items-start" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col items-center justify-center mb-6" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_8 = { class: "grid grid-cols-2 gap-2" }
const _hoisted_9 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "font-sourcesanspro-semibold text-m2" }
const _hoisted_12 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "font-sourcesanspro-semibold text-m2 capitalize" }
const _hoisted_15 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "font-sourcesanspro-semibold text-m2" }

import { RouterLink } from 'vue-router';
import { IonContent, IonFooter, IonIcon, IonSpinner } from '@ionic/vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import { ShopCategory } from '@/shared/interfaces/shopCategory';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import useShop from '@/modules/shop/composables/useShop';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ShopPage',
  setup(__props) {

const { t } = useI18n();
const assetStore = useAssetsStore();

const { getCategories } = useShop();
const now = DateTime.now();
const currDate = now.toFormat('EEEE, LLLL dd');
const { shopCategories, isLoadingShopCategories } = getCategories();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(RouterLink), { to: "/shop/history" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: require('@/statics/svg/shop/history-recent.svg'),
                    class: "w-[28px] h-[28px]"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _unref(assetStore).logoUrl,
                class: "max-w-[70%]"
              }, null, 8, _hoisted_4)
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-[28px]" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", _hoisted_6, _toDisplayString(_unref(t)('shop.pages.title.heading')), 1)
          ]),
          (_unref(isLoadingShopCategories))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_unref(IonSpinner), { name: "dots" })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (_unref(shopCategories))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_unref(RouterLink), {
                    to: "/shop/category",
                    class: "flex justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("img", {
                          src: require('@/statics/svg/shop/all.svg'),
                          class: "w-[56px] h-[56px]"
                        }, null, 8, _hoisted_10),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(t)('shop.pages.title.all')), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shopCategories) as ShopCategory[], (category) => {
                    return (_openBlock(), _createBlock(_unref(RouterLink), {
                      key: category.id,
                      to: `/shop/category/${category.id}`,
                      class: "flex justify-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("img", {
                            src: require('@/statics/svg/shop/merchant.svg'),
                            class: "w-[56px] h-[56px]"
                          }, null, 8, _hoisted_13),
                          _createElementVNode("span", _hoisted_14, _toDisplayString(category.name.toLowerCase()), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128)),
                  _createVNode(_unref(RouterLink), {
                    to: "/shop/history",
                    class: "flex justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("img", {
                          src: require('@/statics/svg/shop/bought-history.svg'),
                          class: "w-[56px] h-[56px]"
                        }, null, 8, _hoisted_16),
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('shop.pages.title.bought_history')), 1)
                      ])
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "shop" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
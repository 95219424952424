import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center h-full" }
const _hoisted_2 = { class: "rounded-lg mx-[34px] mb-4 px-6 py-6 modal-body-bg" }
const _hoisted_3 = {
  key: 0,
  class: "mb-2 font-sourcesanspro-semibold text-m2 text-center text-white"
}

import { ref, watch } from 'vue';
import { IonModal } from '@ionic/vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalOne',
  props: {
	isOpen: { type: Boolean, required: true, default: false },
	title: { type: String },
},
  emits: ['onDismiss'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const isOpenRef = ref(props.isOpen);
const emit = __emit;

// Watch changes on the prop
watch(
	() => props.isOpen,
	(newValue) => {
		isOpenRef.value = newValue;
	},
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": isOpenRef.value,
    "backdrop-dismiss": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (props.title)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(props.title), 1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "content")
        ]),
        _renderSlot(_ctx.$slots, "actions")
      ])
    ]),
    _: 3
  }, 8, ["is-open"]))
}
}

})
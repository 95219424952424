import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["validation-schema"]
const _hoisted_2 = { class: "relative mt-6" }
const _hoisted_3 = { class: "flex relative" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = { class: "flex items-center justify-center" }
const _hoisted_6 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "flex items-center justify-center h-[256px]" }
const _hoisted_9 = { class: "absolute flex items-center justify-center w-full h-full bg-black/50" }
const _hoisted_10 = { class: "uppercase font-sourcesanspro-semibold" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 1,
  class: "flex flex-col items-center text-l break-all text-dark-blue/70"
}
const _hoisted_13 = {
  key: 0,
  class: "absolute flex top-[232px] left-0 right-0 items-center justify-center"
}
const _hoisted_14 = { class: "flex px-6 py-4 bg-black rounded-full font-sourcesanspro-semibolditalic text-xs2 text-white/70" }
const _hoisted_15 = { class: "flex mt-10 items-center justify-center mb-1" }
const _hoisted_16 = { class: "flex items-center justify-center font-sourcesanspro-semibold text-dark-blue" }
const _hoisted_17 = { class: "text-lg24" }
const _hoisted_18 = { class: "px-6 text-dark-blue/70" }
const _hoisted_19 = { class: "flex flex-col items-center justify-center mb-2" }
const _hoisted_20 = { class: "font-sourcesanspro-bold text-base" }
const _hoisted_21 = { class: "font-sourcesanspro-semibold text-base2 text-white" }
const _hoisted_22 = { class: "mb-2" }
const _hoisted_23 = { class: "font-sourcesanspro-semibold text-xs2 text-dark-blue" }
const _hoisted_24 = { class: "flex items-center justify-between mb-3" }
const _hoisted_25 = { class: "flex flex-col" }
const _hoisted_26 = { class: "font-sourcesanspro-bold text-base" }
const _hoisted_27 = { class: "font-sourcesanspro-regular text-base2" }
const _hoisted_28 = { class: "flex items-center justify-between mb-3" }
const _hoisted_29 = { class: "flex flex-col" }
const _hoisted_30 = { class: "font-sourcesanspro-bold text-base" }
const _hoisted_31 = { class: "font-sourcesanspro-regular text-base2" }
const _hoisted_32 = {
  key: 0,
  class: "flex items-center justify-between mb-3"
}
const _hoisted_33 = { class: "flex flex-col" }
const _hoisted_34 = { class: "font-sourcesanspro-bold text-base" }
const _hoisted_35 = { class: "font-sourcesanspro-regular text-base2" }

import { ref, toRef, watch } from 'vue';
import router from '@/router';
import { IonButton, IonIcon, IonInput } from '@ionic/vue';
import {
	calendarClearOutline,
	closeOutline,
	createOutline,
	linkOutline,
	locationOutline,
	timeOutline,
} from 'ionicons/icons';
import * as Yup from 'yup';
import QrcodeVue from 'qrcode.vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useCustomForm from '@/shared/composables/useCustomForm';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketDetailItem',
  props: {
	email: { type: String, required: true },
	eventAllowEditTickets: { type: Boolean, required: true },
	eventDate: { type: String, required: true },
	eventId: { type: Number, required: true },
	eventName: { type: String, required: true },
	hideQrCode: { type: Boolean, default: false },
	name: { type: String, required: true },
	qrCode: { type: String, required: true },
	ticketDisabledEdit: { type: Boolean, required: true },
	ticketId: { type: String, required: true },
	ticketTypeColor: { type: String },
	ticketTypeName: { type: String },
	valueOnly: { type: Boolean, required: true, default: false },
	venue: { type: String, required: true },
	walletLinked: { type: Number as () => number | null | undefined },
},
  emits: ['onRefetchTickets'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const hideQrCode = ref(props.hideQrCode);
const hideQrCodeRef = toRef(props, 'hideQrCode');
const { t } = useI18n();
const emit = __emit;
const isModalOpen = ref(false);
const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	name: Yup.string().required(),
	email: Yup.string().required(),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	`/events/edit-ticket/${props.ticketId}`,
	'post',
	'ticket.saved',
	'ticket.saving_error',
	5000,
);

// Refetch ticket
watch(isSuccess, () => {
	if (isSuccess) {
		emit('onRefetchTickets');
		showHideModal(false);
		isSuccess.value = false;
	}
});

const showQrCode = () => {
	hideQrCode.value = false;
};

// Close modal and go to page
const closeModalAndGo = () => {
	showHideModal(false);
	router.push(`/friends/select?type=ticket&id=${props.ticketId}&eventId=${props.eventId}`);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(ModalOne, {
      title: _unref(t)('components.ticket_detail_item.edit_ticket'),
      "is-open": isModalOpen.value
    }, {
      content: _withCtx(() => [
        _createElementVNode("form", {
          id: "changePasswordForm",
          "validation-schema": _unref(schema),
          onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_unref(IonInput), {
                type: "text",
                hidden: "hidden",
                name: "eventTicketId",
                value: props.ticketId
              }, null, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(TextInput, {
                id: "input-field",
                class: "mr-2",
                "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                type: "text",
                name: "name",
                placeholder: "Name",
                value: props.name,
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["value", "onInputChanged"]),
              _createVNode(_unref(IonButton), {
                class: "go-back-button",
                onClick: closeModalAndGo
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: require('@/statics/svg/people-add.svg'),
                    class: "w-[50px] h-[50px] cursor-pointer"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(TextInput, {
                id: "input-field",
                "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                type: "text",
                name: "email",
                placeholder: "Email",
                value: props.email,
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["value", "onInputChanged"])
            ]),
            _createVNode(_unref(IonButton), {
              disabled: !_unref(isFormValid),
              expand: "full",
              shape: "round",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('components.ticket_detail_item.edit_ticket')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ], 40, _hoisted_1)
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_unref(IonIcon), {
              class: "text-[38px] text-black cursor-pointer",
              icon: _unref(closeOutline),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (showHideModal(false)))
            }, null, 8, ["icon"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "is-open"]),
    _createElementVNode("div", {
      id: `ticket-${props.ticketId}`,
      class: "ticket-detail-item w-[300px] h-[620px]"
    }, [
      _createElementVNode("div", _hoisted_8, [
        (hideQrCode.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "relative cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (showQrCode()))
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('components.ticket_detail_item.press_to_show')), 1)
              ]),
              (!props.valueOnly)
                ? (_openBlock(), _createBlock(QrcodeVue, {
                    key: 0,
                    value: props.qrCode,
                    level: 'M',
                    size: 180,
                    background: "#E0E0E0"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (!props.valueOnly)
                ? (_openBlock(), _createBlock(QrcodeVue, {
                    key: 0,
                    value: props.qrCode,
                    level: 'M',
                    size: 180,
                    background: "#E0E0E0"
                  }, null, 8, ["value"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("span", null, [
                      _createElementVNode("b", null, _toDisplayString(_unref(t)('components.ticket_detail_item.code')), 1)
                    ]),
                    _createTextVNode(" " + _toDisplayString(props.qrCode), 1)
                  ]))
            ]))
      ]),
      (props.walletLinked)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(linkOutline),
                class: "mr-2 text-white"
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_unref(t)('components.ticket_detail_item.your_wallet_linked')), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", _hoisted_17, _toDisplayString(props.name), 1),
          (!props.ticketDisabledEdit && props.eventAllowEditTickets)
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 0,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (showHideModal(true))),
                class: "w-[24px] h-[24px] ion-no-margin ion-no-padding",
                fill: "clear"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(createOutline),
                    class: "text-dark-blue"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(t)('components.ticket_detail_item.ticket_type')), 1),
          _createElementVNode("div", {
            style: _normalizeStyle(`background-color: ${props.ticketTypeColor !== undefined && _ctx.$props.ticketTypeColor != '' ? props.ticketTypeColor : '#000000'}`),
            class: "flex h-[37px] px-14 py-2 items-center justify-center rounded-md"
          }, [
            _createElementVNode("span", _hoisted_21, _toDisplayString(props.ticketTypeName), 1)
          ], 4)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("span", _hoisted_23, _toDisplayString(props.eventName), 1)
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(t)('components.ticket_detail_item.date')), 1),
            _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(DateTime).fromFormat(props.eventDate, 'yyyy-MM-dd HH:mm:ss').toFormat(
									'EEEE dd MMM, yyyy',
								)), 1)
          ]),
          _createVNode(_unref(IonIcon), {
            icon: _unref(calendarClearOutline),
            class: "text-dark-blue"
          }, null, 8, ["icon"])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("span", _hoisted_30, _toDisplayString(_unref(t)('components.ticket_detail_item.time')), 1),
            _createElementVNode("span", _hoisted_31, _toDisplayString(_unref(DateTime).fromFormat(props.eventDate, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm a')), 1)
          ]),
          _createVNode(_unref(IonIcon), {
            icon: _unref(timeOutline),
            class: "text-dark-blue"
          }, null, 8, ["icon"])
        ]),
        (props.venue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("span", _hoisted_34, _toDisplayString(_unref(t)('components.ticket_detail_item.venue')), 1),
                _createElementVNode("span", _hoisted_35, _toDisplayString(props.venue), 1)
              ]),
              _createVNode(_unref(IonIcon), {
                icon: _unref(locationOutline),
                class: "text-dark-blue"
              }, null, 8, ["icon"])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 8, _hoisted_7)
  ]))
}
}

})
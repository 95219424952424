import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex p-3 w-[75px] items-center justify-center" }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col text-xxs break-all text-dark-blue/70"
}
const _hoisted_3 = { class: "flex grow flex-col p-3 text-dark-blue" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-base2" }
const _hoisted_5 = { class: "flex justify-between leading-none" }
const _hoisted_6 = { class: "font-sourcesanspro-bold text-xs2 text-dark-blue/70" }
const _hoisted_7 = { class: "font-sourcesanspro-regular text-xs2 text-dark-blue/70" }
const _hoisted_8 = { class: "flex justify-between items-baseline" }
const _hoisted_9 = { class: "font-sourcesanspro-bold text-xs2 text-dark-blue/70" }
const _hoisted_10 = { class: "font-sourcesanspro-regular text-xs2 text-dark-blue/70 text-right leading-none" }

import router from '@/router';
import QrcodeVue from 'qrcode.vue';
import { useI18n } from 'vue-i18n';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketItem',
  props: {
	qrCode: { type: String },
	class: { type: String },
	link: { type: String, required: true, default: '/dashboard' },
	name: { type: String, required: true },
	type: { type: String, required: true },
	valueOnly: { type: Boolean, required: true, default: false },
	venue: { type: String, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex ticket-item w-[325px] h-[100px]", props.class]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(props.link)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!props.valueOnly)
        ? (_openBlock(), _createBlock(QrcodeVue, {
            key: 0,
            value: props.qrCode,
            level: 'M',
            size: 50
          }, null, 8, ["value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("span", null, [
              _createElementVNode("b", null, "CODE")
            ], -1)),
            _createTextVNode(" " + _toDisplayString(props.qrCode), 1)
          ]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(props.name), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('components.ticket_item.type')) + ": ", 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(props.type), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('components.ticket_item.venue')) + ": ", 1),
        _createElementVNode("span", _hoisted_10, _toDisplayString(props.venue), 1)
      ])
    ])
  ], 2))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "relative flex items-left w-[50px] h-[50px]" }
const _hoisted_3 = { class: "absolute top-0 left-0 w-[24px] h-[24px]" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "absolute top-4 left-4 w-[24px] h-[24px]" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "px-[34px]" }
const _hoisted_8 = { class: "fixed button-container px-[34px] bottom-0 w-full" }
const _hoisted_9 = { class: "relative z-10 w-full h-full" }
const _hoisted_10 = { class: "absolute w-full bottom-0 mb-[34px]" }
const _hoisted_11 = { class: "flex flex-wrap justify-left item-center p-2 rounded-full bg-anykrowd-zinc mb-4" }
const _hoisted_12 = { class: "flex w-full items-center px-2" }

import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonIcon, IonInput } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { addCircle, chevronBackOutline } from 'ionicons/icons';
import ChatMessage from '../components/ChatMessage.vue';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'FriendsGroupChatPage',
  setup(__props) {

const isModalOpen = ref(false);

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "mb-6 px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex items-center justify-start cursor-pointer",
            onClick: goBack
          }, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(chevronBackOutline),
              class: "text-white"
            }, null, 8, ["icon"])
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: require('@/statics/svg-delete-after-implement/person-15.svg'),
                  class: "rounded-full"
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: require('@/statics/svg-delete-after-implement/person-16.svg'),
                  class: "rounded-full"
                }, null, 8, _hoisted_6)
              ])
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col grow" }, [
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "BullPuppy"),
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-sm text-anykrowd-online-dot" }, "Online")
            ], -1))
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(ChatMessage, {
          type: "received",
          "sender-avatar": require('@/statics/svg-delete-after-implement/person-15.svg'),
          "sender-status-class": "bg-anykrowd-online-dot",
          time: "08:22 am"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Hello, how can I help you")
          ])),
          _: 1
        }, 8, ["sender-avatar"]),
        _createVNode(ChatMessage, { type: "sent" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Oh, Hello Angela Young")
          ])),
          _: 1
        }),
        _createVNode(ChatMessage, {
          type: "sent",
          time: "09:24 am"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("I feeling better than before. Should I need a masssage?")
          ])),
          _: 1
        }),
        _createVNode(ChatMessage, {
          type: "received",
          "sender-avatar": require('@/statics/svg-delete-after-implement/person-15.svg'),
          "sender-status-class": "bg-anykrowd-online-dot",
          time: "09:26 am"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("I will check-in this evening at 7:30 pm. It is ok for you?")
          ])),
          _: 1
        }, 8, ["sender-avatar"]),
        _createVNode(ChatMessage, {
          type: "sent",
          time: "09:24 am",
          "read-by-avatars": [require('@/statics/svg-delete-after-implement/person-15.svg')]
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Yeah sure i will be there at 7 pm with my brother ")
          ])),
          _: 1
        }, 8, ["read-by-avatars"]),
        _createVNode(ChatMessage, {
          type: "received",
          "sender-avatar": require('@/statics/svg-delete-after-implement/person-16.svg'),
          "sender-status-class": "bg-anykrowd-online-dot",
          time: "09:26 am",
          "read-by-avatars": [
					require('@/statics/svg-delete-after-implement/person-16.svg'),
					require('@/statics/svg-delete-after-implement/person-15.svg'),
				]
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("Okay. ")
          ])),
          _: 1
        }, 8, ["sender-avatar", "read-by-avatars"]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_unref(IonIcon), {
                  class: "w-[41px] h-[41px] mr-2 text-[#C8C8C8] cursor-pointer",
                  icon: _unref(addCircle),
                  slot: "start"
                }, null, 8, ["icon"]),
                _createVNode(_unref(IonInput), {
                  class: "font-sourcesanspro-regular text-sm2 text-dark-blue",
                  name: "qr-code",
                  type: "text",
                  placeholder: "Write a message"
                }),
                _createVNode(_unref(IonIcon), {
                  class: "text-dark-blue cursor-pointer",
                  src: require('@/statics/svg/friends/send.svg'),
                  slot: "end"
                }, null, 8, ["src"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
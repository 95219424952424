import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_2 = { class: "px-[34px]" }
const _hoisted_3 = {
  key: 0,
  class: "flex p-4 items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "flex p-4 items-center justify-center font-sourcesanspro-regular"
}

import { onMounted, ref, watchEffect } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';
import TopBar from '@/shared/components/TopBar.vue';
import TransactionOverview from '../components/TransactionOverview.vue';
import type { Order, WalletTransaction } from '@/shared/interfaces/wallet';
import useTenant from '@/shared/composables/useTenant';
import useWallet from '@/modules/wallet/composables/useWallet';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'WalletHistoryPage',
  setup(__props) {

const route = useRoute();
const walletId = route.params.wallet_id?.toString();
const encryptedRfidTag = route.query.id?.toString();
const { getWalletTransactions, getTransactionItemName } = useWallet();
const { t, te } = useI18n();
const mappedWalletTransactions = ref<any>([]);
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { refetchGetWalletTransactions, removeGetWalletTransactionsResultsFromCache, walletTransactions } =
	getWalletTransactions(encryptedRfidTag, walletId);
const { groupAndSumTransactionsByCurrency } = useUtils();

// Refresh
const handleRefresh = async (event: any) => {
	removeGetWalletTransactionsResultsFromCache();
	mappedWalletTransactions.value = [];
	await refetchGetWalletTransactions();
	event.target.complete();
};

// Watch for walletTransactions then map the result
watchEffect(() => {
	if (
		walletTransactions.value &&
		walletTransactions.value &&
		walletTransactions.value.data &&
		walletTransactions.value.data.length
	) {
		mappedWalletTransactions.value = walletTransactions.value.data.map((walletTransaction: WalletTransaction) => {
			return {
				id: walletTransaction.id,
				title: walletTransaction.type ? walletTransaction.type.replaceAll('_', ' ') : '',
				type: walletTransaction.type,
				subtitle: '',
				iconClass: 'text-white',
				price: walletTransaction.order_price ? +walletTransaction.order_price : 0,
				currency: walletTransaction.currency,
				date: walletTransaction.created_at,
				transactionType: walletTransaction.direction,
				transactionsPerCurrency: groupAndSumTransactionsByCurrency(
					walletTransaction.overview?.transactions || [],
				),
				first_virtual_wallet_transaction: walletTransaction.first_virtual_wallet_transaction,
			};
		});
	}
});

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonRefresher), {
        class: "mt-8",
        slot: "fixed",
        onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRefresherContent))
        ]),
        _: 1
      }),
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex items-center justify-start cursor-pointer",
            onClick: goBack
          }, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(chevronBackOutline),
              class: "text-white"
            }, null, 8, ["icon"])
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('wallet.history')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(AccordionList, {
          title: "All transactions",
          class: "mb-4"
        }, {
          items: _withCtx(() => [
            (!mappedWalletTransactions.value.length && mappedWalletTransactions.value.length !== 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_unref(IonSpinner), { name: "dots" })
                ]))
              : _createCommentVNode("", true),
            (!mappedWalletTransactions.value.length && mappedWalletTransactions.value.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(t)('wallet.history_no_transactions')), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mappedWalletTransactions.value, (item, idx) => {
              return (_openBlock(), _createBlock(AccordionListItem, {
                key: idx,
                "key-value": item.id,
                icon: require('@/statics/svg/arrows-left-right.svg'),
                iconClass: item.iconClass,
                subtitle: item.subtitle,
                title: _unref(te)(item.title) ? _unref(t)(item.title) : item.title,
                "transaction-type": item.transactionType
              }, {
                actions: _withCtx(() => [
                  (_unref(tenantConfig))
                    ? (_openBlock(), _createBlock(AccordionListItemAction, {
                        key: item.id,
                        date: _unref(DateTime).fromISO(item.date).toFormat('dd LLLL yyyy HH:mm:ss'),
                        price: item.price,
                        "price-detail": item.transactionsPerCurrency,
                        currency: item.currency,
                        "transaction-type": item.transactionType,
                        "payment-type": item.type,
                        "tenant-config": _unref(tenantConfig)
                      }, null, 8, ["date", "price", "price-detail", "currency", "transaction-type", "payment-type", "tenant-config"]))
                    : _createCommentVNode("", true)
                ]),
                content: _withCtx((slotProps) => [
                  (_unref(tenantConfig) && item.type !== 'wallet_merge')
                    ? (_openBlock(), _createBlock(TransactionOverview, {
                        key: item.id,
                        "transaction-id": 
									item.first_virtual_wallet_transaction
										? item.first_virtual_wallet_transaction?.id
										: item.id
								,
                        encryptedRfidTag: _unref(encryptedRfidTag),
                        "should-load": slotProps.shouldLoad,
                        "tenant-config": _unref(tenantConfig)
                      }, null, 8, ["transaction-id", "encryptedRfidTag", "should-load", "tenant-config"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["key-value", "icon", "iconClass", "subtitle", "title", "transaction-type"]))
            }), 128))
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})
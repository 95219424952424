import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/statics/svg-delete-after-implement/qr-code.svg'


const _hoisted_1 = { class: "flex grow flex-col p-3 text-dark-blue" }
const _hoisted_2 = { class: "font-sourcesanspro-semibold text-base2" }
const _hoisted_3 = { class: "flex justify-between leading-none" }
const _hoisted_4 = { class: "font-sourcesanspro-regular text-xs2 text-dark-blue/70" }
const _hoisted_5 = { class: "flex items-baseline" }
const _hoisted_6 = { class: "font-sourcesanspro-regular text-xs2 text-dark-blue/70 text-right leading-none" }

import router from '@/router';

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketItem',
  props: {
	class: { type: String },
	link: { type: String, required: true, default: '/dashboard' },
	name: { type: String, required: true },
	type: { type: String, required: true },
	venue: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex ticket-item w-[325px] h-[100px]", props.class]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(props.link)))
  }, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex p-3 w-[75px] items-center justify-center" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "w-[50px] h-[50px]"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(props.name), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-sourcesanspro-bold text-xs2 text-dark-blue/70" }, "TYPE: ", -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(props.type), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-sourcesanspro-bold text-xs2 text-dark-blue/70" }, "VENUE: ", -1)),
        _createElementVNode("span", _hoisted_6, _toDisplayString(props.venue), 1)
      ])
    ])
  ], 2))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/statics/svg/subtract-2.svg'
import _imports_1 from '@/statics/svg/add-2.svg'


const _hoisted_1 = { class: "fixed button-container h-[150px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full" }
const _hoisted_2 = { class: "relative z-10 w-full h-full" }
const _hoisted_3 = { class: "absolute w-full bottom-0 mb-[34px]" }
const _hoisted_4 = { class: "flex w-full justify-between items-center btn-container bg-white text-dark font-sourcesanspro-semibold text-l2 px-5 rounded" }
const _hoisted_5 = { class: "flex items-center justify-between w-[100px]" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-lg" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { ShopProduct } from '@/shared/interfaces/shopProduct';
import { useCart } from '@/modules/shop/composables/useCart';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductQuantityBottomBar',
  props: {
	product: {
		type: Object as () => ShopProduct,
		required: true,
	},
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const { getProductCount, addToCart, decreaseProductQuantity } = useCart();

// Compute the product count based on the current product ID
const productCartCount = computed(() => getProductCount(props.product.id));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_unref(t)('shop.cart.add_to_cart')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "custom-button",
              id: "custom-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(decreaseProductQuantity)(__props.product.id)))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "stroke-black fill-back"
              }, null, -1)
            ])),
            _createElementVNode("span", _hoisted_6, _toDisplayString(productCartCount.value), 1),
            _createElementVNode("button", {
              class: "custom-button",
              id: "custom-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(addToCart)(__props.product, _unref(CartCategoriesEnum).shop)))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_1,
                class: "stroke-black fill-back"
              }, null, -1)
            ]))
          ])
        ])
      ])
    ])
  ]))
}
}

})
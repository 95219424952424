import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex p-4 items-center justify-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "p-4 bg-white/10" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "font-sourcesanspro-semibold text-l2 uppercase" }
const _hoisted_6 = { class: "font-sourcesanspro-regular text-xs uppercase font-color-text-50" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = { class: "font-sourcesanspro-regular text-xs uppercase font-color-text-50" }
const _hoisted_9 = { class: "font-sourcesanspro-semibold text-sm2" }
const _hoisted_10 = { class: "font-sourcesanspro-regular text-sm font-color-text-50" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_13 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_14 = { class: "font-sourcesanspro-regular text-sm font-color-text-50" }
const _hoisted_15 = {
  key: 0,
  class: "font-sourcesanspro-semibold text-sm2"
}
const _hoisted_16 = {
  key: 0,
  class: "ml-5"
}
const _hoisted_17 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_18 = { class: "font-sourcesanspro-regular text-sm font-color-text-50" }
const _hoisted_19 = {
  key: 0,
  class: "font-sourcesanspro-semibold text-sm2"
}
const _hoisted_20 = {
  key: 0,
  class: "value tx-value"
}
const _hoisted_21 = { key: 2 }
const _hoisted_22 = { class: "font-sourcesanspro-regular text-sm font-color-text-50" }
const _hoisted_23 = { class: "p-4 bg-white/10" }
const _hoisted_24 = { class: "flex justify-between" }
const _hoisted_25 = { class: "font-sourcesanspro-regular text-sm2 font-color-text-50" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "font-sourcesanspro-semibold text-sm2" }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  key: 0,
  class: "font-sourcesanspro-semibold text-sm2"
}
const _hoisted_30 = {
  key: 1,
  class: "font-sourcesanspro-semibold text-sm2"
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = {
  key: 0,
  class: "font-sourcesanspro-regular text-sm2 font-color-text-50"
}
const _hoisted_35 = {
  key: 1,
  class: "font-sourcesanspro-regular text-sm2 font-color-text-50"
}
const _hoisted_36 = {
  key: 2,
  class: "font-sourcesanspro-regular text-sm2 font-color-text-50"
}
const _hoisted_37 = {
  key: 3,
  class: "font-sourcesanspro-regular text-sm2 font-color-text-50"
}
const _hoisted_38 = {
  key: 4,
  class: "font-sourcesanspro-regular text-sm2 font-color-text-50"
}
const _hoisted_39 = {
  key: 5,
  class: "font-sourcesanspro-semibold text-sm2 text-nowrap"
}
const _hoisted_40 = { key: 6 }
const _hoisted_41 = { class: "font-sourcesanspro-semibold text-sm2 text-nowrap" }
const _hoisted_42 = { key: 1 }
const _hoisted_43 = { class: "flex justify-between" }
const _hoisted_44 = { class: "font-sourcesanspro-regular text-sm2 font-color-text-50" }
const _hoisted_45 = { class: "font-sourcesanspro-semibold text-sm2" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { class: "flex justify-between" }
const _hoisted_49 = { class: "font-sourcesanspro-regular text-sm2 font-color-text-50" }
const _hoisted_50 = { class: "font-sourcesanspro-semibold text-sm2" }
const _hoisted_51 = { key: 2 }
const _hoisted_52 = { class: "font-sourcesanspro-regular text-sm2 font-color-text-50" }
const _hoisted_53 = { class: "flex justify-between" }
const _hoisted_54 = { class: "font-sourcesanspro-regular text-sm2 font-color-text-50" }
const _hoisted_55 = { class: "font-sourcesanspro-semibold text-sm2 capitalize" }

import { PropType, watch } from 'vue';
import { IonButton, IonIcon, IonSpinner, loadingController } from '@ionic/vue';
import { downloadOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import type { DownloadModalProps } from '@/shared/interfaces/download';
import { DownloadType } from '@/shared/interfaces/download';
import type { TenantConfig } from '@/shared/interfaces/tenant';
import useDownload from '@/shared/composables/useDownload';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useCurrencies from '@/shared/composables/useCurrencies';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionOverview',
  props: {
	shouldLoad: { type: Boolean, required: true },
	transactionId: { type: String, required: true },
	encryptedRfidTag: { type: String },
	tenantConfig: { type: Object as PropType<TenantConfig>, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { convertValutaSync } = useConvertValuta();
const { t } = useI18n();
const { getTransactionDownloadLink, getWalletTransaction } = useWallet();
const { isLoading, shouldLoad, walletTransaction } = getWalletTransaction(props.transactionId, props.encryptedRfidTag);
const { convertToCurrency, groupAndSumTransactionsByCurrency } = useUtils();
const { presentDownloadAvailableModal } = useDownload();
const { fiatCurrency } = useCurrencies();

const onDownload = async () => {
	const loader = await loadingController.create({
		message: t('general.please_wait'),
	});
	await loader.present();

	const response = await getTransactionDownloadLink(walletTransaction.value!.transaction.id);

	const data: DownloadModalProps = {
		downloadItem: {
			href: response.download_link,
			linkName: `Receipt ${walletTransaction.value!.transaction.reference}`,
		},
		title: t('download_modal.title', { file_name: `Receipt ${walletTransaction.value!.transaction.reference}` }),
		downloadType: DownloadType.INVOICE,
	};

	await presentDownloadAvailableModal(data);
	await loader.dismiss();
};

// Helper method to calculate the paid price for a 'top up cost' transaction
const paidTransactionCostPrice = (): number | string => {
	try {
		if (walletTransaction.value!.transaction.price_excl && walletTransaction.value!.transaction.price_vat) {
			return +walletTransaction.value!.transaction.price_excl + +walletTransaction.value!.transaction.price_vat;
		} else {
			return walletTransaction.value!.transaction.amount;
		}
	} catch (e) {
		return walletTransaction.value!.transaction.amount;
	}
};

const isCurrencyPrice = (transactionOverviewItem: any): boolean => {
	return transactionOverviewItem.is_currency_price && transactionOverviewItem.is_currency_price === true;
};

const paymentMethodTranslationKey = (): string => {
	let key = 'undefined';
	const translationKey = 'components.transaction_detail.';

	switch (walletTransaction.value!.transaction.order?.payment_method) {
		case 'ideal':
			key = 'payment_method_ideal';
			break;
		case 'paypal':
			key = 'payment_method_paypal';
			break;
		case 'creditcard':
			key = 'payment_method_credit_card';
			break;
		case 'applepay':
			key = 'payment_method_applepay';
			break;
		case 'bancontact':
			key = 'payment_method_bancontact';
			break;
		case 'voucher':
			key = 'payment_method_voucher';
			break;
		case 'wire_transfer':
			key = 'payment_method_wire_transfer';
			break;
		case 'virtual_wallet_transaction':
			key = 'payment_method_virtual_wallet';
			break;
		case 'virtual_wallet_transaction_order_only':
			key = 'payment_method_virtual_wallet_order_only';
			break;
		case 'virtual_wallet_transaction_free_offer':
			key = 'payment_method_virtual_wallet_free_offer';
			break;
		case 'virtual_wallet_transaction_free_staff':
			key = 'payment_method_virtual_wallet_free_staff';
			break;
		case 'virtual_wallet_transaction_cash_payment':
			key = 'payment_method_virtual_wallet_cash_payment';
			break;
		case 'virtual_wallet_transaction_card_payment':
			key = 'payment_method_virtual_wallet_card_payment';
			break;
	}

	return translationKey + key;
};

// Watch props and check if shouldLoad should change the state of the query
// and load data
watch(props, () => {
	if (props.shouldLoad) {
		shouldLoad.value = props.shouldLoad;
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(IonSpinner), { name: "dots" })
        ]))
      : _createCommentVNode("", true),
    (_unref(walletTransaction) && _unref(walletTransaction).overview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(walletTransaction)?.overview.type !== 'topup'
							? _unref(t)('transaction_overview.receipt')
							: _unref(t)('transaction_overview.proof_of_transaction')), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('components.transaction.pos_id')) + " " + _toDisplayString(_unref(walletTransaction)?.transaction.scanned_by_id || _unref(t)('components.transaction.none')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('components.transaction.id')) + " " + _toDisplayString(_unref(walletTransaction)?.transaction.order?.nanoid || _unref(t)('components.transaction.none')), 1)
            ]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(walletTransaction)?.overview.invoicing.company), 1),
            _createElementVNode("div", _hoisted_10, [
              (_unref(walletTransaction)?.overview.invoicing.vat_id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(t)('components.transaction.vat')) + ": " + _toDisplayString(_unref(walletTransaction)?.overview.invoicing.vat_id), 1))
                : _createCommentVNode("", true),
              (
						_unref(walletTransaction)?.overview.invoicing.address.street &&
						_unref(walletTransaction)?.overview.invoicing.address.number &&
						_unref(walletTransaction)?.overview.invoicing.address.postal_code &&
						_unref(walletTransaction)?.overview.invoicing.address.city
					)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(walletTransaction)?.overview.invoicing.address.street) + " " + _toDisplayString(_unref(walletTransaction)?.overview.invoicing.address.number) + ", " + _toDisplayString(_unref(walletTransaction)?.overview.invoicing.address.postal_code) + " " + _toDisplayString(_unref(walletTransaction)?.overview.invoicing.address.city), 1))
                : _createCommentVNode("", true)
            ]),
            (_unref(walletTransaction)?.transaction.type !== 'top_up_cost')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(walletTransaction)?.overview.items, (transactionOverviewItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex justify-between"
                    }, [
                      _createElementVNode("div", _hoisted_14, _toDisplayString(transactionOverviewItem.quantity) + "x " + _toDisplayString(transactionOverviewItem.label), 1),
                      (transactionOverviewItem.price !== null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("span", null, _toDisplayString(isCurrencyPrice(transactionOverviewItem)
									? transactionOverviewItem.currency?.prefix
									: _unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(transactionOverviewItem.quantity * +transactionOverviewItem.price)), 1),
                            (transactionOverviewItem.vat_percent)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(transactionOverviewItem.vat_percent) + "% ", 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_unref(walletTransaction)?.transaction.type === 'top_up_cost')
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(walletTransaction)?.overview.items, (transactionOverviewItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex justify-between"
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createTextVNode(" 1x " + _toDisplayString(_unref(t)('components.transaction.type.top_up_cost')) + " ", 1),
                        _createElementVNode("span", null, " (" + _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+_unref(walletTransaction)?.transaction?.amount)) + ") ", 1)
                      ]),
                      (transactionOverviewItem.price !== null)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            (_unref(walletTransaction)?.transaction?.vat_percentage)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(walletTransaction)?.transaction.vat_percentage) + "% ", 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_unref(walletTransaction)?.overview && _unref(walletTransaction)?.overview.vat_items.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(walletTransaction)?.overview.vat_items, (transactionVatOverviewItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex justify-between"
                    }, [
                      _createElementVNode("div", _hoisted_22, [
                        _createTextVNode(_toDisplayString(_unref(t)('components.transaction.vat')) + " " + _toDisplayString(transactionVatOverviewItem.label) + " ", 1),
                        _createElementVNode("span", null, " (" + _toDisplayString(transactionVatOverviewItem?.currency?.prefix ?? _unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(transactionVatOverviewItem.price)) + ") ", 1)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "h-px bg-white/20 border-0" }, null, -1)),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(t)('components.transaction_detail.total_label')), 1),
              (_unref(walletTransaction)?.transaction.type === 'top_up')
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+_unref(walletTransaction)?.overview.price)), 1)
                  ]))
                : _createCommentVNode("", true),
              (_unref(walletTransaction)?.transaction.type !== 'top_up')
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    (_unref(walletTransaction)?.transaction.type === 'top_up_cost')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+_unref(walletTransaction)?.transaction.amount)), 1))
                      : _createCommentVNode("", true),
                    (_unref(walletTransaction)?.transaction.type !== 'top_up_cost')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                          (_unref(walletTransaction)?.transaction?.order)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+_unref(walletTransaction)?.transaction?.order?.price)), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_unref(walletTransaction)?.transaction?.currency?.prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+_unref(walletTransaction)?.transaction.amount)), 1))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_unref(walletTransaction)?.transaction.type !== 'top_up_cost')
              ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupAndSumTransactionsByCurrency)(
						_unref(walletTransaction)?.overview.transactions,
					), (transactionGroup, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex justify-between"
                    }, [
                      (transactionGroup.type === 'payment')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_unref(t)('components.transaction_detail.transaction_types.payment')), 1))
                        : _createCommentVNode("", true),
                      (transactionGroup.type === 'refund_transaction_cost')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_unref(t)('components.transaction_detail.transaction_types.refund_transaction_cost')), 1))
                        : _createCommentVNode("", true),
                      (transactionGroup.type === 'top_up')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(transactionGroup.reference), 1))
                        : _createCommentVNode("", true),
                      (transactionGroup.type === 'refunded_amount')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_unref(t)('components.transaction_detail.transaction_types.refund')), 1))
                        : _createCommentVNode("", true),
                      (transactionGroup.type === 'top_up_cost')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_unref(t)('components.transaction_detail.transaction_types.top_up_cost')), 1))
                        : _createCommentVNode("", true),
                      (transactionGroup.type !== 'top_up_cost')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                            _createTextVNode(_toDisplayString(transactionGroup.direction === 'CREDIT' ? '+' : '-') + " ", 1),
                            _createElementVNode("span", null, _toDisplayString(transactionGroup?.prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+transactionGroup?.totalAmount)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (transactionGroup.type === 'top_up_cost')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                            _createElementVNode("div", _hoisted_41, [
                              _cache[0] || (_cache[0] = _createTextVNode(" - ")),
                              _createElementVNode("span", null, _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+transactionGroup.totalAmount)), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("span", _hoisted_44, _toDisplayString(_unref(t)('components.transaction_detail.transaction_types.top_up_cost')), 1),
                    _createElementVNode("div", _hoisted_45, [
                      _cache[1] || (_cache[1] = _createTextVNode(" - ")),
                      (_unref(walletTransaction).transaction.type === 'top_up_cost')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+paidTransactionCostPrice())), 1))
                        : _createCommentVNode("", true),
                      (_unref(walletTransaction).transaction.type !== 'top_up_cost')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(__props.tenantConfig.custom_valuta.prefix) + " " + _toDisplayString(_unref(convertValutaSync)(__props.tenantConfig, paidTransactionCostPrice(), true)), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])),
            _createElementVNode("div", _hoisted_48, [
              _createElementVNode("span", _hoisted_49, _toDisplayString(_unref(t)('components.transaction_detail.payment_method')), 1),
              _createElementVNode("span", _hoisted_50, _toDisplayString(_unref(t)(paymentMethodTranslationKey())), 1)
            ]),
            (_unref(walletTransaction)?.overview?.payment_method === 'wire_transfer')
              ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                  _createElementVNode("span", _hoisted_52, _toDisplayString(_unref(t)('components.transaction_detail.refund_wire_transfer_period')), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("span", _hoisted_54, _toDisplayString(_unref(t)('components.transaction_detail.payment_status')), 1),
              _createElementVNode("span", _hoisted_55, _toDisplayString(_unref(walletTransaction)?.overview.status), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-start cursor-pointer"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "flex justify-center w-full font-sourcesanspro-semibold text-l2"
}
const _hoisted_4 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col px-[34px]"
}
const _hoisted_6 = { class: "mb-[100px]" }

import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TimeTable from '@/modules/timetables/components/timetable/TimeTable.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import useUserProfile from '@/modules/profile/composables/useUserProfile';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTablesDetailPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const timeTableId = +route.params.id;
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();

const { getTimeTable, getTimeTables } = useTimeTables();
const { timeTables } = getTimeTables();
const { isFetchedTimeTable, refetchTimeTable, removeTimeTableDataFromCache, timeTable } = getTimeTable(
	timeTableId,
	userProfile.value?.id,
);

// Refresh
const handleRefresh = async (event: any) => {
	removeTimeTableDataFromCache();
	timeTable.value = undefined;
	await refetchTimeTable();
	event.target.complete();
};

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), { class: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonRefresher), {
          class: "mt-8",
          slot: "fixed",
          onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRefresherContent))
          ]),
          _: 1
        }),
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            (_unref(timeTables) && _unref(timeTables).length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_router_link, {
                    to: "/timetables",
                    class: "flex items-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(chevronBackOutline),
                        class: "text-white"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_2))
          ]),
          center: _withCtx(() => [
            (_unref(timeTable))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(timeTable).name), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (!_unref(isFetchedTimeTable))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (_unref(timeTable))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(TimeTable, { timeTable: _unref(timeTable) }, null, 8, ["timeTable"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "timetables" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "px-[34px] w-full" }
const _hoisted_3 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_4 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_5 = { class: "flex flex-col items-center" }
const _hoisted_6 = { class: "mb-2 font-sourcesanspro-regular text-regular text-white/60" }
const _hoisted_7 = {
  key: 0,
  class: "flex mx-[34px] p-4 mb-2 bg-white/10 rounded-lg"
}
const _hoisted_8 = { class: "flex w-full items-center justify-center" }
const _hoisted_9 = { class: "max-w-[220px] mb-[34px] font-sourcesanspro-regular text-sm2 text-center text-white/60" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = {
  key: 0,
  class: "flex items-center justify-left p-4"
}
const _hoisted_12 = { class: "font-sourcesanspro-light text-regular text-white/40" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 0,
  class: "p-4 bg-white/10 font-sourcesanspro-regular text-sm"
}
const _hoisted_15 = {
  key: 1,
  class: "p-4 bg-white/10 font-sourcesanspro-regular text-sm"
}
const _hoisted_16 = {
  key: 2,
  class: "flex p-4 items-center justify-center"
}

import { ref } from 'vue';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
	InfiniteScrollCustomEvent,
} from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import { RefundRequestResource } from '@/shared/interfaces/refundRequest';
import { RefundRequestStatus } from '@/shared/interfaces/refundRequest';
import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { useRoute } from 'vue-router';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'WalletRefundsPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const walletId = route.params.wallet_id?.toString();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { cancelRefund, getRefundRequests, getRefundInfo } = useWallet();
const {
	isFetchingWalletRefundRequests,
	isLoadingRefundRequests,
	refetchWalletRefundRequests,
	removeWalletRefundRequestsFromCache,
	walletRefundRequests,
	fetchNextPage,
	hasNextPage,
} = getRefundRequests(walletId);
const { refundInfo } = getRefundInfo(walletId);
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const cancelRefundRequested = ref(false);

// Refresh
const handleRefresh = async (event: any) => {
	removeWalletRefundRequestsFromCache();
	walletRefundRequests.value = [];
	await refetchWalletRefundRequests();
	event.target.complete();
};

// Infinite scroll
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
	if (hasNextPage && hasNextPage.value) {
		await fetchNextPage();
	}
	ev.target.complete();
};

const requestCancelRefund = (refundId: string | number) => {
	cancelRefund(refundId).then(() => {
		cancelRefundRequested.value = true;
		refetchWalletRefundRequests();
	});
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonRefresher), {
        class: "mt-8",
        slot: "fixed",
        onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRefresherContent))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: "/dashboard",
                  class: "flex items-center justify-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('wallet.refund.title')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_unref(refundInfo) && _unref(refundInfo).refund_message)
                ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_7, null, 512)), [
                    [_directive_dompurify_html, _unref(refundInfo).refund_message]
                  ])
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_router_link, {
              to: `/wallet/refunds/${_unref(walletId)}/request`,
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  class: "w-full mb-3 hover:bg-white hover:rounded-full",
                  expand: "full",
                  size: "large",
                  shape: "round"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('wallet.refund.new')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('wallet.refund.top_warning_message', { days: 14 })), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(AccordionList, {
              title: _unref(t)('wallet.refund.requests')
            }, {
              items: _withCtx(() => [
                (
									!_unref(isLoadingRefundRequests) &&
									_unref(walletRefundRequests) &&
									_unref(walletRefundRequests).length === 0
								)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('wallet.refund.no_entries')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(walletRefundRequests))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(walletRefundRequests).map(
										(refund, index) => {
											return {
												date: refund.created_at,
												id: index.toString(),
												refundId: refund.id,
												title: _unref(t)('wallet.refund.item_title', { status: refund.status }),
												iconClass: 'text-white',
												info: refund.info,
												price: refund.fiat_amount,
												customSubtitle: refund.status,
												tag: refund.status,
												transactionType:
													refund.status !== _unref(RefundRequestStatus).FAILED ? 'CREDIT' : 'DEBIT',
											};
										},
									), (item, idx) => {
                        return (_openBlock(), _createBlock(AccordionListItem, {
                          key: idx,
                          "key-value": item.id,
                          icon: require('@/statics/svg/profile/refund.svg'),
                          iconClass: item.iconClass,
                          tag: item.tag,
                          "transaction-type": item.transactionType,
                          title: item.title
                        }, {
                          actions: _withCtx(() => [
                            (_unref(tenantConfig))
                              ? (_openBlock(), _createBlock(AccordionListItemAction, {
                                  key: 0,
                                  date: _unref(DateTime).fromISO(item.date).toFormat('dd LLLL yyyy • HH:mm'),
                                  "hide-transaction-sign": true,
                                  price: +item.price,
                                  "transaction-type": item.transactionType,
                                  "tenant-config": _unref(tenantConfig)
                                }, null, 8, ["date", "price", "transaction-type", "tenant-config"]))
                              : _createCommentVNode("", true)
                          ]),
                          content: _withCtx(() => [
                            (item.info)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(item.info), 1))
                              : _createCommentVNode("", true),
                            (item.customSubtitle === 'pending' && item.refundId != null)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                  _createVNode(_unref(IonButton), {
                                    disabled: cancelRefundRequested.value,
                                    onClick: ($event: any) => (requestCancelRefund(item.refundId!)),
                                    color: "primary",
                                    form: "signInWithEmailForm",
                                    shape: "round",
                                    size: "large",
                                    type: "submit"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('wallet.refund.cancel')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["disabled", "onClick"])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["key-value", "icon", "iconClass", "tag", "transaction-type", "title"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_unref(isLoadingRefundRequests) || _unref(isFetchingWalletRefundRequests))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_unref(IonSpinner), { name: "dots" })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ])
      ]),
      _createVNode(_unref(IonInfiniteScroll), { onIonInfinite: ionInfinite }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonInfiniteScrollContent))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
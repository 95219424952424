import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer" }
const _hoisted_3 = { class: "flex items-center justify-between mb-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex justify-between mb-2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  id: "popover-button",
  class: "flex items-center justify-center rounded-lg font-sourcesanspro-bold text-anykrowd-dark text-md cursor-pointer"
}
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "flex flex-col px-3 py-2 font-sourcesanspro-regular" }
const _hoisted_12 = { class: "mb-1" }
const _hoisted_13 = {
  key: 2,
  class: "w-full flex justify-center font-sourcesanspro-bold text-anykrowd-dark text-md pt-4"
}
const _hoisted_14 = { class: "flex items-center mb-4" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = {
  key: 0,
  class: "font-sourcesanspro-black text-l2 font-color-dark leading-none"
}
const _hoisted_17 = {
  key: 1,
  class: "font-sourcesanspro-regular text-base font-color-dark leading-none break-words"
}
const _hoisted_18 = { class: "flex items-center justify-between" }
const _hoisted_19 = { class: "flex" }
const _hoisted_20 = {
  key: 1,
  class: "flex items-baseline"
}
const _hoisted_21 = { class: "mr-1" }
const _hoisted_22 = { class: "font-sourcesanspro-black text-lg3 font-color-dark" }
const _hoisted_23 = { class: "font-sourcesanspro-regular text-md font-color-dark" }
const _hoisted_24 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_25 = { class: "mr-2" }
const _hoisted_26 = {
  key: 0,
  class: "flex flex-col font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm mb-6 mt-4"
}
const _hoisted_27 = { class: "flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer" }

import { PropType, ref, watchEffect } from 'vue';
import { IonContent, IonButton, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { addOutline, chevronBackOutline, chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { UserProfile } from '@/shared/interfaces/user';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useTenant from '@/shared/composables/useTenant';
import { captureMessage } from '@/sentry';
import { Wallet } from '@/shared/interfaces/balance';


export default /*@__PURE__*/_defineComponent({
  __name: 'GuestWalletBox',
  props: {
	userProfile: { type: Object as PropType<UserProfile | null> },
	isSuccessProfile: { type: Boolean, default: false },
	loginToken: { type: String, default: '' },
},
  setup(__props) {

const props = __props;

const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const selectedWallet = ref<Wallet | undefined>();

watchEffect(() => {
	const wallets = props.userProfile?.wallets;

	if (wallets && wallets.length && wallets.length > 0) {
		if (!selectedWallet.value) {
			selectedWallet.value = wallets.find((wallet) => {
				return wallet.currency.default;
			});

			if (!selectedWallet.value && wallets.length > 0) {
				selectedWallet.value = wallets[0];
			}
		}
	}
});

const selectWallet = (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
};

const getWalletAtIndex = (index: number) => {
	return props.userProfile?.wallets[index];
};

const currentWalletIndex = () => {
	const index = props.userProfile?.wallets.findIndex((wallet) => wallet === selectedWallet.value);
	if (index === -1) {
		// dirty as f*** but it works
		return -100000;
	}
	return index ?? -100000;
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!!getPreviousWallet())
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: _unref(chevronBackOutline),
              class: "text-white active:bg-white/30 active:rounded-full active:p-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (selectPreviousWallet()))
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["flex w-full flex-col justify-between px-3 bg-white rounded-xl mb-2", props.userProfile ? 'py-3' : 'pt-3'])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (props.userProfile)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require('@/statics/svg/rfid/bracelet-with-bg.svg'),
                height: "34",
                width: "34"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (props.userProfile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
                  (selectedWallet.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(selectedWallet.value.currency.prefix), 1),
                          _createElementVNode("div", _hoisted_10, _toDisplayString(selectedWallet.value?.currency.name), 1),
                          (__props.userProfile?.wallets && __props.userProfile?.wallets?.length > 1)
                            ? (_openBlock(), _createBlock(_unref(IonIcon), {
                                key: 0,
                                icon: _unref(chevronDownOutline),
                                class: "text-anykrowd-dark text-md"
                              }, null, 8, ["icon"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(_unref(IonPopover), {
                          trigger: "popover-button",
                          "dismiss-on-select": true
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonContent), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonList), null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.userProfile?.wallets, (wallet) => {
                                      return (_openBlock(), _createBlock(_unref(IonItem), {
                                        class: "flex items-center justify-center ion-no-padding ion-no-margin",
                                        key: wallet.id,
                                        button: true,
                                        onClick: ($event: any) => (selectWallet(wallet)),
                                        detail: false
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_11, [
                                            _createElementVNode("div", _hoisted_12, [
                                              _createElementVNode("span", null, _toDisplayString(wallet.currency.prefix) + " " + _toDisplayString(wallet.current_balance) + " " + _toDisplayString(wallet.currency.name), 1)
                                            ])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (!props.userProfile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(t)('guest_top_up.not_found')), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          (props.userProfile)
            ? (_openBlock(), _createBlock(UserAvatar, {
                key: 0,
                "container-class": 'h-[42px] mr-2',
                src: 
							props.userProfile.profile_image_url
								? props.userProfile.profile_image_url
								: require('@/statics/img/no_profile_pic.jpg')
						,
                alt: "User icon",
                "show-status": false
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_15, [
            (props.userProfile)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(props.userProfile
									? `${props.userProfile.firstname} ${props.userProfile.lastname}`
									: `${_unref(t)('rfid.guest.wallet')} #${selectedWallet.value?.id}`), 1))
              : _createCommentVNode("", true),
            (__props.userProfile && __props.userProfile.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(__props.userProfile.email), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            (!__props.isSuccessProfile && !_unref(tenantConfig))
              ? (_openBlock(), _createBlock(_unref(IonSpinner), {
                  key: 0,
                  name: "dots",
                  class: "spinner-black"
                }))
              : _createCommentVNode("", true),
            (selectedWallet.value && _unref(tenantConfig))
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("span", _hoisted_22, _toDisplayString(selectedWallet.value?.currency.prefix) + _toDisplayString(selectedWallet.value?.current_balance), 1)
                  ]),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(selectedWallet.value.currency.suffix), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (selectedWallet.value && !!selectedWallet.value!.currency.topupable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createVNode(_unref(IonButton), {
                  class: "w-[117px] h-[40px] black font-sourcesanspro-semibold text-sm2 ion-no-margin",
                  shape: "round",
                  routerLink: `/top-up/${selectedWallet.value?.id}?loginToken=${props.loginToken}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(_unref(IonIcon), { icon: _unref(addOutline) }, null, 8, ["icon"])
                    ]),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('rfid.guest.add')), 1)
                  ]),
                  _: 1
                }, 8, ["routerLink"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (selectedWallet.value && selectedWallet.value.currency?.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(selectedWallet.value.currency?.description), 1))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_27, [
        (!!getNextWallet())
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: _unref(chevronForwardOutline),
              class: "text-white active:bg-white/30 active:rounded-full active:p-1",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (selectNextWallet()))
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})
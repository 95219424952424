import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "font-sourcesanspro-regular text-base text-white/60 mb-2" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = { class: "text-m2" }
const _hoisted_5 = { class: "flex items-center justify-center text-white" }
const _hoisted_6 = { class: "text-m2" }
const _hoisted_7 = { class: "flex w-full items-center justify-center mb-4" }
const _hoisted_8 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4" }
const _hoisted_9 = { class: "font-sourcesanspro-semibold text-lg" }
const _hoisted_10 = { class: "text-anykrowd-dark" }
const _hoisted_11 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_12 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_13 = { class: "px-[34px] w-full" }
const _hoisted_14 = { class: "relative flex flex-col items-center justify-center w-full mt-4 mb-2 pt-[52px] pb-6 px-6 backdrop-blur-xl bg-white/20 rounded-lg" }
const _hoisted_15 = {
  key: 1,
  class: "flex justify-center items-center absolute -top-[25px] left-0 right-0"
}
const _hoisted_16 = { class: "flex flex-col items-center justify-center mb-6" }
const _hoisted_17 = { class: "font-sourcesanspro-semibold text-m2" }
const _hoisted_18 = { class: "font-sourcesanspro-semibold text-sm2 text-white/60" }
const _hoisted_19 = { class: "flex w-full justify-evenly font-sourcesanspro-semibold text-sm2" }
const _hoisted_20 = {
  key: 0,
  class: "flex flex-col items-center"
}
const _hoisted_21 = { class: "text-white/60 uppercase" }
const _hoisted_22 = { class: "text-white" }
const _hoisted_23 = {
  key: 1,
  class: "flex flex-col items-center"
}
const _hoisted_24 = { class: "text-white/60 uppercase" }
const _hoisted_25 = { class: "text-white" }
const _hoisted_26 = {
  key: 2,
  class: "flex flex-col items-center"
}
const _hoisted_27 = { class: "text-white/60 uppercase" }
const _hoisted_28 = { class: "text-white" }
const _hoisted_29 = { class: "flex mb-2 w-full items-center justify-end" }
const _hoisted_30 = { class: "flex mb-2" }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_34 = { class: "flex mb-8 font-sourcesanspro-regular text-sm2 underline" }
const _hoisted_35 = { class: "mr-4" }
const _hoisted_36 = { class: "flex flex-col mb-2" }
const _hoisted_37 = { class: "flex items-center justify-center" }
const _hoisted_38 = { class: "text-m2" }
const _hoisted_39 = { class: "flex items-center justify-center text-white" }
const _hoisted_40 = { class: "text-m2" }
const _hoisted_41 = { class: "flex items-center justify-center text-white" }
const _hoisted_42 = { class: "text-m2" }
const _hoisted_43 = { class: "flex justify-center mb-2" }
const _hoisted_44 = { class: "flex justify-center mb-8" }
const _hoisted_45 = { class: "text-center text-sm text-white" }
const _hoisted_46 = {
  key: 0,
  class: "text-center text-sm text-white"
}

import { ref, watch } from 'vue';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, closeOutline, pencil } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import AccordionListItemTwo from '@/shared/components/ui/accordionlisttwo/AccordionListItemTwo.vue';
import AccordionListTwo from '@/shared/components/ui/accordionlisttwo/AccordionListTwo.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import NotificationsSettings from '@/modules/profile/components/NotificationsSettings.vue';
import TagCollections from '@/shared/components/TagCollections.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { Tag } from '@/shared/interfaces/tag';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useTenant from '@/shared/composables/useTenant';

import { buildTimestamp } from '@/config/build';
import { version } from '../../../../package.json';
import { useDebugStore } from '@/stores/debug/debugStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePage',
  setup(__props) {

const isModalOpen = ref(false);
const userTags = ref<number[]>([]);
const { t } = useI18n();
const { getUserProfile, getUserProfileDetails, deleteAccount, logoutUser, openLink, showInstallAppButton } =
	useUserProfile();
const { isFetchedUserProfile, isSuccessUserProfile, userProfile } = getUserProfile();
const { userProfileDetails } = getUserProfileDetails();
const { getTenantConfigAndStyles } = useTenant();
const { isSuccessTenantConfig, tenantConfig } = getTenantConfigAndStyles();

const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

const { incrementDebugClicks, debugEnabled } = useDebugStore();

watch(
	isSuccessUserProfile,
	() => {
		if (userProfile.value) {
			const tags = userProfile.value.tags.map((tag: Tag) => tag.id);
			userTags.value = tags;
		}
	},
	{ immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(ModalOne, {
        title: `Hey ${_unref(userProfile)?.full_name}!`,
        "is-open": isModalOpen.value
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('notifications.request')), 1),
            _createVNode(_unref(IonButton), {
              class: "w-full mb-2",
              expand: "full",
              size: "large",
              shape: "round"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('general.confirm')), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButton), {
              class: "w-full",
              expand: "full",
              size: "large",
              shape: "round",
              fill: "outline",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (showHideModal(false)))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('general.cancel')), 1)
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["title", "is-open"]),
      _createVNode(ModalThree, { "is-open": modalState.value }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (setModal(false)))
              }, null, 8, ["icon"])
            ])
          ]),
          _createElementVNode("h1", _hoisted_9, _toDisplayString(modalType.value === 'tos' ? 'Terms and conditions' : 'Privacy Policy'), 1),
          _withDirectives(_createElementVNode("div", _hoisted_10, null, 512), [
            [_directive_dompurify_html, 
					modalType.value === 'tos' ? _unref(tenantConfig)!.terms_and_conditions : _unref(tenantConfig)!.privacy_policy
				]
          ])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)('profile.title')), 1)
        ]),
        right: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("span", null, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (!_unref(isFetchedUserProfile))
            ? (_openBlock(), _createBlock(_unref(IonSpinner), {
                key: 0,
                name: "dots"
              }))
            : _createCommentVNode("", true),
          (_unref(userProfile) && _unref(userProfileDetails) && _unref(isFetchedUserProfile))
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(UserAvatar, {
                  "container-class": 'w-[70px] h-[70px]',
                  "image-class": 'w-[70px] h-[70px]',
                  src: 
							_unref(userProfile).profile_image_url
								? _unref(userProfile).profile_image_url
								: require('@/statics/img/no_profile_pic.jpg')
						,
                  alt: "User icon",
                  showStatus: false
                }, null, 8, ["src"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(userProfile)?.full_name), 1),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(userProfile)?.email), 1)
          ]),
          _createElementVNode("div", _hoisted_19, [
            (_unref(userProfile)?.gender)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)('profile.gender')), 1),
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(userProfile)?.gender), 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(userProfile)?.date_of_birth)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_unref(t)('profile.date_of_birth')), 1),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(userProfile)?.date_of_birth) + " (" + _toDisplayString(_unref(DateTime).now()
									.diff(_unref(DateTime).fromFormat(_unref(userProfile)?.date_of_birth, 'dd-MM-yyyy'))
									.as('years')
									.toFixed(0)) + " " + _toDisplayString(_unref(t)('profile.years')) + ") ", 1)
                ]))
              : _createCommentVNode("", true),
            (_unref(userProfile)?.city)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(t)('profile.city')), 1),
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_unref(userProfile)?.city), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:bg-white hover:rounded",
            "router-link": "/profile/edit"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), {
                class: "text-anykrowd-dark",
                icon: _unref(pencil)
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_unref(t)('profile.edit_profile')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createVNode(AccordionListTwo, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
							{
								id: 1,
								class: 'rounded-t-lg',
								title: _unref(t)('profile.interests'),
								icon: require('@/statics/svg/profile/price-tags.svg'),
								show: _unref(userProfile) && _unref(userProfile).tags && _unref(userProfile).tags.length > 0,
							},
							{
								id: 2,
								class: 'rounded-b-lg',
								title: _unref(t)('profile.notifications'),
								icon: require('@/statics/svg/profile/notifications.svg'),
								show: true,
							},
						], (item) => {
                return (_openBlock(), _createBlock(AccordionListItemTwo, {
                  key: item.id,
                  id: item.id,
                  "css-class": item.class,
                  title: item.title,
                  icon: item.icon,
                  show: item.show
                }, {
                  default: _withCtx(() => [
                    (item.id === 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          (_unref(userProfile) && _unref(userProfile).tags)
                            ? (_openBlock(), _createBlock(TagCollections, {
                                key: 0,
                                "accordion-class": 'ion-no-padding',
                                "accordion-content-class": 'ion-padding',
                                userTags: userTags.value
                              }, null, 8, ["userTags"]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (item.id === 2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createVNode(NotificationsSettings)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["id", "css-class", "title", "icon", "show"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_33, _toDisplayString(_unref(t)('profile.legal_consents')), 1),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              (_unref(isSuccessTenantConfig))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (setModal(true, 'tos'))),
                    target: "_blank",
                    class: "text-white underline cursor-pointer"
                  }, _toDisplayString(_ctx.$t('profile.terms')), 1))
                : _createCommentVNode("", true)
            ]),
            (_unref(isSuccessTenantConfig))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (setModal(true, 'pp'))),
                  target: "_blank",
                  class: "text-white underline cursor-pointer"
                }, _toDisplayString(_ctx.$t('profile.privacy_policy')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_36, [
            (_unref(showInstallAppButton)())
              ? (_openBlock(), _createBlock(_unref(IonButton), {
                  key: 0,
                  class: "w-full h-[60px] mb-2",
                  expand: "full",
                  size: "large",
                  shape: "round",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(openLink)()))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("span", _hoisted_38, _toDisplayString(_unref(t)('profile.install')), 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_unref(IonButton), {
              class: "w-full h-[60px] mb-2",
              expand: "full",
              size: "large",
              shape: "round",
              fill: "outline",
              "router-link": "/profile/password-reset"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("span", _hoisted_40, _toDisplayString(_unref(t)('profile.change_password')), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButton), {
              class: "w-full h-[60px]",
              expand: "full",
              size: "large",
              shape: "round",
              fill: "outline",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(logoutUser)(_unref(t)('general.please_wait'))))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("span", _hoisted_42, _toDisplayString(_unref(t)('general.logout')), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_unref(IonButton), {
              class: "ion-no-margin font-sourcesanspro-semibold text-base text-white",
              fill: "clear",
              onClick: _unref(deleteAccount)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('profile.delete_account')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_44, [
            _createVNode(_unref(IonButton), {
              onClick: _unref(incrementDebugClicks),
              fill: "clear",
              class: "cursor-default"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_45, "Version " + _toDisplayString(_unref(version)), 1),
                (_unref(debugEnabled)())
                  ? (_openBlock(), _createElementBlock("p", _hoisted_46, "   - " + _toDisplayString(_unref(buildTimestamp)) + " - Debug Mode Enabled ", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col mb-3"
}
const _hoisted_2 = { class: "grid grid-cols-[42px_1fr] gap-3" }
const _hoisted_3 = {
  key: 0,
  class: "relative flex justify-start h-[42px]"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "font-sourcesanspro-regular text-sm2" }
const _hoisted_6 = {
  key: 0,
  class: "text-right font-sourcesanspro-regular text-xs"
}
const _hoisted_7 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_8 = { class: "text-right font-sourcesanspro-regular text-xs" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col mb-3"
}
const _hoisted_12 = { class: "grid grid-cols-[42px_1fr] gap-3" }
const _hoisted_13 = { class: "col-start-2" }
const _hoisted_14 = { class: "flex flex-col items-end" }
const _hoisted_15 = { class: "max-w-[255px] px-5 py-3 bg-anykrowd-zinc rounded-tl-lg rounded-br-lg rounded-bl-lg text-dark-blue leading-none" }
const _hoisted_16 = { class: "font-sourcesanspro-regular text-sm2" }
const _hoisted_17 = { class: "flex justify-between" }
const _hoisted_18 = {
  key: 0,
  class: "text-left font-sourcesanspro-regular text-xs"
}
const _hoisted_19 = { class: "flex" }
const _hoisted_20 = ["src"]


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatMessage',
  props: {
	readByAvatars: { type: Array },
	senderAvatar: { type: String },
	senderStatusClass: { type: String },
	time: { type: String },
	type: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.type === 'received')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (props.senderAvatar)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: props.senderAvatar,
                    class: "w-[42px] h-[42px] rounded-full"
                  }, null, 8, _hoisted_4),
                  _createElementVNode("div", {
                    class: _normalizeClass([props.senderStatusClass, "absolute w-[10px] h-[10px] left-[33px] bottom-[3px] rounded-full border-[2px] border-anykrowd-dark-purple"])
                  }, null, 2)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([[
					{
						'col-start-2': !props.senderAvatar,
					},
				], "flex flex-col items-start"])
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  class: _normalizeClass([[
							{
								'mt-6': props.senderAvatar,
							},
						], "min-w-[200px] max-w-[255px] px-5 py-3 backdrop-blur-xl bg-white/10 rounded-tr-lg rounded-br-lg rounded-bl-lg leading-none"])
                }, [
                  _createElementVNode("span", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "default")
                  ])
                ], 2),
                (!props.readByAvatars)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(props.time), 1))
                  : _createCommentVNode("", true),
                (props.readByAvatars)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(props.time), 1),
                      _createElementVNode("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.readByAvatars, (image) => {
                          return (_openBlock(), _createElementBlock("img", {
                            key: image as string,
                            src: image as string,
                            class: "w-[20px] h-[20px] mt-1 ml-1 rounded-full"
                          }, null, 8, _hoisted_10))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true),
    (props.type === 'sent')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _renderSlot(_ctx.$slots, "default")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    (props.time)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(props.time), 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.readByAvatars, (image) => {
                        return (_openBlock(), _createElementBlock("img", {
                          key: image as string,
                          src: image as string,
                          class: "w-[20px] h-[20px] mt-1 ml-1 rounded-full"
                        }, null, 8, _hoisted_20))
                      }), 128))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-center" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "flex w-full items-center justify-center" }
const _hoisted_4 = { class: "flex w-full items-center justify-center" }
const _hoisted_5 = { class: "flex items-center justify-center" }
const _hoisted_6 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_7 = { class: "flex flex-col h-full" }
const _hoisted_8 = { class: "mb-8" }
const _hoisted_9 = { class: "flex flex-col items-center w-full px-[34px] align-center mb-8" }

import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import VoucherItem from '@/modules/vouchers/components/VoucherItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import { chevronBackOutline, closeOutline, shareSocialOutline } from 'ionicons/icons';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'VouchersPage',
  setup(__props) {

const isModalOpen = ref(false);

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	showHideModal(false);
	await router.push(link);
};

const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

const share = (voucherId: number) => {
	showHideModal(true);
};

return (_ctx: any,_cache: any) => {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(ModalOne, { "is-open": isModalOpen.value }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonIcon), {
              class: "text-[48px] mb-2",
              icon: _unref(shareSocialOutline)
            }, null, 8, ["icon"]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col items-center mb-2" }, [
              _createElementVNode("div", { class: "mb-2" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-m2" }, "Share")
              ]),
              _createElementVNode("div", { class: "max-w-[188px] text-center" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-regular text-base text-[#7E738C]" }, "Share the voucher via email or with friends")
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_button, {
                class: "w-full mb-4",
                expand: "full",
                size: "large",
                shape: "round",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/vouchers/share/email')))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_unref(IonIcon), {
                      src: require('@/statics/svg/email.svg'),
                      class: "h-[24px] w-[24px] mr-2"
                    }, null, 8, ["src"]),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-base text-dark-blue" }, "Email", -1))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                class: "w-full mb-2",
                expand: "full",
                size: "large",
                shape: "round",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo('/friends/select')))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_unref(IonIcon), {
                      src: require('@/statics/svg/users.svg'),
                      class: "h-[24px] w-[24px] mr-2"
                    }, null, 8, ["src"]),
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-base text-dark-blue" }, "Friends", -1))
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (showHideModal(false)))
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(TopBar, { class: "px-[34px]" }, {
            left: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex items-center justify-start cursor-pointer",
                onClick: goBack
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ]),
            center: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("div", { class: "flex grow justify-center w-full font-sourcesanspro-semibold text-l2" }, " My Vouchers ", -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(VoucherItem, {
            id: 1,
            class: "mb-2",
            "ticket-description": "You got a free ticket",
            "ticket-name": "FREE TICKET",
            "ticket-sub-title": "1x VIP",
            "ticket-title": "Boum De Noël 2022"
          }),
          _createVNode(VoucherItem, {
            id: 2,
            activated: true,
            usable: true,
            onOnShare: share,
            class: "mb-2",
            status: "Valid until 16 Apr 2023",
            statusClass: "bg-[#6686F9]",
            "ticket-description": "You got a free drink",
            "ticket-name": "FREE DRINK",
            "ticket-sub-title": "1x",
            "ticket-title": "Coca Cola"
          }),
          _createVNode(VoucherItem, {
            id: 3,
            activated: true,
            shareable: true,
            onOnShare: share,
            class: "mb-2",
            status: "17 h | 32 m | 59 s",
            statusClass: "bg-[#BE0B97]",
            "ticket-description": "You got a free drink",
            "ticket-name": "FREE DRINK",
            "ticket-sub-title": "1x",
            "ticket-title": "Sprite"
          }),
          _createVNode(VoucherItem, {
            id: 4,
            activated: true,
            shareable: true,
            onOnShare: share,
            class: "mb-2",
            status: "12 Apr 2023 | 12 May 2023",
            statusClass: "bg-[#11B767]",
            "ticket-description": "You got a free drink",
            "ticket-name": "FREE DRINK",
            "ticket-sub-title": "1x",
            "ticket-title": "Tonic"
          })
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
    ]),
    _: 1
  }))
}
}

})
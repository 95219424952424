import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "overflow-hidden mb-2 rounded-lg w-full cursor-pointer" }
const _hoisted_2 = ["color"]
const _hoisted_3 = { class: "flex items-center justify-center mr-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col justify-center grow select-none" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-base2" }
const _hoisted_7 = { class: "font-sourcesanspro-semibold text-xs2" }
const _hoisted_8 = { class: "p-4" }

import { ref } from 'vue';
import { IonIcon } from '@ionic/vue';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemWithContentStandalone',
  props: {
	hasDescription: { type: Boolean },
	icon: { type: String, required: true },
	isSelected: { type: Boolean, default: false },
	image: { type: String, default: null },
	price: { type: String, required: true },
	title: { type: String, required: true },
},
  emits: ['onSelectItem'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const isOpen = ref(false);
const emit = __emit;

const open = ($event: MouseEvent) => {
	isOpen.value = !isOpen.value;
	// $event.stopImmediatePropagation();
};

const selectItem = ($event: MouseEvent) => {
	$event.stopPropagation();

	emit('onSelectItem');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([[
				{
					'bg-white/10 text-white': !__props.isSelected,
					'bg-[var(--ion-color-primary)] text-black': __props.isSelected,
				},
			], "flex w-full justify-between h-[64px] cursor-pointer transition-[background-color] duration-500 ease-in-out"]),
      slot: "header",
      color: __props.isSelected ? 'primary' : undefined
    }, [
      _createElementVNode("div", {
        class: "flex items-center px-4 py-2 grow",
        onClick: selectItem
      }, [
        _createElementVNode("div", _hoisted_3, [
          (__props.image === null)
            ? (_openBlock(), _createBlock(_unref(IonIcon), {
                key: 0,
                icon: props.icon,
                class: _normalizeClass([[
							{
								'border-white/10 text-white fill-none': !__props.isSelected,
								'border-black/10 text-black': __props.isSelected,
							},
						], "w-[22px] h-[22px] p-2 border rounded-full"])
              }, null, 8, ["icon", "class"]))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: props.image,
                class: _normalizeClass(["w-[40px] h-[40px] border rounded-full", [
							{
								'border-white/10 text-white fill-none': !__props.isSelected,
								'border-black/10 text-black': __props.isSelected,
							},
						]])
              }, null, 10, _hoisted_4))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(props.title), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(props.price), 1)
        ])
      ]),
      (__props.hasDescription)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex items-center px-4 py-2 font-sourcesanspro-semibold text-lg24",
            onClick: open
          }, [
            _createVNode(_unref(IonIcon), {
              icon: isOpen.value ? _unref(chevronUpOutline) : _unref(chevronDownOutline),
              class: _normalizeClass([
						{
							'border-white/10 text-white/50 fill-none': !__props.isSelected,
							'border-black/10 text-black/50': __props.isSelected,
						},
					])
            }, null, 8, ["icon", "class"])
          ]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_2),
    (__props.hasDescription)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["bg-white/5 transition-[max-height] duration-500 ease-in-out select-none", [
				{
					'max-h-0': !isOpen.value,
					'max-h-screen': isOpen.value,
				},
			]])
        }, [
          _createElementVNode("div", _hoisted_8, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, toRefs } from 'vue';
import { useField } from 'vee-validate';
import { IonToggle } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ToggleInput',
  props: {
	name: { type: String, required: true },
	value: { type: Boolean },
},
  emits: ['onStateChanged'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name, value } = toRefs(props);
const { handleChange } = useField(name);

const toggleState = ref(value.value);

const toggle = () => {
	handleChange(toggleState.value);
	emit('onStateChanged', props.name, toggleState.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(IonToggle), {
      class: "text-sm px-0 pr-1",
      modelValue: toggleState.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((toggleState).value = $event)),
      onIonChange: toggle
    }, null, 8, ["modelValue"])
  ]))
}
}

})
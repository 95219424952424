import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["validation-schema"]

import vue, { watch } from 'vue';
import router from '@/router';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { lockOpenOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useToast from '@/shared/composables/useToast';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInPhoneVerificationCodeForm',
  emits: ['onFormValidation'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emit = __emit;
const { loginUserWithPhone, loginData, isSuccessLogin } = useAuth();
const { presentToast } = useToast();
const route = useRoute();
const uuid = route.params.id;

const verificationCodeRegExp = /^[0-9]{6}$/;

const validationSchema = yup.object({
	verification_code: yup
		.string()
		.matches(verificationCodeRegExp, t('auth.sign_in_phone_page.errors.verification_code'))
		.required(t('auth.sign_in_phone_page.required')),
});

const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

const onSubmit = handleSubmit(async (formData: any) => {
	try {
		await loginUserWithPhone({
			userData: {
				...formData,
				uuid,
			},
			message: t('general.please_wait'),
		});
	} catch (error: any) {
		await presentToast(
			'top',
			error.response?.data?.message ? error.response.data.message.toString() : error.toString(),
			5000,
			'danger',
		);
	}
});

watch(isSuccessLogin, () => {
	if (isSuccessLogin.value && loginData.value) {
		router.push('/profile/additionaldata');
	}
});

const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	emit('onFormValidation', meta.value.valid);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    id: "signInPhoneVerificationCodeForm",
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args))),
    "validation-schema": _unref(validationSchema)
  }, [
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      iconLeft: _unref(lockOpenOutline),
      name: "verification_code",
      type: "text",
      placeholder: _ctx.$t('auth.sign_in_phone_page.verification_code'),
      onInputChanged: onInputChange
    }, null, 8, ["iconLeft", "placeholder"])
  ], 40, _hoisted_1))
}
}

})
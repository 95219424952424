<script setup lang="ts">
import { PropType, watch } from 'vue';
import { IonButton, IonIcon, IonSpinner, loadingController } from '@ionic/vue';
import { downloadOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import type { DownloadModalProps } from '@/shared/interfaces/download';
import { DownloadType } from '@/shared/interfaces/download';
import type { TenantConfig } from '@/shared/interfaces/tenant';
import useDownload from '@/shared/composables/useDownload';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useCurrencies from '@/shared/composables/useCurrencies';

const props = defineProps({
	shouldLoad: { type: Boolean, required: true },
	transactionId: { type: String, required: true },
	encryptedRfidTag: { type: String },
	tenantConfig: { type: Object as PropType<TenantConfig>, required: true },
});

// Init
const { convertValutaSync } = useConvertValuta();
const { t } = useI18n();
const { getTransactionDownloadLink, getWalletTransaction } = useWallet();
const { isLoading, shouldLoad, walletTransaction } = getWalletTransaction(props.transactionId, props.encryptedRfidTag);
const { convertToCurrency, groupAndSumTransactionsByCurrency } = useUtils();
const { presentDownloadAvailableModal } = useDownload();
const { fiatCurrency } = useCurrencies();

const onDownload = async () => {
	const loader = await loadingController.create({
		message: t('general.please_wait'),
	});
	await loader.present();

	const response = await getTransactionDownloadLink(walletTransaction.value!.transaction.id);

	const data: DownloadModalProps = {
		downloadItem: {
			href: response.download_link,
			linkName: `Receipt ${walletTransaction.value!.transaction.reference}`,
		},
		title: t('download_modal.title', { file_name: `Receipt ${walletTransaction.value!.transaction.reference}` }),
		downloadType: DownloadType.INVOICE,
	};

	await presentDownloadAvailableModal(data);
	await loader.dismiss();
};

// Helper method to calculate the paid price for a 'top up cost' transaction
const paidTransactionCostPrice = (): number | string => {
	try {
		if (walletTransaction.value!.transaction.price_excl && walletTransaction.value!.transaction.price_vat) {
			return +walletTransaction.value!.transaction.price_excl + +walletTransaction.value!.transaction.price_vat;
		} else {
			return walletTransaction.value!.transaction.amount;
		}
	} catch (e) {
		return walletTransaction.value!.transaction.amount;
	}
};

const isCurrencyPrice = (transactionOverviewItem: any): boolean => {
	return transactionOverviewItem.is_currency_price && transactionOverviewItem.is_currency_price === true;
};

const paymentMethodTranslationKey = (): string => {
	let key = 'undefined';
	const translationKey = 'components.transaction_detail.';

	switch (walletTransaction.value!.transaction.order?.payment_method) {
		case 'ideal':
			key = 'payment_method_ideal';
			break;
		case 'paypal':
			key = 'payment_method_paypal';
			break;
		case 'creditcard':
			key = 'payment_method_credit_card';
			break;
		case 'applepay':
			key = 'payment_method_applepay';
			break;
		case 'bancontact':
			key = 'payment_method_bancontact';
			break;
		case 'voucher':
			key = 'payment_method_voucher';
			break;
		case 'wire_transfer':
			key = 'payment_method_wire_transfer';
			break;
		case 'virtual_wallet_transaction':
			key = 'payment_method_virtual_wallet';
			break;
		case 'virtual_wallet_transaction_order_only':
			key = 'payment_method_virtual_wallet_order_only';
			break;
		case 'virtual_wallet_transaction_free_offer':
			key = 'payment_method_virtual_wallet_free_offer';
			break;
		case 'virtual_wallet_transaction_free_staff':
			key = 'payment_method_virtual_wallet_free_staff';
			break;
		case 'virtual_wallet_transaction_cash_payment':
			key = 'payment_method_virtual_wallet_cash_payment';
			break;
		case 'virtual_wallet_transaction_card_payment':
			key = 'payment_method_virtual_wallet_card_payment';
			break;
	}

	return translationKey + key;
};

// Watch props and check if shouldLoad should change the state of the query
// and load data
watch(props, () => {
	if (props.shouldLoad) {
		shouldLoad.value = props.shouldLoad;
	}
});
</script>

<template>
	<div class="flex p-4 items-center justify-center" v-if="isLoading">
		<ion-spinner name="dots"></ion-spinner>
	</div>
	<div v-if="walletTransaction && walletTransaction.overview">
		<!-- DETAILS -->
		<div class="p-4 bg-white/10">
			<div class="flex justify-between">
				<span class="font-sourcesanspro-semibold text-l2 uppercase">
					{{
						walletTransaction?.overview.type !== 'topup'
							? t('transaction_overview.receipt')
							: t('transaction_overview.proof_of_transaction')
					}}
				</span>
				<span class="font-sourcesanspro-regular text-xs uppercase font-color-text-50">
					{{ t('components.transaction.pos_id') }}
					{{ walletTransaction?.transaction.scanned_by_id || t('components.transaction.none') }}
				</span>
			</div>
			<div class="flex justify-end">
				<span class="font-sourcesanspro-regular text-xs uppercase font-color-text-50">
					{{ t('components.transaction.id') }}
					{{ walletTransaction?.transaction.order?.nanoid || t('components.transaction.none') }}</span
				>
			</div>
			<div class="font-sourcesanspro-semibold text-sm2">{{ walletTransaction?.overview.invoicing.company }}</div>
			<div class="font-sourcesanspro-regular text-sm font-color-text-50">
				<div v-if="walletTransaction?.overview.invoicing.vat_id">
					{{ t('components.transaction.vat') }}: {{ walletTransaction?.overview.invoicing.vat_id }}
				</div>
				<div
					v-if="
						walletTransaction?.overview.invoicing.address.street &&
						walletTransaction?.overview.invoicing.address.number &&
						walletTransaction?.overview.invoicing.address.postal_code &&
						walletTransaction?.overview.invoicing.address.city
					"
					class="mb-2">
					{{ walletTransaction?.overview.invoicing.address.street }}
					{{ walletTransaction?.overview.invoicing.address.number }},
					{{ walletTransaction?.overview.invoicing.address.postal_code }}
					{{ walletTransaction?.overview.invoicing.address.city }}
				</div>
			</div>

			<!-- TRANSACTION OVERVIEW DETAIL -->

			<!-- If transaction has another type, we will loop over all items and show the correct product line -->
			<div v-if="walletTransaction?.transaction.type !== 'top_up_cost'" class="mb-2">
				<div
					v-for="(transactionOverviewItem, index) of walletTransaction?.overview.items"
					:key="index"
					class="flex justify-between">
					<div class="font-sourcesanspro-regular text-sm font-color-text-50">
						{{ transactionOverviewItem.quantity }}x
						{{ transactionOverviewItem.label }}
					</div>
					<div v-if="transactionOverviewItem.price !== null" class="font-sourcesanspro-semibold text-sm2">
						<span>
							{{
								isCurrencyPrice(transactionOverviewItem)
									? transactionOverviewItem.currency?.prefix
									: fiatCurrency.prefix
							}}
							{{ convertToCurrency(transactionOverviewItem.quantity * +transactionOverviewItem.price) }}
						</span>
						<span class="ml-5" v-if="transactionOverviewItem.vat_percent">
							{{ transactionOverviewItem.vat_percent }}%
						</span>
					</div>
				</div>
			</div>

			<!-- If transaction is top up cost show transactionData instead of looping all transactionOverview lines -->
			<div v-if="walletTransaction?.transaction.type === 'top_up_cost'" class="mb-2">
				<div
					v-for="(transactionOverviewItem, index) of walletTransaction?.overview.items"
					:key="index"
					class="flex justify-between">
					<div class="font-sourcesanspro-regular text-sm font-color-text-50">
						1x {{ t('components.transaction.type.top_up_cost') }}
						<span>
							({{ fiatCurrency.prefix }} {{ convertToCurrency(+walletTransaction?.transaction?.amount) }})
						</span>
					</div>
					<div v-if="transactionOverviewItem.price !== null" class="font-sourcesanspro-semibold text-sm2">
						<span class="value tx-value" v-if="walletTransaction?.transaction?.vat_percentage">
							{{ walletTransaction?.transaction.vat_percentage }}%
						</span>
					</div>
				</div>
			</div>

			<!-- TRANSACTION VAT OVERVIEW DETAIL -->
			<div v-if="walletTransaction?.overview && walletTransaction?.overview.vat_items.length > 0">
				<div
					v-for="(transactionVatOverviewItem, index) of walletTransaction?.overview.vat_items"
					:key="index"
					class="flex justify-between">
					<div class="font-sourcesanspro-regular text-sm font-color-text-50">
						{{ t('components.transaction.vat') }}
						{{ transactionVatOverviewItem.label }}

						<span>
							({{ transactionVatOverviewItem?.currency?.prefix ?? fiatCurrency.prefix }}
							{{ convertToCurrency(transactionVatOverviewItem.price) }})
						</span>
					</div>
				</div>
			</div>
		</div>

		<hr class="h-px bg-white/20 border-0" />

		<!-- TOTAL DETAILS -->
		<div class="p-4 bg-white/10">
			<div class="flex justify-between">
				<span class="font-sourcesanspro-regular text-sm2 font-color-text-50">{{
					t('components.transaction_detail.total_label')
				}}</span>

				<!-- Total of transaction cost -->
				<div v-if="walletTransaction?.transaction.type === 'top_up'">
					<span class="font-sourcesanspro-semibold text-sm2">
						{{ fiatCurrency.prefix }} {{ convertToCurrency(+walletTransaction?.overview.price) }}
					</span>
				</div>
				<!-- If transaction is a 'top up cost' we will show the correct amount -->
				<div v-if="walletTransaction?.transaction.type !== 'top_up'">
					<span
						v-if="walletTransaction?.transaction.type === 'top_up_cost'"
						class="font-sourcesanspro-semibold text-sm2">
						{{ fiatCurrency.prefix }} {{ convertToCurrency(+walletTransaction?.transaction.amount) }}
					</span>
					<span
						v-if="walletTransaction?.transaction.type !== 'top_up_cost'"
						class="font-sourcesanspro-semibold text-sm2">
						<span v-if="walletTransaction?.transaction?.order"
							>{{ fiatCurrency.prefix }}
							{{ convertToCurrency(+walletTransaction?.transaction?.order?.price) }}</span
						>
						<span v-else
							>{{ walletTransaction?.transaction?.currency?.prefix }}
							{{ convertToCurrency(+walletTransaction?.transaction.amount) }}</span
						>
					</span>
				</div>
			</div>

			<!-- TRANSACTION TYPES AND TOTALS -->
			<div v-if="walletTransaction?.transaction.type !== 'top_up_cost'">
				<div
					v-for="(transactionGroup, index) in groupAndSumTransactionsByCurrency(
						walletTransaction?.overview.transactions,
					)"
					:key="index"
					class="flex justify-between">
					<span
						v-if="transactionGroup.type === 'payment'"
						class="font-sourcesanspro-regular text-sm2 font-color-text-50">
						{{ t('components.transaction_detail.transaction_types.payment') }}
					</span>
					<span
						v-if="transactionGroup.type === 'refund_transaction_cost'"
						class="font-sourcesanspro-regular text-sm2 font-color-text-50">
						{{ t('components.transaction_detail.transaction_types.refund_transaction_cost') }}
					</span>
					<span
						v-if="transactionGroup.type === 'top_up'"
						class="font-sourcesanspro-regular text-sm2 font-color-text-50">
						{{ transactionGroup.reference }}
					</span>
					<span
						v-if="transactionGroup.type === 'refunded_amount'"
						class="font-sourcesanspro-regular text-sm2 font-color-text-50">
						{{ t('components.transaction_detail.transaction_types.refund') }}
					</span>

					<span
						v-if="transactionGroup.type === 'top_up_cost'"
						class="font-sourcesanspro-regular text-sm2 font-color-text-50">
						{{ t('components.transaction_detail.transaction_types.top_up_cost') }}
					</span>
					<div
						v-if="transactionGroup.type !== 'top_up_cost'"
						class="font-sourcesanspro-semibold text-sm2 text-nowrap">
						{{ transactionGroup.direction === 'CREDIT' ? '+' : '-' }}
						<span
							>{{ transactionGroup?.prefix }}
							{{ convertToCurrency(+transactionGroup?.totalAmount) }}</span
						>
					</div>

					<div v-if="transactionGroup.type === 'top_up_cost'">
						<div class="font-sourcesanspro-semibold text-sm2 text-nowrap">
							-
							<span>
								{{ fiatCurrency.prefix }} {{ convertToCurrency(+transactionGroup.totalAmount) }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<!--
        IF the transaction in overview has the type top_up_cost,
        We should hide the amount and show:
          - Amount without VAT
          - Amount VAT
      -->
			<div v-else>
				<div class="flex justify-between">
					<span class="font-sourcesanspro-regular text-sm2 font-color-text-50">
						{{ t('components.transaction_detail.transaction_types.top_up_cost') }}
					</span>
					<div class="font-sourcesanspro-semibold text-sm2">
						-
						<span v-if="walletTransaction.transaction.type === 'top_up_cost'">
							{{ fiatCurrency.prefix }} {{ convertToCurrency(+paidTransactionCostPrice()) }}
						</span>
						<span v-if="walletTransaction.transaction.type !== 'top_up_cost'">
							{{ tenantConfig.custom_valuta.prefix }}
							{{ convertValutaSync(tenantConfig, paidTransactionCostPrice(), true) }}
						</span>
					</div>
				</div>
			</div>

			<div class="flex justify-between">
				<span class="font-sourcesanspro-regular text-sm2 font-color-text-50">
					{{ t('components.transaction_detail.payment_method') }}
				</span>
				<span class="font-sourcesanspro-semibold text-sm2">
					{{ t(paymentMethodTranslationKey()) }}
				</span>
			</div>
			<div v-if="walletTransaction?.overview?.payment_method === 'wire_transfer'">
				<span class="font-sourcesanspro-regular text-sm2 font-color-text-50">{{
					t('components.transaction_detail.refund_wire_transfer_period')
				}}</span>
			</div>
			<div class="flex justify-between">
				<span class="font-sourcesanspro-regular text-sm2 font-color-text-50">
					{{ t('components.transaction_detail.payment_status') }}
				</span>
				<span class="font-sourcesanspro-semibold text-sm2 capitalize">
					{{ walletTransaction?.overview.status }}
				</span>
			</div>
		</div>

		<!--		<div class="flex p-4 items-center justify-center bg-white/10">-->
		<!--			<ion-button class="mb-4" size="large" shape="round" @click="onDownload()">-->
		<!--				<div class="flex items-center justify-center">-->
		<!--					<ion-icon :icon="downloadOutline" class="text-dark-blue mr-2"></ion-icon>-->
		<!--					<span class="text-m2">Download</span>-->
		<!--				</div>-->
		<!--			</ion-button>-->
		<!--		</div>-->
	</div>
</template>

<style scoped></style>

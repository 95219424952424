import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap w-full justify-left item-center border-white-15 mb-2" }
const _hoisted_2 = {
  class: "w-full items-center mb-2",
  lines: "none"
}

import { toRefs } from 'vue';
import { useField } from 'vee-validate';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInput',
  props: {
	data: Array,
	icon: { type: String, default: '' },
	label: { type: String },
	name: { String, default: '' },
	placeholder: String,
	value: { type: String },
},
  setup(__props) {

const props = __props;

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name, value } = toRefs(props);
const { handleChange } = useField(name);

const selectItem = (option: string) => {
	handleChange(option);
};

return (_ctx: any,_cache: any) => {
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock("div", null, [
    (props.label)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 0,
          class: "font-sourcesanspro-regular text-xs2 text-white/50"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Multiselect), {
          placeholder: "Select",
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          "allow-empty": false,
          "input-class": 'font-sourcesanspro-regular text-base',
          options: props.data,
          "option-class": { 'my-highlight-class': true },
          "show-labels": false,
          onSelect: selectItem
        }, null, 8, ["modelValue", "options"])
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "mr-2" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-m2" }

import { closeCircle } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonItemMini',
  props: {
	class: { type: String, required: true },
	name: { type: String, required: true },
	image: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([props.class, "flex justify-between ion-no-padding bg-white/[.15] p-2 rounded-full"])
  }, [
    _createVNode(_component_ion_avatar, {
      slot: "start",
      class: "w-[24px] h-[24px] mr-2"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: props.image,
          class: "rounded-full"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(props.name), 1)
      ])
    ]),
    _createVNode(_component_ion_icon, {
      icon: _unref(closeCircle),
      class: "text-white"
    }, null, 8, ["icon"])
  ], 2))
}
}

})
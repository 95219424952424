import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "justify-start font-sourcesanspro-semibold" }

import { IonButton, IonIcon } from '@ionic/vue';
export interface Props {
	fill?: string;
	iconLeft?: string;
	iconRight?: string;
	text?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CustomButton',
  props: {
    fill: { default: undefined },
    iconLeft: { default: undefined },
    iconRight: { default: undefined },
    text: { default: 'This is a default value' }
  },
  emits: ['onClick'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const onClick = async () => {
	emit('onClick');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonButton), {
    color: "primary",
    class: "flex justify-between items-center w-full normal-case text-base text-anykrowd-dark",
    shape: "round",
    onClick: onClick
  }, {
    default: _withCtx(() => [
      (props.iconLeft)
        ? (_openBlock(), _createBlock(_unref(IonIcon), {
            key: 0,
            icon: props.iconLeft,
            class: "mr-2"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(props.text)), 1),
      (props.iconRight)
        ? (_openBlock(), _createBlock(_unref(IonIcon), {
            key: 1,
            icon: props.iconRight
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_3 = { class: "flex grow items-center justify-center w-full" }
const _hoisted_4 = { class: "mr-2" }
const _hoisted_5 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center justify-center mb-4"
}
const _hoisted_7 = { class: "flex items-center justify-center" }
const _hoisted_8 = { class: "text-m2" }

import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Capacitor } from '@capacitor/core';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, downloadOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TicketDetailItem from '@/shared/components/tickets/TicketDetailItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';
import router from '@/router';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsTicketsDetailPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.eventId;
const ticketIdToScroll = route.params.ticketId.toString();
const { downloadTicket, getEventTickets, updateTicket } = useEvents();
const { eventTickets, isFetchingEventTickets, refetchTickets } = getEventTickets(eventId);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const { dragToScroll } = useUtils();

// onMountedHook
onMounted(async () => {
	// Update ticket if query params are present
	const shouldRefetchTickets = await updateTicket();
	if (shouldRefetchTickets) {
		refetchTickets();
	}

	// Scroll to ticket id
	setTimeout(() => {
		if (document.getElementById(`ticket-${ticketIdToScroll}`)) {
			document.getElementById(`ticket-${ticketIdToScroll}`)!.scrollIntoView({
				behavior: 'smooth',
				inline: 'center',
			});
		}
	}, 500);

	// Make content draggable to scroll on desktop
	if (!Capacitor.isNativePlatform()) {
		dragToScroll('dragToScrollElement');
	}
});

// Refetch tickets
const onRefetchTickets = () => {
	refetchTickets();
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px] mb-4" }, {
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: `/events/${eventId}/tickets`,
                class: "flex items-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('events.tickets.tickets_detail.title')), 1)
              ])
            ])
          ]),
          _: 1
        }),
        (_unref(isFetchingEventTickets))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          id: "dragToScrollElement",
          class: _normalizeClass(["flex w-[100%] overflow-scroll invisible-scroller", [
					{
						'justify-center': _unref(eventTickets) && _unref(eventTickets).length === 1,
					},
				]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventTickets), (ticket, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: idx,
              class: _normalizeClass(["flex flex-col", [
						{
							'items-start justify-between': _unref(eventTickets) && _unref(eventTickets)?.length > 1,
							'items-center': _unref(eventTickets) && _unref(eventTickets).length === 1,
						},
					]])
            }, [
              (_openBlock(), _createBlock(TicketDetailItem, {
                onOnRefetchTickets: onRefetchTickets,
                key: idx,
                "qr-code": ticket.qr_code,
                "hide-qr-code": ticket.user_id !== _unref(userProfile)?.id,
                "value-only": !!ticket.ticket_type?.value_only,
                "ticket-id": ticket.id.toString(),
                name: 
							ticket.participant?.full_name
								? ticket.participant?.full_name
								: _unref(userProfile)?.full_name
									? _unref(userProfile)?.full_name
									: ''
						,
                email: 
							ticket.participant?.full_name
								? ticket.participant?.email
								: _unref(userProfile)?.full_name
									? _unref(userProfile)?.email
									: ''
						,
                "ticket-disabled-edit": ticket.disable_edit,
                "ticket-type-color": ticket.ticket_type?.color ? ticket.ticket_type?.color : undefined,
                "ticket-type-name": 
							ticket.combo_ticket_type
								? ticket.combo_ticket_type.name
								: ticket.ticket_type?.name
									? ticket.ticket_type?.name
									: ''
						,
                "event-allow-edit-tickets": !!ticket.event.allow_edit_tickets,
                "event-id": ticket.event_id,
                "event-name": ticket.event.name,
                "event-date": ticket.event.start_datetime,
                venue: ticket.event.address,
                "wallet-linked": ticket.wallet_id,
                class: _normalizeClass([[
							{
								'ml-[34px] mr-4': _unref(eventTickets) && _unref(eventTickets)?.length > 1 && idx === 0,
								'mr-8': _unref(eventTickets) && _unref(eventTickets)?.length > 1 && idx === _unref(eventTickets)!.length - 1,
								'mr-4': _unref(eventTickets) && _unref(eventTickets)?.length > 1 && idx !== _unref(eventTickets)!.length - 1,
							},
						], "mb-6"])
              }, null, 8, ["qr-code", "hide-qr-code", "value-only", "ticket-id", "name", "email", "ticket-disabled-edit", "ticket-type-color", "ticket-type-name", "event-allow-edit-tickets", "event-id", "event-name", "event-date", "venue", "wallet-linked", "class"])),
              _createElementVNode("div", {
                class: _normalizeClass(["flex w-[300px] items-center justify-center", [
							{
								'ml-[34px] mr-4': _unref(eventTickets) && _unref(eventTickets)?.length > 1 && idx === 0,
								'mr-4': _unref(eventTickets) && _unref(eventTickets)?.length > 1 && idx > 0,
							},
						]])
              }, [
                (
								!ticket.event?.delayed_delivery_datetime ||
								(ticket.event?.delayed_delivery_datetime && _unref(eventTickets) && _unref(eventTickets)[0].qr_code)
							)
                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                      key: 0,
                      class: "hover:bg-white hover:rounded-full",
                      expand: "full",
                      size: "large",
                      shape: "round",
                      onClick: ($event: any) => (_unref(downloadTicket)(ticket.event, ticket))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_unref(IonIcon), {
                            icon: _unref(downloadOutline),
                            class: "text-dark-blue mr-2"
                          }, null, 8, ["icon"]),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('events.tickets.tickets_detail.download')), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 2)
            ], 2))
          }), 128))
        ], 2)
      ])
    ]),
    _: 1
  }))
}
}

})
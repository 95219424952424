import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center mb-4 backdrop-blur-2xl bg-black/50 rounded-lg" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = {
  key: 0,
  class: "absolute flex right-[10px]"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "divide-y divide-white/10" }
const _hoisted_6 = { class: "text-l2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = {
  key: 0,
  class: "pb-2"
}
const _hoisted_12 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_13 = { class: "font-sourcesanspro-regular text-xs2 text-white/30" }
const _hoisted_14 = { class: "pt-2 mb-6" }
const _hoisted_15 = { class: "font-sourcesanspro-regular text-sm2 text-white/60" }
const _hoisted_16 = { class: "flex items-center justify-center" }

import { ref } from 'vue';
import { IonButton } from '@ionic/vue';
import router from '@/router';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import ShareButton from '@/shared/components/ShareButton.vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'FeedItem',
  props: {
	canceledAt: { type: String as () => string | Date | null },
	isEvent: { type: Boolean as () => boolean | undefined },
	happeningNow: { type: Boolean as () => boolean | undefined },
	happeningSoon: { type: Boolean as () => boolean | undefined },
	date: { type: String as () => string | undefined },
	favorite: { type: Boolean as () => boolean | undefined, required: true, default: false },
	id: { type: Number as () => number | undefined, required: true },
	image: { type: String, required: true, default: '' },
	link: { type: String, required: true },
	locationAddress: { type: String as () => string | null | undefined },
	summary: { type: String as () => string | null | undefined, required: true, default: '' },
	title: { type: String, required: true },
	type: { type: String, required: true },
	showLoggedData: { type: Boolean, required: true, default: true },
},
  emits: ['onFavoriteClicked'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const { t } = useI18n();
const emit = __emit;

const favoriteClicked = (type: 'event' | 'post', id: number) => {
	emit('onFavoriteClicked', type, id);
};

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (props.image)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "relative w-full aspect-[16/9] overflow-hidden",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(props.link)))
          }, [
            (props.showLoggedData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(FavoriteButton, {
                    onOnFavoriteClicked: favoriteClicked,
                    id: props.id,
                    favorite: props.favorite ? props.favorite : false,
                    type: props.type
                  }, null, 8, ["id", "favorite", "type"]),
                  _createVNode(ShareButton, {
                    id: props.id,
                    title: props.title,
                    type: props.type.includes('event') ? 'events' : 'news'
                  }, null, 8, ["id", "title", "type"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("img", {
              src: props.image,
              class: "w-full h-full rounded-t-lg object-cover object-center"
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "flex flex-col p-4",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo(props.link)))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("h1", _hoisted_6, [
              (props.isEvent && props.canceledAt === null && props.happeningNow)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)('events.live_now')) + ": ", 1))
                : _createCommentVNode("", true),
              (
									props.isEvent &&
									props.canceledAt === null &&
									!props.happeningNow &&
									props.happeningSoon
								)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(t)('events.soon')) + ": ", 1))
                : _createCommentVNode("", true),
              (
									props.isEvent &&
									props.canceledAt === null &&
									!props.happeningNow &&
									!props.happeningSoon
								)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(t)('events.upcoming')) + ": ", 1))
                : _createCommentVNode("", true),
              (props.isEvent && props.canceledAt !== null)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_unref(t)('events.canceled')) + ": ", 1))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(props.title), 1)
            ]),
            (props.type === 'event_now')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (props.date)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(DateTime).fromFormat(props.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy')) + " - " + _toDisplayString(props.locationAddress), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, _toDisplayString(props.summary), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_unref(IonButton), {
            class: "grow h-[36px] text-base2",
            color: "primary",
            fill: "outline",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('components.feed_item.view_details')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full flex-col justify-between" }
const _hoisted_2 = { class: "flex items-center mb-5" }
const _hoisted_3 = { class: "flex justify-evenly" }
const _hoisted_4 = { class: "flex items-center justify-center" }
const _hoisted_5 = { class: "relative px-[34px]" }
const _hoisted_6 = { class: "flex w-full px-[34px] align-center mb-[34px]" }
const _hoisted_7 = { class: "flex w-full justify-between items-center" }

import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import router from '@/router';
import ItemStandalone from '@/shared/components/ui/ItemStandalone.vue';
import TopAlert from '@/shared/components/ui/TopAlert.vue';
import { bagHandleOutline, chevronBackOutline, cafeOutline } from 'ionicons/icons';

// Go back

export default /*@__PURE__*/_defineComponent({
  __name: 'CupsPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(TopAlert, { class: "mb-8 pt-[75px] pb-12 bg-white" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: "flex items-center justify-start mr-4 cursor-pointer",
                  onClick: goBack
                }, [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-dark-blue"
                  }, null, 8, ["icon"])
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "flex font-sourcesanspro-semibold text-l2 text-dark-blue" }, "Your returnable cups", -1))
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(cafeOutline),
                    class: "w-[32px] h-[32px] p-4 bg-dark-blue rounded-full text-white"
                  }, null, 8, ["icon"])
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col items-start text-dark-blue w-[208px]" }, [
                  _createElementVNode("h3", { class: "font-sourcesanspro-semibold text-l2" }, "4x - Cups"),
                  _createElementVNode("p", { class: "font-sourcesanspro-regular text-base2" }, " If we get the cups back we will refund money to you. ")
                ], -1))
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(ItemStandalone, {
              icon: _unref(cafeOutline),
              title: "2x - Coca Cola",
              subtitle: "2.00€ / per cup",
              price: "€ 4.00"
            }, null, 8, ["icon"]),
            _createVNode(ItemStandalone, {
              icon: _unref(cafeOutline),
              title: "1x - Fanta",
              subtitle: "1.00€ / per cup",
              price: "€ 1.00"
            }, null, 8, ["icon"]),
            _createVNode(ItemStandalone, {
              icon: _unref(cafeOutline),
              title: "1x - Red Bull",
              subtitle: "1.50€ / per cup",
              price: "€ 1.50"
            }, null, 8, ["icon"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(IonButton), {
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _cache[2] || (_cache[2] = _createTextVNode(" €6.50 (4 Cups) ")),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(bagHandleOutline),
                  class: "text-dark-blue",
                  slot: "end"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { computed } from 'vue';
import router from '@/router';
import { alertController, loadingController } from '@ionic/vue';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';

import { Address } from '@/shared/interfaces/wallet';
import { anykrowdApi } from '@/api/anykrowdApi';
import { Cart } from '@/shared/interfaces/cart';
import { EventCheckoutReq } from '@/shared/interfaces/event';
import { GenericProduct } from '@/shared/interfaces/genericProduct';
import { Transaction, TransactionItem } from '@/shared/interfaces/transaction';
import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useTransactionStore } from '@/stores/transaction/transactionStore';
import useToast from '@/shared/composables/useToast';
import useUrlHelpers from '@/shared/composables/useUrlHelpers';
import useWallet from '@/modules/wallet/composables/useWallet';
import useProducts from '@/shared/composables/useProducts';
import useTenant from '@/shared/composables/useTenant';

// Init
const { buildRedirectUrl } = useUrlHelpers();

export function useCheckout() {
	const {
		calculateTotalsPerProductCurrency,
		getItemsAndTotal,
		getTotalPrice,
		items,
		setItemsAndTotal,
		setTotalPriceString,
		totalItemsCount,
		getTotalPricePerCurrency,
	} = useTransactionStore();
	const itemsComputed = computed(() => items);
	const totalPrice = computed(() => getTotalPrice);
	const totalItemCount = computed(() => totalItemsCount);
	const { getTenantConfigAndStyles } = useTenant();
	const { tenantConfig } = getTenantConfigAndStyles();

	const { getCartItems, getCartSize, cartTotal } = useCart();
	const { getWalletCurrentBalance } = useWallet();
	//const { userWallet } = getWalletCurrentBalance(0, true);
	const { presentToast } = useToast();
	const { t } = useI18n();

	// Validation Schema
	const billingAddressValidationSchema = yup.object({
		country: yup.string().required(t('checkout.billing_address.errors.country')),
		city: yup.string().required(t('checkout.billing_address.errors.city')),
		address_street: yup.string().required(t('checkout.billing_address.errors.address_street')),
		address_number: yup.string().required(t('checkout.billing_address.errors.address_number')),
		postal_code: yup.string().required(t('checkout.billing_address.errors.postal_code')),
	});

	const { handleSubmit } = useForm({
		validationSchema: billingAddressValidationSchema,
	});

	// Process checkout
	const processCheckout = async (transactionType: string, additionalInfoForCheckout?: any) => {
		switch (transactionType) {
			case TransactionType.PAYMENT:
				processEventTransaction(additionalInfoForCheckout);
				break;
			case TransactionType.SHOP_ORDER:
				processShopTransaction();
				break;
			case TransactionType.SELF_ORDER:
				processSelfOrderTransaction(additionalInfoForCheckout);
				break;
			default:
				// Handle unsupported modules
				break;
		}
		await router.push({ path: '/checkout', query: { transactionType: transactionType } });
	};

	// processSelfOrderTransaction
	const processSelfOrderTransaction = (additionalInfoForProcessSelfOrderTransaction: any) => {
		const items: TransactionItem[] = getCartItems().map((cartItem: Cart) => {
			const product = new GenericProduct(cartItem.product);
			const price = product.discount ? product.discountedPrice()! : cartItem.price;
			return {
				additionalInfo: cartItem.additionalInfo,
				itemId: cartItem.product.id,
				description: cartItem.product.name,
				itemPrice: price * cartItem.quantity,
				unitPrice: cartItem.price,
				quantity: cartItem.quantity,
				discount: cartItem.product.discount,
			};
		});

		// Create transaction
		const transaction: Transaction = {
			additionalInfo: additionalInfoForProcessSelfOrderTransaction.tableSpot.id,
			additionalInfoMore: additionalInfoForProcessSelfOrderTransaction.location,
			items: [...items],
			type: TransactionType.SELF_ORDER,
			totalDescription: `${getCartSize().toString()} ${t('transaction.items_total')}`,
			totalPriceString: cartTotal.value.toString(),
			totalPriceNumber: cartTotal.value,
			totalPriceWithoutFee: cartTotal.value,
		};

		setItemsAndTotal(transaction);
	};

	// processShopTransaction
	const processShopTransaction = () => {
		const items: TransactionItem[] = getCartItems().map((cartItem: Cart) => {
			const product = new GenericProduct(cartItem.product);
			const price = product.discount ? product.discountedPrice()! : cartItem.price;
			return {
				itemId: cartItem.product.id,
				description: cartItem.product.name,
				itemPrice: price * cartItem.quantity,
				unitPrice: cartItem.price,
				quantity: cartItem.quantity,
				discount: cartItem.product.discount,
			};
		});

		// Create transaction
		const transaction: Transaction = {
			items: [...items],
			type: TransactionType.SHOP_ORDER,
			totalDescription: `${getCartSize().toString()} ${t('transaction.items_total')}`,
			totalPriceString: cartTotal.value.toString(),
			totalPriceNumber: cartTotal.value,
			totalPriceWithoutFee: cartTotal.value,
		};

		setItemsAndTotal(transaction);
	};

	// processEventTransaction
	const processEventTransaction = (additionalInfoForProcessEventTransaction: any) => {
		const items: TransactionItem[] = getCartItems().map((cartItem: Cart) => {
			const product = new GenericProduct(cartItem.product);
			const price = product.discount ? product.discountedPrice()! : cartItem.price;
			return {
				additionalInfo: { ...cartItem.additionalInfo, product },
				itemId: cartItem.product.id,
				description: cartItem.product.name,
				itemPrice: price * cartItem.quantity,
				unitPrice: cartItem.price,
				quantity: cartItem.quantity,
				discount: cartItem.product.discount,
			};
		});

		// Create transaction and save the eventId on additionalInfo
		const transaction: Transaction = {
			additionalInfo: additionalInfoForProcessEventTransaction,
			items: [...items],
			type: TransactionType.PAYMENT,
			totalDescription: `${getCartSize().toString()} ${t('transaction.items_total')}`,
			totalPriceString: '',
			totalPriceNumber: cartTotal.value,
			totalPriceWithoutFee: cartTotal.value,
		};

		setItemsAndTotal(transaction);

		// To update totalPriceString first we need items set on the store
		// so this is second step needed for events (more than one wallet)
		const totalPriceString = calculateTotalsPerProductCurrency();
		setTotalPriceString(totalPriceString);
	};

	// Prepare shop order data
	const _prepareShopOrderData = (address: Address) => {
		const products = items.map((item) => {
			return { id: item.itemId, quantity: item.quantity };
		});

		return {
			total_price: Number(totalPrice.value),
			products,
			address,
		};
	};

	// Prepare shop order data
	const _prepareSelfOrderingData = () => {
		const { additionalInfoMore } = getItemsAndTotal();
		const products = items.map((item) => {
			return {
				id: item.itemId,
				comments: item.additionalInfo.comments,
				product_category_id: item.additionalInfo.productCategoryId,
				quantity: item.quantity,
			};
		});

		return {
			total: Number(totalPrice.value),
			products,
			disable_payments: items.findIndex((item) => item.additionalInfo.disableSalesCataloguePayments) !== -1,
			table_number: additionalInfoMore,
		};
	};

	const _prepareEventData = (formData: any) => {
		const participants = items.map((item, idx) => {
			return {
				email: item.additionalInfo.email,
				event_combo_ticket_type_id: item.additionalInfo.event_combo_ticket_type_id,
				event_ticket_type_id: item.additionalInfo.event_ticket_type_id,
				name: item.additionalInfo.name,
				row_index: idx,
				user_id: +item.additionalInfo.user_id,
			};
		});

		return {
			...formData,
			participants,
		};
	};

	// Prepare purchase data
	const _preparePuchaseData = (formData?: any): { data: any; redirectUrl: string } => {
		// Build redirect url
		const redirectUrl = buildRedirectUrl();

		const { type } = getItemsAndTotal();
		switch (type) {
			case TransactionType.PAYMENT:
				return { data: _prepareEventData(formData), redirectUrl };
			case TransactionType.SHOP_ORDER:
				return { data: _prepareShopOrderData(formData!), redirectUrl };
			case TransactionType.SELF_ORDER:
				return { data: _prepareSelfOrderingData(), redirectUrl };
			default:
				// Handle unsupported modules
				return { data: {}, redirectUrl: '' };
		}
	};

	const _purchaseShop = async (transactionData: {
		total_price: number;
		products: Array<object>;
		top_up_amount: number | null;
		redirectUrl: string;
	}): Promise<{ redirect_url: string; code: string }> => {
		const { data } = await anykrowdApi.post<{ redirect_url: string; code: string }>(
			'/shop/purchase',
			transactionData,
			true,
		);
		return data;
	};

	const _purchaseTickets = async (
		eventId: number,
		checkoutData: EventCheckoutReq,
	): Promise<{ success: boolean; redirect_url?: string }> => {
		const { data } = await anykrowdApi.post<{ success: boolean; redirect_url?: string }>(
			`/events/checkout/${eventId}`,
			checkoutData,
			true,
		);
		return data;
	};

	const _purchaseSelfOrder = async (
		tableSpotId: number,
		options: {
			products: { id: number; quantity: number; comments?: string[] }[];
			total: number;
			top_up_amount?: number;
			disable_payments?: boolean;
			redirectUrl?: string;
			table_number?: string;
		},
	): Promise<{ message?: string; code?: string; success?: string; redirect_url?: string }> => {
		const { data } = await anykrowdApi.post<{ status: string; message: string; redirect_url?: string }>(
			`/table-spot/${tableSpotId}/order`,
			options,
			true,
		);
		return data;
	};

	const _executePurchase = async (purchaseData: any) => {
		const { additionalInfo, type } = getItemsAndTotal();
		switch (type) {
			case TransactionType.PAYMENT: {
				const ticketsData = await _purchaseTickets(+additionalInfo, purchaseData);
				return { redirect_url: ticketsData.redirect_url, code: ticketsData.success };
			}
			case TransactionType.SHOP_ORDER: {
				const shopData = await _purchaseShop(purchaseData);
				return { redirect_url: shopData.redirect_url, code: shopData.code };
			}
			case TransactionType.SELF_ORDER: {
				const selfOrderData = await _purchaseSelfOrder(+additionalInfo, purchaseData);
				return {
					redirect_url: selfOrderData.redirect_url,
					code: selfOrderData.code,
					success: selfOrderData.success,
				};
			}
			default:
				// Handle unsupported modules
				return { redirect_url: '', code: '' };
		}
	};

	/**
	 * Check if the products are still available in stock
	 */
	const _checkSelfOrderStock = async () => {
		let inStock = true;
		const { getProductPrices } = useProducts();

		const productsInCart = items.map((item) => {
			return {
				id: item.itemId,
				comments: item.additionalInfo.comments,
				product_category_id: item.additionalInfo.productCategoryId,
				quantity: item.quantity,
			};
		});

		const products = await getProductPrices({
			ids: productsInCart.map((p) => p.id).join(','),
		});

		products.data.forEach((product) => {
			const productInCart = productsInCart.find((p) => p.id === product.id);
			if (
				productInCart &&
				Math.round(Number(product.stock) * 100) < Math.round(Number(productInCart.quantity) * 100)
			) {
				inStock = false;
			}
		});

		return inStock;
	};

	// _checkStock
	const _checkStock = async () => {
		const { type } = getItemsAndTotal();
		switch (type) {
			case TransactionType.SELF_ORDER:
				return await _checkSelfOrderStock();
			default:
				return true;
		}
	};

	// handleCheckoutWithForm
	const handleCheckoutWithForm = handleSubmit(async (formData: any) => {
		handleCheckout(false, formData);
	});

	// handleCheckout
	const handleCheckout = async (checkStock = false, formData?: any) => {
		// Show loader
		const loading = await loadingController.create({ message: t('general.please_wait') });
		await loading.present();

		// Check stock if requested
		const inStock = checkStock ? await _checkStock() : true;
		if (!tenantConfig.value!.out_of_stock_order_clientx && !inStock) {
			await loading.dismiss();
			await presentToast('top', t('checkout.errors.stock_alert'), 5000, 'danger');
		} else {
			// Prepare purchase data
			const { data, redirectUrl } = _preparePuchaseData(formData);

			const badConnectionTimeout = setTimeout(async () => {
				// if we reach this, we don't have a response from the server yet
				await loading.dismiss();
				// await (
				// 	await alertController.create({
				// 		header: t('checkout.errors.connection.header'),
				// 		'message': t('checkout.errors.connection.message'),
				// 		buttons: ['OK'],
				// 	})
				// ).present();
			}, 15000);

			// Execute call
			try {
				const { redirect_url, code } = await _executePurchase({ ...data, redirectUrl });
				clearTimeout(badConnectionTimeout);

				if ((code && code === 'purchase_completed_successfully') || code === 'purchase_success') {
					await loading.dismiss();

					let pathname = new URL(redirectUrl).pathname;
					const search = new URL(redirectUrl).search;

					if (redirect_url) {
						const url = new URL(redirect_url);
						if (url.hash) {
							const hashPath = url.hash.substring(1);
							pathname = hashPath.split('?')[0];
						} else {
							pathname = url.pathname;
						}
					}

					if (pathname) {
						// Push to the route
						await router.push({
							path: pathname,
							query: {
								fromMollie: search.includes('fromMollie=true') ? 'true' : 'false',
							},
						});
					}
				} else if (redirect_url && code !== 'purchase_completed_successfully') {
					await loading.dismiss();

					if (Capacitor.isNativePlatform()) {
						try {
							await AppLauncher.openUrl({ url: redirect_url });
							return;
						} catch (e) {
							console.log(e);
						}
					} else {
						window.location.href = redirect_url;
					}
				}
			} catch (error: any) {
				await loading.dismiss();
				await presentToast(
					'top',
					error.response?.data?.message ? error.response.data.message.toString() : error.toString(),
					5000,
					'danger',
				);
			}
		}
	};

	return {
		items: itemsComputed.value,
		totalItemsPrice: totalPrice.value,
		totalItemsPriceString: calculateTotalsPerProductCurrency(),
		totalItemCount: totalItemCount.value,
		getTotalPricePerCurrency,
		processCheckout,
		handleCheckout,
		handleCheckoutWithForm,
		billingAddressValidationSchema,
	};
}

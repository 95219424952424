import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import * as Sentry from '@sentry/capacitor';
import { authGuard } from '@/guards/authGuard';
import { authRoutes } from '@/modules/auth/router';
import { QueryClient, useQueryClient } from '@tanstack/vue-query';

import ActivateRfid from '@/modules/activate/pages/ActivateRfid.vue';
import BookingsReservationsBookPage from '@/modules/bookings-reservations/pages/BookingsReservationsBookPage.vue';
import BookingsReservationsDetailPage from '@/modules/bookings-reservations/pages/BookingsReservationsDetailPage.vue';
import BookingsReservationsPage from '@/modules/bookings-reservations/pages/BookingsReservationsPage.vue';
import CheckoutPage from '@/shared/pages/CheckoutPage.vue';
import ContactInfoPage from '@/modules/contact/pages/ContactInfoPage.vue';
import CupsPage from '@/modules/cups/pages/CupsPage.vue';
import DashboardPage from '@/modules/dashboard/pages/DashboardPage.vue';
import EventsDetailPage from '@/modules/events/pages/EventsDetailPage.vue';
import EventsPage from '@/modules/events/pages/EventsPage.vue';
import EventsTicketsAddMembersPage from '@/modules/events/pages/EventsTicketsAddMembersPage.vue';
import EventsTicketsBuyPage from '@/modules/events/pages/EventsTicketsBuyPage.vue';
import EventsTicketsDetailPage from '@/modules/events/pages/EventsTicketsDetailPage.vue';
import EventsTicketsPage from '@/modules/events/pages/EventsTicketsPage.vue';
import FriendsAddPage from '@/modules/friends/pages/FriendsAddPage.vue';
import FriendsChatPage from '@/modules/friends/pages/FriendsChatPage.vue';
import FriendsGroupAddPage from '@/modules/friends/pages/FriendsGroupAddPage.vue';
import FriendsGroupChatPage from '@/modules/friends/pages/FriendsGroupChatPage.vue';
import FriendsPage from '@/modules/friends/pages/FriendsPage.vue';
import FriendsSelectPage from '@/shared/pages/FriendsSelectPage.vue';
import GuestWalletRequestRefund from '@/modules/wallet/pages/GuestWalletRequestRefund.vue';
import LockersPage from '@/modules/lockers/pages/LockersPage.vue';
import MapsDetailPage from '@/modules/maps/pages/MapsDetailPage.vue';
import MapsListPage from '@/modules/maps/pages/MapsListPage.vue';
import NewsDetailPage from '@/modules/news/pages/NewsDetailPage.vue';
import NewsEventPage from '@/modules/news/pages/NewsEventPage.vue';
import ProfileAdditionalConfigPage from '@/modules/profile/pages/ProfileAdditionalConfigPage.vue';
import ProfileAdditionalDataPage from '@/modules/profile/pages/ProfileAdditionalDataPage.vue';
import ProfileEditPage from '@/modules/profile/pages/ProfileEditPage.vue';
import ProfilePage from '@/modules/profile/pages/ProfilePage.vue';
import ProfilePasswordReset from '@/modules/profile/pages/ProfilePasswordReset.vue';
import ProfilePasswordResetStepOnePage from '@/modules/profile/pages/ProfilePasswordResetStepOnePage.vue';
import ProfilePasswordResetStepTwoPage from '@/modules/profile/pages/ProfilePasswordResetStepTwoPage.vue';
import ProfileRefundsPage from '@/modules/wallet/pages/WalletRefundsPage.vue';
import ProfileRefundsRequestPage from '@/modules/wallet/pages/WalletRefundsRequestPage.vue';
import QrReaderPage from '@/shared/pages/QrReaderPage.vue';
import QuizPage from '@/modules/quiz/pages/QuizPage.vue';
import QuizQuestionsPage from '@/modules/quiz/pages/QuizQuestionsPage.vue';
import RedeemVoucherPage from '@/modules/top-up/pages/RedeemVoucherPage.vue';
import RfidGuestLinkBraceletPage from '@/modules/rfid/pages/RfidGuestLinkBraceletPage.vue';
import RfidGuestPage from '@/modules/rfid/pages/RfidGuestPage.vue';
import RfidLinkBraceletPage from '@/modules/rfid/pages/RfidLinkBraceletPage.vue';
import RfidPage from '@/modules/rfid/pages/RfidPage.vue';
import SelfOrderingCartPage from '@/modules/self-ordering/pages/SelfOrderingCartPage.vue';
import SelfOrderingGetSpotIdPage from '@/modules/self-ordering/pages/SelfOrderingGetSpotIdPage.vue';
import SelfOrderingPage from '@/modules/self-ordering/pages/SelfOrderingPage.vue';
import ShopCategoryPage from '@/modules/shop/pages/ShopCategoryPage.vue';
import ShopHistoryPage from '@/modules/shop/pages/ShopHistoryPage.vue';
import ShopPage from '@/modules/shop/pages/ShopPage.vue';
import ShopProductDetailPage from '@/modules/shop/pages/ShopProductDetailPage.vue';
import ShopShoppingCartPage from '@/modules/shop/pages/ShopShoppingCartPage.vue';
import TicketsDetailPage from '@/modules/tickets/pages/TicketsDetailPage.vue';
import TicketsPage from '@/modules/tickets/pages/TicketsPage.vue';
import TimeTablesActivityDetailPage from '@/modules/timetables/pages/TimeTablesActivityDetailPage.vue';
import TimeTablesDetailPage from '@/modules/timetables/pages/TimeTablesDetailPage.vue';
import TimeTablesListPage from '@/modules/timetables/pages/TimeTablesListPage.vue';
import TopUpPage from '@/modules/top-up/pages/TopUpPage.vue';
import TransactionPage from '@/shared/pages/TransactionPage.vue';
import VouchersManualActivatePage from '@/modules/vouchers/pages/VouchersManualActivatePage.vue';
import VouchersPage from '@/modules/vouchers/pages/VouchersPage.vue';
import VouchersShareEmailPage from '@/modules/vouchers/pages/VouchersShareEmailPage.vue';
import VouchersSuccessPage from '@/modules/vouchers/pages/VouchersSuccessPage.vue';
import WalletHistoryPage from '@/modules/wallet/pages/WalletHistoryPage.vue';
import WalletManagePage from '@/modules/wallet/pages/WalletManagePage.vue';

import useTenant from '@/shared/composables/useTenant';
import { UserProfile } from '@/shared/interfaces/user';
import { updateScopes } from '@/sentry';
import useStorageService from '@/shared/composables/useStorageService';
import GuestWalletPage from '@/modules/guest/pages/GuestWalletPage.vue';
import VoucherRefundPage from '@/modules/wallet/pages/VoucherRefundPage.vue';

// Init
const { getConfig, getStyles } = useTenant();
const { get, set } = useStorageService();

const routes: Array<RouteRecordRaw> = [
	// Public

	// Auth
	...authRoutes,

	// Activate
	{ path: '/activate/:id', name: 'activate', component: ActivateRfid },

	// Profile Public
	{
		path: '/profile/password/reset/request',
		name: 'profile-password-reset-request',
		component: ProfilePasswordResetStepOnePage,
	},
	{ path: '/auth/set-password/:id', name: 'profile-password-set', component: ProfilePasswordResetStepTwoPage },

	// RFID
	{ path: '/wallet/wallet-handler/:id', name: 'wallet-guest', component: RfidGuestPage },
	{
		path: '/wallet/wallet-handler/link-bracelet',
		name: 'wallet-guest-link-bracelet',
		component: RfidGuestLinkBraceletPage,
	},

	{ path: '/wallet/voucher-refund/:id', name: 'wallet-voucher-refund', component: VoucherRefundPage },

	// Top Up
	{
		path: '/top-up/:wallet_id?', // Making :wallet_id optional by adding a question mark
		name: 'top-up',
		component: TopUpPage,
		props: (route) => ({ id: route.query.id }), // Using the query param called id as a prop
	},
	{ path: '/top-up/redeem', name: 'redeem-voucher', component: RedeemVoucherPage },

	// Guest
	{
		path: '/guest-wallet/:id',
		name: 'guest-wallet',
		component: GuestWalletPage,
		props: (route) => ({ id: route.query.id }),
	},

	// Transaction
	{ path: '/transaction', name: 'transaction-added', component: TransactionPage },

	// Private

	// Wallet
	{ path: '/wallet/history/:wallet_id?', name: 'wallet-history', component: WalletHistoryPage },
	{
		path: '/wallet/refunds/:wallet_id',
		name: 'profile-refunds',
		component: ProfileRefundsPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/wallet/refunds/:wallet_id/request',
		name: 'profile-refunds-request',
		component: ProfileRefundsRequestPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/wallet/manage/:wallet_id',
		name: 'wallet-manage',
		component: WalletManagePage,
		meta: { requiresAuth: true },
	},
	{
		path: '/wallet/refunds/:encryptedrfidtag/guestrequest',
		name: 'profile-refunds-guest-request',
		component: GuestWalletRequestRefund,
	},

	// Bookings & Reservation
	{
		path: '/bookings-reservations',
		name: 'bookings-reservations',
		component: BookingsReservationsPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/bookings-reservations/:id',
		name: 'bookings-reservations-detail',
		component: BookingsReservationsDetailPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/bookings-reservations/book',
		name: 'bookings-reservations-book',
		component: BookingsReservationsBookPage,
		meta: { requiresAuth: true },
	},

	// Checkout
	{ path: '/checkout', name: 'checkout', component: CheckoutPage, meta: { requiresAuth: true } },

	// Contact
	{ path: '/contact', name: 'contact-info', component: ContactInfoPage, meta: { requiresAuth: true } },

	// Cups
	{ path: '/cups', name: 'cups', component: CupsPage, meta: { requiresAuth: true } },

	// Dashboard
	{ path: '/dashboard', name: 'dashboard', component: DashboardPage },

	// Events
	{ path: '/events', name: 'events', component: EventsPage, meta: { requiresAuth: true } },
	{
		path: '/events/:eventId/tickets/:ticketId/details',
		name: 'events-tickets-details',
		component: EventsTicketsDetailPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/events/:id/tickets/buy',
		name: 'events-tickets-buy',
		component: EventsTicketsBuyPage,
		meta: { requiresAuth: true },
	},
	{ path: '/events/:id', name: 'events-detail', component: EventsDetailPage, meta: { requiresAuth: true } },
	{ path: '/events/:id/tickets', name: 'events-tickets', component: EventsTicketsPage, meta: { requiresAuth: true } },
	{
		path: '/events/:id/tickets/addmembers',
		name: 'events-tickets-addmembers',
		component: EventsTicketsAddMembersPage,
		meta: { requiresAuth: true },
	},

	// Friends
	{ path: '/friends', name: 'friends', component: FriendsPage, meta: { requiresAuth: true } },
	{ path: '/friends/add', name: 'friends-add', component: FriendsAddPage, meta: { requiresAuth: true } },
	{ path: '/friends/chat/:id', name: 'friends-chat', component: FriendsChatPage, meta: { requiresAuth: true } },
	{
		path: '/friends/group/add',
		name: 'friends-group-add',
		component: FriendsGroupAddPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/friends/group/chat/:id',
		name: 'frinds-group-chat',
		component: FriendsGroupChatPage,
		meta: { requiresAuth: true },
	},
	{ path: '/friends/select', name: 'friends-select', component: FriendsSelectPage, meta: { requiresAuth: true } },

	// Locker
	{ path: '/lockers', name: 'lockers', component: LockersPage, meta: { requiresAuth: true } },

	// Maps
	{ path: '/maps', name: 'maps', component: MapsListPage, meta: { requiresAuth: true } },
	{ path: '/maps/:id', name: 'maps-detail', component: MapsDetailPage, meta: { requiresAuth: true } },

	// News
	{ path: '/news/:id', name: 'news-post-detail', component: NewsDetailPage, meta: { requiresAuth: true } },
	{ path: '/news/event/:id', name: 'news-event-post', component: NewsEventPage, meta: { requiresAuth: true } },

	// Profile
	{ path: '/profile', name: 'profile', component: ProfilePage, meta: { requiresAuth: true } },
	{
		path: '/profile/additionaldata',
		name: 'profile-additional-data',
		component: ProfileAdditionalDataPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/profile/additionalconfig',
		name: 'profile-additional-config',
		component: ProfileAdditionalConfigPage,
		meta: { requiresAuth: true },
	},
	{ path: '/profile/edit', name: 'profile-edit', component: ProfileEditPage, meta: { requiresAuth: true } },
	{
		path: '/profile/password-reset',
		name: 'profile-password-reset',
		component: ProfilePasswordReset,
		meta: { requiresAuth: true },
	},

	// QR Code Reader
	{ path: '/qr-reader', name: 'qr-reader', component: QrReaderPage, meta: { requiresAuth: true } },

	// Quiz
	{ path: '/quiz', name: 'quiz', component: QuizPage, meta: { requiresAuth: true } },
	{ path: '/quiz/questions', name: 'quiz-questions', component: QuizQuestionsPage, meta: { requiresAuth: true } },

	// RFID
	{ path: '/rfid', name: 'rfid-list', component: RfidPage, meta: { requiresAuth: true } },
	{
		path: '/rfid/link-bracelet',
		name: 'rfid-link-bracelet',
		component: RfidLinkBraceletPage,
		meta: { requiresAuth: true },
	},

	// Self Ordering
	{
		path: '/self-ordering/spot/:id',
		name: 'self-ordering',
		component: SelfOrderingPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/self-ordering/cart/:id',
		name: 'self-ordering-cart',
		component: SelfOrderingCartPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/self-ordering/get-spot-id',
		name: 'self-ordering-get-spot-id',
		component: SelfOrderingGetSpotIdPage,
		meta: { requiresAuth: true },
	},

	// Shop
	{ path: '/shop', name: 'shop', component: ShopPage, meta: { requiresAuth: true } },
	{
		path: '/shop/category/:id?',
		name: 'shop-category',
		component: ShopCategoryPage,
		meta: { requiresAuth: true },
		props: true,
	},
	{ path: '/shop/history', name: 'shop-history', component: ShopHistoryPage, meta: { requiresAuth: true } },
	{
		path: '/shop/product/detail/:id',
		name: 'shop-product-detail',
		component: ShopProductDetailPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/shop/shopping-cart',
		name: 'shop-shopping-cart',
		component: ShopShoppingCartPage,
		meta: { requiresAuth: true },
	},

	// Tickets
	{ path: '/tickets', name: 'tickets', component: TicketsPage, meta: { requiresAuth: true } },
	{ path: '/tickets/:id', name: 'tickets-detail', component: TicketsDetailPage, meta: { requiresAuth: true } },

	// Timetables
	{ path: '/timetables', name: 'timetables', component: TimeTablesListPage, meta: { requiresAuth: true } },
	{
		path: '/timetables/detail/:id',
		name: 'timetables-detail',
		component: TimeTablesDetailPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/timetables/activity-detail/:id',
		name: 'timetables-item-detail',
		component: TimeTablesActivityDetailPage,
		meta: { requiresAuth: true },
	},

	// Vouchers
	{ path: '/vouchers', name: 'vouchers', component: VouchersPage, meta: { requiresAuth: true } },
	{
		path: '/vouchers/activate/manual/:id',
		name: 'vouchers-activate-manual',
		component: VouchersManualActivatePage,
		meta: { requiresAuth: true },
	},
	{
		path: '/vouchers/success',
		name: 'vouchers-success',
		component: VouchersSuccessPage,
		meta: { requiresAuth: true },
	},
	{
		path: '/vouchers/share/email',
		name: 'vouchers-share-email',
		component: VouchersShareEmailPage,
		meta: { requiresAuth: true },
	},

	// Default
	{ path: '/:pathMatch(.*)*', redirect: () => ({ name: 'dashboard' }) },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	scrollBehavior(to, from, SavedPosition) {
		if (to.hash) {
			const el = to.hash.split('#')[1];
			if (el.length && document.getElementById(el)) {
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						resolve(document.getElementById(el)!.scrollIntoView({ behavior: 'smooth' }));
					}, 500);
				});
			}
		} else if (SavedPosition) {
			return SavedPosition;
		}
	},
});

const loadFromCache = (queryClient: QueryClient) => {
	get('cachedDataKeys').then((keys) => {
		if (keys && keys.length) {
			keys.forEach((key: string) => {
				get(key).then((data) => {
					const arrayKey = key.split('-');

					// Use map to iterate over the array
					const arrayKeyTransformed = arrayKey.map((element) => {
						// Check if the element is a string that can be converted to a number
						// Convert the element to a number if it can be
						return element == '' ? '' : isNaN(Number(element)) ? element : Number(element);
					});

					queryClient.setQueryData(arrayKeyTransformed, data);
				});
			});
		}
	});
};

const saveInCache = async (key: string, fn: any, queryClient: QueryClient) => {
	let keys = await get('cachedDataKeys');
	if (!keys) {
		keys = [key];
	} else {
		if (!keys.includes(key)) {
			keys.push(key);
		}
	}
	await set('cachedDataKeys', keys);

	// Get data from server if it doesn't exists on cache
	// Cache will be keep updated every time a query is requested
	// to backend.
	// So if cache is present, then use it as it will be updated
	let data = await get(key);
	if (!data) {
		data = await fn();
		await set(key, data);
	}

	queryClient.setQueryData([key], data);
};

router.beforeEach(async (to, from, next) => {
	const queryClient = useQueryClient();

	await saveInCache('tenantConfig', getConfig, queryClient);
	await saveInCache('tenantStyles', getStyles, queryClient);
	await loadFromCache(queryClient);

	// // Get cached config
	// await queryClient.ensureQueryData({
	// 	queryKey: ['tenantConfig'],
	// 	queryFn: () => getConfig(),
	// 	cacheTime: 1000 * 60 * 60 * 24 * 365,
	// 	staleTime: 1000,
	// });
	// await queryClient.ensureQueryData({
	// 	queryKey: ['tenantStyles'],
	// 	queryFn: () => getStyles(),
	// 	cacheTime: 1000 * 60 * 60 * 24 * 365,
	// 	staleTime: 1000,
	// });

	// Update sentry scope
	const userProfile = queryClient.getQueryCache().find(['userProfile']);
	const uuid =
		userProfile && userProfile.state && userProfile.state.data
			? (userProfile.state.data as UserProfile).uuid
			: null;
	updateScopes({
		userId: uuid,
		destination: to.name,
		tenantName: window.tenantName,
	});

	// Execute guards
	await authGuard(to, from, next, queryClient);
});

export default router;

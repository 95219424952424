import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-center"
}
const _hoisted_5 = {
  key: 2,
  class: "px-[34px]"
}
const _hoisted_6 = { class: "flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller" }
const _hoisted_7 = { class: "flex items-center rounded-xl overflow-hidden" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "p-2" }
const _hoisted_10 = { class: "flex font-sourcesanspro-semibold text-l2" }
const _hoisted_11 = { class: "font-sourcesanspro-regular text-base2 font-color-text-50" }

import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent, IonButton, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';

import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useFavorite from '@/shared/composables/useFavorite';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useTenant from '@/shared/composables/useTenant';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTablesActivityDetailPage',
  setup(__props) {

const route = useRoute();
const { getTimeTable } = useTimeTables();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const timeTableId = +route.query.timeTableId!;
const trackId = +route.query.trackId!;
const activityId = +route.params.id!;
const { isFetchedTimeTable, timeTable } = getTimeTable(timeTableId, userProfile.value?.id);
const { toggleFavorite } = useFavorite();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();

const activity = timeTable.value?.tracks
	.find((track) => track.id === trackId)
	?.activities.find((activity) => activity.id === activityId);

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      (openInFullScreen.value)
        ? (_openBlock(), _createBlock(FullScreenImage, {
            key: 0,
            fullScreenImage: fullScreenImage.value,
            onOnCloseImage: onCloseImage
          }, null, 8, ["fullScreenImage"]))
        : _createCommentVNode("", true),
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: `/timetables/detail/${timeTableId}`,
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(activity)?.name), 1)
        ]),
        right: _withCtx(() => [
          (_unref(showLoggedData)())
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_unref(activity))
                  ? (_openBlock(), _createBlock(FavoriteButton, {
                      key: 0,
                      onOnFavoriteClicked: _unref(toggleFavorite),
                      id: _unref(activity).pivot_id,
                      favorite: _unref(activity).isFavorite,
                      type: 'slot',
                      class: _normalizeClass('w-[20px] h-[20px] ion-no-margin')
                    }, null, 8, ["onOnFavoriteClicked", "id", "favorite"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (!_unref(isFetchedTimeTable))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_unref(IonSpinner), { name: "dots" })
          ]))
        : _createCommentVNode("", true),
      (_unref(timeTable) && _unref(isFetchedTimeTable) && _unref(activity))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: _unref(activity).imageUrl ? _unref(activity).imageUrl : require('@/statics/img/event_bg.png'),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
							viewFullScreen(
								_unref(activity).imageUrl ? _unref(activity).imageUrl : require('@/statics/img/event_bg.png'),
							)
						)),
                  class: "object-fit w-[100%]"
                }, null, 8, _hoisted_8)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(activity).description_short || _unref(activity).name), 1),
              _withDirectives(_createElementVNode("div", _hoisted_11, null, 512), [
                [_directive_dompurify_html, _unref(activity).description]
              ])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[100px] mb-4" }, " ", -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-left px-[34px] w-full text-l2" }
const _hoisted_2 = { class: "flex justify-between overflow-scroll invisible-scroller" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mt-2 text-center" }
const _hoisted_5 = { class: "text-m2" }
const _hoisted_6 = { class: "mt-2" }
const _hoisted_7 = ["v-if"]

import { PropType } from 'vue';

import type { RelatedContent } from '@/shared/interfaces/relatedContent';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'RelatedContent',
  props: {
	contents: {
		type: Array as PropType<RelatedContent[]>,
		default: function () {
			return [];
		},
		required: true,
	},
	subtitle: { type: String },
	title: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(props.title), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.contents, (content, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(idx === 0 ? 'min-w-[150px] ml-[34px] mr-4' : 'min-w-[150px] mr-4'),
          key: idx
        }, [
          _createVNode(_component_router_link, {
            to: content.link,
            class: "flex flex-col items-center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: content.src,
                class: "h-[150px] object-cover rounded-xl"
              }, null, 8, _hoisted_3),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(content.title), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", {
                  "v-if": content.subtitle,
                  class: "text-base2"
                }, _toDisplayString(content.subtitle), 9, _hoisted_7)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ], 2))
      }), 128))
    ])
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-left w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "flex items-center justify-center pt-3"
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "flex justify-center px-[34px] mb-2 w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_7 = { class: "flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller" }
const _hoisted_8 = ["src", "onClick"]
const _hoisted_9 = { class: "px-[34px] mb-2" }
const _hoisted_10 = { class: "flex font-sourcesanspro-regular text-xs2 font-color-text-50 mb-4" }
const _hoisted_11 = { class: "flex items-center mr-2 text-base" }
const _hoisted_12 = { class: "flex justify-between mb-4" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_15 = { class: "font-sourcesanspro-regular text-base font-color-text-50" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "font-sourcesanspro-regular text-base font-color-text-50" }
const _hoisted_18 = { class: "flex flex-col" }
const _hoisted_19 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_20 = { class: "font-sourcesanspro-regular text-base font-color-text-50" }
const _hoisted_21 = { class: "flex justify-right" }
const _hoisted_22 = { class: "font-sourcesanspro-semibold mb-4 px-4 pt-2 pb-4 bg-black/30 rounded-xl" }
const _hoisted_23 = { class: "text-l2" }
const _hoisted_24 = {
  key: 0,
  class: "text-base2 font-sourcesanspro-regular font-white"
}
const _hoisted_25 = {
  key: 1,
  class: "text-base2 font-sourcesanspro-regular font-white"
}
const _hoisted_26 = {
  key: 0,
  class: "flex flex-col mb-8"
}
const _hoisted_27 = { class: "flex items-center justify-between font-sourcesanspro-semibold mb-2" }
const _hoisted_28 = { class: "text-l2" }
const _hoisted_29 = { class: "text-base font-sourcesanspro-regular font-color-text-50" }
const _hoisted_30 = { class: "mb-4 font-sourcesanspro-semibold" }
const _hoisted_31 = { class: "mb-4 font-sourcesanspro-semibold" }
const _hoisted_32 = {
  key: 0,
  class: "fixed justify-center h-[150px] px-[34px] bottom-0 z-10 w-full"
}
const _hoisted_33 = { class: "relative w-full h-full" }
const _hoisted_34 = { class: "absolute w-full bottom-0 mb-[34px]" }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "flex w-full justify-center items-center font-sourcesanspro-semibold text-m2" }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { class: "flex justify-between" }
const _hoisted_40 = { class: "flex w-full justify-center items-center font-sourcesanspro-semibold text-m2" }
const _hoisted_41 = { class: "flex w-full justify-center items-center font-sourcesanspro-semibold text-m2" }
const _hoisted_42 = {
  key: 0,
  class: "flex"
}
const _hoisted_43 = { class: "flex p-2 font-sourcesanspro-regular text-base backdrop-blur bg-white/20 rounded-xl" }
const _hoisted_44 = {
  key: 1,
  class: "relative h-full"
}

import { ref, watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import {
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
	loadingController,
} from '@ionic/vue';
import { bagHandleOutline, chevronBackOutline, locationOutline } from 'ionicons/icons';
import { GoogleMap, Marker } from 'vue3-google-map';
import { DateTime } from 'luxon';
import { Browser } from '@capacitor/browser';
import { useI18n } from 'vue-i18n';

import { DownloadType } from '@/shared/interfaces/download';
import { useAuthStore } from '@/stores/auth/authStore';
import { useCart } from '@/modules/shop/composables/useCart';
import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import RelatedContent from '@/shared/components/ui/RelatedContent.vue';
import ShareButton from '@/shared/components/ShareButton.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { DownloadModalProps } from '@/shared/interfaces/download';
import useDownload from '@/shared/composables/useDownload';
import useEvents from '@/modules/events/composables/useEvents';
import useShop from '@/modules/shop/composables/useShop';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useTenant from '@/shared/composables/useTenant';
import { useSharedStore } from '@/stores/shared/sharedStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsDetailPage',
  setup(__props) {

const { t } = useI18n();
const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
const mapRef = ref();
const latLng = ref({ lat: 0, lng: 0 });
const route = useRoute();
const eventId = +route.params.id;
const {
	addToCalendar,
	doorSalesOnly,
	eventEnded,
	getEvent,
	isGoingToEventOrHasBoughtTickets,
	noTicketsAvailable,
	showTicketButton,
	canBuyTicket,
} = useEvents();
const { clearCart } = useCart();
const { event, isFetchedEvent, refetchEvent, removeEventFromCache } = getEvent(eventId);
const { getProducts } = useShop();
const { products } = getProducts({ upselling_event_id: eventId });
const { convertToCurrency } = useUtils();
const authStore = useAuthStore();
const { presentToast } = useToast();
const { presentDownloadAvailableModal } = useDownload();
const sharedStore = useSharedStore();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};

// Watch map state
watch(
	() => mapRef.value?.ready,
	(ready) => {
		if (!ready) return;
		// Get latitutde and longitude from event address
		const geocoder = new mapRef.value.api.Geocoder();
		geocoder.geocode({ 'address': event.value?.address }, function (results: any, status: string) {
			if (status == google.maps.GeocoderStatus.OK) {
				latLng.value = {
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng(),
				};
			}
		});
	},
);

// shouldAuthenticate
const shouldAuthenticate = async () => {
	await presentToast('top', t('general.please_login_first'), 5000, 'danger');
	await router.push('/auth/signuphome');
};

// download
const download = async (path: string) => {
	const loader = await loadingController.create({
		message: t('general.please_wait'),
	});
	await loader.present();

	const data: DownloadModalProps = {
		downloadItem: {
			extension: 'ics',
			href: `${window.apiUrl}${path}`,
			linkName: `Calendar`,
		},
		title: t('download_modal.title', { file_name: `Calendar` }),
		downloadType: DownloadType.ICS,
	};

	await presentDownloadAvailableModal(data);
	await loader.dismiss();
};

// Refresh
const handleRefresh = async (event: any) => {
	removeEventFromCache();
	event.value = null;
	await refetchEvent();
	event.target.complete();
};

const clearCartAndContinue = (path: string) => {
	clearCart();
	router.push(path);
};

// Save previous visited page if is the first time the page is visited
// as go back is not always the same page
if (!sharedStore.customNavigation.find((item) => item.key === route.path) && router.options.history.state.back) {
	sharedStore.customNavigation.push({ key: route.path, value: router.options.history.state.back?.toString() });
}

// Handle go back
const goBack = async () => {
	const previousRoute = sharedStore.customNavigation.find((item) => item.key === route.path)?.value;
	const indexOfPreviousRoute = sharedStore.customNavigation.findIndex((item) => item.key === route.path);
	if (indexOfPreviousRoute != -1) {
		sharedStore.customNavigation.splice(indexOfPreviousRoute, 1);
		await router.push({ path: previousRoute! });
	} else {
		await router.push({ path: '/dashboard' });
	}
};

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), { class: "z-10" }, {
      default: _withCtx(() => [
        (openInFullScreen.value)
          ? (_openBlock(), _createBlock(FullScreenImage, {
              key: 0,
              fullScreenImage: fullScreenImage.value,
              onOnCloseImage: onCloseImage
            }, null, 8, ["fullScreenImage"]))
          : _createCommentVNode("", true),
        _createVNode(_unref(IonRefresher), {
          class: "mt-8",
          slot: "fixed",
          onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRefresherContent))
          ]),
          _: 1
        }),
        _createVNode(TopBar, { class: "px-[34px]" }, _createSlots({
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "flex items-center justify-center",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (goBack()))
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ])
          ]),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('general.go_back')), 1)
          ]),
          _: 2
        }, [
          (_unref(event))
            ? {
                name: "right",
                fn: _withCtx(() => [
                  (_unref(showLoggedData)())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(FavoriteButton, {
                          id: _unref(event).id,
                          favorite: _unref(event).is_favorite,
                          type: "event"
                        }, null, 8, ["id", "favorite"])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(ShareButton, {
                    id: _unref(event).id,
                    title: _unref(event).name,
                    type: "events"
                  }, null, 8, ["id", "title"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1024),
        (!_unref(event))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (_unref(event))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(event)?.name), 1),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(event).images, (image, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: idx,
                    class: _normalizeClass(["flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden", [{ 'ml-[34px]': idx === 0 }]])
                  }, [
                    _createElementVNode("img", {
                      src: image.image_url,
                      onClick: ($event: any) => (viewFullScreen(image.image_url)),
                      class: "object-fit w-[100%]"
                    }, null, 8, _hoisted_8)
                  ], 2))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_unref(IonIcon), {
                      class: "h-[16px] mr-1 font-color-text-50",
                      icon: _unref(locationOutline)
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_unref(event)?.address), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('dd MMMM')), 1),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('hh:mm a')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('events.details.to')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).end_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('dd MMMM')), 1),
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).end_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('hh:mm a')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_unref(IonButton), {
                      class: "ion-no-margin ion-no-padding",
                      fill: "clear",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (download(`/events/calendar-invite/${_unref(event).id}`)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          src: require('@/statics/svg/events/calendar-add.svg'),
                          class: "h-[54px] w-[54px]"
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(t)('events.details.about_event')) + ":", 1),
                  (!_unref(event).active_now || !_unref(event).active_now_content)
                    ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_24, null, 512)), [
                        [_directive_dompurify_html, _unref(event).description]
                      ])
                    : _createCommentVNode("", true),
                  (_unref(event).active_now && _unref(event).active_now_content)
                    ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_25, null, 512)), [
                        [_directive_dompurify_html, _unref(event).active_now_content]
                      ])
                    : _createCommentVNode("", true)
                ]),
                (_unref(event).address)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_28, _toDisplayString(_unref(t)('events.details.event_location')), 1),
                          _createElementVNode("p", _hoisted_29, _toDisplayString(_unref(event).address), 1)
                        ])
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_unref(GoogleMap), {
                          "api-key": _unref(GOOGLE_MAPS_API_KEY),
                          ref_key: "mapRef",
                          ref: mapRef,
                          style: {"width":"100%","height":"150px"},
                          center: latLng.value,
                          zoom: 15,
                          disableDefaultUi: true
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(Marker), {
                              options: { position: latLng.value }
                            }, null, 8, ["options"])
                          ]),
                          _: 1
                        }, 8, ["api-key", "center"])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_30, [
                (_unref(event)?.posts.length)
                  ? (_openBlock(), _createBlock(RelatedContent, {
                      key: 0,
                      title: "Related News",
                      contents: 
						_unref(event)?.posts.map((post) => {
							return {
								'link': `/news/${post.id}`,
								'src': post.main_image_url,
								'title': post.title,
								'subtitle': '',
							};
						})
					
                    }, null, 8, ["contents"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_31, [
                (_unref(products)?.length)
                  ? (_openBlock(), _createBlock(RelatedContent, {
                      key: 0,
                      title: "Related Products",
                      contents: 
						_unref(products)?.map((product) => {
							return {
								'link': `/shop/product/detail/${product.id}`,
								'src': product.image_url ? product.image_url : '',
								'title': product.name,
								'subtitle': _unref(convertToCurrency)(product.price),
							};
						})
					
                    }, null, 8, ["contents"]))
                  : _createCommentVNode("", true)
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), {
      class: _normalizeClass(["flex items-center justify-center button-container", [
			{
				'z-0': openInFullScreen.value,
			},
		]])
    }, {
      default: _withCtx(() => [
        (_unref(showLoggedData)() && _unref(event))
          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  (!_unref(authStore).isAuthenticated())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createVNode(_unref(IonButton), {
                          class: "flex-grow",
                          expand: "full",
                          size: "large",
                          shape: "round",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (shouldAuthenticate()))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(t)('events.details.buy_tickets')), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                        (
								!_unref(noTicketsAvailable)(_unref(event)) ||
								_unref(showTicketButton)(_unref(event)) ||
								_unref(isGoingToEventOrHasBoughtTickets)(_unref(event))
							)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                              _createElementVNode("div", _hoisted_39, [
                                (_unref(canBuyTicket)(_unref(event), _unref(tenantConfig)))
                                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                                      key: 0,
                                      class: "flex-grow hover:bg-white hover:rounded-full",
                                      expand: "full",
                                      size: "large",
                                      shape: "round",
                                      onClick: _cache[4] || (_cache[4] = ($event: any) => (
										_unref(event)?.tickets_link
											? _unref(Browser).open({ url: _unref(event).tickets_link })
											: clearCartAndContinue(`/events/${_unref(event).id}/tickets/buy`)
									))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_40, [
                                          _createVNode(_unref(IonIcon), {
                                            icon: _unref(bagHandleOutline),
                                            class: "mr-2 text-dark-blue"
                                          }, null, 8, ["icon"]),
                                          _createTextVNode(" " + _toDisplayString(_unref(event)?.is_free
												? _unref(t)('events.details.register')
												: _unref(event).event_tickets_count >= 1
													? _unref(t)('events.details.buy_more')
													: _unref(t)('events.details.buy_tickets')), 1)
                                        ])
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (_unref(event).event_tickets_count > 0)
                                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                                      key: 1,
                                      class: "flex-grow hover:bg-white hover:rounded-full",
                                      expand: "full",
                                      size: "large",
                                      shape: "round",
                                      onClick: _cache[5] || (_cache[5] = ($event: any) => (clearCartAndContinue(`/events/${_unref(event).id}/tickets`)))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(event).event_tickets_count) + " " + _toDisplayString(_unref(t)('events.details.tickets')), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              (_unref(doorSalesOnly)(_unref(event)))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                    _createElementVNode("div", _hoisted_43, _toDisplayString(_unref(t)('events.details.tickets_purchased_door')), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (_unref(event)?.sold_out && !_unref(showTicketButton)(_unref(event)) && !_unref(event)?.tickets_link)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                    _createVNode(_unref(IonButton), {
                                      disabled: "",
                                      class: "w-full",
                                      expand: "full"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('events.details.sold_out')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}
}

})
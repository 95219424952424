import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start" }

import router from '@/router';
import { IonContent, IonIcon, IonFooter } from '@ionic/vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import { chevronBackOutline } from 'ionicons/icons';
import PersonItemCompact from '@/shared/components/ui/PersonItemCompact.vue';
import TopBar from '@/shared/components/TopBar.vue';

// Go back
const friendsCount = 4;

export default /*@__PURE__*/_defineComponent({
  __name: 'FriendsAddPage',
  setup(__props) {

const goBack = () => {
	router.back();
};


return (_ctx: any,_cache: any) => {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(TopBar, { class: "px-[34px]" }, {
            left: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex items-center justify-start cursor-pointer",
                onClick: goBack
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ]),
            center: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "flex grow justify-center w-full font-sourcesanspro-semibold text-l2" }, "Add Friend", -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", {
            class: _normalizeClass([[
					{
						'justify-between': friendsCount === 0,
					},
				], "flex flex-col h-full px-[34px]"])
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_ion_searchbar, {
                placeholder: "Search now",
                class: "mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l",
                shape: "round"
              })
            ]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(PersonItemCompact, {
                name: "BullPuppy",
                description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
                image: require('@/statics/svg-delete-after-implement/person-8.svg'),
                "last-time-online": "Today"
              }, null, 8, ["image"]),
              _createVNode(PersonItemCompact, {
                name: "April Curtis",
                description: "Vivamus rhoncus, quam sed tempor.",
                image: require('@/statics/svg-delete-after-implement/person-9.svg'),
                "last-time-online": "Yesterday"
              }, null, 8, ["image"]),
              _createVNode(PersonItemCompact, {
                name: "Angela Bower",
                description: "Vivamus rhoncus, quam sed tempor.",
                image: require('@/statics/svg-delete-after-implement/person-10.svg'),
                "last-time-online": "Today"
              }, null, 8, ["image"]),
              _createVNode(PersonItemCompact, {
                name: "Thomas Magnum",
                description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
                image: require('@/statics/svg-delete-after-implement/person-11.svg'),
                "last-time-online": "01/21/2023"
              }, null, 8, ["image"]),
              _createVNode(PersonItemCompact, {
                name: "Johnathan Higgins",
                description: "Vivamus rhoncus, quam sed tempor.",
                image: require('@/statics/svg-delete-after-implement/person-12.svg'),
                "last-time-online": "01/17/2023"
              }, null, 8, ["image"]),
              _createVNode(PersonItemCompact, {
                name: "Willie Tanner",
                description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
                image: require('@/statics/svg-delete-after-implement/person-13.svg'),
                "last-time-online": "12/22/2022"
              }, null, 8, ["image"]),
              _createVNode(PersonItemCompact, {
                name: "Sarrah Williams",
                description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
                image: require('@/statics/svg-delete-after-implement/person-14.svg'),
                "last-time-online": "12/22/2022"
              }, null, 8, ["image"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[77px] mb-4" }, " ", -1))
          ], 2)
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "friends" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
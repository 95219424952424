import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between px-[34px]" }
const _hoisted_2 = { class: "flex grow items-center justify-center w-full" }
const _hoisted_3 = { class: "mr-2" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_5 = { class: "flex flex-col items-center justify-center mb-8" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-lg2" }
const _hoisted_7 = { class: "font-sourcesanspro-regular text-base2 text-white/50" }
const _hoisted_8 = { class: "flex flex-col w-full align-center" }
const _hoisted_9 = { class: "flex px-6 py-4 items-center text-white" }
const _hoisted_10 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_11 = { class: "border-y border-y-white/5 px-6" }
const _hoisted_12 = ["validation-schema"]
const _hoisted_13 = {
  key: 1,
  class: "mb-2"
}
const _hoisted_14 = { class: "font-sourcesanspro-regular text-sm2 text-center text-white/50" }
const _hoisted_15 = { class: "mb-8" }
const _hoisted_16 = {
  key: 0,
  class: "flex w-full items-center justify-between"
}
const _hoisted_17 = {
  key: 0,
  class: "text-m2"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 1,
  class: "text-m2"
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 1 }

import vue, { ref, watchEffect, onMounted } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { Form } from 'vee-validate';
import { IonButton, IonContent, IonIcon, IonList } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Address } from '@/shared/interfaces/wallet';
import { TransactionItem } from '@/shared/interfaces/transaction';
import { TransactionType } from '@/enums/wallet.enum';
import { useCheckout } from '@/shared/composables/useCheckout';
import hexRgb from 'hex-rgb';
import ItemsDetails from '@/shared/components/ui/ItemsDetails.vue';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useTenant from '@/shared/composables/useTenant';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutPage',
  emits: ['onFormValidation'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emit = __emit;
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const route = useRoute();
const transactionType = route.query.transactionType;
const checkStock = transactionType === TransactionType.SELF_ORDER ? true : false;
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { convertValutaSync } = useConvertValuta();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const buttonText = ref('');
const formData: any = {
	quantity: 0,
	total: [],
};

// Check user's wallet balance
const { getWallets } = useWallet();
const { refetchWalletsData, wallets } = getWallets();

const {
	items,
	totalItemCount,
	handleCheckout,
	handleCheckoutWithForm,
	totalItemsPrice,
	totalItemsPriceString,
	getTotalPricePerCurrency,
	billingAddressValidationSchema,
} = useCheckout();

const address: Address = {
	country: userProfile.value?.country ?? 'Belgium',
	city: userProfile.value?.city ?? '',
	address_street: userProfile.value?.address_street ?? '',
	address_number: userProfile.value?.address_number ?? '',
	street: userProfile.value?.address_street ?? '',
	number: userProfile.value?.address_number ?? '',
	postal_code: userProfile.value?.postal_code ?? '',
};

// Go back
const goBack = () => {
	router.back();
};

// Validating form
const onInputChange = (formValid: vue.ComputedRef<boolean>) => {
	emit('onFormValidation', formValid);
};

onMounted(async () => {
	await refetchWalletsData();
	const totalPricePerCurrency = getTotalPricePerCurrency();
	if (wallets && wallets.value) {
		const totalCurrenciesNotEnough: any = [];
		wallets.value.filter((wallet) => {
			const matchSubtotalWithCurrentWallet = totalPricePerCurrency.find((subtotal) => {
				return subtotal.currencyName === wallet.currency.name;
			});
			if (matchSubtotalWithCurrentWallet) {
				if (matchSubtotalWithCurrentWallet.total > +wallet.current_balance) {
					totalCurrenciesNotEnough.push({ subtotal: matchSubtotalWithCurrentWallet, wallet });
				}
			}
		});

		if (totalCurrenciesNotEnough.length === 1 && totalCurrenciesNotEnough[0].wallet.currency.topupable) {
			formData.quantity = totalPricePerCurrency.length;
			formData.total = totalPricePerCurrency.map((currency) => {
				return {
					currency_id: currency.currencyId,
					amount: currency.total,
				};
			});
			const currency = totalCurrenciesNotEnough[0];
			const totalToTopUp = Math.abs(currency.wallet.current_balance - currency.subtotal.total);

			buttonText.value = `${t('checkout.top_up')} ${currency.wallet.currency.prefix} ${totalToTopUp} & ${t('checkout.pay')}`;

			if (totalToTopUp > 0) {
				formData.top_up_amount = totalToTopUp;
			}
		} else if (totalCurrenciesNotEnough.length > 1) {
			buttonText.value = 'Not enough balance';
		} else {
			formData.quantity = totalPricePerCurrency.length;
			formData.total = totalPricePerCurrency.map((currency) => {
				return {
					currency_id: currency.currencyId,
					amount: currency.total,
				};
			});
			buttonText.value = t('checkout.pay_now');
		}
	}
});

return (_ctx: any,_cache: any) => {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex items-center justify-start cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack()))
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('checkout.title')), 1)
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h1", _hoisted_6, _toDisplayString(_unref(t)('checkout.title')), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(items)?.length > 0
								? _unref(items)?.length > 1
									? `${Number(_unref(totalItemCount))} ${_unref(t)('checkout.items')}`
									: `${Number(_unref(totalItemCount))} ${_unref(t)('checkout.item')}`
								: _unref(t)('checkout.no_item')), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_unref(items)?.length > 0)
              ? (_openBlock(), _createBlock(ItemsDetails, {
                  key: 0,
                  "border-class": "border-y border-y-white/5",
                  class: "backdrop-blur-xl bg-anykrowd-gray/20 text-white rounded-xl mb-4",
                  "text-class": "text-white",
                  "text-class-two": "text-white/70",
                  title: _unref(t)('checkout.order_details'),
                  items: 
							_unref(items).map((item) => {
								return {
									discount: item.discount,
									itemId: item.itemId,
									title: item.description,
									price: item.unitPrice,
									quantity: item.quantity,
									totalPrice: item.itemPrice,
									totalPriceCurrency: item.additionalInfo.product?.currency?.prefix,
								};
							})
						,
                  "total-title": 
							_unref(items)?.length > 0
								? Number(_unref(totalItemCount)) + ` ${_unref(t)('checkout.items_total')}`
								: _unref(t)('checkout.empty')
						,
                  "total-price-string": _unref(totalItemsPriceString) || '0',
                  "total-price": _unref(totalItemsPrice)
                }, null, 8, ["title", "items", "total-title", "total-price-string", "total-price"]))
              : _createCommentVNode("", true)
          ]),
          (_unref(transactionType) === _unref(TransactionType).SHOP_ORDER)
            ? (_openBlock(), _createBlock(_unref(IonList), {
                key: 0,
                class: "backdrop-blur-xl bg-anykrowd-gray/20 text-white rounded-xl mb-4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('checkout.billing_address.title')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("form", {
                      id: "billingAddressForm",
                      onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(handleCheckoutWithForm) && _unref(handleCheckoutWithForm)(...args))),
                      "validation-schema": _unref(billingAddressValidationSchema)
                    }, [
                      _createVNode(TextInput, {
                        "wrapper-class": "flex flex-wrap justify-left item-center my-2 border border-solid focus-within:background-white-15",
                        name: "country",
                        type: "text",
                        value: address.country,
                        placeholder: _ctx.$t('checkout.billing_address.country'),
                        onInputChanged: onInputChange
                      }, null, 8, ["value", "placeholder"]),
                      _createVNode(TextInput, {
                        "wrapper-class": "flex flex-wrap justify-left item-center my-2 border border-solid focus-within:background-white-15",
                        name: "city",
                        type: "text",
                        value: address.city,
                        placeholder: _ctx.$t('checkout.billing_address.city'),
                        onInputChanged: onInputChange
                      }, null, 8, ["value", "placeholder"]),
                      _createVNode(TextInput, {
                        "wrapper-class": "flex flex-wrap justify-left item-center my-2 border border-solid focus-within:background-white-15",
                        name: "address_street",
                        type: "text",
                        value: address.address_street,
                        placeholder: _ctx.$t('checkout.billing_address.street'),
                        onInputChanged: onInputChange
                      }, null, 8, ["value", "placeholder"]),
                      _createVNode(TextInput, {
                        "wrapper-class": "flex flex-wrap col-md-6 justify-left item-center my-2 border border-solid focus-within:background-white-15",
                        name: "address_number",
                        type: "text",
                        value: address.address_number,
                        placeholder: _ctx.$t('checkout.billing_address.address_number'),
                        onInputChanged: onInputChange
                      }, null, 8, ["value", "placeholder"]),
                      _createVNode(TextInput, {
                        "wrapper-class": "flex flex-wrap col-md-6 justify-left item-center mb-3 mt-2 border border-solid focus-within:background-white-15",
                        name: "postal_code",
                        type: "text",
                        value: address.postal_code,
                        placeholder: _ctx.$t('checkout.billing_address.postal_code'),
                        onInputChanged: onInputChange
                      }, null, 8, ["value", "placeholder"])
                    ], 40, _hoisted_12)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(transactionType) === _unref(TransactionType).PAYMENT)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)('checkout.check')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_unref(transactionType) === _unref(TransactionType).SHOP_ORDER)
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 0,
                disabled: _unref(totalItemsPrice) === 0,
                class: "w-full",
                expand: "full",
                form: "billingAddressForm",
                size: "large",
                shape: "round",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  (_ctx.userWallet)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        (+_unref(totalItemsPrice) > +_ctx.userWallet?.current_balance)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createTextVNode(_toDisplayString(_unref(t)('checkout.top_up')) + " ", 1),
                              (_unref(tenantConfig) && _unref(tenantConfig).custom_valuta_enabled)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(tenantConfig).custom_valuta.prefix) + " " + _toDisplayString(_unref(convertValutaSync)(
										_unref(tenantConfig),
										(+_unref(totalItemsPrice) - +_ctx.userWallet?.current_balance).toFixed(2),
										true,
									)), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(`€${(+_unref(totalItemsPrice) - +_ctx.userWallet?.current_balance).toFixed(2)}`), 1)),
                              _createTextVNode(" & " + _toDisplayString(_unref(t)('checkout.pay')), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(t)('checkout.pay_now')), 1)),
                        _createElementVNode("div", null, [
                          (_unref(tenantConfig) && _unref(tenantConfig).custom_valuta_enabled)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(tenantConfig).custom_valuta.prefix) + " " + _toDisplayString(_unref(convertValutaSync)(_unref(tenantConfig), (+_unref(totalItemsPrice)).toFixed(2) || 0, true)), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_22, "€" + _toDisplayString((+_unref(totalItemsPrice)).toFixed(2) || 0), 1))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_unref(transactionType) !== _unref(TransactionType).SHOP_ORDER)
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 1,
                disabled: buttonText.value === '',
                class: "w-full hover:rounded-full hover:bg-white",
                expand: "full",
                size: "large",
                shape: "round",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(handleCheckout)(_unref(checkStock), formData)))
              }, {
                default: _withCtx(() => [
                  (buttonText.value === '')
                    ? (_openBlock(), _createBlock(_component_ion_spinner, {
                        key: 0,
                        name: "dots"
                      }))
                    : (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(buttonText.value), 1))
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
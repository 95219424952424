import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex grow flex-col items-center justify-center px-[34px]" }
const _hoisted_3 = { class: "flex flex-wrap w-full justify-left item-center p-2 rounded-full background-white-15 mb-4" }
const _hoisted_4 = { class: "flex w-full items-center px-6" }
const _hoisted_5 = { class: "flex flex-col w-full px-[34px] align-center mb-8" }
const _hoisted_6 = { class: "flex w-full items-center justify-between" }

import router from '@/router';
import { IonButton, IonContent, IonIcon, IonInput } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { arrowForwardOutline, chevronBackOutline, qrCodeOutline } from 'ionicons/icons';

// Go back

export default /*@__PURE__*/_defineComponent({
  __name: 'VouchersManualActivatePage',
  setup(__props) {

const goBack = () => {
	router.back();
};

const qrRead = async (link: string) => {
	await router.push({
		name: link,
		query: {
			callbackPage: 'redeem-voucher',
		},
	});
};

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'dashboard' } });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: goBack
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          center: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "flex grow justify-center w-full font-sourcesanspro-regular text-base2 text-white/50" }, " Manual activate ", -1)
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(IonInput), {
                class: "text-center font-sourcesanspro-regular text-l2 tracking-[.20em]",
                name: "voucher-code",
                type: "text",
                placeholder: "xxxx - xxxx - xxxx"
              }),
              _createVNode(_unref(IonIcon), {
                class: "cursor-pointer",
                icon: _unref(qrCodeOutline),
                slot: "end",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (qrRead('qr-reader')))
              }, null, 8, ["icon"])
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-sourcesanspro-regular text-sm2 font-color-text-50" }, "Redeem your voucher code here.", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(IonButton), {
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo('/vouchers/success')))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _cache[4] || (_cache[4] = _createTextVNode(" Continue ")),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(arrowForwardOutline),
                  class: "text-dark-blue mr-2"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
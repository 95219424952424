import { computed, ref, watch } from 'vue';
import { Capacitor } from '@capacitor/core';
import router from '@/router';
import { Browser } from '@capacitor/browser';
import { isPlatform } from '@ionic/vue';
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { anykrowdApi, anykrowdApiBase, anykrowdApiAnonym } from '@/api/anykrowdApi';
import { useAuthStore } from '@/stores/auth/authStore';
import { UserProfile } from '@/shared/interfaces/user';
import { UserProfileUpdate } from '@/shared/interfaces/user';
import type { SignedStorageUploadResponse } from '@/shared/interfaces/storage';
import useAlert from '@/shared/composables/useAlert';
import useAuth from '@/modules/auth/composables/useAuth';
import useLoading from '@/shared/composables/useLoading';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import useQueryCacheHelper from '@/shared/composables/useQueryCacheHelper';
import { Share } from '@capacitor/share';
import useUtils from '@/shared/composables/useUtils';

// Init
const { presentAlert } = useAlert();
const { presentLoading } = useLoading();
const { presentToast } = useToast();
const { getCurrentWindowLocationOrigin } = useUtils();

const _deleteAccount = async () => {
	const { data } = await anykrowdApi.delete('/profile', true);
	return data;
};

// Get user profile api call
const _getUserProfileFromApi = async (): Promise<UserProfile> => {
	const { data } = await anykrowdApi.get<UserProfile>('/profile', true);
	return data;
};

// Get user profile detail api call
const _getUserProfileDetailFromApi = async (userId: number): Promise<UserProfile> => {
	const { data } = await anykrowdApiBase(`/profile/detail/${userId}`);
	return data;
};

/**
 * Helper function that will generate a random user
 */
const _generateRandomUser = (): UserProfile => {
	const dummyUser = new UserProfile();
	// Generate random name
	dummyUser.firstname = 'Guest';
	const randomNumbers = [];
	while (randomNumbers.length < 6) {
		const r = Math.floor(Math.random() * 10) + 1;
		if (randomNumbers.indexOf(r) === -1) {
			randomNumbers.push(r);
		}
	}

	dummyUser.lastname = `#${randomNumbers.join('')}`;

	return dummyUser;
};

// Update profile fields on api
const _updateProfileFieldsOnApi = async (fields: any): Promise<UserProfile> => {
	const { data } = await anykrowdApi.post<UserProfile>('/profile/update-fields', fields, true);
	return data;
};

// Update profile fields
const updateProfileFields = () => {
	// Update
	const {
		data: updateProfileFieldsData,
		mutate: updateProfileFieldsMutate,
		isSuccess: isSuccessUpdateProfileFields,
	} = useMutation(_updateProfileFieldsOnApi);

	// Return
	return {
		isSuccessUpdateProfileFields: computed(() => isSuccessUpdateProfileFields.value),
		updateProfileFieldsData,
		updateProfileFieldsMutate,
	};
};

// Update profile on api
const _updateProfileOnApi = async (profile: UserProfileUpdate | FormData): Promise<UserProfile> => {
	const { data } = await anykrowdApi.post<UserProfile>('/profile', profile, true);
	return data;
};

// Update profile
const updateProfile = () => {
	// Update
	const {
		data: updateProfileData,
		mutateAsync: updateProfileMutate,
		isSuccess: isSuccessUpdateProfile,
	} = useMutation(_updateProfileOnApi);

	// Return
	return {
		isSuccessUpdateProfile: computed(() => isSuccessUpdateProfile.value),
		updateProfileData,
		updateProfileMutate,
	};
};

// store
const store = async (
	file: any,
	options: {
		bucket?: string;
		contentType?: string;
		expires?: string;
		progress?: any;
		signedUrl?: string;
	} = {
		bucket: '',
		contentType: '',
		expires: '',
		progress: '',
		signedUrl: '',
	},
): Promise<SignedStorageUploadResponse> => {
	const signedUrl = options.signedUrl || 'signed-storage-upload';
	const response = await anykrowdApi.post<SignedStorageUploadResponse>(signedUrl, {
		bucket: options.bucket || '',
		content_type: options.contentType || file.type,
		expires: options.expires || '',
	});

	if (typeof options.progress === 'undefined') {
		options.progress = () => {
			console.log('');
		};
	}

	await anykrowdApiAnonym.put(response.data.url, file, {
		onUploadProgress: (progressEvent) => {
			if (progressEvent.total) {
				options.progress(progressEvent.loaded / progressEvent.total);
			}
		},
	});

	response.data.cleanUrl = response.data.url.split('?')[0];
	response.data.extension = file.name.split('.').pop();

	return response.data;
};

// useUserProfile
const useUserProfile = () => {
	// Init
	const userProfile = ref<UserProfile>()!;
	const userProfileDetails = ref<UserProfile>()!;
	const shouldLoadDetails = ref(false);
	const loading = ref();
	const { t } = useI18n();
	const { logoutUser } = useAuth();
	const { isAuthenticated, setGuestUserId } = useAuthStore();
	const { deleteToken } = useAuthStore();
	const { getTenantConfigAndStyles } = useTenant();
	const { tenantConfig } = getTenantConfigAndStyles();
	const queryClient = useQueryClient();

	const {
		isSuccess: isSuccessDeleteAccount,
		isError: isErrorDeleteAccount,
		mutate: deleteAccountMutation,
	} = useMutation(_deleteAccount);

	// Get user profile
	const getUserProfile = () => {
		const {
			data: userProfileData,
			isFetched: isFetchedUserProfile,
			isFetching: isFetchingUserProfile,
			isSuccess: isSuccessUserProfile,
			refetch: refetchUserProfile,
			remove: removeUserProfile,
		} = useQuery({
			queryKey: ['userProfile'],
			queryFn: async () => await _getUserProfileFromApi(),
			retry: 3,
			cacheTime: 1000 * 60 * 5,
			staleTime: 1000 * 60 * 5,
			enabled: isAuthenticated(),
		});

		watch(
			userProfileData,
			async () => {
				if (userProfileData.value) {
					userProfile.value = new UserProfile(userProfileData.value);
					if (userProfile.value.date_of_birth) {
						const parsedDate = DateTime.fromFormat(userProfile.value.date_of_birth, 'yyyy-MM-dd').toFormat(
							'dd-MM-yyyy',
						);
						userProfile.value.date_of_birth = parsedDate;
					}
					shouldLoadDetails.value = true;
				} else if (!userProfile.value && !isAuthenticated()) {
					// If no user is present, generate a random guest user
					const randomUser = _generateRandomUser();
					queryClient.setQueryData(['userProfile'], randomUser);
					setGuestUserId(randomUser.lastname);
					userProfile.value = randomUser;
				}
			},
			{ immediate: true },
		);

		return {
			isFetchedUserProfile: computed(() => isFetchedUserProfile.value),
			isFetchingUserProfile: computed(() => isFetchingUserProfile.value),
			isSuccessUserProfile: computed(() => isSuccessUserProfile.value),
			refetchUserProfile,
			removeUserProfile,
			userProfile,
		};
	};

	// Get user profile details
	const getUserProfileDetails = () => {
		const {
			data: userProfileDataDetails,
			isFetched: isFetchedUserProfileData,
			isSuccess: isSuccessUserProfileData,
		} = useQuery({
			enabled: shouldLoadDetails.value,
			queryKey: ['userProfileDetail'],
			queryFn: () => _getUserProfileDetailFromApi(userProfile.value!.id),
			retry: 3,
		});

		watch(
			userProfileDataDetails,
			() => {
				if (userProfileDataDetails.value) {
					userProfileDetails.value = new UserProfile(userProfileDataDetails.value);
				}
			},
			{ immediate: true },
		);

		return {
			isFetchedUserProfileData: computed(() => isFetchedUserProfileData.value),
			isSuccessUserProfileData: computed(() => isSuccessUserProfileData.value),
			userProfileDetails,
		};
	};

	// deleteAccount
	const deleteAccount = async () => {
		await presentAlert({
			// cssClass: 'my-custom-class',
			header: t('profile.delete_account_alert.header'),
			subHeader: t('profile.delete_account_alert.sub_header'),
			message: t('profile.delete_account_alert.message'),
			inputs: [
				{
					name: 'email',
					type: 'email',
					placeholder: t('profile.delete_account_alert.confirm_input_placeholder'),
				},
			],

			buttons: [
				{
					text: t('profile.delete_account_alert.cancel_button'),
					role: 'cancel',
					cssClass: 'secondary',
					// handler: this.cancelDeletion.bind(this),
				},
				{
					text: t('profile.delete_account_alert.confirm_button'),
					role: 'ok',
					handler: confirmDeletion,
				},
			],
		});
	};

	// Do not async this, we need to return the value directly to be able to cancel the modal close event
	const confirmDeletion = async ({ email }: { email: string }) => {
		if (email !== userProfile.value?.email) {
			await presentToast('top', t('profile.delete_account_alert.wrong_email_supplied'), 5000, 'danger');
			return false;
		} else {
			loading.value = await presentLoading(t('general.please_wait'));
			deleteAccountMutation();
		}
	};

	// openLink
	const openLink = () => {
		if (isPlatform('android')) {
			Browser.open({ url: tenantConfig.value!.native_url_android });
		}

		if (isPlatform('ios')) {
			Browser.open({ url: tenantConfig.value!.native_url_apple });
		}
	};

	// showInstallAppButton
	const showInstallAppButton = (): boolean => {
		// hide / show the install app button based on settings
		// or if the app is already running in a native env we hide the install app button
		if (tenantConfig.value!.enable_install_native_app === false || Capacitor.isNativePlatform()) {
			return false;
		}

		// if there is a native app link set and platform is mobile show the install app
		if (
			(!!tenantConfig.value!.native_url_android && isPlatform('android')) ||
			(!!tenantConfig.value!.native_url_apple && isPlatform('ios'))
		) {
			return true;
		} else {
			return false;
		}
	};

	// Share link to guest top up
	const shareGuestTopup = async () => {
		try {
			const expiresAt = DateTime.now().plus({ days: 1 }).toISO({ includeOffset: false });
			const { data } = await anykrowdApi.post<{ raw_token: string }>(
				'/login-tokens',
				{ expires_at: expiresAt },
				true,
			);

			await Share.share({
				url: `${getCurrentWindowLocationOrigin()}/#/guest-wallet/${data.raw_token}`,
			});
		} catch (error: any) {
			if (error && error.message && error.message !== 'Share canceled') {
				await presentToast('top', error, 5000, 'danger');
			}
		}
	};

	const { removeCaches } = useQueryCacheHelper();

	watch(isSuccessDeleteAccount, async () => {
		await presentToast('top', t('profile.delete_account_alert.api_success'), 5000);
		await deleteToken();
		removeCaches();

		await router.push('/auth/signuphome');
		loading.value.dismiss();
	});

	watch(isErrorDeleteAccount, async () => {
		await loading.value.dismiss();
		await presentToast('top', t('profile.delete_account_alert.api_error'), 5000, 'danger');
	});

	// Return
	return {
		getUserProfile,
		getUserProfileDetails,
		deleteAccount,
		logoutUser,
		openLink,
		showInstallAppButton,
		store,
		updateProfile,
		updateProfileFields,
		shareGuestTopup,
	};
};

export default useUserProfile;

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormStep',
  props: {
	currentStep: {
		type: Number,
		required: true,
	},
	index: {
		type: Number,
		required: true,
	},
	values: Object,
},
  setup(__props) {

const props = __props;

// If this step should be shown
const shouldShow = computed(() => {
	return props.index === props.currentStep;
});

return (_ctx: any,_cache: any) => {
  return (shouldShow.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}
}

})
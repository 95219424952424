import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "px-[34px]" }
const _hoisted_5 = { class: "mb-2 p-4 rounded-md bg-white/10 text-white/60" }
const _hoisted_6 = {
  key: 1,
  class: "px-[34px] grid grid-cols-2 grid-flow-col gap-2 justify-stretch mb-4"
}
const _hoisted_7 = { class: "backdrop-blur-xl bg-white/10 h-[90px] rounded cursor-pointer" }
const _hoisted_8 = { class: "flex flex-col items-center justify-center" }
const _hoisted_9 = { class: "font-sourcesanspro-semibold text-sm2 text-white" }
const _hoisted_10 = {
  key: 0,
  class: "backdrop-blur-xl bg-white/10 h-[90px] rounded cursor-pointer"
}
const _hoisted_11 = { class: "flex flex-col items-center justify-center" }
const _hoisted_12 = { class: "font-sourcesanspro-semibold text-sm2 text-white" }

import { ref } from 'vue';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { cashOutline, chevronBackOutline, helpBuoyOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { Wallet } from '@/shared/interfaces/balance';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import WalletBox from '@/shared/components/WalletBox.vue';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'WalletManagePage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const selectedWallet = ref();
const walletId = route.params.wallet_id?.toString();

const onWalletSet = (wallet: Wallet) => {
	selectedWallet.value = wallet;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('wallet.manage_wallet.title')), 1)
        ]),
        right: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('wallet.manage_wallet.help')), 1)
        ]),
        (_unref(tenantConfig))
          ? (_openBlock(), _createBlock(WalletBox, {
              key: 0,
              user: `${_unref(userProfile)?.firstname} ${_unref(userProfile)?.lastname}`,
              onOnWalletSet: onWalletSet,
              "wallet-id": _unref(walletId)
            }, null, 8, ["user", "wallet-id"]))
          : _createCommentVNode("", true),
        (selectedWallet.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: `/wallet/history/${selectedWallet.value.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonButton), { class: "flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_unref(IonIcon), {
                            class: "mt-1",
                            icon: _unref(cashOutline)
                          }, null, 8, ["icon"]),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('wallet.manage_wallet.transactions')), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              (selectedWallet.value && _unref(tenantConfig)?.enable_refund)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_router_link, {
                      to: `/wallet/refunds/${selectedWallet.value.id}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonButton), { class: "flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_unref(IonIcon), {
                                class: "mt-1",
                                icon: _unref(helpBuoyOutline)
                              }, null, 8, ["icon"]),
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('wallet.manage_wallet.request_refunds')), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})
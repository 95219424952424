import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "relative flex results flex-col items-center justify-center h-[316px]" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col w-full px-[34px] align-center mb-8" }
const _hoisted_5 = { class: "flex w-full items-center justify-between" }

import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'VouchersSuccessPage',
  setup(__props) {

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'dashboard' } });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("div", null, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute -top-14 flex flex-col items-center mb-4 px-[34px]" }, [
            _createElementVNode("div", { class: "mb-2" }, [
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-lg3" }, "Successful Activated!")
            ]),
            _createElementVNode("div", { class: "mb-2" }, [
              _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base text-[#7E738C]" }, "You have successfully activated voucher")
            ])
          ], -1)),
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              src: require('@/statics/svg/vouchers/success.svg'),
              class: "w-[180px] h-[180px]"
            }, null, 8, _hoisted_3)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(IonButton), {
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/vouchers')))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _cache[2] || (_cache[2] = _createTextVNode(" Back to My Voucher ")),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(arrowForwardOutline),
                  class: "text-dark-blue mr-2"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["name"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-sourcesanspro-semibold" }


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsList',
  props: {
	checks: { type: Array, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.checks, (check) => {
    return (_openBlock(), _createElementBlock("button", {
      class: _normalizeClass(["flex justify-center items-center background-white-15 border border-solid border-white-15 rounded-full mb-2 mr-1 px-3 normal-case text-m", [
			{
				'background-primary': check.selected,
				'font-color-button': check.selected,
			},
		]]),
      name: check.name,
      key: check.name
    }, [
      (check.icon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(check.icon), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(check.name), 1)
    ], 10, _hoisted_1))
  }), 128))
}
}

})
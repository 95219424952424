import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, toRef, watch } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { heart, heartOutline } from 'ionicons/icons';

import useFavorite from '@/shared/composables/useFavorite';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'FavoriteButton',
  props: {
	class: { type: String },
	favorite: { type: Boolean, required: true, default: false },
	id: { type: Number as () => number | undefined, required: true },
	type: { type: String, required: true },
},
  emits: ['onFavoriteChanged'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const emit = __emit;
const favorite = ref(props.favorite);
const isFavorite = toRef(props, 'favorite');
const { toggleFavorite } = useFavorite();

// Swap favorite
const swapFavorite = async (event: PointerEvent) => {
	event.preventDefault();
	event.stopPropagation();
	// Update local favorite state
	// Props will not change, but we have an isFavorite ref that is listening
	// If it changes, we will catch the new state in the watch and assign it to the local favorite
	favorite.value = !favorite.value;
	const feedItemType = props.type.includes('event') ? 'event' : props.type;
	await toggleFavorite(feedItemType, props.id!);
	emit('onFavoriteChanged', favorite.value, props.id);
};

// Watch favorite state
watch(isFavorite, () => {
	favorite.value = isFavorite.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonButton), {
    class: _normalizeClass(["transparent ion-no-padding", [
			{
				'w-[30px]': !props.class,
			},
			props.class,
		]]),
    onClick: swapFavorite
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonIcon), {
        class: _normalizeClass(["cursor-pointer text-lg24", [
				{
					'text-white': props.favorite,
				},
			]]),
        icon: favorite.value ? _unref(heart) : _unref(heartOutline)
      }, null, 8, ["class", "icon"])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})
import { defineStore } from 'pinia';

import type { Transaction, TransactionItem } from '@/shared/interfaces/transaction';
import { Currency } from '@/shared/interfaces/balance';

export const useTransactionStore = defineStore('transaction', {
	state: () => ({
		additionalInfo: <string | number>'',
		additionalInfoMore: <string | number>'',
		items: [] as TransactionItem[],
		type: <string | undefined>'',
		subtype: <string | undefined>'',
		totalDescription: <string>'',
		totalPriceString: <string>'',
		totalPriceNumber: <number>0,
		totalPriceWithoutFee: <number>0,
		totalPricePerCurrency: <
			{
				currencyId: any;
				currencyPrefix: any;
				currencyName: any;
				total: number;
			}[]
		>[],
	}),
	getters: {
		getTotalPrice(state) {
			return state.totalPriceNumber;
		},
		totalItemsCount(state) {
			return state.items.reduce((count, item) => count + item.quantity, 0);
		},
	},
	actions: {
		calculateTotalsPerProductCurrency() {
			const groups = this.items.reduce(
				(acc, obj) => {
					const key = obj.additionalInfo?.product?.currency?.name ?? '';
					const curGroup = acc[key] ?? [];
					return { ...acc, [key]: [...curGroup, obj] };
				},
				{} as { [key: string]: TransactionItem[] },
			);

			const totals = Object.keys(groups).map((key: string) => {
				const currency = (groups[key][0].additionalInfo?.product?.currency ?? {}) as Currency;
				return {
					currencyId: currency.id,
					currencyPrefix: currency && currency!.prefix ? currency!.prefix : '',
					currencyName: currency && currency!.name ? currency!.name : '',
					total: groups[key].reduce((a, b: TransactionItem) => {
						return a + b.itemPrice;
					}, 0),
				};
			});
			this.totalPricePerCurrency = totals;

			return totals.reduce(
				(acc: string, obj: { currencyPrefix: string; currencyName: string; total: number }) => {
					return `${acc} ${obj.currencyPrefix} ${obj.total}`;
				},
				'' as string,
			);
		},
		getTotalPricePerCurrency() {
			return this.totalPricePerCurrency;
		},
		getItemsAndTotal() {
			return {
				additionalInfo: this.additionalInfo,
				additionalInfoMore: this.additionalInfoMore,
				items: this.items,
				type: this.type,
				subtype: this.subtype,
				totalDescription: this.totalDescription,
				totalPriceString: this.totalPriceString,
				totalPriceNumber: this.totalPriceNumber,
				totalPriceWithoutFee: this.totalPriceWithoutFee,
			};
		},
		setItemsAndTotal(transaction: Transaction) {
			this.additionalInfo = transaction.additionalInfo;
			this.additionalInfoMore = transaction.additionalInfoMore;
			this.type = transaction.type ? transaction.type : undefined;
			this.subtype = transaction.subtype ? transaction.subtype : undefined;
			this.items = transaction.items;
			this.totalDescription = transaction.totalDescription;
			this.totalPriceString = transaction.totalPriceString;
			this.totalPriceNumber = transaction.totalPriceNumber;
			this.totalPriceWithoutFee = transaction.totalPriceWithoutFee;
		},
		setTotalPriceString(totalPriceString: string) {
			this.totalPriceString = totalPriceString;
		},
		clear() {
			this.$reset();
		},
	},
	persist: true,
});

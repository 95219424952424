import type { RouteRecordRaw } from 'vue-router';

import SignUpHomePage from '@/modules/auth/pages/SignUpHomePage.vue';
import SignUpWithEmail from '@/modules/auth/pages/SignUpWithEmailPage.vue';

import SignInHomePage from '@/modules/auth/pages/SignInHomePage.vue';
import SignInWithEmailPage from '@/modules/auth/pages/SignInWithEmailPage.vue';
import SignInWithPhonePage from '@/modules/auth/pages/SignInWithPhonePage.vue';
import MagicLoginPage from '@/modules/auth/pages/MagicLoginPage.vue';
import SignInPhoneVerificationCodePage from '@/modules/auth/pages/SignInPhoneVerificationCodePage.vue';
import SignUpWithPhonePage from '@/modules/auth/pages/SignUpWithPhonePage.vue';

export const authRoutes: Array<RouteRecordRaw> = [
	{
		path: '/auth/signuphome',
		name: 'sign-up-home',
		component: SignUpHomePage,
	},
	{
		path: '/auth/signupwithemail',
		name: 'sign-up-with-email',
		component: SignUpWithEmail,
	},
	{
		path: '/auth/signupwithphone',
		name: 'sign-up-with-phone',
		component: SignUpWithPhonePage,
	},
	{
		path: '/auth/signinhome',
		name: 'sign-in-home',
		component: SignInHomePage,
	},
	{
		path: '/auth/signinwithemail',
		name: 'sign-in-with-email',
		component: SignInWithEmailPage,
	},
	{
		path: '/auth/signinwithphone',
		name: 'sign-in-with-phone',
		component: SignInWithPhonePage,
	},
	{
		path: '/auth/signinphoneverificationcode/:id',
		name: 'sign-phone-verification-code',
		component: SignInPhoneVerificationCodePage,
	},
	{
		path: '/magic-login/:token',
		name: 'magic-login',
		component: MagicLoginPage,
	},
];

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center px-[34px] pt-16" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex items-start" }
const _hoisted_4 = { class: "flex items-center mr-2" }
const _hoisted_5 = { class: "flex items-center justify-center mb-4" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-center mb-6"
}
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = {
  key: 0,
  class: "flex flex-col mb-2"
}
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "font-sourcesanspro-semibold text-base2" }
const _hoisted_12 = { class: "flex items-center mb-4 font-color-text-50" }
const _hoisted_13 = {
  key: 0,
  class: "flex items-center justify-center pt-3"
}

import { ref } from 'vue';
import { useRoute } from 'vue-router';
import {
	IonContent,
	IonButton,
	IonFooter,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonSearchbar,
	IonSpinner,
	InfiniteScrollCustomEvent,
} from '@ionic/vue';
import { close, search } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import EventsItem from '@/modules/events/components/EventsItem.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useTenant from '@/shared/composables/useTenant';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const eventTypeQueryString = route.query.eventType?.toString();
const logoUrl = window.logoUrl;
const eventType = ref(eventTypeQueryString ? eventTypeQueryString : 'all');
const assetStore = useAssetsStore();
const eventSearch = ref('');
const { getEvents } = useEvents();
const { events, fetchNextPage, hasNextPage, isFetchingEventsDataRequests } = getEvents(eventSearch, eventType);
const showSearch = ref(false);
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();

// Infinite scroll
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
	if (hasNextPage && hasNextPage.value) {
		await fetchNextPage();
	}
	ev.target.complete();
};

const clearSearch = () => {
	eventSearch.value = '';
};

const swapSearch = () => {
	showSearch.value = !showSearch.value;
	setTimeout(() => {
		if (showSearch.value) {
			const searchBar = document.querySelector('.events-searchbar');
			const inputElement = searchBar!.querySelector('input');
			inputElement!.focus();
		}
	}, 500);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-[20px]" }, null, -1)),
              _createElementVNode("div", _hoisted_3, [
                (eventSearch.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (swapSearch())),
                      class: "flex pl-3 pr-4 py-2 items-center ml-2 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-sm"
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(search),
                          class: "w-[20px] h-[20px]"
                        }, null, 8, ["icon"])
                      ]),
                      _createElementVNode("span", null, _toDisplayString(eventSearch.value), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _unref(assetStore).logoUrl,
                class: "max-w-[70%]"
              }, null, 8, _hoisted_6)
            ]),
            (_unref(showLoggedData)())
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(IonButton), {
                    class: _normalizeClass(["mr-2 w-[140px] h-[40px] button-solid rounded-full", eventType.value === 'all' ? 'hover:bg-white' : 'backdrop-blur bg-white/20']),
                    shape: "round",
                    fill: eventType.value === 'all' ? 'default' : 'clear',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (eventType.value = 'all'))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass(["text-base2", eventType.value === 'all' ? '' : 'text-white'])
                      }, _toDisplayString(_unref(t)('events.all_events')), 3)
                    ]),
                    _: 1
                  }, 8, ["class", "fill"]),
                  (_unref(tenantConfig) && !_unref(tenantConfig)?.hide_tickets)
                    ? (_openBlock(), _createBlock(_unref(IonButton), {
                        key: 0,
                        class: _normalizeClass(["w-[140px] h-[40px] button-solid rounded-full", eventType.value !== 'all' ? 'hover:bg-white' : 'backdrop-blur bg-white/20 hover:bg-white/20']),
                        shape: "round",
                        fill: eventType.value !== 'all' ? 'default' : 'clear',
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (eventType.value = 'going'))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            class: _normalizeClass(["text-base2", eventType.value !== 'all' ? '' : 'text-white'])
                          }, _toDisplayString(_unref(t)('events.my_tickets')), 3)
                        ]),
                        _: 1
                      }, 8, ["class", "fill"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, !showSearch.value]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_unref(IonSearchbar), {
              modelValue: eventSearch.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((eventSearch).value = $event)),
              class: "mb-4 backdrop-blur bg-white/20 rounded-full font-sourcesanspro-semibold text-l events-searchbar",
              shape: "round",
              debounce: 500,
              onIonCancel: _cache[4] || (_cache[4] = ($event: any) => (clearSearch())),
              onIonBlur: _cache[5] || (_cache[5] = ($event: any) => (swapSearch()))
            }, null, 8, ["modelValue"]), [
              [_vShow, showSearch.value]
            ]),
            (eventSearch.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(t)('events.autocomplete_suggestions')), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(events)?.filter((event) =>
							event.name.toLowerCase().includes(eventSearch.value.toLowerCase()),
						), (event, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: idx,
                      class: "flex flex-col items-start justify-center font-sourcesanspro-regular text-m2"
                    }, [
                      _createVNode(_component_router_link, {
                        to: `/events/${event.id}`
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_unref(IonIcon), {
                              class: "mr-2",
                              icon: _unref(close)
                            }, null, 8, ["icon"]),
                            _withDirectives(_createElementVNode("div", null, null, 512), [
                              [_directive_dompurify_html, 
										event.name.replaceAll(
											eventSearch.value,
											`<span class='text-white'><b>${eventSearch.value}</b></span>`,
										)
									]
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 512), [
            [_vShow, showSearch.value]
          ]),
          (_unref(isFetchingEventsDataRequests))
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_unref(IonSpinner), { name: "dots" })
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(events), (event, idx) => {
            return (_openBlock(), _createBlock(EventsItem, {
              id: event.id,
              key: idx,
              summary: event.summary ? event.summary : '',
              image: event.main_image_url,
              link: `/events/${event.id}`,
              title: event.name,
              "ticket-count": event.event_tickets_count,
              going: event.going,
              hour: 
					event.start_datetime
						? _unref(DateTime).fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('HH:mm')
						: ''
				,
              location: event.address ? event.address : '',
              date: event.start_datetime ? event.start_datetime : '',
              "show-logged-data": _unref(showLoggedData)()
            }, null, 8, ["id", "summary", "image", "link", "title", "ticket-count", "going", "hour", "location", "date", "show-logged-data"]))
          }), 128))
        ]),
        _createVNode(_unref(IonInfiniteScroll), { onIonInfinite: ionInfinite }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonInfiniteScrollContent))
          ]),
          _: 1
        }),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "events" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
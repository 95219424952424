import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col px-[34px] mb-4"
}
const _hoisted_4 = { class: "flex items-center justify-between mb-4 p-3 backdrop-blur-xl bg-white/10 rounded-lg cursor-pointer" }
const _hoisted_5 = { class: "flex flex-col items-start justify-start" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold" }
const _hoisted_7 = { class: "flex items-center font-sourcesanspro-regular" }
const _hoisted_8 = { class: "text-white/50" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "text-white/50" }
const _hoisted_11 = { class: "flex items-end justify-center" }

import { watchEffect } from 'vue';
import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonRefresher, IonRefresherContent, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTablesListPage',
  setup(__props) {

const { t } = useI18n();
const { getTimeTables } = useTimeTables();
const { isFetchedTimeTables, removeTimeTablesDataFromCache, refetchTimeTables, timeTables } = getTimeTables();

watchEffect(() => {
	if (timeTables && timeTables.value && timeTables.value.length === 1) {
		router.push({ path: `/timeTables/detail/${timeTables.value[0].id}` });
	}
});

// Refresh
const handleRefresh = async (event: any) => {
	removeTimeTablesDataFromCache();
	timeTables.value = undefined;
	await refetchTimeTables();
	event.target.complete();
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), { class: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonRefresher), {
          class: "mt-8",
          slot: "fixed",
          onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRefresherContent))
          ]),
          _: 1
        }),
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", null, null, -1)
          ])),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('timetables.title')), 1)
          ]),
          _: 1
        }),
        (!_unref(isFetchedTimeTables))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (_unref(timeTables))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(timeTables), (timeTable, idx) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: idx,
                  to: `/timetables/detail/${timeTable.id}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(timeTable.name), 1),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(timeTable.startAt.toFormat('ccc dd/MM/yyyy')), 1),
                          (
									timeTable.startAt.toFormat('ccc dd/MM/yyyy') !==
									timeTable.endAt.toFormat('ccc dd/MM/yyyy')
								)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "mx-1 text-white/50" }, "→", -1)),
                                _createElementVNode("span", _hoisted_10, _toDisplayString(timeTable.endAt.toFormat('ccc dd/MM/yyyy')), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(arrowForwardOutline),
                          class: "text-white"
                        }, null, 8, ["icon"])
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "timetables" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
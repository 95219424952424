import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "font-sourcesanspro-regular text-sm text-center text-white/50 leading-[18px]" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_4 = { class: "flex flex-col h-full justify-between px-[34px]" }
const _hoisted_5 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_6 = { class: "flex grow items-center justify-center w-full" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_9 = { class: "flex flex-col items-center justify-center mb-8" }
const _hoisted_10 = { class: "font-sourcesanspro-semibold text-lg2" }
const _hoisted_11 = { class: "font-sourcesanspro-regular text-base2 text-white/50" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col w-[100%] items-start overflow-scroll"
}
const _hoisted_13 = { class: "flex w-full items-center justify-between" }
const _hoisted_14 = { class: "text-m2" }

import { onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import EventsItemMemberAdder from '@/modules/events/components/EventsItemMemberAdder.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useEventsTicketsAddMembers from '@/modules/events/composables/useEventsTicketsAddMembers';
import useTenant from '@/shared/composables/useTenant';
import useEvents from '@/modules/events/composables/useEvents';
import useUserProfile from '@/modules/profile/composables/useUserProfile';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsTicketsAddMembersPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.id;
const isModalOpen = ref(false);
const { calculateTotalsPerProductCurrency, cartTotal, getCartItems } = useCart();
const { processCheckout } = useCheckout();
const { updateTicketData } = useEventsTicketsAddMembers();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { convertValutaSync } = useConvertValuta();
const { getEvent } = useEvents();
const { event } = getEvent(eventId);
const { getUserProfile } = useUserProfile();
const { isFetchedUserProfile, userProfile } = getUserProfile();

// onMountedHook
onMounted(async () => {
	if (event.value) {
		updateTicketData(event.value);
	}
});

const showHideModal = (openState: boolean) => {
	isModalOpen.value = openState;
};

const canStartCheckout = () => {
	const isValid = !getCartItems().filter((cartItem) => !cartItem.additionalInfo.name).length;
	if (!isValid) {
		showHideModal(true);
	} else {
		processCheckout(TransactionType.PAYMENT, eventId);
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(ModalOne, {
        title: `${_unref(t)('ticket.add_members.please_complete_title')}`,
        "is-open": isModalOpen.value
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('ticket.add_members.please_complete_description')), 1)
        ]),
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (showHideModal(false)))
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "is-open"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: `/events/${eventId}/tickets/buy`,
                  class: "flex items-center justify-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('ticket.add_members.ticket_details')), 1)
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h1", _hoisted_10, _toDisplayString(_unref(t)('ticket.add_members.checkout')), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(getCartItems)().length) + " " + _toDisplayString(_unref(t)('ticket.add_members.tickets')), 1)
          ]),
          (_unref(isFetchedUserProfile))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getCartItems)(), (cartItem, idx) => {
                  return (_openBlock(), _createBlock(EventsItemMemberAdder, {
                    key: idx,
                    "should-block-changes": cartItem.additionalInfo.email === _unref(userProfile)?.email,
                    cartItemIndex: idx,
                    cartItemProductId: cartItem.product.id,
                    "cart-item": cartItem,
                    eventId: eventId,
                    class: "w-full"
                  }, null, 8, ["should-block-changes", "cartItemIndex", "cartItemProductId", "cart-item"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "mb-8",
          onClick: _withModifiers(canStartCheckout, ["prevent"])
        }, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:rounded-full hover:bg-white",
            disabled: !!_unref(getCartItems)().filter((cartItem) => !cartItem.additionalInfo.name).length,
            expand: "full",
            size: "large",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)('events.checkout')), 1),
                _createElementVNode("span", null, _toDisplayString(_unref(calculateTotalsPerProductCurrency)()), 1)
              ])
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/statics/svg-delete-after-implement/qr-code.svg'


const _hoisted_1 = { class: "ticket-detail-item min-w-[300px] h-[620px]" }
const _hoisted_2 = { class: "absolute flex top-[232px] left-0 right-0 items-center justify-center" }
const _hoisted_3 = { class: "flex px-6 py-4 bg-black rounded-full font-sourcesanspro-semibolditalic text-xs2 text-white/70" }
const _hoisted_4 = { class: "px-6 text-dark-blue/70" }
const _hoisted_5 = { class: "flex items-center justify-between mb-4" }
const _hoisted_6 = { class: "flex items-center justify-between mb-4" }
const _hoisted_7 = { class: "flex items-center justify-between mb-4" }

import { IonIcon } from '@ionic/vue';
import { calendarClearOutline, linkOutline, locationOutline, timeOutline } from 'ionicons/icons';

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketDetailItem',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex items-center justify-center h-[256px]" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "w-[180px]"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(IonIcon), {
          icon: _unref(linkOutline),
          class: "mr-2 text-white"
        }, null, 8, ["icon"]),
        _cache[0] || (_cache[0] = _createTextVNode(" Your wallet linked "))
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex mt-10 items-center justify-center mb-8" }, [
      _createElementVNode("div", { class: "flex flex-col items-center justify-center font-sourcesanspro-semibold text-dark-blue" }, [
        _createElementVNode("span", { class: "text-lg24" }, "Derek McLean"),
        _createElementVNode("span", { class: "text-xs2" }, "Le Noël de Monsieur")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_4, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex items-center justify-between mb-4" }, [
        _createElementVNode("span", { class: "font-sourcesanspro-bold text-base" }, "TYPE"),
        _createElementVNode("div", { class: "flex h-[24px] px-3 items-center justify-center bg-anykrowd-ticket-type-green rounded-md" }, [
          _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 text-white" }, "Copper")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_5, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "font-sourcesanspro-bold text-base" }, "DATE"),
          _createElementVNode("span", { class: "font-sourcesanspro-regular text-base2" }, "Friday 23 Dec, 2022")
        ], -1)),
        _createVNode(_unref(IonIcon), {
          icon: _unref(calendarClearOutline),
          class: "text-dark-blue"
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "font-sourcesanspro-bold text-base" }, "TIME"),
          _createElementVNode("span", { class: "font-sourcesanspro-regular text-base2" }, "12:30pm")
        ], -1)),
        _createVNode(_unref(IonIcon), {
          icon: _unref(timeOutline),
          class: "text-dark-blue"
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "font-sourcesanspro-bold text-base" }, "VENUE"),
          _createElementVNode("span", { class: "font-sourcesanspro-regular text-base2" }, "Antwerps Sportpalies, BE")
        ], -1)),
        _createVNode(_unref(IonIcon), {
          icon: _unref(locationOutline),
          class: "text-dark-blue"
        }, null, 8, ["icon"])
      ])
    ])
  ]))
}
}

})
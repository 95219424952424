import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-between h-full" }
const _hoisted_2 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_3 = { class: "flex items-center justify-center" }

import { useRoute } from 'vue-router';
import { IonContent, IonButton, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';
import useToast from '@/shared/composables/useToast';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'RfidGuestLinkBraceletPage',
  setup(__props) {

const { t } = useI18n();
const { onScanQR } = useRfidGuest();
const route = useRoute();
const amount = route.query.amount?.toString();
const encryptedRfidTag = route.query.encryptedRfidTag?.toString();
const walletId = route.query.walletId?.toString();
const rfidTag = route.query.rfidTag?.toString();
const { presentToast } = useToast();

const onScanQRPrepare = async (scannedQrQcode: string) => {
	if (encryptedRfidTag && walletId && rfidTag && amount) {
		onScanQR(scannedQrQcode, encryptedRfidTag, walletId, rfidTag, amount);
	} else {
		await presentToast('top', t('rfid.guest.scan_error'), 5000, 'danger');
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: "/dashboard",
                class: "flex items-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ScanQR, {
            onOnScanQR: onScanQRPrepare,
            "start-camera": true,
            permissionTitle: _unref(t)('scan_qr.camera_permission_title'),
            permissionDescription: _unref(t)('scan_qr.camera_permission_description')
          }, null, 8, ["permissionTitle", "permissionDescription"])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[200px]" }, null, -1))
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "font-sourcesanspro-semibold text-lg2" }

import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'NewsHeader',
  setup(__props) {

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('news.header.title')), 1)
  ]))
}
}

})
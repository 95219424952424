import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = {
  key: 0,
  class: "px-[34px] mb-2"
}
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_7 = { class: "font-sourcesanspro-regular text-base font-color-text-50" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_10 = { class: "font-sourcesanspro-regular text-base font-color-text-50" }
const _hoisted_11 = { class: "flex justify-right" }
const _hoisted_12 = {
  key: 1,
  class: "flex items-center justify-center mb-4"
}
const _hoisted_13 = { class: "flex flex-col items-center" }
const _hoisted_14 = {
  key: 0,
  class: "w-full mb-4 h-px bg-white/10 border-0"
}

import { useRoute } from 'vue-router';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, share } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import BottomGradient from '@/shared/components/ui/BottomGradient.vue';
import TicketItem from '@/shared/components/tickets/TicketItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useSharedStore } from '@/stores/shared/sharedStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsTicketsPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.id;
const { addToCalendar, getEvent, getEventTickets } = useEvents();
const { event } = getEvent(eventId);
const { eventTickets, isFetchingEventTickets } = getEventTickets(eventId);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const sharedStore = useSharedStore();

// Save previous visited page if is the first time the page is visited
// as go back is not always the same page
if (!sharedStore.customNavigation.find((item) => item.key === route.path) && router.options.history.state.back) {
	sharedStore.customNavigation.push({ key: route.path, value: router.options.history.state.back?.toString() });
}

// Handle go back
const goBack = async () => {
	const previousRoute = sharedStore.customNavigation.find((item) => item.key === route.path)?.value;
	const indexOfPreviousRoute = sharedStore.customNavigation.findIndex((item) => item.key === route.path);
	if (indexOfPreviousRoute != -1) {
		sharedStore.customNavigation.splice(indexOfPreviousRoute, 1);
		await router.push({ path: previousRoute! });
	} else {
		await router.push({ path: '/dashboard' });
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "flex items-center justify-center",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack()))
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(event)?.name), 1)
        ]),
        _: 1
      }),
      (_unref(event))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('dd')), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('MMMM')), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('cccc')), 1),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(DateTime).fromFormat(_unref(event).start_datetime, 'yyyy-MM-dd HH:mm:ss').toFormat('hh:ss a')) + " - " + _toDisplayString(_unref(t)('events.tickets.end')), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_unref(IonButton), {
                  class: "ion-no-margin ion-no-padding",
                  fill: "clear",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(addToCalendar)(_unref(event))))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      src: require('@/statics/svg/events/calendar-add.svg'),
                      class: "h-[54px] w-[54px]"
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(isFetchingEventTickets))
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_unref(IonSpinner), { name: "dots" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventTickets), (ticket, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            class: "flex flex-col w-full items-center justify-center"
          }, [
            _createVNode(TicketItem, {
              "qr-code": ticket.qr_code,
              link: `/events/${_unref(event)?.id}/tickets/${ticket.id}/details`,
              name: 
						ticket.participant?.full_name
							? ticket.participant?.full_name
							: _unref(userProfile)?.full_name
								? _unref(userProfile)?.full_name
								: ''
					,
              type: 
						ticket.combo_ticket_type
							? ticket.combo_ticket_type.name
							: ticket.ticket_type?.name
								? ticket.ticket_type?.name
								: ''
					,
              venue: ticket.event.address,
              "value-only": !!ticket.ticket_type?.value_only,
              class: "mb-4"
            }, null, 8, ["qr-code", "link", "name", "type", "venue", "value-only"]),
            (idx === 0)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_14))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createVNode(BottomGradient)
    ]),
    _: 1
  }))
}
}

})
import { computed, ref, watch } from 'vue';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/vue-query';
import { DateTime } from 'luxon';

import { anykrowdApi } from '@/api/anykrowdApi';
import type { TopUpTransactionMetadata } from '@/shared/interfaces/topUp';
import { TopUpType } from '@/shared/interfaces/topUp';
import { TransactionType } from '@/enums/wallet.enum';
import { useAuthStore } from '@/stores/auth/authStore';
import type { MinimumTopUpAmount, PreviousTopUpAmount } from '@/shared/interfaces/balance';
import { CurrentBalance, RefundInfo, Wallet } from '@/shared/interfaces/balance';
import type { RefundRequestResource } from '@/shared/interfaces/refundRequest';
import type {
	WalletPackage,
	WalletQr,
	WalletTransaction,
	WalletTransactionOverview,
	WalletTransactions,
} from '@/shared/interfaces/wallet';
import useQueryCacheHelper from '@/shared/composables/useQueryCacheHelper';
import { Order, Orders } from '@/shared/interfaces/wallet';
import useStorageService from '@/shared/composables/useStorageService';
import { wallet } from 'ionicons/icons';
import { storeToRefs } from 'pinia';

const { get, set } = useStorageService();

const cancelRefund = async (requestId: string | number) => {
	const { data } = await anykrowdApi.delete(`/wallet/refund-request/${requestId}`, true);
	return data;
};

// Get packages api call
const getWalletPackagesApiCall = async (): Promise<WalletPackage[]> => {
	const { data } = await anykrowdApi.get<WalletPackage[]>('/wallet/wallet-packages', false);
	return data;
};

// Get Packages
const getPackages = () => {
	const walletPackages = ref<WalletPackage[]>([]);

	const {
		data: packagesData,
		isFetched,
		isLoading,
		refetch: refetchPackagesData,
	} = useQuery({
		queryKey: ['walletPackages'],
		queryFn: () => getWalletPackagesApiCall(),
		retry: 3,
		enabled: false,
	});

	watch(
		packagesData,
		() => {
			if (packagesData.value) {
				walletPackages.value = [...packagesData.value];

				// Update cache
				set('walletPackages', packagesData.value);
			}
		},
		{ immediate: true },
	);

	return {
		isFetched,
		isLoading,
		refetchPackagesData,
		walletPackages,
	};
};

// Get refund requests from api
const _getRefundRequestsFromApi = async (pageParam: number, walletId: string): Promise<any> => {
	const { data } = await anykrowdApi.get<RefundRequestResource[]>(`/wallet/${walletId}/refund-requests`, true, {
		params: { page: pageParam },
	});
	return data;
};

// Get refund requests
const getRefundRequests = (walletId: string) => {
	// Init
	const walletRefundRequests = ref<RefundRequestResource[]>()!;

	const {
		data: walletRefundRequestsData,
		isFetched: isFetchedWalletRefundRequests,
		isFetching: isFetchingWalletRefundRequests,
		isLoading: isLoadingRefundRequests,
		refetch: refetchWalletRefundRequests,
		remove: removeWalletRefundRequestsFromCache,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: [`walletRefundRequests-${walletId}`],
		queryFn: ({ pageParam = 1 }) => _getRefundRequestsFromApi(pageParam, walletId),
		getNextPageParam: (lastPage) => {
			return lastPage.meta.last_page >= lastPage.meta.current_page + 1
				? lastPage.meta.current_page + 1
				: undefined;
		},
		retry: 3,
	});

	watch(
		walletRefundRequestsData,
		() => {
			if (walletRefundRequestsData.value) {
				walletRefundRequests.value = [...walletRefundRequestsData.value.pages.map((page) => page.data).flat()];
			}
		},
		{ immediate: true },
	);

	return {
		fetchNextPage,
		hasNextPage,
		isFetchedWalletRefundRequests,
		isFetchingWalletRefundRequests,
		isLoadingRefundRequests,
		refetchWalletRefundRequests,
		removeWalletRefundRequestsFromCache,
		walletRefundRequests,
	};
};

// Transaction Download Link
const getTransactionDownloadLink = async (transactionId: string | number): Promise<{ download_link: string }> => {
	const { data } = await anykrowdApi.get<{ download_link: string }>(
		`/wallet/transaction/${transactionId}/download`,
		false,
	);
	return data;
};

const getWalletsFromApi = async (): Promise<Wallet[]> => {
	const { data } = await anykrowdApi.get<Wallet[]>('/wallets', false);
	return data;
};

const getWallets = () => {
	const { isAuthenticated } = useAuthStore();

	const { getScopedQueryKey } = useQueryCacheHelper();
	const {
		data: wallets,
		dataUpdatedAt: walletUpdatedAt,
		isFetched,
		isFetching,
		isSuccess,
		refetch: refetchWalletsData,
	} = useQuery({
		queryKey: getScopedQueryKey(['wallets']),
		queryFn: () => getWalletsFromApi(),
		retry: 3,
		enabled: isAuthenticated(),
	});

	watch(
		wallets,
		async () => {
			if (wallets.value) {
				// Update cache
				const key = 'wallets';
				let keys = await get('cachedDataKeys');
				if (!keys) {
					keys = [key];
				} else {
					if (!keys.includes(key)) {
						keys.push(key);
					}
				}
				await set('cachedDataKeys', keys);

				set('wallets', wallets.value);
			}
		},
		{ immediate: true },
	);

	return {
		walletsFetched: isFetched,
		fetchingWallets: isFetching,
		walletsSuccess: isSuccess,
		refetchWalletsData,
		wallets,
		walletUpdatedAt,
	};
};

const getWallet = (walletId: string) => {
	const wallet = ref<Wallet>()!;
	const { getScopedQueryKey } = useQueryCacheHelper();

	const {
		data: walletsData,
		isFetched,
		isFetching,
		isSuccess,
		refetch: refetchWalletsData,
	} = useQuery({
		queryKey: getScopedQueryKey([`wallet-${walletId}`]),
		queryFn: () => getWalletsFromApi(),
		retry: 3,
	});

	watch(
		walletsData,
		() => {
			if (walletsData.value) {
				walletsData.value.find((w) => {
					if (w.id.toString() === walletId) {
						wallet.value = { ...w };
					}
				});
			}
		},
		{ immediate: true },
	);

	return {
		walletsFetched: computed(() => isFetched.value),
		fetchingWallets: computed(() => isFetching.value),
		walletsSuccess: computed(() => isSuccess.value),
		refetchWalletsData,
		wallet,
	};
};

// Get wallet current balance Api call
const getWalletCurrentBalanceFromApi = async (
	walletId: string,
	includeLatestTransaction = false,
): Promise<CurrentBalance> => {
	const { data } = await anykrowdApi.get<CurrentBalance>(
		`/wallet/${walletId}/current-balance${includeLatestTransaction ? '?fromMollie=true' : ''}`,
		false,
	);
	return data;
};

// Get wallet current balance
const getWalletCurrentBalance = (walletId: string, includeLatestTransaction = false, isEnabled = false) => {
	const userWallet = ref<CurrentBalance>()!;
	const topUpMetadata = ref<TopUpTransactionMetadata>();
	const authStore = useAuthStore();

	const {
		data: userWalletData,
		isFetched,
		isFetching,
		isSuccess,
		refetch: refetchWalletData,
	} = useQuery({
		queryKey: [`userWallet-${walletId}`],
		queryFn: () => getWalletCurrentBalanceFromApi(walletId, includeLatestTransaction),
		retry: 3,
		enabled: false,
	});

	// Get packages
	const { refetchPackagesData, walletPackages } = getPackages();

	if (!!(authStore.user && authStore.user.token) && isEnabled) {
		refetchWalletData();
		refetchPackagesData();
	}

	// Watch both wallet data and packages
	watch(
		[userWalletData, walletPackages],
		() => {
			if (userWalletData.value && isFetched.value) {
				userWallet.value = { ...userWalletData.value };

				let topUpAmount = userWalletData.value.latest_topup_order?.price || '';

				// If there is an extra attribute, search for packages and set the actual_value for price
				if (userWalletData.value.latest_topup_order?.extras) {
					const topUpExtras = JSON.parse(userWalletData.value.latest_topup_order.extras);
					if (topUpExtras.wallet_package_id) {
						const walletPackage = walletPackages.value.find((w) => w.id === topUpExtras.wallet_package_id);
						if (walletPackage) {
							topUpAmount = walletPackage.actual_value;
						}
					}
				}

				// Prepare topUpMetadata
				topUpMetadata.value = {
					transactionId: '',
					paymentAmount: +topUpAmount,
					paymentStatus: userWalletData.value.latest_topup_order?.payment_status || '',
					transactionType: userWalletData.value.latest_topup_order?.type || TopUpType.UNDEFINED,
				};
			}
		},
		{ immediate: true },
	);

	return {
		userWallet,
		isFetchedUserWallet: computed(() => isFetched.value),
		isFetchingUserWallet: computed(() => isFetching.value),
		isSuccessUserWallet: computed(() => isSuccess.value),
		topUpMetadata,
	};
};

// getTransactionItemName
const getTransactionItemName = (transactionData: WalletTransaction): string => {
	switch (transactionData.type) {
		case TransactionType.PAYMENT: {
			return 'components.transaction.type.payment';
		}
		case TransactionType.VOUCHER_TOP_UP: {
			return 'components.transaction.type.voucher_top_up';
		}
		case TransactionType.TOP_UP: {
			return 'components.transaction.type.top_up';
		}
		case TransactionType.TOP_UP_COST: {
			return 'components.transaction.type.top_up_cost';
		}
		case TransactionType.REFUNDED_AMOUNT: {
			return 'components.transaction.type.refunded_amount';
		}
		case TransactionType.REFUND_HOLDING: {
			return 'components.transaction.type.refund_holding';
		}
		case TransactionType.REFUND_TRANSACTION_COST: {
			return 'components.transaction.type.refund_transaction_cost';
		}
		case TransactionType.REVERT_REFUND_HOLDING: {
			return 'components.transaction.type.revert_refund_holding';
		}
		case TransactionType.RELEASE_REFUND_HOLDING: {
			return 'components.transaction.type.release_refund_holding';
		}
		case TransactionType.WALLET_MERGE: {
			return 'components.transaction.type.wallet_merge';
		}
		default: {
			return transactionData.reference;
		}
	}
};

// Get guest wallet order Api call
const _getGuestWalletOrders = async (page: number, per_page: number, walletId?: string): Promise<any> => {
	const { data } = await anykrowdApi.get<Orders>(
		`/rfid-tags/${walletId}/orders?page=${page}&per_page=${per_page}&wallet_id=${walletId}&order_by=created_at`,
		false,
	);
	return data;
};

// Get wallet order Api call
const _getWalletOrders = async (page: number, per_page: number, walletId?: string): Promise<any> => {
	const { data } = await anykrowdApi.get<Orders>(
		`/orders?page=${page}&per_page=${per_page}&wallet_id=${walletId}&order_by=created_at`,
	);
	return data;
};

// Get wallet transactions Api call
const _getWalletTransactions = async (page: number, walletId?: string): Promise<WalletTransactions> => {
	const { data } = await anykrowdApi.get<WalletTransactions>(
		`/wallet/${walletId}/recent-transactions?page=${page}&paginated=1`,
	);
	return data;
};

// _getGuestWalletTransactions
const _getGuestWalletTransactions = async (page: number, encryptedRfidTag: string): Promise<WalletTransactions> => {
	const { data } = await anykrowdApi.get(
		`/rfid-tags/${encryptedRfidTag}/wallet-history?page=${page}&paginated=1`,
		false,
	);
	return data;
};

// Get wallet transactions
const getWalletTransactions = (encryptedRfidTag?: string, walletId?: string) => {
	// Init
	const page = ref(1);
	const walletTransactions = ref<WalletTransactions>()!;

	const {
		data: walletTransactionsData,
		isFetched,
		isLoading,
		isSuccess: isSuccessWalletTransactions,
		refetch,
		remove,
	} = useQuery({
		queryKey: [`walletTransactions-${walletId}`],
		queryFn: () =>
			encryptedRfidTag
				? _getGuestWalletTransactions(page.value, encryptedRfidTag)
				: _getWalletTransactions(page.value, walletId),
		retry: 3,
	});

	watch(
		walletTransactionsData,
		() => {
			if (walletTransactionsData.value) {
				walletTransactions.value = { ...walletTransactionsData.value };
			}
		},
		{ immediate: true },
	);

	return {
		isFetched,
		isLoading,
		isSuccessWalletTransactions: computed(() => isSuccessWalletTransactions.value),
		refetchGetWalletTransactions: refetch,
		removeGetWalletTransactionsResultsFromCache: remove,
		walletTransactions,
	};
};

// Get wallet transactions
const getWalletOrders = (encryptedRfidTag?: string, walletId?: string) => {
	// Init
	const page = ref(1);
	const walletOrders = ref<Order[]>()!;

	const {
		data: walletOrdersData,
		fetchNextPage,
		hasNextPage,
		isFetched,
		isLoading,
		isSuccess: isSuccessWalletOrders,
		refetch,
		remove,
	} = useInfiniteQuery({
		queryKey: [`walletOrders-${walletId}`],
		queryFn: ({ pageParam = 1 }) =>
			encryptedRfidTag
				? _getGuestWalletOrders(pageParam, 10, encryptedRfidTag)
				: _getWalletOrders(pageParam, 10, walletId),
		getNextPageParam: (lastPage) => {
			return lastPage.meta.last_page >= lastPage.meta.current_page + 1
				? lastPage.meta.current_page + 1
				: undefined;
		},
		retry: 3,
	});

	watch(
		walletOrdersData,
		() => {
			if (walletOrdersData.value) {
				walletOrders.value = [...walletOrdersData.value.pages.map((page) => page.data).flat()];
			}
		},
		{ immediate: true },
	);

	return {
		fetchNextPage,
		hasNextPage,
		isFetched,
		isLoading,
		isSuccessWalletOrders: computed(() => isSuccessWalletOrders.value),
		refetchGetWalletOrders: refetch,
		removeGetWalletOrdersResultsFromCache: remove,
		walletOrders,
	};
};

// Get wallet transactions Api call
const _getWalletTransaction = async (transactionId: string): Promise<WalletTransactionOverview> => {
	const { data } = await anykrowdApi.get<WalletTransactionOverview>(`/wallet/transaction/${transactionId}`, false);
	return data;
};

// Get wallet order Api call
const _getWalletOrder = async (orderId: string): Promise<Order> => {
	const { data } = await anykrowdApi.get<Order>(`/orders/${orderId}`, false);
	return data;
};

// _getGuestWalletTransaction
const _getGuestWalletTransaction = async (
	transactionId: string,
	encryptedRfidTag: string,
): Promise<WalletTransactionOverview> => {
	const { data } = await anykrowdApi.get(
		`/rfid-tags/${encryptedRfidTag}/transactions/${transactionId}/overview`,
		false,
	);
	return data;
};

// Get wallet transaction
const getWalletTransaction = (transactionId: string, encryptedRfidTag?: string) => {
	const shouldLoad = ref(false);
	const walletTransaction = ref<WalletTransactionOverview>()!;

	const {
		data: walletTransactionData,
		isFetched,
		isLoading,
		isSuccess: isSuccessWalletTransaction,
	} = useQuery({
		queryKey: ['walletTransaction', transactionId],
		queryFn: () =>
			encryptedRfidTag
				? _getGuestWalletTransaction(transactionId, encryptedRfidTag)
				: _getWalletTransaction(transactionId),
		enabled: shouldLoad,
		retry: 3,
	});

	watch(
		walletTransactionData,
		() => {
			if (walletTransactionData.value) {
				walletTransaction.value = { ...walletTransactionData.value };
			}
		},
		{ immediate: true },
	);

	return {
		isFetched,
		isLoading,
		isSuccessWalletTransaction: computed(() => isSuccessWalletTransaction.value),
		shouldLoad,
		walletTransaction,
	};
};

// _getGuestWalletTransaction
const _getGuestOrderTransaction = async (
	transactionId: string,
	encryptedRfidTag: string,
): Promise<WalletTransactionOverview> => {
	const { data } = await anykrowdApi.get(`/rfid-tags/${encryptedRfidTag}/orders/${transactionId}`, false);
	return data;
};

// Get wallet transactions Api call
const _getOrderTransaction = async (orderId: string): Promise<WalletTransactionOverview> => {
	const { data } = await anykrowdApi.get<WalletTransactionOverview>(`/orders/${orderId}`, false);
	return data;
};

// Get wallet transaction
const getWalletOrder = (orderId: string, encryptedRfidTag?: string) => {
	const shouldLoad = ref(false);
	const walletOrder = ref<any>()!;

	const {
		data: walletOrderData,
		isFetched,
		isLoading,
		isSuccess: isSuccessWalletTransaction,
	} = useQuery({
		queryKey: ['walletOrder', orderId],
		queryFn: () =>
			encryptedRfidTag ? _getGuestOrderTransaction(orderId, encryptedRfidTag) : _getOrderTransaction(orderId),
		enabled: shouldLoad,
		retry: 3,
	});

	watch(
		walletOrderData,
		() => {
			if (walletOrderData.value) {
				walletOrder.value = { ...walletOrderData.value };
			}
		},
		{ immediate: true },
	);

	return {
		isFetched,
		isLoading,
		isSuccessWalletTransaction: computed(() => isSuccessWalletTransaction.value),
		shouldLoad,
		walletOrder,
	};
};

// Get minimum top up api call
const getMinimumTopUpAmountApiCall = async (): Promise<MinimumTopUpAmount> => {
	const { data } = await anykrowdApi.get<MinimumTopUpAmount>('/wallet/minimum-topup-amount', false);

	// Update cache
	set('walletMinimumTopUpAmount', data);

	return data;
};

// Get minimum top up amount
const getMinimumTopUpAmount = () => {
	const {
		data: minimumTopUp,
		isFetched,
		isLoading,
	} = useQuery({
		queryKey: ['walletMinimumTopUpAmount'],
		queryFn: () => getMinimumTopUpAmountApiCall(),
		retry: 3,
	});

	return {
		isFetchedMinimumTopAmount: computed(() => isFetched.value),
		isLoading,
		minimumTopUp,
	};
};

// Get previous top up api call
const getPreviousTopUpAmountApiCall = async (walletId?: string): Promise<PreviousTopUpAmount> => {
	const { data } = await anykrowdApi.get<PreviousTopUpAmount>(`/wallet/${walletId}/previous-topup-amount`, false);
	return data;
};

// Get previous top up amount
const getPreviousTopUpAmount = (walletId?: string, enabled?: boolean) => {
	const previousTopUp = ref<PreviousTopUpAmount>()!;

	const {
		data: previousTopUpData,
		isFetched,
		isLoading,
	} = useQuery({
		queryKey: ['walletPreviousTopUpAmount'],
		queryFn: () => getPreviousTopUpAmountApiCall(walletId),
		retry: 3,
		enabled: enabled,
	});

	watch(
		previousTopUpData,
		() => {
			if (previousTopUpData.value) {
				previousTopUp.value = { ...previousTopUpData.value };
			}
		},
		{ immediate: true },
	);

	return {
		isFetched,
		isLoading,
		previousTopUp,
	};
};

// Top up Api call
const topUp = async (transactionData: { amount: string; redirectUrl: string }): Promise<{ redirectUrl: string }> => {
	const { data } = await anykrowdApi.post<{ redirectUrl: string }>('/wallet/top-up', transactionData, true);
	return data;
};

// Guest top up
const guestTopUp = async (transactionData: {
	encrypted_rfid_tag?: string;
	amount: string;
	redirectUrl: string;
	payconiq: boolean;
	login_token?: string;
}): Promise<{ redirectUrl: string }> => {
	const { data } = await anykrowdApi.post<{ redirectUrl: string }>('/wallet/guest-top-up', transactionData, false);
	return data;
};

// topUpWalletPackage
const topUpWalletPackage = async (transactionData: {
	walletPackageId: number;
	redirectUrl: string;
}): Promise<{ redirectUrl: string }> => {
	const { data } = await anykrowdApi.post<{ redirectUrl: string }>('/wallet/top-up-wallet-package', transactionData);
	return data;
};

// guestTopUpWalletPackage
const guestTopUpWalletPackage = async (transactionData: {
	encrypted_rfid_tag?: string;
	walletPackageId: number;
	redirectUrl: string;
	login_token?: string;
}): Promise<{ redirectUrl: string }> => {
	const { data } = await anykrowdApi.post<{ redirectUrl: string }>(
		'/wallet/guest-top-up-wallet-package',
		transactionData,
		false,
	);
	return data;
};

// getNewPaymentQrCode Api call
const _getNewPaymentQrCode = async (): Promise<WalletQr> => {
	const { data } = await anykrowdApi.get<WalletQr>('/wallet/generate-payment-qr-code', true);
	return data;
};

// getWalletQrCode
const getWalletQrCode = () => {
	// Init
	const walletQrCode = ref<WalletQr>();
	// const { isOffline, getApiErrorString } = useUtils();
	const queryClient = useQueryClient();
	const authStore = useAuthStore();
	const isAuthenticated = authStore.isAuthenticated();

	const {
		data: walletData,
		isFetched: isFetchedWalletQrCode,
		isLoading: isLoadingWalletQrCode,
		isError: isErrorWalletQrCode,
		error: errorWalletQrCode,
		refetch: refetchWalletQrCode,
	} = useQuery({
		queryKey: ['walletQrCode'],
		queryFn: () => _getNewPaymentQrCode(),
		retry: 3,
		enabled: isAuthenticated,
		cacheTime: 1000 * 60 * 60 * 24 * 365,
	});

	watch(
		walletData,
		async () => {
			let shouldInvalidate = false;
			if (walletData.value) {
				walletQrCode.value = walletData.value;

				// Get now and valid until dates
				const dateNow = DateTime.now();
				const dateValidUntil = DateTime.fromFormat(walletData.value.valid_until, 'yyyy-MM-dd HH:mm:ss');

				// Check if comparison is valid and difference is greater than zero
				if (dateNow.diff(dateValidUntil).isValid && dateValidUntil.diff(dateNow).milliseconds > 0) {
					shouldInvalidate = false;
				} else {
					shouldInvalidate = true;
				}

				// If should invalidate cache
				if (shouldInvalidate && isAuthenticated) {
					// Invalidate every query with a key that starts with `walletQrCode`
					queryClient.invalidateQueries({ queryKey: ['walletQrCode'] });
					refetchWalletQrCode();
				}

				// Update cache
				const key = 'walletQrCode';
				let keys = await get('cachedDataKeys');
				if (!keys) {
					keys = [key];
				} else {
					if (!keys.includes(key)) {
						keys.push(key);
					}
				}
				await set('cachedDataKeys', keys);

				set('walletQrCode', walletData.value);
			}
		},
		{ immediate: true },
	);

	return {
		errorWalletQrCode,
		isErrorWalletQrCode: computed(() => isErrorWalletQrCode.value),
		isFetchedWalletQrCode,
		isLoadingWalletQrCode,
		refetchWalletQrCode,
		walletQrCode,
	};
};

const _getRefundInfoApicall = async (walletId: string): Promise<RefundInfo> => {
	const { data } = await anykrowdApi.get<RefundInfo>(`/wallet/${walletId}/refund-request`, false);
	return data;
};

const getRefundInfo = (walletId: string) => {
	const refundInfo = ref<RefundInfo>();

	const {
		data: refundInfoData,
		isFetched,
		isLoading,
	} = useQuery({
		queryKey: [`refundInfo-${walletId}`],
		queryFn: () => _getRefundInfoApicall(walletId),
		retry: 3,
	});

	watch(
		refundInfoData,
		() => {
			if (refundInfoData.value) {
				refundInfo.value = new RefundInfo(refundInfoData.value);
			}
		},
		{ immediate: true },
	);

	return {
		isFetchedRefundInfo: computed(() => isFetched.value),
		isLoading,
		refundInfo,
	};
};

const mergeWalletByRfidTag = async (
	encryptedRfidTag: string,
	confirmationEncryptedTag: string,
	amount: number,
	redirectUrl: string,
): Promise<{ balance: string; wallet_id: number }> => {
	const { data } = await anykrowdApi.post<{ balance: string; wallet_id: number }>(
		`/wallet/merge?encrypted_rfid_tag=${encryptedRfidTag}&encrypted_rfid_tag_confirm=${confirmationEncryptedTag}`,
		{ amount, redirectUrl },
		true,
	);
	return data;
};

// useWallet
const useWallet = () => {
	const selectedWallet = ref<Wallet | undefined>();
	const selectedWalletIndex = ref(-1);

	return {
		cancelRefund,
		getMinimumTopUpAmount,
		getMinimumTopUpAmountApiCall,
		getPackages,
		getPreviousTopUpAmount,
		getPreviousTopUpAmountApiCall,
		getRefundInfo,
		getRefundRequests,
		getTransactionDownloadLink,
		getTransactionItemName,
		getWallets,
		getWalletsFromApi,
		getWallet,
		getWalletPackagesApiCall,
		getWalletCurrentBalance,
		getWalletCurrentBalanceFromApi,
		getWalletOrder,
		getWalletOrders,
		getWalletQrCode,
		getWalletTransaction,
		getWalletTransactions,
		guestTopUp,
		guestTopUpWalletPackage,
		mergeWalletByRfidTag,
		selectedWallet,
		selectedWalletIndex,
		topUp,
		topUpWalletPackage,
	};
};

export default useWallet;

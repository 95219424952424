import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-margin-top text-anykrowd-dark" }

import { IonContent, IonModal } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalThree',
  props: {
	modalId: { type: String },
	isOpen: { type: Boolean, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": props.isOpen,
    trigger: props.modalId,
    "initial-breakpoint": 0.95,
    "backdrop-dismiss": false,
    "handle-behavior": "cycle"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["is-open", "trigger"]))
}
}

})
import { computed, ref, watch } from 'vue';
import { useMutation } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import { UserProfile } from '@/shared/interfaces/user';

const _searchUser = async (dataParams: { login_token: string }): Promise<any> => {
	const { data } = await anykrowdApi.post<any>(`/users/search`, dataParams, false);
	return data;
};

const searchUser = () => {
	const guestUserProfile = ref<UserProfile | null>(null);

	const { data: userData, mutate: userMutate, isSuccess } = useMutation(_searchUser);

	// Watch
	watch(
		userData,
		() => {
			if (userData.value) {
				guestUserProfile.value = { ...userData.value };
			}
		},
		{ immediate: true },
	);

	return {
		guestUserProfile,
		isSuccessGuestProfile: computed(() => isSuccess.value),
		userMutate,
	};
};

export default searchUser;

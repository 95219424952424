import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-[60px] min-w-[60px] h-[60px] mr-2" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex flex-col grow" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "font-sourcesanspro-semibold text-m2" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-sm" }
const _hoisted_7 = { class: "max-w-[202px]" }
const _hoisted_8 = { class: "font-sourcesanspro-regular text-sm text-white/50 leading-normal" }

import router from '@/router';
import { IonItem } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonItemBig',
  props: {
	id: { type: String, required: true, default: '' },
	image: { type: String, required: true },
	lastTimeOnline: { type: String, required: true },
	description: { type: String, required: true },
	name: { type: String, required: true },
	type: { type: String, required: true, default: 'person' },
},
  emits: ['onClick'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const onClick = async (id: string) => {
	const url = props.type === 'group' ? `/friends/group/chat/${id}` : `/friends/chat/${id}`;
	emit('onClick', url);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonItem), {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (onClick(props.id))),
    class: "flex justify-between w-full ion-no-padding py-3 border-b border-b-white/5 cursor-pointer",
    lines: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: props.image,
          class: "rounded-full"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(props.name), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(props.lastTimeOnline), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(props.description), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-col px-[34px]" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "relative flex flex-col px-[34px]"
}
const _hoisted_5 = { class: "relative flex flex-col" }
const _hoisted_6 = { class: "flex w-[100%] h-[182px] overflow-hidden mb-4 rounded-2xl p-0" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "img-fluid object-fit-cover h-[182px] w-[100%] flex justify-center text-center backdrop-blur-3xl bg-black/60"
}
const _hoisted_9 = { class: "mb-2" }
const _hoisted_10 = { class: "flex mb-4 font-sourcesanspro-semibold text-lg2" }
const _hoisted_11 = { class: "capitalize" }
const _hoisted_12 = {
  key: 0,
  class: "flex items-center mb-4"
}
const _hoisted_13 = { class: "mr-2" }
const _hoisted_14 = { class: "font-sourcesanspro-semibold text-base2 line-through text-white/30" }
const _hoisted_15 = { class: "font-sourcesanspro-bold text-lg" }
const _hoisted_16 = {
  key: 1,
  class: "flex items-center mb-4"
}
const _hoisted_17 = { class: "font-sourcesanspro-bold text-lg" }
const _hoisted_18 = { class: "mb-4" }
const _hoisted_19 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_20 = { class: "capitalize" }
const _hoisted_21 = { class: "mb-4 text-base2 font-color-text-50 first-letter:capitalize" }

import router from '@/router';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, imageOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import useShop from '@/modules/shop/composables/useShop';
import TopBar from '@/shared/components/TopBar.vue';
import ProductQuantityBottomBar from '@/shared/components/shop/ProductQuantityBottomBar.vue';
import ShoppingCartIcon from '@/shared/components/shop/ShoppingCartIcon.vue';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ShopProductDetailPage',
  setup(__props) {

const { t } = useI18n();
const { params } = useRoute();
const { getProductDetails } = useShop();
const productId = Number(params.id);
const { product } = getProductDetails(productId);

const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, null, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: goBack
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('shop.product.title')), 1)
          ]),
          right: _withCtx(() => [
            _createVNode(ShoppingCartIcon)
          ]),
          _: 1
        })
      ]),
      (!_unref(product))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(IonSpinner), { name: "dots" })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_unref(product).image_url)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _unref(product).image_url,
                      class: "img-fluid object-fit-cover w-[100%]"
                    }, null, 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(imageOutline),
                        size: "large",
                        class: "text-white my-auto"
                      }, null, 8, ["icon"])
                    ]))
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(product).name.toLowerCase()), 1)
                ]),
                (_unref(product).discount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, " € " + _toDisplayString(_unref(product).price), 1)
                      ]),
                      _createElementVNode("span", _hoisted_15, "€" + _toDisplayString(_unref(product).discountedPrice()?.toFixed(2)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (!_unref(product).discount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("span", _hoisted_17, "€" + _toDisplayString(_unref(product).price), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[0] || (_cache[0] = _createTextVNode(" About ")),
                    _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(product).name.toLowerCase()), 1)
                  ]),
                  _withDirectives(_createElementVNode("p", _hoisted_21, null, 512), [
                    [_directive_dompurify_html, _unref(product).description]
                  ])
                ])
              ])
            ])
          ])),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[100px] mb-10" }, " ", -1)),
      (_unref(product))
        ? (_openBlock(), _createBlock(ProductQuantityBottomBar, {
            key: 2,
            product: _unref(product)
          }, null, 8, ["product"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
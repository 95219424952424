import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "flex col-start-1 font-sourcesanspro-semibold text-sm relative",
  style: {"grid-column-end":"-1"}
}
const _hoisted_2 = { class: "sticky flex items-center left-0 uppercase" }
const _hoisted_3 = ["src"]

import { IonIcon } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTableItemTitle',
  props: {
	iconUrl: { type: String },
	imageUrl: { type: String },
	title: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.iconUrl || __props.imageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "max-w-[1rem] max-h-[1rem] mr-1 -rotate-90 invert",
            src: __props.iconUrl || __props.imageUrl
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(props.title), 1)
    ])
  ]))
}
}

})
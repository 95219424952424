import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex relative h-[80px] padding-app-left padding-app-right" }
const _hoisted_2 = { class: "flex h-[80px] padding-app-left padding-app-right justify-between items-center sign-in-sign-up-alert-container absolute left-0 bottom-0" }
const _hoisted_3 = { class: "font-sourcesanspro-regular text-base text-white" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-base" }

import router from '@/router';
import { IonButton } from '@ionic/vue';

export interface Props {
	buttonText?: string;
	text?: string;
	link?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInSignUpAlert',
  props: {
    buttonText: { default: 'Text' },
    text: { default: 'This is a default value' },
    link: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(props.text)), 1),
      _createVNode(_unref(IonButton), {
        fill: "outline",
        shape: "round",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(props.link))),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(props.buttonText)), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "grow" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-m2" }

import { IonAvatar, IonItem } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonItemCompact',
  props: {
	image: { type: String, required: true },
	location: { type: String },
	name: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonItem), {
    class: "flex justify-between w-full ion-no-padding pb-2",
    lines: "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonAvatar), { slot: "start" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: props.image
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(props.name), 1)
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex items-center justify-center w-[113px] h-[34px] border border-white/50 rounded-full" }, [
        _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 text-white/50" }, "+ Add friend")
      ], -1))
    ]),
    _: 1
  }))
}
}

})
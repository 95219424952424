import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-[34px] mb-2" }
const _hoisted_2 = { class: "flex justify-between mb-4" }
const _hoisted_3 = { class: "flex justify-right" }
const _hoisted_4 = { class: "flex flex-col px-[34px] items-center" }

import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import TicketItem from '@/modules/tickets/components/TicketItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import BottomGradient from '@/shared/components/ui/BottomGradient.vue';
import { chevronBackOutline } from 'ionicons/icons';

// Go back

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketsPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex items-center justify-start cursor-pointer",
            onClick: goBack
          }, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(chevronBackOutline),
              class: "text-white"
            }, null, 8, ["icon"])
          ])
        ]),
        center: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }, "Le Noël de Monsieur", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col" }, [
            _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "29"),
            _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, "December")
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col" }, [
            _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "Tuesday"),
            _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, "10:00 PM - End")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(IonIcon), {
              src: require('@/statics/svg/events/calendar-add.svg'),
              class: "h-[54px] w-[54px]"
            }, null, 8, ["src"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(TicketItem, {
          link: "/tickets/1",
          name: "Derek McLean",
          type: "Coper",
          venue: "Antwerps Sportpalies, BE",
          class: "mb-4"
        }),
        _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "mb-4 h-px bg-white/10 border-0" }, null, -1)),
        _createVNode(TicketItem, {
          link: "/tickets/2",
          name: "Christophe Stemberg",
          type: "Coper",
          venue: "Antwerps Sportpalies, BE",
          class: "mb-2"
        }),
        _createVNode(TicketItem, {
          link: "/tickets/3",
          name: "Stijn DeBeker",
          type: "Coper",
          venue: "Antwerps Sportpalies, BE",
          class: "mb-2"
        }),
        _createVNode(TicketItem, {
          link: "/tickets/4",
          name: "Stijn DeBrauwer",
          type: "Coper",
          venue: "Antwerps Sportpalies, BE",
          class: "mb-2"
        }),
        _createVNode(TicketItem, {
          link: "/tickets/5",
          name: "Kim De Jong",
          type: "Coper",
          venue: "Antwerps Sportpalies, BE",
          class: "mb-2"
        }),
        _createVNode(TicketItem, {
          link: "/tickets/6",
          name: "Elma Klose",
          type: "Coper",
          venue: "Antwerps Sportpalies, BE",
          class: "mb-2"
        })
      ]),
      _createVNode(BottomGradient)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "px-[34px] w-full" }
const _hoisted_3 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_4 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_5 = { class: "relative mb-4" }
const _hoisted_6 = ["validation-schema"]
const _hoisted_7 = { class: "px-[34px] mb-[34px]" }
const _hoisted_8 = { class: "flex w-full items-center justify-center" }

import { watch } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline, lockClosedOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePasswordReset',
  setup(__props) {

const { t } = useI18n();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	password: Yup.string().min(6).required(),
	new_password: Yup.string().min(6).required(),
	new_password_confirmation: Yup.string()
		.required()
		.oneOf([Yup.ref('new_password')], t('profile.password_reset.errors.passwords_do_not_match')),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/profile/change-password',
	'post',
	'profile.password_change.messages.password_changed',
	'profile.password_change.errors.error',
	5000,
);

watch(isSuccess, async () => {
	await router.push('/profile');
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  to: "/profile",
                  class: "flex items-center justify-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('profile.password_reset.title')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("form", {
              id: "changePasswordForm",
              "validation-schema": _unref(schema),
              onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                iconLeft: _unref(lockClosedOutline),
                name: "password",
                type: "password",
                placeholder: _ctx.$t('profile.password_reset.current_password'),
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["iconLeft", "placeholder", "onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                name: "new_password",
                type: "password",
                placeholder: _ctx.$t('profile.password_reset.new_password'),
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["placeholder", "onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                name: "new_password_confirmation",
                type: "password",
                placeholder: _ctx.$t('profile.password_reset.confirm_password'),
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["placeholder", "onInputChanged"])
            ], 40, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:bg-white hover:rounded-full",
            disabled: !_unref(isFormValid),
            expand: "full",
            form: "changePasswordForm",
            size: "large",
            shape: "round",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('profile.password_reset.change_password')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mt-6" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = { class: "flex overflow-scroll mb-10 invisible-scroller scroll-smooth" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 0,
  class: "flex items-center justify-center mb-4"
}
const _hoisted_10 = {
  key: 1,
  class: "px-[34px]"
}
const _hoisted_11 = { class: "fixed button-container mb-[34px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full" }
const _hoisted_12 = { class: "relative z-10 w-full h-full" }
const _hoisted_13 = { class: "flex w-full justify-between items-center font-sourcesanspro-semibold text-m2" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

import { onMounted, ref } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonTextarea, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import hexRgb from 'hex-rgb';
import ItemAdder from '@/shared/components/ui/ItemAdder.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import rgbHex from 'rgb-hex';
import TopBar from '@/shared/components/TopBar.vue';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useSelfOrdering from '@/modules/self-ordering/components/useSelfOrdering';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SelfOrderingCartPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const categoryId = ref(+route.params.id);
const {
	contentMessage,
	currentProduct,
	decreaseQuantity,
	getProductCategories,
	getProductsForCategory,
	getTableSpotAndQrCodeAndLocationFromStore,
	increaseQuantity,
	isModalOpen,
	onDeleteAComment,
	onWriteAComment,
	scrollToCategory,
	showHideModal,
} = useSelfOrdering();
const { tableSpot, location, qrCode } = getTableSpotAndQrCodeAndLocationFromStore();
const { tableSpotProductCategories } = getProductCategories(qrCode);
const { isFetchingProductsForCategory, tableSpotProducts } = getProductsForCategory(categoryId);
const {
	getCartItem,
	getCartTotalItemsByCategory,
	getProductCount,
	getCartTotalPrice,
	updateCartAdditionalInfoByProductId,
} = useCart();
const { processCheckout } = useCheckout();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { convertValutaSync } = useConvertValuta();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Go back
const goBack = () => {
	router.back();
};

// onMountedHook
onMounted(async () => {
	selectCategory(+route.params.id);
});

const selectCategory = (selectedCategoryId: number) => {
	categoryId.value = selectedCategoryId;
	scrollToCategory(selectedCategoryId);
};

const saveMessage = () => {
	const cartItem = getCartItem(currentProduct.value!.id);
	const additionalInfo = cartItem?.additionalInfo ? cartItem.additionalInfo : {};
	const comments =
		cartItem?.additionalInfo && cartItem.additionalInfo.comments ? cartItem?.additionalInfo.comments : [];
	updateCartAdditionalInfoByProductId(currentProduct.value!.id, {
		...additionalInfo,
		comments: [...comments, contentMessage.value],
	});
	showHideModal(false, false);
	contentMessage.value = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createVNode(ModalOne, {
        title: _unref(t)('self_ordering.cart.add_content'),
        "is-open": _unref(isModalOpen)
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(IonTextarea), {
              class: "mb-2 px-2 rounded-xl bg-white text-anykrowd-dark-purple font-sourcesanspro-regular text-sm",
              "max-length": "100",
              placeholder: _unref(t)('self_ordering.cart.type_something_here'),
              modelValue: _unref(contentMessage),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(contentMessage) ? (contentMessage).value = $event : null))
            }, null, 8, ["placeholder", "modelValue"]),
            _createVNode(_unref(IonButton), {
              expand: "full",
              shape: "round",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (saveMessage()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('self_ordering.cart.add_content')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(showHideModal)(false, true)))
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "is-open"]),
      (_unref(tenantConfig))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(TopBar, { class: "px-[34px]" }, {
              left: _withCtx(() => [
                _createElementVNode("div", {
                  class: "flex items-center justify-start cursor-pointer",
                  onClick: goBack
                }, [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableSpotProductCategories), (category, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: idx,
                  class: _normalizeClass([[
						{
							'ml-[34px]': idx === 0,
						},
					], "flex flex-col items-center justify-center mr-5"]),
                  onClick: ($event: any) => (selectCategory(category.id))
                }, [
                  _createElementVNode("div", {
                    id: `category-${category.id}`,
                    class: _normalizeClass([[
							{
								'bg-white': category.id === categoryId.value,
								'bg-white/10': category.id !== categoryId.value,
							},
						], "flex w-[72px] h-[72px] items-center justify-center mb-3 rounded-md"])
                  }, [
                    (category.image_url)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: _normalizeClass([[
								{
									'invert': category.id !== categoryId.value,
									'opacity-40': category.id !== categoryId.value,
								},
							], "w-[32px] h-[32px]"]),
                          src: category.image_url
                        }, null, 10, _hoisted_8))
                      : _createCommentVNode("", true)
                  ], 10, _hoisted_7),
                  _createElementVNode("div", {
                    class: _normalizeClass(["h-[65px] font-sourcesanspro-regular text-center text-m2 capitalize", [
							{
								'text-white': category.id === categoryId.value,
								'text-white/30': category.id !== categoryId.value,
							},
						]])
                  }, _toDisplayString(category.name.toLowerCase()), 3)
                ], 10, _hoisted_6))
              }), 128))
            ]),
            (_unref(isFetchingProductsForCategory))
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_unref(IonSpinner), { name: "dots" })
                ]))
              : _createCommentVNode("", true),
            (_unref(tableSpotProducts))
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableSpotProducts).filter(
						(productItem) => productItem.product_category_id === categoryId.value,
					), (productItem, idx) => {
                    return (_openBlock(), _createBlock(ItemAdder, {
                      onOnDecreaseProductQuantity: _cache[3] || (_cache[3] = 
						(product) => {
							_unref(decreaseQuantity)(product);
						}
					),
                      onOnIncreaseProductQuantity: _cache[4] || (_cache[4] = 
						(product) => {
							_unref(increaseQuantity)(product);
						}
					),
                      "add-more-error": "self_ordering.cart.stock_alert_cart",
                      "add-more-error-detail": (+productItem.stock).toString(),
                      key: idx,
                      productId: productItem.id,
                      product: productItem,
                      class: "mb-4",
                      title: productItem.name,
                      description: productItem.description,
                      discount: productItem.discount,
                      price: +productItem.price,
                      "price-with-discount": +productItem.discountedPrice()!,
                      quantity: _unref(getProductCount)(productItem.id),
                      "can-write-a-comment": true,
                      comments: 
						_unref(getCartItem)(productItem.id)?.additionalInfo
							? _unref(getCartItem)(productItem.id)?.additionalInfo.comments
							: []
					,
                      onOnDeleteAComment: _unref(onDeleteAComment),
                      onOnWriteAComment: ($event: any) => (_unref(onWriteAComment)(productItem)),
                      "can-add-more": 
						_unref(tenantConfig).out_of_stock_order_clientx
							? true
							: productItem.stock_type === 'managed' || productItem.stock_type === 'cross_stock'
								? _unref(getProductCount)(productItem.id) < +productItem.stock
								: true
					
                    }, null, 8, ["add-more-error-detail", "productId", "product", "title", "description", "discount", "price", "price-with-discount", "quantity", "comments", "onOnDeleteAComment", "onOnWriteAComment", "can-add-more"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_unref(IonButton), {
            disabled: _unref(getCartTotalItemsByCategory)(_unref(CartCategoriesEnum).selfOrder) === 0,
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round",
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (
						_unref(processCheckout)(_unref(TransactionType).SELF_ORDER, {
							tableSpot: _unref(tableSpot),
							location: _unref(location) ? _unref(location) : _unref(tableSpot).manual_location_type,
						})
					), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_unref(getCartTotalItemsByCategory)(_unref(CartCategoriesEnum).selfOrder, true)) + " Items", 1),
                (_unref(tenantConfig) && _unref(tenantConfig).custom_valuta_enabled)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(tenantConfig).custom_valuta.prefix) + " " + _toDisplayString(_unref(convertValutaSync)(
									_unref(tenantConfig),
									_unref(getCartTotalPrice)(_unref(CartCategoriesEnum).selfOrder).toFixed(2),
									true,
								)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, "€" + _toDisplayString(_unref(getCartTotalPrice)(_unref(CartCategoriesEnum).selfOrder).toFixed(2)), 1))
              ])
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "w-full mb-4 event-item bg-cover bg-center rounded-md backdrop-blur-2xl bg-black/50" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "event-detail-image aspect-[16/9] relative overflow-hidden" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col p-4" }
const _hoisted_6 = { class: "divide-y divide-white/10" }
const _hoisted_7 = { class: "text-l2" }
const _hoisted_8 = { class: "pb-2" }
const _hoisted_9 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_10 = { class: "font-sourcesanspro-regular text-xs2 text-white/30" }
const _hoisted_11 = { class: "pt-2 mb-6" }
const _hoisted_12 = { class: "font-sourcesanspro-regular text-sm2 text-white/60" }
const _hoisted_13 = { class: "flex items-center justify-center" }
const _hoisted_14 = { class: "font-sourcesanspro-semibold text-base2" }

import { ref } from 'vue';
import router from '@/router';
import LabelTopLeft from '@/shared/components/ui/LabelTopLeft.vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { location as locationIcon, timeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventsItem',
  props: {
	id: { type: Number, required: true },
	date: { type: String, required: true },
	going: { type: Boolean, default: false },
	hour: { type: String, required: true },
	image: { type: String, required: true },
	link: { type: String, required: true },
	location: { type: String, required: true, default: '' },
	summary: { type: String as () => string | null | undefined, required: true, default: '' },
	ticketCount: { type: Number },
	title: { type: String, required: true },
	showLoggedData: { type: Boolean, required: true, default: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: props.image,
          class: "w-full h-full relative rounded-lg object-cover object-center"
        }, null, 8, _hoisted_4),
        (props.going)
          ? (_openBlock(), _createBlock(LabelTopLeft, {
              key: 0,
              class: "border-l-anykrowd-item-added-green",
              "label-container-class": "left-1 top-2",
              label: "GOING"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_7, _toDisplayString(props.title), 1),
          _createElementVNode("div", _hoisted_8, [
            (props.date)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(DateTime).fromFormat(props.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy')) + " @ " + _toDisplayString(props.hour) + " - " + _toDisplayString(props.location), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(props.summary), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_router_link, {
          to: props.link,
          class: "w-full"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonButton), {
              class: _normalizeClass([[
							{
								'w-full': !props.going,
								'w-[145px]': props.going,
							},
						], "h-[48px]"]),
              color: "primary",
              fill: "outline",
              shape: "round"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2" }, "View Details", -1)
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        }, 8, ["to"]),
        (props.showLoggedData && props.going && props.ticketCount)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `/events/${__props.id}/tickets`
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  class: "w-[145px] h-[48px] hover:bg-white hover:rounded-full",
                  shape: "round"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(props.ticketCount) + " Ticket(s) ", 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["to"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})
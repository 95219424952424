import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full items-center justify-between" }
const _hoisted_2 = { class: "flex w-full items-center justify-between" }
const _hoisted_3 = { class: "flex w-full items-center justify-between" }
const _hoisted_4 = { class: "flex flex-col items-center justify-center w-full" }
const _hoisted_5 = {
  key: 0,
  class: "flex w-full items-center justify-between"
}

import { ref, watch } from 'vue';
import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/vue';

import SmsPhoneHandler from '@/modules/profile/components/SmsPhoneHandler.vue';
import ToggleInput from '@/shared/components/sign-up-forms/ToggleInput.vue';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useProfileAdditionalConfig from '@/modules/profile/composables/useProfileAdditionalConfig';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsSettings',
  setup(__props) {

const isUpdatingCellPhoneNumber = ref(false);
const { getUserProfile } = useUserProfile();
const { onNotificationChange, onCellPhoneNumberChange } = useProfileAdditionalConfig();
const { userProfile, isSuccessUserProfile, isFetchingUserProfile } = getUserProfile();
const phoneNumberState = ref(!!userProfile.value?.notification_sms);

// Check if userProfile changed and update phone number state
watch(userProfile, () => {
	phoneNumberState.value = !!userProfile.value?.notification_sms;
});

// If cellphone number changed, block continue if not valid
const cellPhoneNumberSent = async (userPhoneNumber: string) => {
	isUpdatingCellPhoneNumber.value = true;
	await onCellPhoneNumberChange(userPhoneNumber, () => {
		isUpdatingCellPhoneNumber.value = false;
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isSuccessUserProfile))
      ? (_openBlock(), _createBlock(_unref(IonList), {
          key: 0,
          class: "ion-no-padding bg-transparent",
          lines: "full"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonItem), {
              class: "w-full border-b border-white/5",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-regular text-base" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.email')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(ToggleInput, {
                    name: "emailNotifications",
                    slot: "end",
                    value: !!_unref(userProfile)?.notification_email,
                    onOnStateChanged: _unref(onNotificationChange)
                  }, null, 8, ["value", "onOnStateChanged"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(IonItem), {
              class: "w-full border-b border-white/5",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-regular text-base" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.push')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(ToggleInput, {
                    name: "push",
                    slot: "end",
                    value: !!_unref(userProfile)?.notification_in_app,
                    onOnStateChanged: _unref(onNotificationChange)
                  }, null, 8, ["value", "onOnStateChanged"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(IonItem), {
              class: "w-full",
              lines: "none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-regular text-base" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.sms')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(ToggleInput, {
                    name: "smsNotifications",
                    slot: "end",
                    value: !!_unref(userProfile)?.notification_sms,
                    onOnStateChanged: _unref(onNotificationChange)
                  }, null, 8, ["value", "onOnStateChanged"])
                ])
              ]),
              _: 1
            }),
            (!!_unref(userProfile)?.notification_sms)
              ? (_openBlock(), _createBlock(_unref(IonItem), {
                  key: 0,
                  class: "w-full mb-4",
                  lines: "none"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      (!!_unref(userProfile)?.notification_sms)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(SmsPhoneHandler, {
                              "cell-phone-number": _unref(userProfile).notification_cellphone_number,
                              onOnCellPhoneNumberSent: cellPhoneNumberSent
                            }, null, 8, ["cell-phone-number"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex w-full items-center justify-between" }
const _hoisted_4 = ["name", "onClick"]
const _hoisted_5 = { class: "font-sourcesanspro-semibold" }

import { ref, watch } from 'vue';
import { IonAccordionGroup, IonAccordion, IonItem, IonIcon, IonSpinner, IonToggle } from '@ionic/vue';
import { checkmarkOutline, chevronDown } from 'ionicons/icons';

import useTagCollections from '@/shared/composables/useTagCollections';


export default /*@__PURE__*/_defineComponent({
  __name: 'TagCollections',
  props: {
	accordionClass: { type: String, required: false },
	accordionContentClass: { type: String, required: false },
	userTags: {
		type: Array<number>,
		required: false,
		default: function () {
			return [];
		},
	},
},
  setup(__props) {

const props = __props;

// Init
const openAccordions = ref<string[]>();
const {
	childTagsSelected,
	error,
	isError,
	isLoading,
	isSelected,
	selectChildTag,
	selectParentTag,
	tagsList,
	userTags,
} = useTagCollections(props.userTags);

watch(tagsList, () => {
	if (tagsList.value) {
		openAccordions.value = tagsList.value
			.filter((tagParent) => {
				return childTagsSelected(tagParent).length;
			})
			.map((tagParent) => {
				return tagParent.id.toString();
			});
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_unref(IonSpinner), { name: "dots" })
        ]))
      : _createCommentVNode("", true),
    (_unref(isError))
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_unref(error)), 1))
      : _createCommentVNode("", true),
    _createVNode(_unref(IonAccordionGroup), {
      multiple: true,
      value: openAccordions.value
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tagsList), (tagParent) => {
          return (_openBlock(), _createBlock(_unref(IonAccordion), {
            class: _normalizeClass(props.accordionClass ? props.accordionClass : 'ion-padding'),
            key: tagParent.id,
            value: tagParent.id.toString(),
            toggleIcon: tagParent.children && tagParent.children.length ? _unref(chevronDown) : '',
            toggleIconSlot: "start"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonItem), { slot: "header" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, _toDisplayString(tagParent.name), 1),
                    _createElementVNode("div", {
                      onClick: _cache[0] || (_cache[0] = ($event) => $event.stopPropagation())
                    }, [
                      _createVNode(_unref(IonToggle), {
                        disabled: !!_unref(childTagsSelected)(tagParent).length,
                        "v-model": _unref(userTags).includes(tagParent.id),
                        checked: _unref(userTags).includes(tagParent.id),
                        onIonChange: ($event: any) => (_unref(selectParentTag)($event, tagParent))
                      }, null, 8, ["disabled", "v-model", "checked", "onIonChange"])
                    ])
                  ])
                ]),
                _: 2
              }, 1024),
              (tagParent.children && tagParent.children.length)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass([props.accordionContentClass ? props.accordionContentClass : 'ion-no-padding', "flex flex-wrap justify-between pt-4"]),
                    slot: "content"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tagParent.children, (tag) => {
                      return (_openBlock(), _createElementBlock("button", {
                        class: _normalizeClass(["flex h-[30px] justify-center items-center background-white-15 border border-solid border-white-15 rounded-full mb-4 px-3 normal-case text-m", [
							{
								'background-primary': _unref(userTags).includes(tag.id),
								'text-color-button-text': _unref(userTags).includes(tag.id),
							},
						]]),
                        name: tag.name,
                        key: tag.id,
                        onClick: ($event: any) => (_unref(selectChildTag)(tag, tagParent))
                      }, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(tag.name), 1),
                        (_unref(isSelected)(tag))
                          ? (_openBlock(), _createBlock(_unref(IonIcon), {
                              key: 0,
                              class: "text-color-button-text",
                              icon: _unref(checkmarkOutline),
                              slot: "end"
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true)
                      ], 10, _hoisted_4))
                    }), 128))
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["class", "value", "toggleIcon"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value"])
  ]))
}
}

})
<script setup lang="ts">
import vue, { ref } from 'vue';
import { IonContent, IonButton, IonIcon } from '@ionic/vue';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';

import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useModal from '@/shared/composables/useModal';
import useTenant from '@/shared/composables/useTenant';
import SignInWithPhoneForm from '../components/SignInWithPhoneForm.vue';

// Init
const { isModalOpen, showHideModal, modalTitle, modalContent } = useModal();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

const isFormValid = ref(false);
const onFormValidation = (formValid: boolean) => {
	isFormValid.value = formValid;
};
</script>

<template>
	<ion-content>
		<ModalOne :title="modalTitle" :is-open="isModalOpen">
			<template #content>
				<div class="font-sourcesanspro-regular text-sm text-center text-white/50 leading-[18px]">
					{{ modalContent }}
				</div>
			</template>

			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="showHideModal(false)"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>

		<div class="flex flex-col justify-between h-full">
			<div>
				<TopBar class="flex px-[34px]">
					<template #left>
						<div class="flex items-center justify-start cursor-pointer">
							<router-link to="/auth/signinhome" class="flex items-center justify-center">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</router-link>
						</div>
					</template>
				</TopBar>

				<div class="px-[34px] pt-6">
					<h1 class="mb-4 font-sourcesanspro-semibold text-white text-lg text-center">
						{{ tenantConfig?.welcome_title }}
					</h1>
					<div class="max-w-xs mx-auto mb-8 text-color-white text-base text-center">
						{{ tenantConfig?.welcome_line }}
					</div>

					<SignInWithPhoneForm @on-form-validation="onFormValidation" />
				</div>
			</div>

			<div class="w-full">
				<div class="flex justify-center next-button w-full mb-4 padding-app-left padding-app-right">
					<ion-button
						color="primary"
						:disabled="!isFormValid"
						expand="full"
						form="signInWithPhoneForm"
						shape="round"
						size="large"
						type="submit">
						{{ $t('auth.sign_in_phone_page.button') }}
					</ion-button>
				</div>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-button {
	width: 100%;
}
</style>

import { computed, ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Capacitor } from '@capacitor/core';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { Browser } from '@capacitor/browser';

import { anykrowdApi } from '@/api/anykrowdApi';
import { BrowserType } from '@/shared/interfaces/browser';
import { CartCategoriesEnum } from '@/enums/values.enum';
import { DownloadType } from '@/shared/interfaces/download';
import { Event, EventParticipant, EventTicket } from '@/shared/interfaces/event';
import { EventCombo } from '@/shared/interfaces/eventCombo';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { useCart } from '@/modules/shop/composables/useCart';
import type { DownloadModalProps } from '@/shared/interfaces/download';
import useBrowser from '@/shared/composables/useBrowser';
import useDownload from '@/shared/composables/useDownload';
import useLoading from '@/shared/composables/useLoading';
import useToast from '@/shared/composables/useToast';
import useStorageService from '@/shared/composables/useStorageService';
import { TenantConfig } from '@/shared/interfaces/tenant';

// Init
const { presentLoading } = useLoading();
const { presentToast } = useToast();
const { get, set } = useStorageService();

// Door sales only
const doorSalesOnly = (event: Event) => {
	// eslint-disable-next-line eqeqeq
	return event?.ticket_types?.length === 1 && event?.ticket_types[0]?.door_sales == true;
};

const _getDownloadUrl = async (eventId: number, ticketId: number): Promise<{ url: string }> => {
	const { data } = await anykrowdApi.get<{ url: string }>(`/events/tickets/${eventId}/download/${ticketId}`, true);
	return data;
};

// Get event api call
const getEventApiCall = async (eventId: number): Promise<Event> => {
	const url = `/events/detail/${eventId}`;
	const { data } = await anykrowdApi.get<Event>(url, false);

	if (data.tags) {
		data.tags = data.tags.map((tag) => {
			tag.isActive = false;
			return tag;
		});
	}

	return data;
};

// Get event ticket types api call
const _getEventTicketTypes = async (
	eventId: number,
): Promise<{ ticket_types: EventTicketType[]; combos: EventCombo[] }> => {
	const { data } = await anykrowdApi.get<{ ticket_types: EventTicketType[]; combos: EventCombo[] }>(
		`/events/ticket-types/${eventId}`,
		false,
	);
	return data;
};

// Get event
const getEvent = (eventId: number) => {
	// Init
	const queryClient = useQueryClient();
	const event = ref<Event | any>()!;
	const { addToCartDuplicated, decreaseProductQuantity, getProductCountWithDuplicates } = useCart();
	const ticketLimitIncrease = ref(false);
	const buyTicketLimits = ref<{
		eventLimit: number;
		ticketLimit: { type: string; limit: number }[];
		comboLimit: { type: string; limit: number }[];
	}>();

	const {
		data: eventData,
		error: eventError,
		isFetched,
		refetch,
		remove,
	} = useQuery({
		queryKey: ['event', eventId],
		cacheTime: 1000 * 60 * 60 * 24 * 365,
		queryFn: () => getEventApiCall(eventId),
	});

	// Watch userProfile and registrationFormData
	watch(
		eventData,
		() => {
			if (eventData.value) {
				event.value = { ...eventData.value };
				// adding main image as event images
				event.value.images = [...event.value.images, { image_url: event.value.main_image_url }];

				// Update cache
				set(`event-${eventId}`, event.value);
			}
		},
		{ immediate: true },
	);

	// Decrease product quantity
	const decreaseQuantity = (item: EventTicketType | EventComboTicketType) => {
		item.quantity--;
		ticketLimitIncrease.value = false;
		decreaseProductQuantity(item);
	};

	// Get event ticket types
	const getEventTicketTypes = (eventId: number) => {
		// Init
		const eventTicketTypes = ref<{ ticket_types: EventTicketType[]; combos: EventCombo[] }>()!;
		const eventComboTicketTypes = ref<EventComboTicketType[]>();
		const { getProductCountWithDuplicates } = useCart();

		const {
			data: eventTicketTypesData,
			isFetched,
			error: eventError,
		} = useQuery({
			queryKey: ['eventTicketTypes', eventId],
			queryFn: () => _getEventTicketTypes(eventId),
			cacheTime: 1000 * 60 * 60 * 24 * 365,
		});

		// Get ticket limits
		const getBuyTicketLimits = (
			eventTicketTypes: EventTicketType[],
			eventComboTicketTypes: EventComboTicketType[],
		) => {
			return {
				eventLimit: event.value!.available_tickets_count,
				ticketLimit: eventTicketTypes.map((ticketType) => {
					// If ticket type limit is greater than event limit for the user
					// limit the ticket type
					let limit = ticketType.remaining_tickets;
					if (
						ticketType.remaining_tickets !== -1 &&
						ticketType.remaining_tickets > event.value!.available_tickets_count
					) {
						limit = event.value!.available_tickets_count;
					}

					return {
						type: ticketType.name,
						limit,
					};
				}),
				comboLimit: eventComboTicketTypes.map((comboTicketType) => {
					return {
						type: comboTicketType.name,
						limit:
							comboTicketType.remaining_tickets === -1
								? event.value!.available_tickets_count
								: comboTicketType.remaining_tickets,
					};
				}),
			};
		};

		// Watch userProfile and registrationFormData
		watch(
			eventTicketTypesData,
			() => {
				if (eventTicketTypesData.value) {
					eventTicketTypes.value = { ...eventTicketTypesData.value };

					// Instantiate all ticket types
					eventTicketTypes.value.ticket_types = eventTicketTypes.value.ticket_types.map(
						(product: EventTicketType) => {
							// Instantiate a new EventTicketType
							const eventTicketType = new EventTicketType(product);

							// Get and set the quantity of products in the cart
							eventTicketType.quantity = getProductCountWithDuplicates(product);

							return eventTicketType;
						},
					);
					eventTicketTypes.value.combos = eventTicketTypes.value.combos.map(
						(combo: EventCombo) => new EventCombo(combo),
					);

					// Instantiate all event combo ticket types
					const eventComboTicketTypesArray = <EventComboTicketType[]>[];
					eventComboTicketTypes.value = eventTicketTypesData.value.combos.reduce(
						(previous: EventComboTicketType[], eventCombo) => {
							previous.push(
								...eventCombo.ticket_types.map((ticketType) => {
									// Instantiate a new EventComboTicketType
									const eventComboTicketType = new EventComboTicketType(ticketType);

									// Get and set the quantity of products in the cart
									eventComboTicketType.quantity = getProductCountWithDuplicates(ticketType);
									eventComboTicketTypesArray.push(eventComboTicketType);
									return eventComboTicketType;
								}),
							);
							return previous;
						},
						[],
					);

					// Get limits
					buyTicketLimits.value = getBuyTicketLimits(
						eventTicketTypes.value.ticket_types,
						eventComboTicketTypesArray,
					);

					// Update cache
					const key = ['eventTicketTypes', eventId].join('-');
					set(key, eventTicketTypesData.value);
				}
			},
			{ immediate: true },
		);

		// Return
		return {
			eventComboTicketTypes,
			eventTicketTypes,
			isFetchedEventTicketTypes: computed(() => isFetched.value),
		};
	};

	// Increase product quantity
	const increaseQuantity = async (productId: number, item: EventTicketType | EventComboTicketType) => {
		addToCartDuplicated(item, CartCategoriesEnum.ticket, {
			event_combo_ticket_type_id: item instanceof EventComboTicketType ? productId : null,
			event_ticket_type_id: item instanceof EventTicketType ? productId : null,
		});
	};

	// Return
	return {
		buyTicketLimits,
		decreaseQuantity,
		event,
		eventError,
		getEventTicketTypes,
		increaseQuantity,
		isFetchedEvent: computed(() => isFetched.value),
		ticketLimitIncrease,
		refetchEvent: refetch,
		removeEventFromCache: remove,
	};
};

const getEventParticipants = async (eventId: number): Promise<EventParticipant[]> => {
	const { data } = await anykrowdApi.get<EventParticipant[]>(`/events/participants/${eventId}`, true);

	// Update cache
	const key = ['eventParticipants', eventId].join('-');
	set(key, data);

	return data;
};

const getEventTicketsApiCall = async (eventId: number): Promise<EventTicket[]> => {
	const { data } = await anykrowdApi.get<EventTicket[]>(`/events/tickets/${eventId}`, true);
	return data;
};

const getEventTickets = (eventId: number) => {
	// Init
	const eventTickets = ref<EventTicket[]>()!;

	const {
		data: eventTicketsData,
		error: eventsError,
		isFetching,
		refetch,
	} = useQuery({
		queryKey: ['eventTickets', eventId],
		queryFn: () => getEventTicketsApiCall(eventId),
		cacheTime: 1000 * 60 * 60 * 24 * 365,
	});

	// Watch userProfile and registrationFormData
	watch(
		eventTicketsData,
		() => {
			if (eventTicketsData.value) {
				eventTickets.value = [...eventTicketsData.value];

				// Update cache
				const key = ['eventTickets', eventId].join('-');
				set(key, eventTicketsData.value);
			}
		},
		{ immediate: true },
	);

	// Return
	return {
		eventTickets,
		isFetchingEventTickets: computed(() => isFetching.value),
		refetchTickets: refetch,
	};
};

// Get events api call
const getEventsApiCall = async (search: string, pageParam: number, type: string): Promise<any> => {
	pageParam = pageParam !== null ? pageParam : 1;
	const { data } = await anykrowdApi.get<Event[]>(
		`/events/list?search=${search}&page=${pageParam}&type=${type}`,
		false,
	);
	return data;
};

const getEventsCount = async (search: string, type: string): Promise<{ count: number }> => {
	const { data } = await anykrowdApi.get<{ count: number }>(`${window.apiUrl}/events/count`, true, {
		params: { search, type },
	});
	return data;
};

// Get Events
const getEvents = (search: Ref<string>, type: Ref<string>) => {
	// Init
	const events = ref<Event[]>()!;

	const {
		data: eventsData,
		isFetched: isFetchedEventsDataRequests,
		isFetching: isFetchingEventsDataRequests,
		isLoading: isLoadingEventsData,
		refetch: refetchEventsDataRequests,
		remove: removeEventsDataRequestsFromCache,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ['events', search, type],
		queryFn: async ({ pageParam = 1 }) => await getEventsApiCall(search.value, pageParam, type.value),
		cacheTime: 1000 * 60 * 60 * 24 * 365,
		getNextPageParam: (lastPage) => {
			return lastPage.last_page >= lastPage.current_page + 1 ? lastPage.current_page + 1 : undefined;
		},
		retry: 3,
	});

	const sortAndReverse = (events: any[]) => {
		return events
			.sort((eventA, eventB) => {
				if (eventA.start_datetime !== null && eventB.start_datetime != null) {
					const eventADate = DateTime.fromFormat(eventA.start_datetime, 'yyyy-MM-dd HH:mm:ss');
					const eventBDate = DateTime.fromFormat(eventB.start_datetime, 'yyyy-MM-dd HH:mm:ss');
					return eventBDate.diff(eventADate, 'days').days;
				} else {
					return -1;
				}
			})
			.reverse();
	};

	// Watch eventsData
	watch(
		eventsData,
		() => {
			if (eventsData.value) {
				const allEvents = [...eventsData.value.pages.map((page) => page.data).flat()].filter(
					(event) => event.start_datetime !== null,
				);

				// Filter active events
				const activeEvents = allEvents.filter((event) => {
					const eventStartDate = DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss');
					const eventEndDate = DateTime.fromFormat(event.end_datetime, 'yyyy-MM-dd HH:mm:ss');
					const now = DateTime.now();
					return eventStartDate < now && eventEndDate > now;
				});

				// Filter upcoming events
				const upcomingEvents = allEvents.filter((event) => {
					const eventStartDate = DateTime.fromFormat(event.start_datetime, 'yyyy-MM-dd HH:mm:ss');
					const now = DateTime.now();
					return eventStartDate > now && !activeEvents.includes(event);
				});

				// Past events
				const pastEvents = allEvents.filter((event) => {
					const eventEndDate = DateTime.fromFormat(event.end_datetime, 'yyyy-MM-dd HH:mm:ss');
					const now = DateTime.now();
					return eventEndDate < now;
				});

				// Return events
				events.value = [...sortAndReverse(activeEvents), ...sortAndReverse(upcomingEvents)];

				// Add past events if events are not all events
				if (type.value !== 'all') {
					events.value = [...events.value, ...sortAndReverse(pastEvents)];
				}

				// Update cache
				const key = ['events', search, type].join('-');
				set(key, eventsData.value);
			}
		},
		{ immediate: true },
	);

	return {
		events,
		fetchNextPage,
		hasNextPage,
		isFetchedEventsDataRequests,
		isFetchingEventsDataRequests,
		isLoadingEventsData,
		refetchEventsDataRequests,
		removeEventsDataRequestsFromCache,
	};
};

const eventEnded = (event: Event) => {
	const eventEndDate = DateTime.fromFormat(event.end_datetime, 'yyyy-MM-dd HH:mm:ss');
	const now = DateTime.now();
	return eventEndDate < now;
};

const getICSfile = async (eventId: number): Promise<any> => {
	const headers: { [header: string]: string } = {
		'Content-Type': 'text/calendar',
		'Content-Disposition': 'attachment; filename="tenant-name.ics',
		charset: 'utf-8',
		method: 'REQUEST',
	};

	const { data } = await anykrowdApi.get<any>(`/events/calendar-invite/${eventId}`, false, headers);
	return data;
};

// Is going to event or has bought tickets
const isGoingToEventOrHasBoughtTickets = (event: Event): boolean => {
	return event && (event.going || event?.event_tickets_count > 0);
};

// No tickets available
const noTicketsAvailable = (event: Event) => {
	return event?.ticket_types.length === 0 && !event?.tickets_link;
};

// Show ticket button
const showTicketButton = (event: Event) => {
	return event.event_tickets_count && event.event_tickets_count > 0 && !eventEnded(event);
};

const canBuyTicket = (event: Event, tenantConfig?: TenantConfig) => {
	if (tenantConfig && tenantConfig?.buy_ticket_clientx === false) {
		return false;
	}

	if (event?.tickets_link) {
		return true;
	}

	if (event?.ticket_types.length === 0 && !event?.tickets_link) {
		return false;
	}

	if (event?.ticket_types?.length === 1 && event?.ticket_types[0]?.door_sales == true) {
		return false;
	}

	return !event?.sold_out && !event?.is_past;
};

// Use events
const useEvents = () => {
	// Init
	const { t } = useI18n();
	const { presentDownloadAvailableModal } = useDownload();
	const { detectBrowser } = useBrowser();
	const route = useRoute();

	// addToCalendar
	const addToCalendar = async (event: Event) => {
		if (Capacitor.isNativePlatform()) {
			const url = `${window.apiUrl}/events/calendar-invite/${event.id}`;
			Browser.open({ url });
		} else {
			try {
				const icsFile = await getICSfile(event?.id);
				const blob = new Blob([icsFile], { type: 'text/calendar' });
				const url = window.URL.createObjectURL(blob);
				const browser = detectBrowser();

				// Fix for Safari
				if (
					browser === BrowserType.IOS ||
					browser === BrowserType.IOS_WEBVIEW ||
					browser === BrowserType.SAFARI
				) {
					window.location.assign(url);
					return;
				}

				const pwa = window.open(url);

				if (!pwa || pwa?.closed || typeof pwa?.closed === 'undefined') {
					await presentToast('top', t('general.pop_up_blocker'), 5000, 'danger');
				}
			} catch (e: any) {
				await presentToast('top', e.toString(), 5000, 'danger');
			}
		}
	};

	// Download ticket
	const downloadTicket = async (event: Event, ticket: EventTicket) => {
		const loading = await presentLoading(t('general.please_wait'));
		const result = await _getDownloadUrl(event.id, ticket.id);
		try {
			const data: DownloadModalProps = {
				downloadItem: {
					href: result.url,
					linkName: `Ticket ${ticket.event.name}`,
				},
				downloadType: DownloadType.TICKET,
				title: t('download_modal.title', { file_name: `Ticket ${event.name}` }),
			};

			await presentDownloadAvailableModal(data);
			loading.dismiss();
		} catch (e: any) {
			loading.dismiss();
		}
	};

	// updateTicket
	const updateTicket = async () => {
		// Process ticket according to its type
		const name = route.query.friendFullName?.toString();
		const email = route.query.friendEmail?.toString();
		const eventTicketId = route.query.ticketId?.toString();

		if (name && email && eventTicketId) {
			const loading = await presentLoading(t('general.please_wait'));
			const { data } = await anykrowdApi.post(
				`/events/edit-ticket/${eventTicketId}`,
				{
					name,
					email,
					eventTicketId,
				},
				true,
			);
			if (data.code && data.code === 'ticket_edited') {
				await presentToast('top', t('ticket.saved'), 5000);
			} else {
				await presentToast('top', t('ticket.saving_error'), 5000);
			}
			loading.dismiss();

			return true;
		} else {
			return false;
		}
	};

	return {
		addToCalendar,
		doorSalesOnly,
		downloadTicket,
		eventEnded,
		getEvent,
		getEventApiCall,
		getEventParticipants,
		getEventTicketsApiCall,
		getEvents,
		getEventsApiCall,
		getEventsCount,
		getEventTickets,
		isGoingToEventOrHasBoughtTickets,
		noTicketsAvailable,
		showTicketButton,
		updateTicket,
		canBuyTicket,
	};
};

export default useEvents;

import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ActivateRfid',
  setup(__props) {

const { activateRfid } = useRfidGuest();
const route = useRoute();
const selfLinkingBraceletToken = route.params.id.toString();

// onMountedHook
onMounted(async () => {
	if (selfLinkingBraceletToken) {
		const data = await activateRfid(selfLinkingBraceletToken);
		if (data.encrypted_rfid_tag) {
			await router.push({ path: `/wallet/wallet-handler/${data.encrypted_rfid_tag}` });
		}
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["validation-schema"]

import vue from 'vue';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { callOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useToast from '@/shared/composables/useToast';
import { useReCaptcha } from 'vue-recaptcha-v3';
import useLoading from '@/shared/composables/useLoading';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInWithPhoneForm',
  emits: ['onFormValidation'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emit = __emit;
const { verifyPhone } = useAuth();
const { presentToast } = useToast();
const { presentLoading } = useLoading();
const recaptcha = useReCaptcha();
const executeRecaptcha = recaptcha?.executeRecaptcha;

const phoneRegExp = /^\+[1-9]\d{7,14}$/;

const validationSchema = yup.object({
	phone_number: yup
		.string()
		.matches(phoneRegExp, t('auth.sign_in_phone_page.errors.phone'))
		.required(t('auth.sign_in_phone_page.required')),
});

const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

const onSubmit = handleSubmit(async (formData: any) => {
	const loading = await presentLoading(t('general.please_wait'));

	try {
		let recaptchaToken = '';

		if (executeRecaptcha) {
			recaptchaToken = await executeRecaptcha('login_action');
		}

		await verifyPhone({
			userData: {
				...formData,
			},
			message: t('general.please_wait'),
			recaptchaToken: recaptchaToken,
		});

		await loading.dismiss();
	} catch (error: any) {
		await loading.dismiss();

		await presentToast(
			'top',
			error.response?.data?.message ? error.response.data.message.toString() : error.toString(),
			5000,
			'danger',
		);
	}
});

const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	emit('onFormValidation', meta.value.valid);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    id: "signInWithPhoneForm",
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args))),
    "validation-schema": _unref(validationSchema)
  }, [
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      iconLeft: _unref(callOutline),
      name: "phone_number",
      type: "tel",
      placeholder: _ctx.$t('auth.sign_in_phone_page.phone'),
      onInputChanged: onInputChange
    }, null, 8, ["iconLeft", "placeholder"])
  ], 40, _hoisted_1))
}
}

})
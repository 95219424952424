import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-sourcesanspro-semibold text-xs2" }


export default /*@__PURE__*/_defineComponent({
  __name: 'LabelTopLeft',
  props: {
	class: { type: String },
	label: { type: String, required: true },
	labelContainerClass: { type: String },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([props.class, "absolute left-0 top-0 w-0 h-0 border-solid border-b-[70px] border-l-[70px] border-transparent rounded-tl-md z-10"])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([props.labelContainerClass, "absolute -rotate-[45deg] z-20"])
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(props.label), 1)
    ], 2)
  ], 64))
}
}

})
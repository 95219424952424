import { CacheMeta } from '@/shared/interfaces/api';
import { LatestTopUpTransaction } from '@/shared/interfaces/topUp';

export class Currency {
	id: number;
	default: boolean;
	refundable: boolean;
	topupable: boolean;
	name: string;
	description: string;
	prefix: string;
	suffix: string;
	exchange_rate: number;
	burning_weight: number;

	constructor() {
		this.id = 0;
		this.default = false;
		this.refundable = false;
		this.topupable = false;
		this.name = '';
		this.description = '';
		this.prefix = '';
		this.suffix = '';
		this.exchange_rate = 0;
		this.burning_weight = 0;
	}
}

export class Wallet {
	id: number;
	current_balance: string;

	currency: Currency;

	constructor() {
		this.id = 0;
		this.current_balance = '0.00';
		this.currency = new Currency();
	}
}

export class CurrentBalance extends CacheMeta {
	current_balance: string;
	latest_topup_order?: LatestTopUpTransaction;
	message_body?: string;
	message_title?: string;
	wallets: Wallet[];

	constructor() {
		super();
		this.current_balance = '0.00';
		this.wallets = [];
	}
}

export class PreviousTopUpAmount {
	amount: string;
	_isActive: boolean;
	constructor() {
		this.amount = '';
		this._isActive = false;
	}
}
export class MinimumTopUpAmount {
	amount: number;

	constructor() {
		this.amount = 0;
	}
}

export class RefundInfo {
	enabled: boolean;
	refund_message: string | null;
	manual_refund_enabled: boolean;
	manual_refund_days: number | null;
	cost: number;
	total_amount: number;
	online_amount: number;
	online_cutoff_days: number | null;

	constructor(data?: any) {
		this.enabled = data?.enabled || true;
		let refundMessage = data?.refund_message || null;
		this.manual_refund_enabled = data?.manual_refund_enabled || false;
		this.manual_refund_days = data?.manual_refund_days || null;
		this.cost = data?.cost || 0;
		this.total_amount = data?.total_amount || 0;
		this.online_amount = data?.online_amount || 0;
		this.online_cutoff_days = data?.online_cutoff_days;

		if (refundMessage) {
			refundMessage = refundMessage
				.replace(/:refundCost/g, this.cost.toFixed(2))
				.replace(/:mollieRefundTopUpAge/g, this.online_cutoff_days)
				.replace(/:manualRefundProcessingDays/g, this.manual_refund_days);
		}

		this.refund_message = refundMessage;
	}
}

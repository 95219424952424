import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex w-full items-center justify-center mb-4" }
const _hoisted_2 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4" }
const _hoisted_3 = { class: "font-sourcesanspro-semibold text-lg" }
const _hoisted_4 = { class: "text-anykrowd-dark" }
const _hoisted_5 = { class: "flex flex-col h-full mb-[300px]" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center justify-start cursor-pointer"
}
const _hoisted_7 = {
  key: 0,
  class: "font-sourcesanspro-regular text-base"
}
const _hoisted_8 = {
  key: 0,
  class: "h-full px-[34px]"
}
const _hoisted_9 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_10 = { class: "flex flex-col grow" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = {
  for: "terms_and_conditions_accepted",
  class: "font-sourcesanspro-regular text-small text-color-magenta text-left"
}
const _hoisted_14 = {
  key: 4,
  class: "mb-[100px]"
}
const _hoisted_15 = {
  for: "privacy_policy_accepted",
  class: "font-sourcesanspro-regular text-small text-color-magenta text-left"
}

import { onBeforeMount, ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { DateTime } from 'luxon';
import CheckboxInput from '@/shared/components/sign-up-forms/CheckboxInput.vue';
import countries from '@/statics/countries.json';
import DateInputMask from '@/shared/components/sign-up-forms/DateInputMask.vue';
import FormStep from '@/shared/components/sign-up-forms/FormStep.vue';
import FormWizard from '@/shared/components/sign-up-forms/FormWizard.vue';
import hexRgb from 'hex-rgb';
import ImageInput from '@/shared/components/sign-up-forms/ImageInput.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import RadioInput from '@/shared/components/sign-up-forms/RadioInput.vue';
import rgbHex from 'rgb-hex';
import SearchInput from '@/shared/components/sign-up-forms/SearchInput.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useStepChanger from '@/shared/composables/useStepChanger';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SignUpWithEmailPage',
  setup(__props) {

const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { getRegistrationForm } = useRegistrationForm();
const { registrationForm, validationSchema } = getRegistrationForm();
const { nextStep, previousStep, stepIndex } = useStepChanger();
const { registerUser, isErrorRegister } = useAuth();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const disableSubmitButton = ref(false);

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

// Reset step
onBeforeMount(() => {
	stepIndex.value = 0;
});

// Submit form
const onSubmit = async (form: any) => {
	const formData: any = {};
	disableSubmitButton.value = true;

	Object.keys(form).forEach((key) => {
		if (form[key] !== undefined && form[key] !== null) {
			if (key === 'date_of_birth') {
				if (form.date_of_birth == '') {
					// Remove date of birth if it was set with incorrect data
					delete form.date_of_birth;
				} else {
					formData['date_of_birth'] = DateTime.fromFormat(form.date_of_birth, 'dd-MM-yyyy').toFormat(
						'yyyy-MM-dd',
					);
				}
			} else {
				formData[key] = form[key];
			}
		}
	});

	// Send data using api
	await registerUser({
		...formData,
	});
};

watch(isErrorRegister, async () => {
	if (isErrorRegister.value) {
		disableSubmitButton.value = false;
	}
});

return (_ctx: any,_cache: any) => {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`),
    class: "flex"
  }, {
    default: _withCtx(() => [
      _createVNode(ModalThree, { "is-open": modalState.value }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (setModal(false)))
              }, null, 8, ["icon"])
            ])
          ]),
          _createElementVNode("h1", _hoisted_3, _toDisplayString(modalType.value === 'tos' ? 'Terms and conditions' : 'Privacy Policy'), 1),
          _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
            [_directive_dompurify_html, 
					modalType.value === 'tos' ? _unref(tenantConfig)!.terms_and_conditions : _unref(tenantConfig)!.privacy_policy
				]
          ])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            (_unref(stepIndex) === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_unref(RouterLink), {
                    to: "/dashboard",
                    class: "flex items-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(chevronBackOutline),
                        class: "text-white"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_unref(stepIndex) > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "flex items-center justify-start cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(previousStep) && _unref(previousStep)(...args)))
                }, [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ]))
              : _createCommentVNode("", true)
          ]),
          center: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(validationSchema), (step, index) => {
              return (_openBlock(), _createElementBlock("button", {
                type: "button",
                class: _normalizeClass(["step-line bg-white mx-1", [{ 'background-white-15': index > _unref(stepIndex) }]]),
                key: index
              }, null, 2))
            }), 128))
          ]),
          right: _withCtx(() => [
            (
							_unref(registrationForm)?.length &&
							!_unref(registrationForm)[_unref(stepIndex)].filter((field) => field.required).length
						)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(RouterLink), {
                    to: "",
                    onClick: _unref(nextStep)
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Skip")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_unref(validationSchema) && _unref(registrationForm))
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(FormWizard, {
                class: "height-inherit mb-4",
                "validation-schema": _unref(validationSchema),
                "last-step-index": _unref(validationSchema).length - 1,
                currentStep: _unref(stepIndex),
                onOnGoNextStep: _unref(nextStep),
                onSubmit: onSubmit,
                "disable-submit-button": disableSubmitButton.value
              }, {
                default: _withCtx((slotProps) => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(registrationForm), (fields, index) => {
                    return (_openBlock(), _createBlock(FormStep, {
                      key: index,
                      index: index,
                      currentStep: _unref(stepIndex),
                      class: "flex flex-col height-inherit mb-2 pt-4"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields, (field, indexField) => {
                          return (_openBlock(), _createElementBlock("div", { key: indexField }, [
                            (
									field.field_name !== 'terms_and_conditions_accepted' &&
									field.field_name !== 'privacy_policy_accepted'
								)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createVNode(_component_ion_label, { class: "font-sourcesanspro-regular text-white/50" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(field.field_label + (field.required ? '' : ' (optional)')), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", null, [
                                (
											(field.type === 'text' || field.type === 'tel') &&
											!['country'].includes(field.field_name)
										)
                                  ? (_openBlock(), _createBlock(TextInput, {
                                      key: 0,
                                      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                                      name: field.field_name,
                                      placeholder: field.field_label,
                                      "show-error-message": true,
                                      "show-input-success": true,
                                      value: slotProps.values[field.field_name],
                                      type: "text"
                                    }, null, 8, ["name", "placeholder", "value"]))
                                  : _createCommentVNode("", true),
                                (field.type === 'password')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                      _createVNode(TextInput, {
                                        "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                                        name: field.field_name,
                                        placeholder: field.field_label,
                                        "show-error-message": true,
                                        "show-input-success": true,
                                        value: slotProps.values[field.field_name],
                                        "manual-submit": slotProps.onSubmit,
                                        type: "password"
                                      }, null, 8, ["name", "placeholder", "value", "manual-submit"]),
                                      _createVNode(TextInput, {
                                        "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                                        name: "password_confirmation",
                                        placeholder: _unref(t)('auth.sign_up.confirm_password'),
                                        "show-error-message": true,
                                        "show-input-success": true,
                                        "manual-submit": slotProps.onSubmit,
                                        value: slotProps.values['password_confirmation'],
                                        type: "password"
                                      }, null, 8, ["placeholder", "manual-submit", "value"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (field.type === 'select')
                                  ? (_openBlock(), _createBlock(RadioInput, {
                                      key: 2,
                                      name: field.field_name,
                                      values: field.options,
                                      value: slotProps.values[field.field_name]
                                    }, null, 8, ["name", "values", "value"]))
                                  : _createCommentVNode("", true),
                                (
											field.type === 'checkbox' &&
											field.field_name === 'terms_and_conditions_accepted'
										)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                      _createVNode(CheckboxInput, {
                                        "container-class": "items-center justify-start",
                                        id: "terms_and_conditions_accepted",
                                        name: "terms_and_conditions_accepted",
                                        type: "checkbox"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("label", _hoisted_13, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.terms')) + " ", 1),
                                            _createElementVNode("a", {
                                              onClick: _cache[2] || (_cache[2] = ($event: any) => (setModal(true, 'tos'))),
                                              target: "_blank",
                                              class: "text-white underline cursor-pointer"
                                            }, _toDisplayString(_ctx.$t('auth.sign_in_home_page.terms_of_use')), 1)
                                          ])
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  : _createCommentVNode("", true),
                                (
											field.type === 'checkbox' && field.field_name === 'privacy_policy_accepted'
										)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                      _createVNode(CheckboxInput, {
                                        "container-class": "items-center justify-start",
                                        id: "privacy_policy_accepted",
                                        name: "privacy_policy_accepted",
                                        type: "checkbox"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("label", _hoisted_15, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.terms')) + " ", 1),
                                            _createElementVNode("a", {
                                              onClick: _cache[3] || (_cache[3] = ($event: any) => (setModal(true, 'pp'))),
                                              target: "_blank",
                                              class: "text-white underline cursor_pointer"
                                            }, _toDisplayString(_ctx.$t('auth.sign_in_home_page.terms_privacy_policy')), 1)
                                          ])
                                        ]),
                                        _: 1
                                      })
                                    ]))
                                  : _createCommentVNode("", true),
                                (field.type === 'date')
                                  ? (_openBlock(), _createBlock(DateInputMask, {
                                      key: 5,
                                      "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                                      customInputClass: "justify-start",
                                      name: field.field_name,
                                      value: slotProps.values[field.field_name],
                                      type: "date"
                                    }, null, 8, ["name", "value"]))
                                  : _createCommentVNode("", true),
                                (field.field_name === 'country')
                                  ? (_openBlock(), _createBlock(SearchInput, {
                                      key: 6,
                                      name: field.field_name,
                                      placeholder: _ctx.$t('auth.sign_up_additional_page.step_four.placeholder'),
                                      data: _unref(countries).map((country) => country.name),
                                      icon: _unref(chevronDownOutline),
                                      value: slotProps.values[field.field_name]
                                    }, null, 8, ["name", "placeholder", "data", "icon", "value"]))
                                  : _createCommentVNode("", true),
                                (field.field_name === 'profile_image')
                                  ? (_openBlock(), _createBlock(ImageInput, {
                                      key: 7,
                                      "wrapper-class": "flex flex-col items-center justify-center",
                                      name: field.field_name,
                                      placeholder: field.field_label,
                                      "show-error-message": false,
                                      "show-input-success": false,
                                      value: slotProps.values[field.field_name],
                                      type: "text"
                                    }, null, 8, ["name", "placeholder", "value"]))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["index", "currentStep"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["validation-schema", "last-step-index", "currentStep", "onOnGoNextStep", "disable-submit-button"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
<script setup lang="ts">
import { onMounted, PropType, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { IonContent, IonButton, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { addOutline, chevronBackOutline, chevronDownOutline, chevronForwardOutline, linkOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { RfidTag } from '@/shared/interfaces/rfidTag';
import { UserProfile } from '@/shared/interfaces/user';
import useAlert from '@/shared/composables/useAlert';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useTenant from '@/shared/composables/useTenant';
import { captureMessage } from '@/sentry';
import { Wallet } from '@/shared/interfaces/balance';
import useAuth from '@/modules/auth/composables/useAuth';
import { useAuthStore } from '@/stores/auth/authStore';
import { RefundRequestResource } from '@/shared/interfaces/refundRequest';
import { RefundRequestStatus } from '@/shared/interfaces/refundRequest';
import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';

// Props
const props = defineProps({
	rfidTag: { type: Object as PropType<RfidTag> },
	encryptedRfidTag: { type: String, required: true },
	isSuccessRfidTag: { type: Boolean, default: false },
	userProfile: { type: Object as PropType<UserProfile> },
	continueLinking: { type: Boolean, default: false },
});

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();
const { presentAlert } = useAlert();
const selectedWallet = ref<Wallet | undefined>();
const linkingStarted = ref(false);
const { loginUserWithRfid } = useAuth();
const { isAuthenticated } = useAuthStore();

// showConfirmLinkingAlert
const showConfirmLinkingAlert = async () => {
	await presentAlert({
		// cssClass: 'my-custom-class',
		header: t('rfid.guest.linking_confirmation_alert.title'),
		buttons: [
			{
				text: t('rfid.guest.linking_confirmation_alert.cancel'),
				role: 'cancel',
				cssClass: 'secondary',
			},
			{
				text: t('rfid.guest.linking_confirmation_alert.confirm'),
				role: 'ok',
				handler: linkBracelet,
			},
		],
	});
};

watchEffect(() => {
	const wallets = props.rfidTag?.wallets;
	if (wallets && wallets.length && wallets.length > 0) {
		if (!selectedWallet.value) {
			selectedWallet.value = wallets.find((wallet) => {
				return wallet.currency.default;
			});

			if (!selectedWallet.value && wallets.length > 0) {
				selectedWallet.value = wallets[0];
			}
		}
	}
	if (props.continueLinking) {
		startLink();
	}
});

const selectWallet = (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
};

const getWalletAtIndex = (index: number) => {
	return props.rfidTag?.wallets[index];
};

const currentWalletIndex = () => {
	const index = props.rfidTag?.wallets.findIndex((wallet) => wallet === selectedWallet.value);
	if (index === -1) {
		// dirty as f*** but it works
		return -100000;
	}
	return index ?? -100000;
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};

// linkBracelet
const linkBracelet = () => {
	router.push({
		path: '/wallet/wallet-handler/link-bracelet',
		query: {
			amount: selectedWallet.value!.current_balance,
			encryptedRfidTag: props.encryptedRfidTag,
			walletId: selectedWallet.value!.id,
			rfidTag: props.rfidTag?.uuid,
		},
	});
};

// startLink
const startLink = () => {
	if (!props.userProfile || props.userProfile.id === 0) {
		router.push({
			path: '/auth/signuphome',
			query: { callbackPage: 'wallet/wallet-handler', id: props.encryptedRfidTag },
		});
	} else {
		if (!linkingStarted.value) {
			showConfirmLinkingAlert();
			linkingStarted.value = true;
		}
	}
};
</script>

<template>
	<div>
		<div class="flex">
			<div class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="!!getPreviousWallet()"
					:icon="chevronBackOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectPreviousWallet()"></ion-icon>
			</div>
			<div class="flex w-full flex-col justify-between px-3 py-3 bg-white rounded-xl mb-2">
				<div class="flex items-center justify-between mb-4">
					<img :src="require('@/statics/svg/rfid/bracelet-with-bg.svg')" height="34" width="34" />
					<div v-if="props.rfidTag">
						<div class="flex justify-between mb-2">
							<div></div>
							<div v-if="selectedWallet">
								<div
									id="popover-button"
									class="flex items-center justify-center rounded-lg font-sourcesanspro-bold text-anykrowd-dark text-md cursor-pointer">
									<div class="mr-2">{{ selectedWallet.currency.prefix }}</div>
									<div class="mr-2">{{ selectedWallet?.currency.name }}</div>
									<ion-icon
										v-if="rfidTag?.wallets && rfidTag?.wallets?.length > 1"
										:icon="chevronDownOutline"
										class="text-anykrowd-dark text-md"></ion-icon>
								</div>
								<ion-popover trigger="popover-button" :dismiss-on-select="true">
									<ion-content>
										<ion-list>
											<ion-item
												v-for="wallet in rfidTag?.wallets"
												class="flex items-center justify-center ion-no-padding ion-no-margin"
												:key="wallet.id"
												:button="true"
												@click="selectWallet(wallet)"
												:detail="false">
												<div class="flex flex-col px-3 py-2 font-sourcesanspro-regular">
													<div class="mb-1">
														<span>
															{{ wallet.currency.prefix }}
															{{ wallet.current_balance }}
															{{ wallet.currency.name }}
														</span>
													</div>
													<!-- <span class="text-sm text-white/60">+2 BONUS COOKIES</span> -->
												</div>
											</ion-item>
										</ion-list>
									</ion-content>
								</ion-popover>
							</div>
						</div>
					</div>
				</div>
				<div class="flex items-center mb-4">
					<UserAvatar
						v-if="props.rfidTag && props.rfidTag.user"
						:container-class="'h-[42px] mr-2'"
						:src="
							props.rfidTag?.user.profile_image_url
								? props.rfidTag?.user.profile_image_url
								: require('@/statics/img/no_profile_pic.jpg')
						"
						alt="User icon"
						:show-status="false" />
					<div class="flex flex-col">
						<span
							v-if="props.rfidTag"
							class="font-sourcesanspro-black text-l2 font-color-dark leading-none">
							{{
								props.rfidTag.user
									? `${rfidTag?.user.firstname} ${rfidTag?.user.lastname}`
									: `${t('rfid.guest.wallet')} #${selectedWallet?.id}`
							}}
						</span>
						<div
							v-if="rfidTag?.user && rfidTag?.user.email"
							class="font-sourcesanspro-regular text-base font-color-dark leading-none break-words">
							{{ rfidTag?.user.email }}
						</div>
						<span
							v-if="props.rfidTag"
							class="font-sourcesanspro-regular text-base font-color-dark leading-none">
							{{ `${props.rfidTag?.uuid}` }}
						</span>
					</div>
				</div>

				<div class="flex items-center justify-between">
					<div class="flex">
						<ion-spinner
							v-if="!isSuccessRfidTag && !!tenantConfig"
							name="dots"
							class="spinner-black"></ion-spinner>
						<div v-if="selectedWallet && tenantConfig" class="flex items-baseline">
							<div class="mr-1">
								<span class="font-sourcesanspro-black text-lg3 font-color-dark">
									{{ selectedWallet?.currency.prefix }}{{ selectedWallet?.current_balance }}
								</span>
							</div>
							<span class="font-sourcesanspro-regular text-md font-color-dark">{{
								selectedWallet.currency.suffix
							}}</span>
						</div>
					</div>
					<div v-if="selectedWallet && selectedWallet!.currency.topupable == 1" class="flex items-center">
						<ion-button
							class="w-[117px] h-[40px] black font-sourcesanspro-semibold text-sm2 ion-no-margin"
							shape="round"
							:routerLink="`/top-up/${selectedWallet?.id}?id=${encryptedRfidTag}`">
							<div class="mr-2"><ion-icon :icon="addOutline"></ion-icon></div>
							{{ t('rfid.guest.add') }}
						</ion-button>
					</div>
				</div>

				<div
					v-if="selectedWallet && selectedWallet.currency?.description"
					class="flex flex-col font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm mb-6 mt-4">
					{{ selectedWallet.currency?.description }}
				</div>
			</div>
			<div class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="!!getNextWallet()"
					:icon="chevronForwardOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectNextWallet()"></ion-icon>
			</div>
		</div>

		<div v-if="showLoggedData() && !props.rfidTag?.user" class="px-[34px]">
			<ion-button
				v-if="selectedWallet"
				class="w-full items-center rounded-box hover:bg-white hover:rounded-xl"
				@click="startLink()">
				<div class="mr-2">
					<ion-icon :icon="linkOutline" class="text-anykrowd-dark"></ion-icon>
				</div>
				{{ userProfile?.id != 0 ? t('rfid.guest.link_wallet_cta') : t('rfid.guest.authenticate') }}
			</ion-button>
		</div>

		<div v-if="props.rfidTag && props.rfidTag.user && !props.rfidTag.user.has_external_id" class="px-[34px]">
			<router-link :to="isAuthenticated() ? '/dashboard' : '/login'">
				<ion-button class="w-full items-center rounded-box hover:bg-white hover:rounded-xl">
					{{ t('rfid.guest.continue_to_dashboard') }}
				</ion-button>
			</router-link>
		</div>

		<div
			v-if="
				props.encryptedRfidTag &&
				props.rfidTag &&
				props.rfidTag.user &&
				props.rfidTag.user.has_external_id &&
				!isAuthenticated()
			"
			class="px-[34px]">
			<ion-button
				class="w-full items-center rounded-box hover:bg-white hover:rounded-xl"
				@click="loginUserWithRfid(props.encryptedRfidTag, t('common.wait'))">
				<div class="mr-2">
					<ion-icon :icon="linkOutline" class="text-anykrowd-dark"></ion-icon>
				</div>
				{{ t('rfid.manage') }}
			</ion-button>
		</div>

		<div
			v-if="
				tenantConfig?.enable_full_guest_mode &&
				tenantConfig.enable_refund &&
				selectedWallet?.currency.refundable &&
				props.rfidTag?.is_refundable
			"
			class="px-[34px]">
			<router-link
				:to="`/wallet/refunds/${props.encryptedRfidTag}/guestrequest?rfidTagUuid=${props.rfidTag?.uuid}`">
				<ion-button class="w-full items-center rounded-box hover:bg-white hover:rounded-xl">
					{{ t('rfid.guest.request_full_guest_mode_refund') }}
				</ion-button>
			</router-link>
		</div>
		<div
			v-if="
				tenantConfig?.enable_full_guest_mode &&
				tenantConfig.enable_refund &&
				props.rfidTag &&
				props.rfidTag.refund_requests &&
				props.rfidTag.refund_requests.length > 0
			"
			class="px-[34px] mb-4 mt-2">
			<div class="my-6 font-sourcesanspro-regular text-sm2 text-center text-white/60">
				{{ t('wallet.refund.top_warning_message', { days: 14 }) }}
			</div>
			<AccordionList :title="t('wallet.refund.requests')">
				<template #items>
					<div
						v-if="
							props.rfidTag && props.rfidTag.refund_requests && props.rfidTag.refund_requests.length === 0
						"
						class="flex items-center justify-left p-4">
						<span class="font-sourcesanspro-light text-regular text-white/40">
							{{ t('wallet.refund.no_entries') }}
						</span>
					</div>

					<!-- Father item -->
					<div v-if="props.rfidTag && props.rfidTag.refund_requests">
						<AccordionListItem
							v-for="(item, idx) in props.rfidTag.refund_requests.map(
								(refund: RefundRequestResource, index) => {
									return {
										date: refund.created_at,
										id: index.toString(),
										refundId: refund.id,
										title: t('wallet.refund.item_title', { status: refund.status }),
										iconClass: 'text-white',
										info: refund.info,
										price: refund.fiat_amount,
										customSubtitle: refund.status,
										tag: refund.status,
										transactionType:
											refund.status !== RefundRequestStatus.FAILED ? 'CREDIT' : 'DEBIT',
									};
								},
							)"
							:key="idx"
							:key-value="item.id"
							:icon="require('@/statics/svg/profile/refund.svg')"
							:iconClass="item.iconClass"
							:tag="item.tag"
							:transaction-type="item.transactionType"
							:title="item.title">
							<template #actions>
								<AccordionListItemAction
									v-if="tenantConfig"
									:date="DateTime.fromISO(item.date).toFormat('dd LLLL yyyy • HH:mm')"
									:hide-transaction-sign="true"
									:price="+item.price"
									:transaction-type="item.transactionType"
									:tenant-config="tenantConfig" />
							</template>
							<template #content>
								<div v-if="item.info" class="p-4 bg-white/10 font-sourcesanspro-regular text-sm">
									{{ item.info }}
								</div>
							</template>
						</AccordionListItem>
					</div>
				</template>
			</AccordionList>
		</div>
	</div>
</template>

<style scoped></style>

import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex justify-between w-full" }
const _hoisted_2 = { class: "flex flex-col font-sourcesanspro-semibold" }
const _hoisted_3 = { class: "text-sm whitespace-nowrap" }
const _hoisted_4 = { class: "text-base2 whitespace-nowrap" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "w-full" }

import { ref, toRef, PropType, watch } from 'vue';
import router from '@/router';
import { IonProgressBar } from '@ionic/vue';
import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query';

import { ActivityDuration } from '@/shared/interfaces/activityDuration';
import { Track, TrackActivity } from '@/shared/interfaces/timeTables';
import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import useStorageService from '@/shared/composables/useStorageService';


export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTableItem',
  props: {
	activity: { type: TrackActivity, required: true },
	backgroundColor: { type: String },
	backgroundColorOpacity: { type: String, required: true },
	barPercent: { type: Number, required: true },
	durationData: { type: Object as PropType<ActivityDuration>, required: true },
	time: { type: String, required: true },
	timeTableId: { type: Number, required: true },
	title: { type: String, required: true },
	track: { type: Track, required: true },
	showLoggedData: { type: Boolean, required: true, default: true },
},
  setup(__props) {

const { get, set } = useStorageService();
const props = __props;

// Init
const favorite = ref(props.activity.isFavorite);
const isFavorite = toRef(props.activity, 'isFavorite');
const queryClient = useQueryClient();

// goToDetails
const goToDetails = async () => {
	await router.push({
		path: `/timetables/activity-detail/${props.activity.id}`,
		query: {
			timeTableId: props.timeTableId,
			trackId: props.track.id,
		},
	});
};

const onFavoriteChanged = (isFavorite: boolean, activityId: number) => {
	favorite.value = isFavorite;
	refreshFilteredTracks(isFavorite, activityId);
};

const refreshFilteredTracks = async (isFavorite: boolean, activityId: number) => {
	// console.log('refreshFilteredTracks call');
	// debugger;
	const timetables = await get('timeTables');
	timetables.forEach(async (timetable: { id: number; tracks: any[] }) => {
		if (timetable.id === props.timeTableId) {
			timetable.tracks.forEach((track) => {
				if (track.id === props.track.id) {
					track.activities.forEach((activity: { pivot_id: number; is_favorite: boolean }) => {
						if (activity.pivot_id === activityId) {
							activity.is_favorite = isFavorite;
						}
					});
				}
			});
			await set(`timeTable-${props.timeTableId}`, timetable);
			queryClient.setQueryData([`timeTable`, props.timeTableId], timetable);
		}
	});

	await set('timeTables', timetables);
	queryClient.setQueryData(['timeTables'], timetables);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative pb-4 h-[85px] cursor-pointer",
    style: _normalizeStyle(props.durationData.duration),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (goToDetails()))
  }, [
    _createElementVNode("div", {
      class: "absolute flex flex-col w-full h-full justify-between p-3 rounded-xl bg-center bg-no-repeat bg-cover",
      style: _normalizeStyle([props.durationData.startFineOffset, `background-image: url(${props.activity.imageUrl})`])
    }, null, 4),
    _createElementVNode("div", {
      class: "absolute flex flex-col w-full h-full justify-between p-3 rounded-xl bg-center bg-no-repeat bg-cover",
      style: _normalizeStyle([
				props.durationData.startFineOffset,
				`background-color: ${props.activity.backgroundColor}`,
				`opacity: ${+__props.backgroundColorOpacity / 100}`,
			])
    }, null, 4),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute flex flex-col w-full h-full items-start justify-between p-3 overflow-hidden", [{ 'border-solid border-2 border-orange-300 rounded-xl': favorite.value }]]),
      style: _normalizeStyle([props.durationData.startFineOffset])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(props.time), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(props.title), 1)
        ]),
        (props.showLoggedData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(FavoriteButton, {
                onOnFavoriteChanged: onFavoriteChanged,
                id: props.activity.pivot_id,
                favorite: props.activity.isFavorite,
                type: 'slot',
                class: _normalizeClass('absolute top-2 right-2 w-[20px] h-[20px] ion-no-margin z-30')
              }, null, 8, ["id", "favorite"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(IonProgressBar), {
          value: props.barPercent
        }, null, 8, ["value"])
      ])
    ], 6)
  ], 4))
}
}

})
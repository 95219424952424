import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { class: "px-[34px] w-full" }
const _hoisted_3 = { class: "flex items-center justify-start" }
const _hoisted_4 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_5 = {
  key: 0,
  class: "mb-2 font-sourcesanspro-regular text-regular text-white/60"
}
const _hoisted_6 = { class: "flex p-4 mt-2 mb-6 rounded-lg bg-white/10" }
const _hoisted_7 = { class: "relative mb-4" }
const _hoisted_8 = ["validation-schema"]
const _hoisted_9 = { class: "px-[34px] mb-[34px]" }
const _hoisted_10 = { class: "flex items-center justify-center w-full" }

import { watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { StringSchema } from 'yup';
import { isValid } from 'iban-validator-js';

import { useSharedStore } from '@/stores/shared/sharedStore';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useTenant from '@/shared/composables/useTenant';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'GuestWalletRequestRefund',
  setup(__props) {

const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const sharedStore = useSharedStore();
const route = useRoute();
const rfidTagUuid = route.query.rfidTagUuid ? route.query.rfidTagUuid.toString() : '';
const encryptedrfidtag = route.params.encryptedrfidtag?.toString();
const isVoucher = route.query.isVoucher ? route.query.isVoucher.toString() : '';
const voucherCode = isVoucher ? route.params.encryptedrfidtag?.toString() : '';

Yup.addMethod<StringSchema>(Yup.string, 'validIban', function () {
	return Yup.string().test('test-valid-iban', 'Not valid IBAN number', (iban) => {
		iban = iban?.replaceAll(' ', '');
		return iban !== '' && iban !== undefined && iban !== null && isValid(iban);
	});
});

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	firstname: Yup.string().required(),
	lastname: Yup.string().required(),
	email: Yup.string().matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email')).required(),
	iban_number: Yup.string().when('amount', ([amount], schema) => {
		return Yup.string().validIban('no valid iban').required('Field is required');
	}),
	// encrypted_rfid_tag: Yup.string().optional(),
	// rfid_tag_uuid: Yup.string().optional(),
	// voucher_code: Yup.string().optional(),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/rfid-tags/refund',
	'post',
	'',
	'',
	5000,
	{
		overwriteDefaultResponseMessage: true,
		responseField: 'message',
	},
);

watch(isSuccess, async () => {
	await router.back();
});

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(IonButton), {
                  class: "ion-no-margin ion-no-padding",
                  fill: "clear",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('rfid.guest.refund')), 1)
            ]),
            _: 1
          }),
          (_unref(tenantConfig) && _unref(tenantConfig).refund_message)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _withDirectives(_createElementVNode("div", _hoisted_6, null, 512), [
                  [_directive_dompurify_html, _unref(tenantConfig).refund_message]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("form", {
              id: "resetPasswordForm",
              "validation-schema": _unref(schema),
              onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-2 border border-solid justify-left item-center focus-within:background-white-15",
                label: "First name",
                name: "firstname",
                type: "text",
                placeholder: "First name",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-2 border border-solid justify-left item-center focus-within:background-white-15",
                label: "Last name",
                name: "lastname",
                type: "text",
                placeholder: "Last name",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-2 border border-solid justify-left item-center focus-within:background-white-15",
                label: "Email",
                name: "email",
                type: "text",
                placeholder: "Email",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15",
                label: "IBAN",
                name: "iban_number",
                placeholder: "IBAN",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15",
                value: _unref(encryptedrfidtag),
                name: "encrypted_rfid_tag",
                type: "hidden",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["value", "onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15",
                value: _unref(rfidTagUuid),
                name: "rfid_tag_uuid",
                type: "hidden",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["value", "onInputChanged"]),
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15",
                value: _unref(voucherCode),
                name: "voucher_code",
                type: "hidden",
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["value", "onInputChanged"])
            ], 40, _hoisted_8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:bg-white hover:rounded-full",
            disabled: !_unref(isFormValid),
            expand: "full",
            form: "resetPasswordForm",
            size: "large",
            shape: "round",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('profile.password_reset.forgot_password_submit')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative flex flex-col px-[34px]" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "relative flex flex-col px-[34px]"
}
const _hoisted_5 = { class: "font-sourcesanspro-semibold text-l2 mb-4 first-letter:capitalize" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-4 w-full" }
const _hoisted_7 = { class: "relative flex flex-col items-center justify-end w-[155px] h-[155px] rounded-lg overflow-hidden backdrop-blur-3xl bg-white/10" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "mb-1 text-center z-10" }
const _hoisted_10 = { class: "font-sourcesanspro-bold text-base2" }
const _hoisted_11 = { class: "flex items-center mb-4 z-10" }
const _hoisted_12 = {
  key: 0,
  class: "flex items-center mb-4"
}
const _hoisted_13 = { class: "mr-2" }
const _hoisted_14 = { class: "font-sourcesanspro-semibold text-sm2 line-through text-white/30" }
const _hoisted_15 = { class: "font-sourcesanspro-bold text-l2" }
const _hoisted_16 = {
  key: 1,
  class: "flex items-center mb-4"
}
const _hoisted_17 = { class: "font-sourcesanspro-bold text-l2" }
const _hoisted_18 = { class: "fixed button-container h-[150px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full" }
const _hoisted_19 = { class: "relative z-10 w-full h-full" }
const _hoisted_20 = { class: "absolute w-full bottom-0 mb-[34px]" }
const _hoisted_21 = { class: "flex w-full justify-between items-center font-sourcesanspro-semibold text-l2" }

import router from '@/router';
import { IonContent, IonIcon, IonSpinner, IonButton } from '@ionic/vue';
import { bagHandleOutline, chevronBackOutline, imageOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import ShoppingCartIcon from '@/shared/components/shop/ShoppingCartIcon.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useShop from '@/modules/shop/composables/useShop';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ShopCategoryPage',
  props: {
	id: { type: String, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const { getCategorizedProducts } = useShop();
const { categoriesWithProducts } = getCategorizedProducts(+props.id);
const { getCartTotalItemsByCategory, getCartTotalPrice } = useCart();

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "mb-16" }, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack()))
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(props.id ? _unref(t)('shop.pages.title.category') : _unref(t)('shop.pages.title.all')), 1)
          ]),
          right: _withCtx(() => [
            _createVNode(ShoppingCartIcon)
          ]),
          _: 1
        })
      ]),
      (!_unref(categoriesWithProducts))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(IonSpinner), { name: "dots" })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categoriesWithProducts), (category, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "flex flex-col mb-7",
                key: i
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(category.category_name.toLowerCase()), 1),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.products, (product) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      to: product.in_stock ? { name: 'shop-product-detail', params: { id: product.id } } : {},
                      key: product.id,
                      class: _normalizeClass(["flex justify-center", [
							{
								'opacity-50': !product.in_stock,
							},
						]])
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          (product.image_url)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: product.image_url,
                                class: "absolute w-[100%] h-[100%] object-fit-cover"
                              }, null, 8, _hoisted_8))
                            : (_openBlock(), _createBlock(_unref(IonIcon), {
                                key: 1,
                                icon: _unref(imageOutline),
                                class: "text-white"
                              }, null, 8, ["icon"])),
                          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute w-[100%] h-[100%] rounded-lg bg-black/60" }, null, -1)),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", _hoisted_10, _toDisplayString(product.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            (product.discount)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  _createElementVNode("div", _hoisted_13, [
                                    _createElementVNode("span", _hoisted_14, " € " + _toDisplayString(product.price), 1)
                                  ]),
                                  _createElementVNode("span", _hoisted_15, "€" + _toDisplayString(product.discountedPrice()?.toFixed(2)), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (!product.discount)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  _createElementVNode("span", _hoisted_17, "€" + _toDisplayString(product.price), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to", "class"]))
                  }), 128))
                ])
              ]))
            }), 128))
          ])),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_unref(IonButton), {
              class: "w-full h-[60px] hover:bg-white hover:rounded-full",
              expand: "full",
              size: "large",
              shape: "round",
              "router-link": "/shop/shopping-cart"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(bagHandleOutline),
                    class: "w-[28px] h-[28px] text-dark-blue",
                    slot: "end"
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(_unref(t)('shop.cart.title')) + " (" + _toDisplayString(_unref(getCartTotalItemsByCategory)(_unref(CartCategoriesEnum).shop)) + ")", 1),
                  _createElementVNode("span", null, "€" + _toDisplayString(_unref(getCartTotalPrice)(_unref(CartCategoriesEnum).shop).toFixed(2)), 1)
                ])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
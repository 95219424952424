import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full pt-[64px] px-[34px] pb-[34px] bg-white items-center justify-between" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-col mb-8 text-center text-anykrowd-dark-purple" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_5 = { class: "font-sourcesanspro-regular p-1 text-base" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 2,
  class: "flex flex-col items-center text-anykrowd-dark-purple"
}
const _hoisted_8 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_9 = { class: "font-sourcesanspro-regular text-base" }
const _hoisted_10 = {
  key: 1,
  class: "flex flex-col items-center justify-center"
}
const _hoisted_11 = { class: "flex items-center justify-center font-sourcesanspro-semibold text-anykrowd-dark-purple mb-2" }
const _hoisted_12 = { class: "text-m2" }
const _hoisted_13 = { class: "flex items-center justify-center text-white" }
const _hoisted_14 = { class: "text-m2" }
const _hoisted_15 = {
  key: 2,
  class: "flex items-center justify-center"
}
const _hoisted_16 = { class: "flex items-center justify-center text-white" }
const _hoisted_17 = { class: "text-m2" }
const _hoisted_18 = {
  key: 3,
  class: "flex"
}
const _hoisted_19 = {
  key: 0,
  class: "flex"
}
const _hoisted_20 = { class: "flex items-center justify-center text-white" }
const _hoisted_21 = { class: "flex items-center justify-center" }
const _hoisted_22 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4" }

import { onMounted, ref, toRef, watch, watchEffect } from 'vue';
import { IonButton, IonIcon, IonSpinner } from '@ionic/vue';
import { Share } from '@capacitor/share';
import { closeOutline, downloadOutline, shareSocialOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import useToast from '@/shared/composables/useToast';
import QrcodeVue from 'qrcode.vue';
import { DateTime } from 'luxon';

import ModalTwo from '@/shared/components/ui/ModalTwo.vue';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useYourId from '@/modules/dashboard/composables/useYourId';
import { useAuthStore } from '@/stores/auth/authStore';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'YourId',
  props: {
	isModalOpen: { type: Boolean, required: true },
},
  emits: ['onCloseModal'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const { t } = useI18n();
const emit = __emit;
const isYourIdModalOpen = ref(false);
const isOpen = toRef(props, 'isModalOpen');
const { getWalletQrCode } = useWallet();
const { errorWalletQrCode, isErrorWalletQrCode, isFetchedWalletQrCode, refetchWalletQrCode, walletQrCode } =
	getWalletQrCode();
const { presentToast } = useToast();
const { getApiErrorString, isOffline } = useUtils();

const isShareAvailable = ref(false);
const { close, qrcode, saveImageAs, share } = useYourId(emit, isYourIdModalOpen);
const authStore = useAuthStore();
const isAuthenticated = authStore.isAuthenticated();

// onMountedHook
onMounted(async () => {
	isShareAvailable.value = (await Share.canShare()).value;
});

// Download
const download = () => {
	close();
	saveImageAs();
};

const isQRCodeExpired = (walletQrCodeValidUntil: string) => {
	return DateTime.now() > DateTime.fromFormat(walletQrCodeValidUntil, 'yyyy-MM-dd HH:mm:ss');
};

const willQRCodeExpireSoon = (walletQrCodeValidUntil: string) => {
	return DateTime.now().plus({ hour: 1 }) > DateTime.fromFormat(walletQrCodeValidUntil, 'yyyy-MM-dd HH:mm:ss');
};

// Watch open state
watch(isOpen, () => {
	isYourIdModalOpen.value = isOpen.value;
});

watchEffect(() => {
	if (walletQrCode && walletQrCode.value && willQRCodeExpireSoon(walletQrCode.value.valid_until) && isAuthenticated) {
		refetchWalletQrCode();
	}
});

// Watch error
watch(isErrorWalletQrCode, async () => {
	if (isErrorWalletQrCode.value) {
		if (isOffline()) {
			await presentToast('top', t('error.offline'), 5000, 'danger');
		} else {
			const error = getApiErrorString(errorWalletQrCode.value);
			if (Array.isArray(error)) {
				await presentToast('top', t(error[0]), 5000, 'danger');
			} else {
				await presentToast('top', t(error.error), 5000, 'danger');
			}
		}
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalTwo, { "is-open": isYourIdModalOpen.value }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_unref(errorWalletQrCode))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h2", _hoisted_4, _toDisplayString(_unref(t)('wallet.qr_code.header')), 1),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)('wallet.qr_code.subtitle')), 1)
              ]),
              (!_unref(isFetchedWalletQrCode))
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_unref(IonSpinner), { name: "dots" })
                  ]))
                : _createCommentVNode("", true),
              (_unref(walletQrCode) && _unref(isFetchedWalletQrCode) && !isQRCodeExpired(_unref(walletQrCode).valid_until))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    ref_key: "qrcode",
                    ref: qrcode,
                    class: "flex justify-center mb-8"
                  }, [
                    _createVNode(QrcodeVue, {
                      value: _unref(walletQrCode)?.qr_code,
                      level: 'M',
                      size: 300
                    }, null, 8, ["value"])
                  ], 512))
                : _createCommentVNode("", true),
              (_unref(walletQrCode) && _unref(isFetchedWalletQrCode) && !isQRCodeExpired(_unref(walletQrCode).valid_until))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('wallet.qr_code.valid')) + ":", 1),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(DateTime).fromFormat(_unref(walletQrCode).valid_until, 'yyyy-MM-dd HH:mm:ss').toFormat(
								'EEEE dd MMM, yyyy HH:mm',
							)), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_unref(walletQrCode) && isQRCodeExpired(_unref(walletQrCode).valid_until))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('wallet.qr_code.expired')), 1)
              ]),
              _createVNode(_unref(IonButton), {
                onClick: _unref(refetchWalletQrCode),
                class: "w-[155px] black ion-no-margin",
                shape: "round"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(t)('wallet.qr_code.refetch')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_unref(errorWalletQrCode))
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createTextVNode(_toDisplayString(_unref(errorWalletQrCode)) + " ", 1),
              _createVNode(_unref(IonButton), {
                onClick: _unref(refetchWalletQrCode),
                class: "w-[155px] black ion-no-margin",
                shape: "round"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('general.retry')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_unref(walletQrCode))
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              (!_unref(errorWalletQrCode) && !isQRCodeExpired(_unref(walletQrCode).valid_until))
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_unref(IonButton), {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (download())),
                      class: "w-[155px] bg-anykrowd-dark-purple rounded-full",
                      shape: "round",
                      size: "large",
                      fill: "outline"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(_unref(IonIcon), {
                            icon: _unref(downloadOutline),
                            class: "mr-2"
                          }, null, 8, ["icon"]),
                          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-m2" }, "Download", -1))
                        ])
                      ]),
                      _: 1
                    }),
                    (isShareAvailable.value)
                      ? (_openBlock(), _createBlock(_unref(IonButton), {
                          key: 0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(share)())),
                          class: "w-[155px] border border-anykrowd-dark-purple rounded-full",
                          shape: "round",
                          size: "large"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_21, [
                              _createVNode(_unref(IonIcon), {
                                icon: _unref(shareSocialOutline),
                                class: "mr-2 text-anykrowd-dark-purple"
                              }, null, 8, ["icon"]),
                              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-anykrowd-dark-purple text-m2" }, "Share", -1))
                            ])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_22, [
          _createVNode(_unref(IonIcon), {
            class: "text-[38px] text-black cursor-pointer",
            icon: _unref(closeOutline),
            onClick: _unref(close)
          }, null, 8, ["icon", "onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/statics/svg/dont-miss-out.svg'


const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "font-sourcesanspro-regular text-base"
}
const _hoisted_4 = { class: "flex flex-col h-full p-[34px]" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-col height-inherit"
}
const _hoisted_6 = { class: "flex flex-col grow justify-center" }
const _hoisted_7 = { class: "mb-4 font-sourcesanspro-semibold text-white text-lg text-center" }
const _hoisted_8 = { class: "font-sourcesanspro-regular text-base text-color-magenta text-center" }
const _hoisted_9 = {
  key: 1,
  class: "flex flex-col height-inherit"
}
const _hoisted_10 = { class: "mb-8" }
const _hoisted_11 = { class: "mb-2 font-sourcesanspro-semibold text-white text-lg text-center" }
const _hoisted_12 = { class: "max-w-[65%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center" }
const _hoisted_13 = { class: "flex flex-col grow" }
const _hoisted_14 = {
  key: 2,
  class: "flex flex-col height-inherit"
}
const _hoisted_15 = { class: "mb-8" }
const _hoisted_16 = { class: "mb-4 font-sourcesanspro-semibold text-white text-lg text-center" }
const _hoisted_17 = { class: "font-sourcesanspro-regular text-base text-color-magenta text-center" }
const _hoisted_18 = { class: "px-[34px]" }
const _hoisted_19 = { class: "flex justify-center next-button w-full mb-6" }
const _hoisted_20 = { class: "flex w-full items-center justify-between" }

import { watch, onBeforeMount } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';

import hexRgb from 'hex-rgb';
import NotificationsSettings from '@/modules/profile/components/NotificationsSettings.vue';
import rgbHex from 'rgb-hex';
import TagCollections from '@/shared/components/TagCollections.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useNotifications from '@/shared/composables/useNotifications';
import useStepChanger from '@/shared/composables/useStepChanger';
import useTagCollections from '@/shared/composables/useTagCollections';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileAdditionalConfigPage',
  setup(__props) {

const { requestPushNotificationsPermission } = useNotifications();
const { tagsList } = useTagCollections([]);
const { isLastStep, nextStep, previousStep, stepIndex } = useStepChanger(
	tagsList && tagsList.value && tagsList.value.length ? 2 : 1,
);
const { getRedirect, setRedirect } = useAuth();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Reset step
onBeforeMount(() => {
	stepIndex.value = 0;
});

watch(stepIndex, () => {
	if (stepIndex.value === 1) {
		// Request push notifications permission
		requestPushNotificationsPermission();
	}
});

const goToDashboard = async () => {
	// Get redirection from store
	// If set, it can be used to go back to callback page
	const redirect = getRedirect();
	if (redirect.callbackPage && redirect.id) {
		// reset redirection and redirect
		setRedirect({ callbackPage: '', id: '' });
		router.push({ path: `/${redirect.callbackPage}/${redirect.id}`, query: { continueLinking: 'true' } });
	} else {
		router.push('/dashboard');
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`),
    class: "flex"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(previousStep) && _unref(previousStep)(...args)))
            }, [
              (_unref(stepIndex) === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true),
              (_unref(stepIndex) > 0)
                ? (_openBlock(), _createBlock(_unref(IonIcon), {
                    key: 1,
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true)
            ])
          ]),
          right: _withCtx(() => [
            (!_unref(isLastStep))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: "",
                    onClick: _unref(nextStep)
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Skip")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          (_unref(stepIndex) === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    src: _imports_0,
                    class: "mb-6"
                  }, null, -1)),
                  _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_five.title')), 1),
                  _createElementVNode("p", _hoisted_8, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_five.subtitle')), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(stepIndex) === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.title')), 1),
                  _createElementVNode("p", _hoisted_12, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_six.subtitle')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(NotificationsSettings)
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(stepIndex) === 2 && _unref(tagsList) && _unref(tagsList).length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("h1", _hoisted_16, _toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_seven.title')), 1),
                  _createElementVNode("p", _hoisted_17, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('auth.sign_up_additional_page.step_seven.subtitle')), 1)
                  ])
                ]),
                _createVNode(TagCollections, {
                  "accordion-class": 'ion-no-padding',
                  "accordion-content-class": 'ion-padding',
                  userTags: []
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_unref(IonButton), {
              class: "continue-button w-full",
              type: "submit",
              color: "primary",
              size: "large",
              shape: "round",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(isLastStep) ? goToDashboard() : _unref(nextStep)()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_20, [
                  _createTextVNode(_toDisplayString(_unref(isLastStep) ? "Let's finish" : 'Continue') + " ", 1),
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(arrowForwardOutline),
                    slot: "end"
                  }, null, 8, ["icon"])
                ])
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
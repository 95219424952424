import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col grow pl-2 justify-center" }
const _hoisted_2 = { class: "font-sourcesanspro-semibold text-base leading-tight capitalize" }
const _hoisted_3 = { class: "leading-none" }
const _hoisted_4 = {
  key: 0,
  class: "font-sourcesanspro-regular text-xs font-color-text-50 leading-none"
}
const _hoisted_5 = {
  key: 1,
  class: "font-sourcesanspro-regular text-xs font-color-text-50 leading-none"
}
const _hoisted_6 = {
  key: 2,
  class: "font-sourcesanspro-regular text-xs"
}
const _hoisted_7 = {
  key: 3,
  class: "tag font-sourcesanspro-regular text-xs capitalize"
}
const _hoisted_8 = { slot: "content" }

import { ref } from 'vue';
import { IonAccordion, IonIcon } from '@ionic/vue';
import { RefundTagsColors } from '@/enums/values.enum';
import { RefundRequestStatus } from '@/shared/interfaces/refundRequest';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionListItem',
  props: {
	customSubtitle: { type: String },
	icon: { type: String, required: true },
	iconClass: { type: String, required: true },
	keyValue: { type: String, required: true },
	subtitle: { type: String },
	tag: { type: String, required: false },
	title: { type: String, required: true },
	transactionType: { type: String },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "1493c02e": (_unref(tagColor))
}))

const props = __props;

// Init
const { t } = useI18n();
const isOpen = ref(false);

const getTagColor = (value: string | undefined) => {
	let color = '';
	switch (value) {
		case RefundRequestStatus.APPROVED:
			color = RefundTagsColors.APPROVED;
			break;
		case RefundRequestStatus.FAILED:
			color = RefundTagsColors.FAILED;
			break;
		case RefundRequestStatus.REFUNDED:
			color = RefundTagsColors.REFUNDED;
			break;
		case RefundRequestStatus.PENDING:
			color = RefundTagsColors.PENDING;
			break;
		default:
			color = RefundTagsColors.PENDING;
			break;
	}
	return color;
};

const tagColor = getTagColor(props.tag);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAccordion), {
    class: "cursor-pointer",
    value: props.keyValue
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "flex p-4 items-center justify-between",
        slot: "header",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
      }, [
        _createVNode(_unref(IonIcon), {
          class: _normalizeClass([[
					{
						'bg-anykrowd-green': props.transactionType === 'CREDIT',
						'bg-anykrowd-red': props.transactionType === 'DEBIT',
					},
					__props.iconClass,
				], "w-[24px] h-[24px] min-w-[24px] p-2 rounded-full"]),
          icon: props.icon
        }, null, 8, ["class", "icon"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(props.title), 1),
          _createElementVNode("div", _hoisted_3, [
            (props.subtitle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(props.subtitle === 'DEBIT'
								? _unref(t)('components.transaction.debit')
								: _unref(t)('components.transaction.credit')), 1))
              : _createCommentVNode("", true),
            (props.customSubtitle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.customSubtitle), 1))
              : _createCommentVNode("", true),
            (props.subtitle && props.tag)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, " • "))
              : _createCommentVNode("", true),
            (props.tag)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(props.tag), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _renderSlot(_ctx.$slots, "actions")
      ]),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "content", { shouldLoad: isOpen.value })
      ])
    ]),
    _: 3
  }, 8, ["value"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-[34px] pt-16" }
const _hoisted_2 = { class: "flex items-center justify-end mb-3" }
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = { class: "w-[200px] rounded-xl bg-white" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "flex justify-between" }
const _hoisted_7 = { class: "w-[20px]" }
const _hoisted_8 = {
  class: "flex items-center cursor-pointer",
  id: "open-modal"
}
const _hoisted_9 = { class: "flex items-center justify-center" }
const _hoisted_10 = { class: "flex flex-col items-start" }

import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonItem, IonModal } from '@ionic/vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import PersonItemBig from '@/shared/components/ui/PersonItemBig.vue';
import { addCircle, peopleOutline, personOutline, search } from 'ionicons/icons';

// Go back
const friendsCount = 4;

export default /*@__PURE__*/_defineComponent({
  __name: 'FriendsPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push(link);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createVNode(_unref(IonModal), {
          ref: "modal",
          trigger: "open-modal",
          class: "items-start"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold test-base2 mr-2" }, "Add")
                ], -1)),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(addCircle),
                  class: "w-[20px] h-[20px]"
                }, null, 8, ["icon"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(IonItem), {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/friends/add'))),
                    class: "flex ion-no-padding px-4 py-2 border-b border-b-anykrowd-dark-two cursor-pointer",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(personOutline),
                        class: "text-anykrowd-dark-purple mr-2"
                      }, null, 8, ["icon"]),
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 text-anykrowd-dark-purple" }, "Add new friend", -1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonItem), {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo('/friends/group/add'))),
                    class: "flex ion-no-padding px-4 py-2 text-anykrowd-dark-purple cursor-pointer",
                    lines: "none"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(peopleOutline),
                        class: "text-anykrowd-dark-purple mr-2"
                      }, null, 8, ["icon"]),
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2 text-anykrowd-dark-purple" }, "Add new group", -1))
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ]),
          _: 1
        }, 512),
        _createElementVNode("div", {
          class: _normalizeClass([[
				{
					'justify-between': friendsCount === 0,
				},
			], "flex flex-col h-full px-[34px] pt-16"])
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(search),
                  class: "w-[20px] h-[20px]"
                }, null, 8, ["icon"])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_8, [
                _cache[5] || (_cache[5] = _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold test-base2 mr-2" }, "Add")
                ], -1)),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(addCircle),
                  class: "w-[20px] h-[20px]"
                }, null, 8, ["icon"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(peopleOutline),
                class: "w-[20px] h-[20px] mr-1"
              }, null, 8, ["icon"]),
              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "Friends", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              type: "group",
              id: "1",
              name: "BullPuppy",
              description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
              image: require('@/statics/svg-delete-after-implement/person-8.svg'),
              "last-time-online": "Today"
            }, null, 8, ["image"]),
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              id: "2",
              name: "April Curtis",
              description: "Vivamus rhoncus, quam sed tempor.",
              image: require('@/statics/svg-delete-after-implement/person-9.svg'),
              "last-time-online": "Yesterday"
            }, null, 8, ["image"]),
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              id: "3",
              name: "Angela Bower",
              description: "Vivamus rhoncus, quam sed tempor.",
              image: require('@/statics/svg-delete-after-implement/person-10.svg'),
              "last-time-online": "Today"
            }, null, 8, ["image"]),
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              id: "4",
              name: "Thomas Magnum",
              description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
              image: require('@/statics/svg-delete-after-implement/person-11.svg'),
              "last-time-online": "01/21/2023"
            }, null, 8, ["image"]),
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              id: "5",
              name: "Johnathan Higgins",
              description: "Vivamus rhoncus, quam sed tempor.",
              image: require('@/statics/svg-delete-after-implement/person-12.svg'),
              "last-time-online": "01/17/2023"
            }, null, 8, ["image"]),
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              id: "6",
              name: "Willie Tanner",
              description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
              image: require('@/statics/svg-delete-after-implement/person-13.svg'),
              "last-time-online": "12/22/2022"
            }, null, 8, ["image"]),
            _createVNode(PersonItemBig, {
              onOnClick: goTo,
              id: "7",
              name: "Sarrah Williams",
              description: "Aenean tempor, diam volutpat tempor varius, enim ligula..",
              image: require('@/statics/svg-delete-after-implement/person-14.svg'),
              "last-time-online": "12/22/2022"
            }, null, 8, ["image"])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h-[77px] mb-4" }, " ", -1))
        ], 2)
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "friends" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
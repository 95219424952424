import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_3 = { class: "flex flex-col items-center justify-center" }
const _hoisted_4 = { class: "flex flex-col items-center justify-center p-4 font-sourcesanspro-regular text-center" }

import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { useCart } from '@/modules/shop/composables/useCart';
import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useSelfOrderingGetSpotId from '@/modules/self-ordering/components/useSelfOrderingGetSpotId';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SelfOrderingGetSpotIdPage',
  setup(__props) {

const { t } = useI18n();
const { handleSpotIdOnQueryParams, onScanQR, shouldOpenCamera } = useSelfOrderingGetSpotId();
const { clearCart } = useCart();
clearCart();
handleSpotIdOnQueryParams();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: "/dashboard",
                class: "flex items-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('self_ordering.context')), 1),
          _createVNode(ScanQR, {
            onOnScanQR: _unref(onScanQR),
            "start-camera": _unref(shouldOpenCamera),
            permissionTitle: _unref(t)('scan_qr.camera_permission_title'),
            permissionDescription: _unref(t)('self_ordering.camera_request')
          }, null, 8, ["onOnScanQR", "start-camera", "permissionTitle", "permissionDescription"])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[200px]" }, null, -1))
      ])
    ]),
    _: 1
  }))
}
}

})
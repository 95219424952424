import { PaymentStatusEnum } from '@/enums/values.enum';

export interface LatestTopUpTransaction {
	address_number: string;
	address_street: string;
	city: string;
	country: string;
	created_at: string;
	dont_print: number;
	extras: any;
	id: number;
	in_site_table_id: any;
	invoiced: boolean;
	nanoid: string;
	paid_price: string;
	paid_price_amount_vat: string;
	paid_price_excl_vat: string;
	paid_price_vat_percentage: null;
	payment_gateway: 'mollie';
	payment_method: string;
	payment_reference: string;
	payment_status: PaymentStatusEnum;
	postal_code: number;
	price: string;
	price_amount_vat: string;
	price_excl_vat: string;
	price_vat_percentage: string;
	self_order: boolean;
	type: TopUpType;
	updated_at: string;
	user_id: number;
	wallet_id: number;
}

export enum TopUpType {
	UNDEFINED = 'undefined',
	TOPUP = 'topup',
	TOPUP_REVOKE = 'topup_revoke',
	EVENT_TICKET_PURCHASE = 'event_ticket_purchase',
	EVENT_DOORSALE_TICKET_PURCHASE = 'event_doorsale_ticket_purchase',
	BOOKING = 'booking',
	PRODUCTS_PURCHASED = 'products_purchased',
	REFUND_TRANSACTION_COST = 'refund_transaction_cost',
	REFUND = 'refund',
	WEB_SHOP_PRODUCTS_PURCHASED = 'web_shop_products_purchased',
	WALLET_MERGE = 'wallet_merge',
	WALLET_MERGE_PAID = 'wallet_merge_paid',
	WALLET_EXPIRY = 'wallet_expiry',
	WARRANTY_PRODUCTS_RETURN = 'warranty_products_return',
	GUEST_TOP_UP = 'guest_top_up',
}

export interface TopUpTransactionMetadata {
	transactionId: string;
	paymentAmount: number;
	paymentAmountTotal?: string;
	paymentStatus: string;
	transactionType: TopUpType;
}

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center py-4" }
const _hoisted_2 = { slot: "content" }

import { IonAccordion, IonIcon, IonItem, IonLabel } from '@ionic/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionListItemTwo',
  props: {
	cssClass: { type: String },
	icon: { type: String, required: true },
	id: { type: Number, required: true },
	title: { type: String, required: true },
	show: { type: Boolean, default: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createBlock(_unref(IonAccordion), {
        key: 0,
        class: _normalizeClass(props.cssClass),
        value: props.id.toString()
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonItem), {
            class: "border-b border-white/5",
            slot: "header",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_unref(IonIcon), {
                  class: "text-[20px] mr-2",
                  src: props.icon
                }, null, 8, ["src"]),
                _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-semibold text-m2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.title), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      }, 8, ["class", "value"]))
    : _createCommentVNode("", true)
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "font-sourcesanspro-semibold" }

import { toRefs } from 'vue';
import { useField } from 'vee-validate';
import type { Option } from '@/shared/interfaces/registrationForm';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioInput',
  props: {
	modelValue: { type: null },
	name: String,
	values: { type: Array<Option> },
},
  setup(__props) {

const props = __props;

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { value, handleChange } = useField(name);

const selectItem = (event: MouseEvent, radio: Option) => {
	handleChange(radio.value);
	event.preventDefault();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.values, (radio) => {
    return (_openBlock(), _createElementBlock("button", {
      key: radio.value,
      class: _normalizeClass(["flex justify-center items-center w-full border border-solid border-white-15 rounded-full mb-4 px-5 normal-case text-base", [
			{
				'background-white-15': radio.value === _unref(value),
			},
		]]),
      onClick: ($event) => selectItem($event, radio)
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(radio.label), 1)
    ], 10, _hoisted_1))
  }), 128))
}
}

})
<script setup lang="ts">
import { onMounted, ref, toRef, watch, watchEffect } from 'vue';
import { IonButton, IonIcon, IonSpinner } from '@ionic/vue';
import { Share } from '@capacitor/share';
import { closeOutline, downloadOutline, shareSocialOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import useToast from '@/shared/composables/useToast';
import QrcodeVue from 'qrcode.vue';
import { DateTime } from 'luxon';

import ModalTwo from '@/shared/components/ui/ModalTwo.vue';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useYourId from '@/modules/dashboard/composables/useYourId';
import { useAuthStore } from '@/stores/auth/authStore';

// Props
const props = defineProps({
	isModalOpen: { type: Boolean, required: true },
});

// Init
const { t } = useI18n();
const emit = defineEmits(['onCloseModal']);
const isYourIdModalOpen = ref(false);
const isOpen = toRef(props, 'isModalOpen');
const { getWalletQrCode } = useWallet();
const { errorWalletQrCode, isErrorWalletQrCode, isFetchedWalletQrCode, refetchWalletQrCode, walletQrCode } =
	getWalletQrCode();
const { presentToast } = useToast();
const { getApiErrorString, isOffline } = useUtils();

const isShareAvailable = ref(false);
const { close, qrcode, saveImageAs, share } = useYourId(emit, isYourIdModalOpen);
const authStore = useAuthStore();
const isAuthenticated = authStore.isAuthenticated();

// onMountedHook
onMounted(async () => {
	isShareAvailable.value = (await Share.canShare()).value;
});

// Download
const download = () => {
	close();
	saveImageAs();
};

const isQRCodeExpired = (walletQrCodeValidUntil: string) => {
	return DateTime.now() > DateTime.fromFormat(walletQrCodeValidUntil, 'yyyy-MM-dd HH:mm:ss');
};

const willQRCodeExpireSoon = (walletQrCodeValidUntil: string) => {
	return DateTime.now().plus({ hour: 1 }) > DateTime.fromFormat(walletQrCodeValidUntil, 'yyyy-MM-dd HH:mm:ss');
};

// Watch open state
watch(isOpen, () => {
	isYourIdModalOpen.value = isOpen.value;
});

watchEffect(() => {
	if (walletQrCode && walletQrCode.value && willQRCodeExpireSoon(walletQrCode.value.valid_until) && isAuthenticated) {
		refetchWalletQrCode();
	}
});

// Watch error
watch(isErrorWalletQrCode, async () => {
	if (isErrorWalletQrCode.value) {
		if (isOffline()) {
			await presentToast('top', t('error.offline'), 5000, 'danger');
		} else {
			const error = getApiErrorString(errorWalletQrCode.value);
			if (Array.isArray(error)) {
				await presentToast('top', t(error[0]), 5000, 'danger');
			} else {
				await presentToast('top', t(error.error), 5000, 'danger');
			}
		}
	}
});
</script>

<template>
	<ModalTwo :is-open="isYourIdModalOpen">
		<div class="flex flex-col h-full pt-[64px] px-[34px] pb-[34px] bg-white items-center justify-between">
			<div v-if="!errorWalletQrCode">
				<div class="flex flex-col mb-8 text-center text-anykrowd-dark-purple">
					<h2 class="font-sourcesanspro-semibold text-l2">{{ t('wallet.qr_code.header') }}</h2>
					<p class="font-sourcesanspro-regular p-1 text-base">{{ t('wallet.qr_code.subtitle') }}</p>
				</div>

				<div v-if="!isFetchedWalletQrCode">
					<ion-spinner name="dots"></ion-spinner>
				</div>
				<div
					ref="qrcode"
					v-if="walletQrCode && isFetchedWalletQrCode && !isQRCodeExpired(walletQrCode.valid_until)"
					class="flex justify-center mb-8">
					<qrcode-vue :value="walletQrCode?.qr_code" :level="'M'" :size="300" />
				</div>

				<div
					v-if="walletQrCode && isFetchedWalletQrCode && !isQRCodeExpired(walletQrCode.valid_until)"
					class="flex flex-col items-center text-anykrowd-dark-purple">
					<span class="font-sourcesanspro-semibold text-l2">{{ t('wallet.qr_code.valid') }}:</span>
					<span class="font-sourcesanspro-regular text-base">
						{{
							DateTime.fromFormat(walletQrCode.valid_until, 'yyyy-MM-dd HH:mm:ss').toFormat(
								'EEEE dd MMM, yyyy HH:mm',
							)
						}}
					</span>
				</div>
			</div>

			<div
				v-if="walletQrCode && isQRCodeExpired(walletQrCode.valid_until)"
				class="flex flex-col items-center justify-center">
				<div
					class="flex items-center justify-center font-sourcesanspro-semibold text-anykrowd-dark-purple mb-2">
					<span class="text-m2">{{ t('wallet.qr_code.expired') }}</span>
				</div>
				<ion-button @click="refetchWalletQrCode" class="w-[155px] black ion-no-margin" shape="round">
					<div class="flex items-center justify-center text-white">
						<span class="text-m2">{{ t('wallet.qr_code.refetch') }}</span>
					</div>
				</ion-button>
			</div>

			<div v-if="errorWalletQrCode" class="flex items-center justify-center">
				{{ errorWalletQrCode }}
				<ion-button @click="refetchWalletQrCode" class="w-[155px] black ion-no-margin" shape="round">
					<div class="flex items-center justify-center text-white">
						<span class="text-m2">{{ t('general.retry') }}</span>
					</div>
				</ion-button>
			</div>

			<div class="flex" v-if="walletQrCode">
				<div v-if="!errorWalletQrCode && !isQRCodeExpired(walletQrCode.valid_until)" class="flex">
					<ion-button
						@click="download()"
						class="w-[155px] bg-anykrowd-dark-purple rounded-full"
						shape="round"
						size="large"
						fill="outline">
						<div class="flex items-center justify-center text-white">
							<ion-icon :icon="downloadOutline" class="mr-2"></ion-icon>
							<span class="text-m2">Download</span>
						</div>
					</ion-button>
					<ion-button
						v-if="isShareAvailable"
						@click="share()"
						class="w-[155px] border border-anykrowd-dark-purple rounded-full"
						shape="round"
						size="large">
						<div class="flex items-center justify-center">
							<ion-icon :icon="shareSocialOutline" class="mr-2 text-anykrowd-dark-purple"></ion-icon>
							<span class="text-anykrowd-dark-purple text-m2">Share</span>
						</div>
					</ion-button>
				</div>
			</div>

			<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4">
				<ion-icon class="text-[38px] text-black cursor-pointer" :icon="closeOutline" @click="close"></ion-icon>
			</div>
		</div>
	</ModalTwo>
</template>

<style scoped></style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { onMounted, ref } from 'vue';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { IonApp, IonRouterOutlet, IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import router from '@/router';
import { FacebookLogin } from '@whiteguru/capacitor-plugin-facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import NativeAppConfig from '@/config/native-app.config.json';
import { QueryClient, QueryObserver, useQueryClient } from '@tanstack/vue-query';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import type { TenantStyles } from '@/shared/interfaces/tenant';
import useTenant from '@/shared/composables/useTenant';
import useAlert from '@/shared/composables/useAlert';
import { useI18n } from 'vue-i18n';
import gt from 'semver/functions/gt';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import { ActionPerformed, PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';

// Init
import { version } from '../package.json';
import useQueryCacheHelper from '@/shared/composables/useQueryCacheHelper';
import { captureError } from '@/sentry';
import { useStyles } from '@/shared/composables/useStyles';
import useUtils from '@/shared/composables/useUtils';
import { getTokenFromMagicLinkUrl } from '@/shared/composables/useUrlHelpers';
import { useAuthStore } from '@/stores/auth/authStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { getConfig, getStyles } = useTenant();
const { presentAlert } = useAlert();
const { t } = useI18n();
const assetStore = useAssetsStore();
const { activateMagicLink, openURL } = useUtils();

const FACEBOOK_APP_ID = process.env.VUE_APP_FACEBOOK_APP_ID;
const GOOGLE_ANALYTCS_TRACKING_CODE = process.env.VUE_APP_GOOGLE_ANALYTCS_TRACKING_CODE;
const ENVIRONMENT_DEBUG = process.env.VUE_APP_ENVIRONMENT_DEBUG;
const { setStyles } = useStyles();
const queryClient = useQueryClient();
const tenantStylesObserver = new QueryObserver(queryClient, { queryKey: ['tenantStyles'], queryFn: () => getStyles() });

// Debug
if (ENVIRONMENT_DEBUG === 'true') {
	window.apiUrl = process.env.VUE_APP_API_URL ?? NativeAppConfig.API_URL;
	window.tenantName = process.env.VUE_APP_TENANT_NAME ?? NativeAppConfig.TENANT_NAME;
	window.logoUrl = process.env.VUE_APP_LOGO_URL ?? NativeAppConfig.LOGO_URL;
	window.backgroundUrl = process.env.VUE_APP_BACKGROUND_URL ?? NativeAppConfig.BACKGROUND_URL;
	window.primary_color = process.env.VUE_APP_PRIMARY_COLOR ?? NativeAppConfig.PRIMARY_COLOR;
	window.secondary_color = process.env.VUE_APP_SECONDARY_COLOR ?? NativeAppConfig.SECONDARY_COLOR;
	window.tertiary_color = process.env.VUE_APP_TERTIARY_COLOR ?? NativeAppConfig.TERTIARY_COLOR;
	window.centralUrl = process.env.VUE_APP_CENTRAL_URL ?? NativeAppConfig.CENTRAL_URL;

	// Updating store
	assetStore.updateAssetsValues({
		logoUrl: window.logoUrl,
		backgroundUrl: window.backgroundUrl,
	});

	document.dispatchEvent(new Event('apiUrlChanged'));
}

// Handle tenant details
if (Capacitor.isNativePlatform()) {
	Preferences.get({ key: 'tenantDetails' }).then((tenantDetailsLocalStorage) => {
		// Get
		if (!tenantDetailsLocalStorage.value) {
			// Set initial details.
			Preferences.set({
				key: 'tenantDetails',
				value: JSON.stringify({
					api_url: NativeAppConfig.API_URL,
					central_url: NativeAppConfig.CENTRAL_URL,
					tenant_name: NativeAppConfig.TENANT_NAME,
					logo_url: NativeAppConfig.LOGO_URL,
					bg_image_url: NativeAppConfig.BACKGROUND_URL,
					primary_color: NativeAppConfig.PRIMARY_COLOR,
					secondary_color: NativeAppConfig.SECONDARY_COLOR,
					tertiary_color: NativeAppConfig.TERTIARY_COLOR,
				}),
			});

			// Updating store
			assetStore.updateAssetsValues({
				logoUrl: NativeAppConfig.LOGO_URL,
				backgroundUrl: NativeAppConfig.BACKGROUND_URL,
			});

			window.apiUrl = NativeAppConfig.API_URL;
			window.tenantName = NativeAppConfig.TENANT_NAME;
			window.logoUrl = NativeAppConfig.LOGO_URL;
			window.backgroundUrl = NativeAppConfig.BACKGROUND_URL;
			window.primary_color = NativeAppConfig.PRIMARY_COLOR;
			window.secondary_color = NativeAppConfig.SECONDARY_COLOR;
			window.tertiary_color = NativeAppConfig.TERTIARY_COLOR;
			window.centralUrl = NativeAppConfig.CENTRAL_URL;

			document.dispatchEvent(new Event('apiUrlChanged'));
		} else if (tenantDetailsLocalStorage && tenantDetailsLocalStorage.value) {
			// Fetch
			const tenantDetails = JSON.parse(tenantDetailsLocalStorage.value);
			if (tenantDetails) {
				console.log('Tenant details fetched from local storage.', tenantDetails);
				setStyles(tenantDetails);
				document.dispatchEvent(new Event('apiUrlChanged'));
			}
		}
	});

	// Subscribe to updates on styles
	const unsubscribe = tenantStylesObserver.subscribe((result) => {
		const tenantStylesData = ref<TenantStyles>(result.data as TenantStyles);
		console.log('tenant styles found');
		if (tenantStylesData.value) {
			Preferences.get({ key: 'tenantDetails' }).then((tenantDetailsLocalStorage) => {
				if (tenantDetailsLocalStorage && tenantDetailsLocalStorage.value) {
					const tenantDetails = JSON.parse(tenantDetailsLocalStorage.value);
					if (tenantDetails) {
						tenantDetails.primary_color =
							tenantStylesData.value.primary_color ?? tenantDetails.primary_color;
						tenantDetails.secondary_color =
							tenantStylesData.value.secondary_color ?? tenantDetails.secondary_color;
						tenantDetails.tertiary_color =
							tenantStylesData.value.tertiary_color ?? tenantDetails.tertiary_color;
						tenantDetails.logo_url = tenantStylesData.value.logo_url ?? tenantDetails.logo_url;
						tenantDetails.bg_image_url = tenantStylesData.value.bg_image_url ?? tenantDetails.bg_image_url;

						Preferences.set({
							key: 'tenantDetails',
							value: JSON.stringify(tenantDetails),
						});

						setStyles(tenantDetails);
					}
				}
			});
		}
	});

	window.assetUrl = '/';
	window.googleAnalyticsTrackingCode = GOOGLE_ANALYTCS_TRACKING_CODE!;
} else {
	// we are not in debug mode and on web -- let's inject the necessary styles into the pinia store for vue to pick up
	// in normal circumstances this information is simply in index.html
	// let's not do more than this -- we want to make sure the backend can also override styles when they are updated.
	if (
		window.logoUrl &&
		!window.logoUrl?.includes('REPLACE') &&
		window.backgroundUrl &&
		!window.backgroundUrl?.includes('REPLACE')
	) {
		assetStore.updateAssetsValues({
			logoUrl: window.logoUrl,
			backgroundUrl: window.backgroundUrl,
		});
	}
}

// Initialize Facebook and Google logins
onMounted(async () => {
	if (Capacitor.isNativePlatform()) {
		console.log('Updater: Notifying app ready.');
		CapacitorUpdater.notifyAppReady();

		// Init social logins
		await FacebookLogin.initialize({ appId: FACEBOOK_APP_ID });
		GoogleAuth.initialize();
		_initAppLinksListener();
		listenForNotifications();

		if (ENVIRONMENT_DEBUG == 'false') {
			console.log('Updater: Checking for updates.');
			checkForUpdates();
		}
	}
	// setColors();
});

const { removeCaches } = useQueryCacheHelper();

const checkForUpdates = async () => {
	try {
		// !! We will need to revise the way we handle the update channels in the future, it's spread all over the place
		// Currently it is in nativeappconfig, but that can get overwritten by the bundle update. Not a problem per se since we can expect a channel to stay
		// stable within itself. But it's not ideal.
		const id = `clientx-2.0-${NativeAppConfig.CAP_SOFT_UPDATE_CHANNEL ?? process.env.VUE_APP_ENVIRONMENT_CAP_UPDATE_CHANNEL ?? 'production-mc'}`;
		console.log(`Checking for soft updates on channel ${id}. Current version of bundle is ${version}.`);
		// if we await here, it hangs. Seems to be a bug in capacitorupdater
		CapacitorUpdater.setCustomId({
			customId: id,
		});
		const latestVersion = await CapacitorUpdater.getLatest();
		if (latestVersion?.url && gt(latestVersion.version, version)) {
			console.log('An update is available. Presenting alert.', { latestVersion, version });
			await presentAlert({
				// cssClass: 'my-custom-class',
				header: t('general.update_available'),
				buttons: [
					{
						text: t('general.cancel'),
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: t('general.ok'),
						role: 'ok',
						handler: async () => {
							CapacitorUpdater.download({
								url: latestVersion.url!,
								version: latestVersion.version,
							}).then((appUpdateData) => {
								// Remove caches
								removeCaches();

								// Update
								CapacitorUpdater.set({ id: appUpdateData.id });
							});
							return true;
						},
					},
				],
			});

			console.log('Update alert presented.');
		} else {
			console.log('No updates available', { latestVersion, version });
		}
	} catch (e: unknown) {
		console.log(`Failed to check for updates.`, e);
		captureError(e);
	}
};

const listenForNotifications = () => {
	if (Capacitor.isNativePlatform()) {
		PushNotifications.addListener('registrationError', (error: any) => {
			console.error('Error on registration: ', error);
		});

		PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
			console.log('notification received!', notification);
		});

		PushNotifications.addListener('pushNotificationActionPerformed', async (action: ActionPerformed) => {
			console.log('pushNotificationActionPerformed', action);
			const {
				notification: { data: notificationData },
			} = action;

			if (notificationData?.url) {
				const pathname = new URL(notificationData?.url).pathname;
				const hash = new URL(notificationData?.url).hash;
				const path = hash != '' ? `${hash.substring(1)}` : pathname;
				handleRedirection(notificationData.url, path);
			}
		});
	}
};

const handleRedirection = (url: string, path: string) => {
	let query;
	if (url.includes('#')) {
		const [, queryString] = url.split('#')[1].split('?');
		query = Object.fromEntries(new URLSearchParams(queryString));
	} else {
		const [, queryString] = url.split('?');
		query = Object.fromEntries(new URLSearchParams(queryString));
	}

	setTimeout(() => {
		router.push({
			path,
			query: {
				...query,
			},
		});
	}, 1000);
};

const _initAppLinksListener = () => {
	// Deeplink handler
	App.addListener('appUrlOpen', async function (event: URLOpenListenerEvent) {
		// Example: https://testing.anykrowd.review/events/68
		// pathname = /events/68
		const pathname = new URL(event.url).pathname;
		const search = new URL(event.url).search;
		const hash = new URL(event.url).hash;

		if (pathname || hash) {
			// Push to the route
			const path = hash != '' ? `${hash.substring(1)}` : pathname;
			if (path.includes('magiclink')) {
				const magicLinkToken = getTokenFromMagicLinkUrl(event.url);
				activateMagicLink(magicLinkToken!).then(async (res) => {
					const token = res?.token;
					let redirectUrl = res?.redirect_url;
					// decode url to navigate
					try {
						redirectUrl = decodeURIComponent(redirectUrl);
					} catch (e) {
						console.log(e);
					}
					if (token) {
						const authStore = useAuthStore();
						await authStore.saveToken({ token });
					}
					if (redirectUrl) {
						const hashIndex = redirectUrl.indexOf('#');
						if (hashIndex > -1) {
							const path = redirectUrl.substring(hashIndex + 2);
							if (path) {
								router.push({ path });
							} else {
								await openURL(redirectUrl);
							}
						} else {
							await openURL(redirectUrl);
						}
						return;
					}
					await router.push({ path: '/dashboard' });
				});
			} else {
				handleRedirection(event.url, path);
			}
		}
	});
};

const prefetch = async (queryClient: QueryClient, queryKey: any, queryFn: any) => {
	await queryClient.prefetchQuery({
		queryKey,
		queryFn,
		cacheTime: 1000 * 60 * 60 * 24 * 365,
	});
};
document.addEventListener('apiUrlChanged', async () => {
	await prefetch(queryClient, ['tenantConfig'], () => getConfig());
	await prefetch(queryClient, ['tenantStyles'], () => getStyles());
});

// Handle logout event triggered by a deleteToken
window.document.addEventListener(
	'logout',
	async (e) => {
		// Remove caches
		removeCaches();

		// Reload page
		location.href = '/';
	},
	false,
);

const hideOfflineMessage = () => {
	document.getElementsByClassName('network-status')[0]?.classList.remove('visible');
	document.getElementsByClassName('network-status')[0]?.classList.add('hidden');
};

const showOfflineMessage = () => {
	document.getElementsByClassName('network-status')[0]?.classList.remove('hidden');
	document.getElementsByClassName('network-status')[0]?.classList.add('visible');
};

window.addEventListener('offline', (e) => {
	showOfflineMessage();
});

window.addEventListener('online', (e) => {
	hideOfflineMessage();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonApp), {
      style: _normalizeStyle(`background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${_unref(assetStore).backgroundUrl}) no-repeat center center / cover;`)
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_unref(IonRouterOutlet), {
          key: _ctx.$route.fullPath
        }))
      ]),
      _: 1
    }, 8, ["style"]),
    _createElementVNode("div", {
      style: _normalizeStyle([
			{
				'padding-top': _unref(Capacitor).isNativePlatform() ? 'env(safe-area-inset-top, 20px)' : 'pt-4',
			},
		]),
      class: "flex items-center justify-between p-4 network-status relative z-10 bg-white/10 text-xs text-center text-white hidden"
    }, [
      _createElementVNode("span", null, _toDisplayString(_unref(t)('general.connection.offline')), 1),
      _createElementVNode("div", {
        class: "flex items-center justify-start cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (hideOfflineMessage()))
      }, [
        _createVNode(_unref(IonIcon), {
          icon: _unref(closeOutline),
          class: "text-white text-xs"
        }, null, 8, ["icon"])
      ])
    ], 4)
  ], 64))
}
}

})
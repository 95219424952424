import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "flex grow items-center justify-end cursor-pointer" }
const _hoisted_3 = { class: "flex pb-4 w-[100%] items-start overflow-scroll" }
const _hoisted_4 = { class: "flex w-full px-[34px] align-center mb-2" }
const _hoisted_5 = { class: "flex items-center justify-center" }
const _hoisted_6 = { class: "flex items-center justify-center text-white" }

import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import TicketDetailItem from '@/modules/tickets/components/TicketDetailItem.vue';
import { chevronBackOutline, downloadOutline, pencil, search, shareSocialOutline } from 'ionicons/icons';

// Go back

export default /*@__PURE__*/_defineComponent({
  __name: 'TicketsDetailPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, { class: "px-[34px]" }, {
            left: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex items-center justify-start cursor-pointer",
                onClick: goBack
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ]),
            center: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "flex grow items-center justify-center w-full" }, [
                _createElementVNode("div", { class: "mr-2" }, [
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "Ticket Details")
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, "(7/24)")
                ])
              ], -1)
            ])),
            right: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(search),
                  class: "mr-2"
                }, null, 8, ["icon"]),
                _createVNode(_unref(IonIcon), { icon: _unref(pencil) }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(TicketDetailItem, { class: "ml-[34px] mr-4" }),
          _createVNode(TicketDetailItem, { class: "mr-4" })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(IonButton), {
            class: "w-full mb-4",
            expand: "full",
            size: "large",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(downloadOutline),
                  class: "text-dark-blue mr-2"
                }, null, 8, ["icon"]),
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-m2" }, "Download", -1))
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round",
            fill: "outline"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(shareSocialOutline),
                  class: "mr-2"
                }, null, 8, ["icon"]),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-m2" }, "Share", -1))
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller" }
const _hoisted_2 = { class: "flex items-center min-w-[326px] ml-[34px] mr-4 rounded-xl overflow-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex flex-col px-[34px] mb-4" }
const _hoisted_7 = { class: "flex items-center mb-6" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "flex flex-row justify-left flex-wrap px-[34px] mb-4" }

import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonFooter, IonIcon } from '@ionic/vue';
import { chevronBackOutline, heartOutline, shareSocialOutline } from 'ionicons/icons';

import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import TopBar from '@/shared/components/TopBar.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TagsList from '../components/TagsList.vue';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventPage',
  setup(__props) {

const assetStore = useAssetsStore();

// Go back
const goBack = () => {
	router.back();
};

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (openInFullScreen.value)
      ? (_openBlock(), _createBlock(FullScreenImage, {
          key: 0,
          fullScreenImage: fullScreenImage.value,
          onOnCloseImage: onCloseImage
        }, null, 8, ["fullScreenImage"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: goBack
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          center: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }, "Event map", -1)
          ])),
          right: _withCtx(() => [
            _createVNode(_unref(IonIcon), {
              class: "mr-1",
              icon: _unref(heartOutline)
            }, null, 8, ["icon"]),
            _createVNode(_unref(IonIcon), {
              class: "mr-1",
              icon: _unref(shareSocialOutline)
            }, null, 8, ["icon"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: require('@/statics/png-delete-after-implement/img-1.png'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (viewFullScreen(require('@/statics/png-delete-after-implement/img-1.png')))),
              class: "object-fit w-[100%]"
            }, null, 8, _hoisted_3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: require('@/statics/png-delete-after-implement/img-2.png'),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (viewFullScreen(require('@/statics/png-delete-after-implement/img-2.png')))),
              class: "object-fit w-[100%]"
            }, null, 8, _hoisted_5)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _unref(assetStore).logoUrl,
              class: "max-w-[70%]"
            }, null, 8, _hoisted_8),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "Map of the Event", -1))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: require('@/statics/svg-delete-after-implement/event-map-image.svg')
            }, null, 8, _hoisted_10)
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("div", null, [
            _createElementVNode("div", { class: "font-sourcesanspro-regular text-base2 mb-4 font-color-text-50" }, [
              _createElementVNode("p", { class: "mb-4" }, " Aenean in aliquam purus, eget molestie diam. Etiam velit ante, dictum vitae orci sed, mollis tincidunt felis. Proin quis ipsum et odio rutrum facilisis quis a est. "),
              _createElementVNode("p", { class: "" }, " Curabitur et magna vel nisi tincidunt placerat sed id felis. Nam varius tempor quam, at hendrerit massa fermentum quis. ")
            ])
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(TagsList, {
            name: "interests",
            checks: [
					{ name: 'lifeimprovement' },
					{ name: 'knowledge', selected: true },
					{ name: 'freedrinks' },
					{ name: 'nightparty' },
					{ name: 'music' },
					{ name: 'eventproofs' },
					{ 'icon': '⠇' },
				]
          })
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[100px] mb-10" }, " ", -1))
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "news" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]
const _hoisted_2 = { class: "font-sourcesanspro-semibold text-base" }
const _hoisted_3 = ["src"]

import { ref, onMounted, toRef, watch } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { useField } from 'vee-validate';
import { cameraOutline } from 'ionicons/icons';

import useCamera from '@/shared/composables/useCamera';
import useUtils from '@/shared/composables/useUtils';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageInput',
  props: {
	iconLeft: { type: String, default: '' },
	inputClass: { type: String, default: 'font-sourcesanspro-regular text-base' },
	label: { type: String },
	name: { type: String, required: true },
	placeholder: { type: String, default: '' },
	showErrorMessage: { type: Boolean, default: true },
	showInputSuccess: { type: Boolean, default: true },
	successMessage: { type: String, default: '' },
	type: { type: String, default: 'text' },
	value: { type: Object as () => Blob, default: null },
	wrapperClass: { type: String, default: '' },
},
  emits: ['inputChanged'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const emit = __emit;
const { cameraGetPhoto, photo } = useCamera();
const { dataURLtoBlob, convertBlobToBase64 } = useUtils();
const profileImage = ref('');

// onMountedHook
onMounted(async () => {
	if (props.value) {
		profileImage.value = await convertBlobToBase64(props.value);
	}
});

// Handle password visibility
const type = ref(props.type);
const showPassword = ref(false);
const swapPasswordVisibility = () => {
	type.value = type.value === 'password' ? 'text' : 'password';
	showPassword.value = !showPassword.value;
};

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { handleChange } = useField(name, undefined, {
	initialValue: props.value,
});

// getPhoto
const getPhoto = async () => {
	await cameraGetPhoto();

	watch(photo, async () => {
		if (photo.value) {
			profileImage.value = photo.value;
			const blob = dataURLtoBlob(photo.value);
			handleChange(blob);
		}
	});
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex mb-4", __props.wrapperClass])
    }, [
      _createElementVNode("input", {
        name: props.name,
        type: "file",
        accept: "image/*;capture=camera",
        hidden: ""
      }, null, 8, _hoisted_1),
      _createVNode(_unref(IonButton), {
        fill: "outline",
        shape: "round",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (getPhoto())),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('scan_qr.use_camera')), 1),
          _createVNode(_unref(IonIcon), {
            class: "cursor-pointer",
            icon: _unref(cameraOutline),
            slot: "end"
          }, null, 8, ["icon"])
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("img", { src: profileImage.value }, null, 8, _hoisted_3)
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center justify-between h-full px-[34px]" }
const _hoisted_2 = { class: "flex justify-center mb-2" }
const _hoisted_3 = { class: "flex flex-col w-full max-w-[325px] h-[200px] items-center justify-center backdrop-blur-2xl bg-white/10 border border-dashed border-white/20 rounded-md" }
const _hoisted_4 = { class: "flex justify-center mb-2" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "flex justify-center mb-2" }
const _hoisted_7 = { class: "flex w-full justify-between items-center font-sourcesanspro-semibold text-base" }
const _hoisted_8 = { class: "flex flex-wrap max-w-[325px] justify-between" }
const _hoisted_9 = { class: "flex w-full align-center mb-[34px]" }

import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, cloudUploadOutline } from 'ionicons/icons';
import PersonItemMini from '@/shared/components/ui/PersonItemMini.vue';
import TopBar from '@/shared/components/TopBar.vue';

// Go back
const friendsCount = 4;

export default /*@__PURE__*/_defineComponent({
  __name: 'FriendsGroupAddPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push(link);
};


return (_ctx: any,_cache: any) => {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex items-center justify-start cursor-pointer",
                onClick: goBack
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ]),
            center: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "flex grow justify-center w-full font-sourcesanspro-semibold text-l2" }, "New Group", -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(cloudUploadOutline),
                class: "w-[40px] h-[40px] mb-4"
              }, null, 8, ["icon"]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-sourcesanspro-semibold text-base2" }, [
                _createElementVNode("span", { class: "text-[#32BCE8]" }, "Click here "),
                _createElementVNode("span", null, "to upload image")
              ], -1)),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-sm text-white/50" }, "20MB is max file size", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ion_input, {
                id: "input-field",
                class: "w-[325px] h-[52px] ion-padding-horizontal py-4 rounded-full border border-anykrowd-gray/20 font-sourcesanspro-regular text-base text-white",
                type: "text",
                placeholder: "Group name*"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ion_button, {
              class: "w-[325px] h-[52px]",
              expand: "full",
              shape: "round"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", null, "Select friends to group", -1)),
                  _createVNode(_unref(IonIcon), {
                    icon: require('@/statics/svg/people-add-2.svg'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/friends/select'))),
                    class: "w-[26px] h-[26px] text-anykrowd-dark-purple cursor-pointer"
                  }, null, 8, ["icon"])
                ])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(PersonItemMini, {
              class: "mb-2",
              name: "Allien M.",
              image: require('@/statics/svg-delete-after-implement/person-1.svg')
            }, null, 8, ["image"]),
            _createVNode(PersonItemMini, {
              class: "mb-2",
              name: "Amanda B.",
              image: require('@/statics/svg-delete-after-implement/person-3.svg')
            }, null, 8, ["image"]),
            _createVNode(PersonItemMini, {
              class: "mb-2",
              name: "Adebayor M",
              image: require('@/statics/svg-delete-after-implement/person-6.svg')
            }, null, 8, ["image"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ion_button, {
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "flex w-full justify-center items-center" }, "Confirm", -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
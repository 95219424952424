import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex w-full justify-between mb-3" }
const _hoisted_2 = { class: "flex flex-col mb-2" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "flex w-full justify-between" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex items-center justify-center" }

import router from '@/router';
import { IonButton } from '@ionic/vue';
import { shareSocialOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'VoucherItem',
  props: {
	activated: { type: Boolean, default: false },
	class: { type: String },
	id: { type: Number, required: true },
	link: { type: String, required: true, default: '/dashboard' },
	shareable: { type: Boolean, default: false },
	status: { type: String },
	statusClass: { type: String },
	ticketDescription: { type: String, required: true },
	ticketName: { type: String, required: true },
	ticketSubTitle: { type: String, required: true },
	ticketTitle: { type: String, required: true },
	usable: { type: Boolean, default: false },
},
  emits: ['onShare'],
  setup(__props, { emit: __emit }) {

const props = __props;

const goTo = async (link: string) => {
	await router.push(link);
};

const emit = __emit;
const share = () => {
	emit('onShare', props.id);
};

return (_ctx: any,_cache: any) => {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col w-[325px] h-[130px] px-6 py-3", [
			{
				'voucher-item': !props.activated,
				'voucher-item-2': props.activated,
			},
			props.class,
		]]),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (goTo('')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", {
          class: _normalizeClass(["font-sourcesanspro-semibold text-sm2", [
						{
							'text-white': !props.activated,
							'text-dark-blue': props.activated,
						},
					]])
        }, _toDisplayString(props.ticketName), 3),
        _createElementVNode("span", {
          class: _normalizeClass(["font-sourcesanspro-regular text-sm", [
						{
							'text-white/60': !props.activated,
							'text-dark-blue/60': props.activated,
						},
					]])
        }, _toDisplayString(props.ticketDescription), 3)
      ]),
      _createElementVNode("div", null, [
        (!props.activated)
          ? (_openBlock(), _createBlock(_unref(IonButton), {
              key: 0,
              class: "w-[89px] h-[33px]",
              expand: "full",
              size: "large",
              shape: "round",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(`/vouchers/activate/manual/${props.id}`)))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "flex items-center justify-center" }, [
                  _createElementVNode("span", { class: "text-sm2 text-[#OD132A]" }, "Activate")
                ], -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (props.activated)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["p-1 px-2 rounded-md font-sourcesanspro-regular text-sm", props.statusClass])
              }, _toDisplayString(props.status), 3)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: _normalizeClass(["font-sourcesanspro-semibold text-l2 leading-6", [
						{
							'text-white': !props.activated,
							'text-dark-blue': props.activated,
						},
					]])
        }, _toDisplayString(props.ticketTitle), 3),
        _createElementVNode("span", {
          class: _normalizeClass(["font-sourcesanspro-regular text-sm text-white/60", [
						{
							'text-white/60': !props.activated,
							'text-dark-blue/60': props.activated,
						},
					]])
        }, _toDisplayString(props.ticketSubTitle), 3)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (props.activated && props.usable)
          ? (_openBlock(), _createBlock(_unref(IonButton), {
              key: 0,
              class: "w-[74px] h-[33px] black font-sourcesanspro-semibold text-sm2 ion-no-margin",
              expand: "full",
              size: "large",
              shape: "round",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo(`/vouchers/activate/manual/${props.id}`)))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "flex items-center justify-center" }, [
                  _createElementVNode("span", { class: "text-sm2" }, "Use it")
                ], -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (props.activated && props.shareable)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 1,
              icon: _unref(shareSocialOutline),
              onClick: share,
              class: "text-dark-blue"
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})
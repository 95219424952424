import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center w-full text-center font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { class: "px-[34px] icon flex justify-center mb-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "p-6 mx-auto mb-4 bg-white rounded-lg shadow-md" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = {
  key: 0,
  class: "text-center text-gray-900 black font-sourcesanspro-bold"
}
const _hoisted_8 = { class: "mb-4 text-center" }
const _hoisted_9 = { class: "text-sm text-gray-500" }
const _hoisted_10 = { class: "text-2xl font-bold text-gray-900" }
const _hoisted_11 = {
  key: 1,
  class: "text-center text-gray-900 black font-sourcesanspro-bold"
}
const _hoisted_12 = ["innerHTML"]

import { computed, onMounted, ref } from 'vue';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TopBar from '@/shared/components/TopBar.vue';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import { useRoute } from 'vue-router';
import { anykrowdApi } from '@/api/anykrowdApi';
import useTenant from '@/shared/composables/useTenant';
import { data } from 'autoprefixer';

export default /*@__PURE__*/_defineComponent({
  __name: 'VoucherRefundPage',
  setup(__props) {

const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

// Init
const { t } = useI18n();
const assetStore = useAssetsStore();
const route = useRoute();
const voucherCode = route.params.id.toString();

const voucherMetaData: any = ref(null);
const voucherAmount = ref('');
const voucherRedeemed = ref(false);
const error = ref('');

// onMountedHook
onMounted(async () => {
	const { data } = await anykrowdApi.get('/vouchers/warranty-voucher-value/' + voucherCode, false);
	if (data && !data.error) {
		try {
			voucherMetaData.value = data.voucher_meta_data;
			voucherAmount.value = parseFloat(data.voucher_meta_data.full_fiat_value).toFixed(2);
			voucherRedeemed.value = data.voucher_meta_data['status'] === 'redeemed';
		} catch (e) {
			console.error(e);
		}
	}

	if (data.error === 'invalid_voucher_code') {
		error.value = 'invalid_voucher_code';
	}
});

const isValidAlready = computed(() => {
	console.log(voucherMetaData.value);
	return voucherMetaData.value && new Date(voucherMetaData.value.valid_at) < new Date();
});

const hasExpired = computed(() => {
	return voucherMetaData.value && new Date(voucherMetaData.value.expires_at) < new Date();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('wallet.voucher')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(assetStore).logoUrl,
            class: "max-w-[70%]"
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (error.value === 'invalid_voucher_code')
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, " The voucher code is invalid "))
            : _createCommentVNode("", true),
          (!error.value && voucherMetaData.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('wallet.voucher_amount')), 1),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(voucherAmount.value) + " EUR", 1)
                ]),
                (!voucherRedeemed.value && isValidAlready.value && !hasExpired.value)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: `/wallet/refunds/${_unref(voucherCode)}/guestrequest?isVoucher=true`,
                      class: "mx-auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          class: "w-[117px] h-[40px] black font-sourcesanspro-semibold text-sm2 ion-no-margin mx-auto",
                          shape: "round"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('wallet.request_refund')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["to"]))
                  : (!isValidAlready.value || hasExpired.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, " The voucher can be redeemed between " + _toDisplayString(new Date(voucherMetaData.value?.valid_at)
								.toLocaleString('nl-BE', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								})
								.replace(',', '')) + " and " + _toDisplayString(new Date(voucherMetaData.value?.expires_at)
								.toLocaleString('nl-BE', {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								})
								.replace(',', '')), 1))
                    : (voucherRedeemed.value)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 2,
                          class: "text-center text-gray-900 black font-sourcesanspro-bold",
                          innerHTML: _unref(tenantConfig)?.warranty_settings_qr_voucher_redeemed_message ??
						'The voucher has been redeemed'
						
                        }, null, 8, _hoisted_12))
                      : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "flex grow justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = {
  key: 0,
  class: "flex grow flex-col items-center justify-center px-[34px]"
}
const _hoisted_4 = {
  key: 1,
  class: "flex grow flex-col justify-center px-[34px]"
}
const _hoisted_5 = { class: "mb-4 font-sourcesanspro-regular text-center text-sm2 font-color-text-50" }
const _hoisted_6 = { class: "flex flex-wrap w-full justify-left item-center p-2 rounded-full background-white-15 mb-8" }
const _hoisted_7 = { class: "flex w-full items-center px-6" }
const _hoisted_8 = { class: "separator mb-8" }
const _hoisted_9 = { class: "font-sourcesanspro-regular text-base text-white/50" }
const _hoisted_10 = { class: "flex flex-col w-full px-[34px] align-center mb-8" }
const _hoisted_11 = { class: "flex w-full items-center justify-between" }

import { watch } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonInput, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { TransactionType } from '@/enums/wallet.enum';
import { useTransactionStore } from '@/stores/transaction/transactionStore';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { FreeTickets } from '@/shared/interfaces/voucher';
import type { Transaction } from '@/shared/interfaces/transaction';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useVoucher from '@/modules/vouchers/composables/useVoucher';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'RedeemVoucherPage',
  setup(__props) {

const { t } = useI18n();
const transactionStore = useTransactionStore();
const { setItemsAndTotal } = transactionStore;
const { errorVoucher, redeemVoucher, voucherText, voucherData, isLoadingVoucher } = useVoucher();
const { presentToast } = useToast();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Watch any change on voucherData
watch(voucherData, async () => {
	if (voucherData.value && voucherData.value.error) {
		// Show toast with error
		await presentToast('top', t(voucherData.value.error), 5000, 'danger');
	} else if (
		voucherData.value &&
		voucherData.value.voucherMetaData.status === 'redeemed' &&
		voucherData.value.voucherRedeemedItems.length
	) {
		const walletBalanceAdded: FreeTickets[] = voucherData.value.voucherRedeemedItems.filter(
			(item: FreeTickets) => item.item_type === 'wallet_balance',
		);

		const voucherItems: any = [];
		// Transform in one dimesion array
		voucherData.value.voucherRedeemedItems.forEach((item: any) => {
			if (item.length) {
				voucherItems.push(...item);
			} else {
				voucherItems.push(item);
			}
		});

		const transaction: Transaction = {
			items: voucherItems.map((item: any) => {
				let description = '';
				let quantity = null;
				let price = '';
				let currency = null;

				if (item.item_type === 'wallet_balance') {
					description = t('top_up.redeem_voucher.wallet_balance');
					currency = item.currency.prefix;
					price = item.amount;
				} else if (item.item_type === 'free_tickets') {
					description = `${item.item_extra_data!.event_name} - ${item.item_extra_data!.ticket_type_name}`;
					quantity = item.item_quantity;
					price = '0';
				} else if (item.item_type === 'tags_assignment') {
					description = `${t('top_up.redeem_voucher.now_identified_as')} ${item.item_names.join(' - ')}`;
				}

				return {
					description,
					quantity: quantity,
					itemPrice: price,
					currency,
					unitPrice: 0,
				};
			}),
			type: TransactionType.VOUCHER_TOP_UP,
			totalDescription: t('top_up.redeem_voucher.voucher_redeemed_items'),
			totalPriceString: walletBalanceAdded.length
				? `€${walletBalanceAdded[0].item_extra_data!.amount.toString()}`
				: '',
			totalPriceNumber: walletBalanceAdded.length ? walletBalanceAdded[0].item_extra_data!.amount : 0,
			totalPriceWithoutFee: 0,
		};
		setItemsAndTotal(transaction);
		await router.push({ path: '/transaction' });
	}
});

watch(errorVoucher, async () => {
	console.log(errorVoucher.value);
});

// onScanQR
const onScanQR = (qrCode: string) => {
	voucherText.value = qrCode;
};

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: goBack
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('top_up.redeem_voucher.title')), 1)
          ]),
          _: 1
        }),
        (_unref(isLoadingVoucher))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (!_unref(isLoadingVoucher))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('top_up.redeem_voucher.redeem_here')), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(IonInput), {
                    modelValue: _unref(voucherText),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(voucherText) ? (voucherText).value = $event : null)),
                    class: "text-center font-sourcesanspro-regular text-l2 tracking-[.20em]",
                    name: "voucher-code",
                    type: "text",
                    placeholder: "xxxx - xxxx - xxxx"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h5", null, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(t)('top_up.redeem_voucher.redeem_or')), 1)
                ])
              ]),
              _createVNode(ScanQR, {
                onOnScanQR: onScanQR,
                extractParam: "voucherID",
                permissionTitle: _unref(t)('scan_qr.camera_permission_title'),
                permissionDescription: _unref(t)('scan_qr.camera_permission_description')
              }, null, 8, ["permissionTitle", "permissionDescription"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:bg-white hover:rounded-full",
            expand: "full",
            size: "large",
            shape: "round",
            disabled: _unref(isLoadingVoucher) || !_unref(voucherText),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(redeemVoucher)(_unref(voucherText))))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _createTextVNode(_toDisplayString(_unref(t)('general.continue')) + " ", 1),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(arrowForwardOutline),
                  class: "text-dark-blue mr-2"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
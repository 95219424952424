import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import { IonButton, IonIcon, isPlatform } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { shareOutline, shareSocialOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import useUtils from '@/shared/composables/useUtils';


export default /*@__PURE__*/_defineComponent({
  __name: 'ShareButton',
  props: {
	id: { type: Number as () => number | undefined, required: true },
	title: { type: String, required: true },
	type: { type: String, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const isShareAvailable = ref(false);
const { getCurrentWindowLocationOrigin } = useUtils();

// onMountedHook
onMounted(async () => {
	isShareAvailable.value = (await Share.canShare()).value;
});

// Share
const share = async () => {
	await Share.share({
		title: props.title,
		text: props.title,
		url: `${getCurrentWindowLocationOrigin()}/#/${props.type}/${props.id}`,
		dialogTitle: t('general.share_with_friends'),
	});
};

return (_ctx: any,_cache: any) => {
  return (_unref(Capacitor).getPlatform() !== 'web' && isShareAvailable.value)
    ? (_openBlock(), _createBlock(_unref(IonButton), {
        key: 0,
        class: "transparent w-[30px] ion-no-padding",
        onClick: share
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), {
            icon: _unref(isPlatform)('android') ? _unref(shareSocialOutline) : _unref(shareOutline),
            class: _normalizeClass([
				{
					'text-[24px] pb-[2px]': _unref(isPlatform)('ios'),
				},
			])
          }, null, 8, ["icon", "class"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})
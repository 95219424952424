import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed bottom-0 w-full bg-white" }
const _hoisted_2 = { class: "flex flex-col mb-2 mx-4 pt-4 bg-white rounded-xl" }
const _hoisted_3 = { class: "px-4 pb-4 text-center text-[#999999]" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "flex flex-col mb-4 mx-4 bg-white rounded-xl" }

import { PropType } from 'vue';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { IonButton, IonContent, modalController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import { anykrowdApi } from '@/api/anykrowdApi';
import { DownloadableItem, DownloadType } from '@/shared/interfaces/download';
import useLoading from '@/shared/composables/useLoading';
import useToast from '@/shared/composables/useToast';


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadModal',
  props: {
	title: { type: String },
	downloadItem: { type: Object as PropType<DownloadableItem> },
	downloadType: { type: String, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const { presentLoading } = useLoading();
const { presentToast } = useToast();
let loader: any;

const downloadFile = () => {
	_downloadFile();
	closeModal();
};

const _downloadFile = async () => {
	if (Capacitor.isNativePlatform()) {
		loader = await presentLoading(t('general.please_wait'));

		if (!props.downloadItem!.data) {
			await anykrowdApi
				.get(props.downloadItem!.href, false, { responseType: 'blob', method: 'get' })
				.then(async (response) => {
					await saveFile(response.data);
				});
			return;
		}

		await saveFile(props.downloadItem!.data);
		return;
	}

	const strWindowFeatures = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes';
	window.open(props.downloadItem!.href, '_blank', strWindowFeatures);
};

const blobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, _) => {
		const fileReader = new FileReader();
		// eslint-disable-next-line @typescript-eslint/dot-notation
		const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
		const reader = zoneOriginalInstance || fileReader;

		reader.onloadend = () => resolve(reader.result.toString());
		reader.readAsDataURL(blob);
	});
};

const saveFile = async (fileData: any) => {
	try {
		const blob: Blob = fileData;

		const base64 = await blobToBase64(blob);

		const extension = props.downloadItem!.extension ?? 'pdf';

		const savedFile = await Filesystem.writeFile({
			path: `${encodeURI(props.downloadItem!.linkName + '.' + extension)}`,
			data: base64,
			directory: Directory.Documents,
		});

		presentToast(
			'top',
			t('download_modal.file_stored_successfully', { file_name: props.downloadItem!.linkName }),
			4000,
		);

		let options: any = {
			filePath: savedFile.uri,
		};

		if (Object.prototype.hasOwnProperty.call(blob, 'type')) {
			options = {
				...options,
				contentType: blob.type,
			};
		}

		await FileOpener.open(options).catch(async (e: any) => {
			console.error(e);
			presentToast('top', t('download_modal.open_file_error'), 4000);
		});
	} catch (e) {
		console.error(e, e === 'FILE_NOTCREATED');
		presentToast('top', t('download_modal.open_file_error'), 4000);
	}

	await loader.dismiss();
};

const closeModal = async () => {
	await modalController.dismiss();
};

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), { class: "z-30" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("h2", _hoisted_4, null, 512), [
              [_directive_dompurify_html, props.title]
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_unref(IonButton), {
            class: "text-[#3880ff]",
            fill: "clear",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadFile()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)(__props.downloadType)), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(IonButton), {
            class: "text-[#3880ff]",
            fill: "clear",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (closeModal()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('general.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full" }
const _hoisted_2 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_3 = { class: "flex w-full items-center px-6" }
const _hoisted_4 = ["name", "value"]

import { ref, toRef } from 'vue';
import { IonLabel } from '@ionic/vue';
import { useField, useIsFormValid } from 'vee-validate';


export default /*@__PURE__*/_defineComponent({
  __name: 'DateInputMask',
  props: {
	disabled: { type: Boolean, default: false },
	iconLeft: { type: String, default: '' },
	inputClass: { type: String, default: 'font-sourcesanspro-regular text-base' },
	inputMode: {
		type: String as () => 'text' | 'search' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined,
		default: 'text',
	},
	label: { type: String },
	name: { type: String, required: true },
	manualSubmit: { type: Function },
	placeholder: { type: String, default: '' },
	step: { type: String as () => string | undefined },
	showErrorMessage: { type: Boolean, default: true },
	showInputSuccess: { type: Boolean, default: true },
	shouldHandleBlur: { type: Boolean, default: true },
	shouldValidate: { type: Boolean, default: true },
	successMessage: { type: String, default: '' },
	type: { type: String, default: 'text' },
	value: { type: String as () => string | number | null | undefined, default: null },
	wrapperClass: { type: String, default: '' },
},
  emits: ['inputChanged', 'enterHit'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { value, errorMessage, handleChange, meta, setValue, setErrors, validate } = useField(name, undefined, {
	initialValue: props.value,
});

const isFormValid = useIsFormValid();
const formattedDate = ref(value);

const updateDate = (event: any) => {
	if (event.target.value) {
		formattedDate.value = event.target.value;
		emit('inputChanged', isFormValid, name);
	}
};

return (_ctx: any,_cache: any) => {
  const _directive_mask = _resolveDirective("mask")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-regular" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.label), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["wrapper border-white-15", [
				{
					'border-error': !!_unref(errorMessage) && !!__props.wrapperClass,
					'border-success': props.showInputSuccess && _unref(meta).valid && _unref(meta).dirty,
				},
				__props.wrapperClass,
			]])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          class: "font-sourcesanspro-regular",
          type: "text",
          id: "dateInput",
          name: props.name,
          value: formattedDate.value,
          placeholder: "DD-MM-YYYY",
          onInput: _cache[0] || (_cache[0] = (event) => updateDate(event))
        }, null, 40, _hoisted_4), [
          [_directive_mask, '##-##-####']
        ])
      ])
    ], 2),
    _withDirectives(_createElementVNode("p", { class: "mb-4 font-sourcesanspro-regular text-sm font-color-error" }, _toDisplayString(_unref(errorMessage)), 513), [
      [_vShow, _unref(errorMessage) && __props.showErrorMessage]
    ])
  ], 512)), [
    [_vShow, __props.type !== 'hidden']
  ])
}
}

})
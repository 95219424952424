import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full pt-[64px] px-[34px] pb-[34px] bg-black items-center justify-between" }
const _hoisted_2 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_3 = { class: "flex w-full items-center justify-center" }
const _hoisted_4 = { class: "flex flex-col h-full justify-between" }
const _hoisted_5 = { class: "px-[34px] w-full" }
const _hoisted_6 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_7 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_8 = { class: "flex justify-center mb-8 cursor-pointer" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "relative mb-4"
}
const _hoisted_11 = ["validation-schema"]
const _hoisted_12 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_13 = {
  key: 4,
  class: "mt-4 mb-4 font-sourcesanspro-semibold text-base2"
}
const _hoisted_14 = { class: "px-[34px] mb-[34px]" }
const _hoisted_15 = { class: "flex w-full items-center justify-center" }

import { onMounted, ref, watch } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonLabel, IonSpinner } from '@ionic/vue';
import { chevronBackOutline, chevronDownOutline, closeOutline } from 'ionicons/icons';
import { Form } from 'vee-validate';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import CircleStencil from '@/shared/components/CircleStencil.vue';
import DateInputMask from '@/shared/components/sign-up-forms/DateInputMask.vue';
import hexRgb from 'hex-rgb';
import ModalTwo from '@/shared/components/ui/ModalTwo.vue';
import rgbHex from 'rgb-hex';
import SelectInput from '@/shared/components/sign-up-forms/SelectInput.vue';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCamera from '@/shared/composables/useCamera';
import useCustomForm from '@/shared/composables/useCustomForm';
import useLoading from '@/shared/composables/useLoading';
import useProfileEdit from '@/modules/profile/composables/useProfileEdit';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileEditPage',
  setup(__props) {

const { t } = useI18n();
const editingImage = ref(false);
const img = ref();
const editedImg = ref();
const { savePhoto } = useProfileEdit();
const { photo, cameraGetPhoto } = useCamera();
const { presentLoading } = useLoading();
const loading = ref();
const { getRegistrationFormForAdditionalData } = useRegistrationForm();
const {
	registrationFormOriginal,
	validationSchemaOneStep,
	userProfile,
	refetchUserProfile,
	removeUserProfile,
	isFetchedUserProfile,
} = getRegistrationFormForAdditionalData();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// useCustomForm
const { isSuccess, isFormValid, customOnSubmit, onInputChange, validate } = useCustomForm(
	validationSchemaOneStep.value,
	'/profile',
	'post',
	'profile.saved',
	'profile.saving_error',
	5000,
);

// Validate form on mounted
onMounted(async () => {
	const { valid } = await validate();
	if (valid) {
		isFormValid.value = valid;
	}
});

const handleSubmit = (event: Event) => {
	if (event.target) {
		let formData = {};
		const data = new FormData(event.target);
		const dataEntries = Object.fromEntries(data.entries());
		if (dataEntries.date_of_birth) {
			formData = {
				...dataEntries,
				'date_of_birth': DateTime.fromFormat(dataEntries.date_of_birth, 'dd-MM-yyyy').toFormat('yyyy-MM-dd'),
			};
		} else {
			formData = {
				...dataEntries,
			};
		}
		customOnSubmit(formData);
	}
	event.preventDefault();
};

const refetchUserAndRedirect = async () => {
	// fetch user
	await removeUserProfile();
	await refetchUserProfile();
	img.value = null;
	editingImage.value = false;
	await router.push({ path: `/profile` });
	if (loading.value) {
		loading.value.dismiss();
	}
};

// Refetch user after profile change
watch(isSuccess, async () => {
	await refetchUserAndRedirect();
});

// getPhoto
const getPhoto = async () => {
	await cameraGetPhoto();

	watch(photo, async () => {
		if (photo.value && userProfile.value) {
			img.value = photo.value;
			editingImage.value = true;
		}
	});
};

// change
const change = async ({ coordinates, canvas }: { coordinates: any; canvas: any }) => {
	editedImg.value = canvas.toDataURL();
};

const close = () => {
	img.value = null;
	editingImage.value = false;
};

// saveModifiedPhoto
const saveModifiedPhoto = async () => {
	loading.value = await presentLoading(t('general.please_wait'));
	try {
		await savePhoto(editedImg.value, userProfile.value!);
	} catch (e) {
		loading.value.dismiss();
	}

	setTimeout(async () => {
		await refetchUserAndRedirect();
	}, 1000);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createVNode(ModalTwo, { "is-open": editingImage.value }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: close
              }, null, 8, ["icon"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_unref(Cropper), {
                class: "w-[300px] h-[300px]",
                src: img.value,
                onChange: change,
                "stencil-component": CircleStencil
              }, null, 8, ["src"])
            ]),
            _createVNode(_unref(IonButton), {
              class: "w-full hover:bg-white hover:rounded-full",
              expand: "full",
              size: "large",
              shape: "round",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (saveModifiedPhoto()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('profile.save_changes')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/profile",
                  class: "flex items-center justify-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('profile.edit_profile')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_8, [
            (!_unref(isFetchedUserProfile))
              ? (_openBlock(), _createBlock(_unref(IonSpinner), {
                  key: 0,
                  name: "dots"
                }))
              : _createCommentVNode("", true),
            (_unref(userProfile) && _unref(isFetchedUserProfile))
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  (!img.value)
                    ? (_openBlock(), _createBlock(UserAvatar, {
                        key: 0,
                        "container-class": 'w-[100px] h-[100px]',
                        "image-class": 'w-[100px] h-[100px]',
                        src: 
								_unref(userProfile).profile_image_url
									? _unref(userProfile).profile_image_url
									: require('@/statics/img/no_profile_pic.jpg')
							,
                        alt: "User icon",
                        showStatus: false,
                        onClick: getPhoto
                      }, null, 8, ["src"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_unref(registrationFormOriginal) && _unref(userProfile) && _unref(validationSchemaOneStep))
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("form", {
                  id: "profileEditForm",
                  onSubmit: _cache[1] || (_cache[1] = (event) => handleSubmit(event)),
                  "validation-schema": _unref(validationSchemaOneStep),
                  "validate-on-mount": ""
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(registrationFormOriginal).data, (field, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      (field.field_name === 'email')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-regular text-xs2 text-white/50" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(field.field_label), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", null, _toDisplayString(_unref(userProfile)[field.field_name]), 1),
                            (field.field_name === 'email')
                              ? (_openBlock(), _createBlock(TextInput, {
                                  key: 0,
                                  "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                                  value: _unref(userProfile)[field.field_name],
                                  label: field.field_label,
                                  name: field.field_name,
                                  placeholder: field.field_label,
                                  type: "hidden",
                                  onInputChanged: _unref(onInputChange)
                                }, null, 8, ["value", "label", "name", "placeholder", "onInputChanged"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (
									(field.type === 'text' || field.type === 'tel') &&
									!['email'].includes(field.field_name)
								)
                        ? (_openBlock(), _createBlock(TextInput, {
                            key: 1,
                            "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                            value: _unref(userProfile)[field.field_name],
                            label: field.field_label,
                            name: field.field_name,
                            placeholder: field.field_label,
                            type: "text",
                            onInputChanged: _unref(onInputChange)
                          }, null, 8, ["value", "label", "name", "placeholder", "onInputChanged"]))
                        : _createCommentVNode("", true),
                      (field.type === 'select')
                        ? (_openBlock(), _createBlock(SelectInput, {
                            key: 2,
                            name: field.field_name,
                            placeholder: _ctx.$t('auth.sign_up_additional_page.step_four.placeholder'),
                            values: field.options,
                            label: field.field_label,
                            icon: _unref(chevronDownOutline),
                            value: _unref(userProfile)[field.field_name],
                            onInputChanged: _unref(onInputChange)
                          }, null, 8, ["name", "placeholder", "values", "label", "icon", "value", "onInputChanged"]))
                        : _createCommentVNode("", true),
                      (field.type === 'date')
                        ? (_openBlock(), _createBlock(DateInputMask, {
                            key: 3,
                            "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                            customInputClass: "justify-start",
                            name: field.field_name,
                            label: field.field_label,
                            value: _unref(userProfile)[field.field_name],
                            type: "date"
                          }, null, 8, ["name", "label", "value"]))
                        : _createCommentVNode("", true),
                      (_unref(registrationFormOriginal).data.length > 3 && index === 2)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(t)('profile.other_information')), 1))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ], 40, _hoisted_11)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:bg-white hover:rounded-full",
            disabled: !_unref(isFormValid),
            expand: "full",
            form: "profileEditForm",
            size: "large",
            shape: "round",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(t)('profile.save_changes')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { class: "px-[34px] icon flex justify-center mb-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "mb-2 px-[34px] flex"
}
const _hoisted_6 = { class: "ml-2" }
const _hoisted_7 = { class: "flex w-[100px] h-[34px] items-center justify-center p-2 bg-white rounded-full" }
const _hoisted_8 = { class: "font-sourcesanspro-semibold text-xs2 text-anykrowd-dark" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "flex w-[100px] h-[34px] items-center justify-between p-2 bg-white rounded-full" }
const _hoisted_11 = { class: "flex items-center mr-2" }
const _hoisted_12 = { class: "font-sourcesanspro-semibolditalic text-xs2 text-[#707070]" }

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, linkOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import RfidWalletBox from '@/modules/rfid/components/RfidWalletBox.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'RfidGuestPage',
  setup(__props) {

const { t } = useI18n();
const assetStore = useAssetsStore();

const route = useRoute();
const encryptedRfidTag = route.params.id.toString();
const { searchRfidTag } = useRfidGuest();
const { isSuccessRfidTag, rfidTag, rfidTagMutate } = searchRfidTag();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const continueLinking = ref(false);

// onMountedHook
onMounted(async () => {
	rfidTagMutate({ encrypted_rfid_tag: encryptedRfidTag });
	if (route.query.continueLinking) {
		continueLinking.value = true;
	}
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('rfid.guest.dashboard')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(assetStore).logoUrl,
            class: "max-w-[70%]"
          }, null, 8, _hoisted_4)
        ]),
        (_unref(rfidTag))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: `/wallet/history?id=${_unref(encryptedRfidTag)}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('components.rfid_wallet_box.history')), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              (_unref(rfidTag).user)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(linkOutline),
                          class: "text-anykrowd-dark text-[#707070]"
                        }, null, 8, ["icon"])
                      ]),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(t)('components.rfid_wallet_box.rfid_linked')), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createVNode(RfidWalletBox, {
          encryptedRfidTag: _unref(encryptedRfidTag),
          isSuccessRfidTag: _unref(isSuccessRfidTag),
          rfidTag: _unref(rfidTag),
          "user-profile": _unref(userProfile),
          "continue-linking": continueLinking.value
        }, null, 8, ["encryptedRfidTag", "isSuccessRfidTag", "rfidTag", "user-profile", "continue-linking"])
      ])
    ]),
    _: 1
  }))
}
}

})
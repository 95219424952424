import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { class: "flex grow justify-left w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-center mb-14 px-[34px]"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col height-inherit px-[34px]"
}
const _hoisted_5 = { class: "flex flex-col grow justify-start" }
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = {
  key: 2,
  class: "flex flex-col height-inherit px-[34px]"
}
const _hoisted_8 = { class: "flex flex-col grow justify-start" }
const _hoisted_9 = { class: "mb-2" }
const _hoisted_10 = {
  key: 3,
  class: "flex flex-col height-inherit px-[34px]"
}
const _hoisted_11 = { class: "flex flex-col grow justify-start" }
const _hoisted_12 = { class: "mb-2" }
const _hoisted_13 = {
  key: 4,
  class: "flex quiz-results flex-col items-center h-[316px]"
}
const _hoisted_14 = {
  key: 0,
  class: "flex w-full px-[34px] align-center mb-[34px]"
}
const _hoisted_15 = { class: "flex w-full justify-between items-center" }

import { ref } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useQuizStore } from '@/stores/auth/quizStore';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { arrowForward, chevronBackOutline } from 'ionicons/icons';
import * as yup from 'yup';

import RadioInput from '@/modules/quiz/components/RadioInput.vue';
import TopBar from '@/shared/components/TopBar.vue';

// break down the validation steps into multiple schemas

export default /*@__PURE__*/_defineComponent({
  __name: 'QuizQuestionsPage',
  setup(__props) {

const validationSchema = [
	yup.object({
		question1: yup.string().required().oneOf(['Anykrowd', 'Krowd', 'Any-KroWd'], 'Question'),
	}),
	yup.object({
		question2: yup.string().required().oneOf(['Easy', 'Medium', 'Heavy'], 'Question'),
	}),
	yup.object({
		question3: yup.string().required().oneOf(['1-2', '2-3', '3-5'], 'Question'),
	}),
];

const showResults = ref(false);
const quizStore = useQuizStore();
const { currentStep } = storeToRefs(quizStore);
const { decrementStep, incrementStep } = quizStore;
const goBackOneStep = () => {
	if (currentStep.value > 0) {
		decrementStep();
	}
};
const goForwardOneStep = () => {
	incrementStep();
};

const onSelect = () => {
	if (currentStep.value + 1 < validationSchema.length) {
		goForwardOneStep();
	} else {
		showResults.value = true;
	}
};

const goTo = async (link: string) => {
	await router.push(link);
};

// /**
//  * Only Called when the last step is submitted
//  */
// async function onSubmit(formData: FormData) {
//   console.log(JSON.stringify(formData, null, 2));
//   await router.push('/auth/signinhome');
// }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, { class: "mb-12 px-[34px]" }, _createSlots({ _: 2 }, [
            (!showResults.value)
              ? {
                  name: "left",
                  fn: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      class: "go-back-button",
                      onClick: goBackOneStep
                    }, {
                      default: _withCtx(() => [
                        _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(chevronBackOutline),
                          class: "text-white"
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  key: "0"
                }
              : undefined,
            (!showResults.value)
              ? {
                  name: "center",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, " Quiz question " + _toDisplayString(_unref(currentStep) + 1), 1)
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1024),
          (!showResults.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(validationSchema, (step, index) => {
                  return _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(["step-line bg-white mx-1", [{ 'background-white-15': index > _unref(currentStep) }]]),
                    key: index
                  }, null, 2)
                }), 64))
              ]))
            : _createCommentVNode("", true),
          (_unref(currentStep) === 0 && !showResults.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex justify-center mb-10" }, [
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold text-lg" }, "What's name of this app?")
                ], -1)),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(RadioInput, {
                      onOnSelect: onSelect,
                      name: "question1",
                      values: [
									{ name: 'Anykrowd', option: 'A' },
									{ name: 'Krowd', option: 'B' },
									{ name: 'Any-KroWd', option: 'C' },
								]
                    })
                  ]),
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "max-w-[95%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center" }, [
                    _createElementVNode("span", null, " Etiam iaculis risus a dolor ultrices, dignissim aliquam mauris elementum. Morbi blandit luctus purus. Aenean fringilla aliquet sagittis. ")
                  ], -1))
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(currentStep) === 1 && !showResults.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex justify-center mb-10" }, [
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold text-lg" }, "How easy is using the app?")
                ], -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(RadioInput, {
                      onOnSelect: onSelect,
                      name: "question2",
                      values: [
									{ name: 'Easy', option: 'A' },
									{ name: 'Medium', option: 'B' },
									{ name: 'Heavy', option: 'C' },
								]
                    })
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("p", { class: "max-w-[95%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center" }, [
                    _createElementVNode("span", null, " Etiam iaculis risus a dolor ultrices, dignissim aliquam mauris elementum. Morbi blandit luctus purus. Aenean fringilla aliquet sagittis. ")
                  ], -1))
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(currentStep) === 2 && !showResults.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex justify-center mb-10" }, [
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold text-lg" }, "Rate the app?")
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(RadioInput, {
                      onOnSelect: onSelect,
                      name: "question3",
                      values: [
									{ name: '1-2', option: 'A' },
									{ name: '2-3', option: 'B' },
									{ name: '3-5', option: 'C' },
								]
                    })
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("p", { class: "max-w-[95%] mx-auto font-sourcesanspro-regular text-base text-color-magenta text-center" }, [
                    _createElementVNode("span", null, " Etiam iaculis risus a dolor ultrices, dignissim aliquam mauris elementum. Morbi blandit luctus purus. Aenean fringilla aliquet sagittis. ")
                  ], -1))
                ])
              ]))
            : _createCommentVNode("", true),
          (showResults.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "flex flex-col items-center mb-4 px-[34px]" }, [
                  _createElementVNode("div", { class: "mb-2" }, [
                    _createElementVNode("span", { class: "font-sourcesanspro-semibold text-lg3" }, "Congratulations!")
                  ]),
                  _createElementVNode("div", { class: "mb-2" }, [
                    _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base text-[#7E738C]" }, "You did a great job in the quiz")
                  ]),
                  _createElementVNode("span", { class: "font-sourcesanspro-semibold text-lg" }, "6/8")
                ], -1),
                _createElementVNode("div", { class: "flex items-center justify-center" }, [
                  _createElementVNode("div", { class: "progress" }, [
                    _createElementVNode("div", { class: "barOverflow" }, [
                      _createElementVNode("div", { class: "bar" })
                    ]),
                    _createElementVNode("div", { class: "flex flex-col absolute top-10 left-0 right-0" }, [
                      _createElementVNode("div", { class: "mb-2" }, [
                        _createElementVNode("span", { class: "font-sourcesanspro-semibold text-[52px] leading-none" }, "184")
                      ]),
                      _createElementVNode("span", { class: "font-sourcesanspro-regular text-base text-[#7E738C]" }, "Excellent")
                    ])
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (showResults.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_unref(IonButton), {
                  class: "w-full",
                  expand: "full",
                  size: "large",
                  shape: "round",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/quiz')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _cache[9] || (_cache[9] = _createTextVNode(" Start New Quiz ")),
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(arrowForward),
                        class: "text-dark-blue",
                        slot: "end"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
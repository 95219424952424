import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex px-5 py-[13px] justify-between background-white-15 rounded-full font-sourcesanspro-semibold text-base2 mb-5"
}
const _hoisted_2 = { class: "w-[22px]" }
const _hoisted_3 = { class: "w-[22px]" }
const _hoisted_4 = { class: "flex justify-between mb-4" }
const _hoisted_5 = { class: "text-base2" }
const _hoisted_6 = { class: "text-base2" }

import { onMounted, ref, computed } from 'vue';
import { IonIcon, IonButton } from '@ionic/vue';
import { chevronBackOutline, chevronForwardOutline, heartOutline, timeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TimeTableHeader from '@/modules/timetables/components/timetable/TimeTableHeader.vue';
import TimeTableItem from '@/modules/timetables/components/timetable/TimeTableItem.vue';
import TimeTableItemTitle from '@/modules/timetables/components/timetable/TimeTableItemTitle.vue';
import { Timetable } from '@/shared/interfaces/timeTables';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import { Track } from '@/shared/interfaces/timeTables';
import useTenant from '@/shared/composables/useTenant';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTable',
  props: {
	timeTable: { type: Timetable, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();
const timeTableDate = ref(props.timeTable.timelineDetails[0]);
const days = ref([...new Set(props.timeTable.timelineDetails.map((dateItem) => dateItem?.day))]);
const { calculateDurationData, calculateProgressPercent, scrollToNextDay, scrollToPreviousDay } = useTimeTables();
const timeTableHoursCount = props.timeTable.timelineDetails.length * 4;
const gridTemplateColumns = `grid-template-columns: 50px repeat(${timeTableHoursCount}, 40px);`;
// Grid rows + titles
const gridRowsCount = props.timeTable.tracks.length * 2;
const gridRowsHeights: string[] = [];
for (let i = 0; i < gridRowsCount; i++) {
	const height = i % 2 === 0 ? '34px' : '85px';
	gridRowsHeights.push(height);
}
const gridTemplateRows = `grid-template-rows: 34px ${gridRowsHeights.join(' ')};`;

// onMountedHook
onMounted(() => {
	const div = document.getElementById('timetable');

	// Find label position and update the date if needed
	div!.addEventListener('scroll', () => {
		props.timeTable.timelineDetails.find((date) => {
			if (timeTableDate.value != date) {
				const element = document.querySelector(`#day-${date?.day.toString()}`);
				const position = element!.getBoundingClientRect();
				if (position.x <= 100) {
					timeTableDate.value = date;
				}
			}
		});
	});
});

const filterValue = ref('all');

const filteredTracks = computed(() => {
	if (props.timeTable.tracks) {
		if (filterValue.value === 'favorites') {
			return props.timeTable.tracks.map((track: Track) => ({
				...track,
				activities: track.activities.filter((activity) => activity.isFavorite),
			}));
		} else {
			return props.timeTable.tracks;
		}
	} else {
		return [];
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (timeTableDate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createVNode(_unref(IonButton), {
              icon: _unref(chevronBackOutline),
              class: "flex h-[22px] transparent ion-no-padding ion-no-margin",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(scrollToPreviousDay)(timeTableDate.value.minus({ day: 1 }))))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["icon"]), [
              [_vShow, days.value.findIndex((day) => day === timeTableDate.value!.day) > 0]
            ])
          ]),
          _createTextVNode(" " + _toDisplayString(timeTableDate.value?.toFormat('ccc - dd MMM, yy')) + " ", 1),
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_unref(IonButton), {
              class: "flex h-[22px] transparent ion-no-padding ion-no-margin",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(scrollToNextDay)(timeTableDate.value.plus({ day: 1 }))))
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronForwardOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 512), [
              [_vShow, days.value.findIndex((day) => day === timeTableDate.value!.day) < days.value.length - 1]
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(IonButton), {
        icon: _unref(timeOutline),
        class: _normalizeClass(["flex w-1/2 h-[40px] button-solid rounded-full", 
					filterValue.value === 'all'
						? 'hover:bg-white text-color-button-text'
						: 'backdrop-blur bg-white/20 text-white'
				]),
        shape: "round",
        fill: filterValue.value === 'all' ? 'default' : 'clear',
        onClick: _cache[2] || (_cache[2] = ($event: any) => (filterValue.value = 'all'))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), {
            class: _normalizeClass(["mr-2", filterValue.value === 'all' ? 'text-color-button-text' : 'text-white']),
            role: "img",
            icon: _unref(timeOutline)
          }, null, 8, ["icon", "class"]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('timetables.all_activities')), 1)
        ]),
        _: 1
      }, 8, ["icon", "class", "fill"]),
      _createVNode(_unref(IonButton), {
        icon: _unref(heartOutline),
        class: _normalizeClass(["flex w-1/2 h-[40px] button-solid rounded-full", 
					filterValue.value === 'favorites'
						? 'hover:bg-white text-color-button-text'
						: 'backdrop-blur bg-white/20 text-white'
				]),
        shape: "round",
        fill: filterValue.value === 'favorites' ? 'default' : 'clear',
        onClick: _cache[3] || (_cache[3] = ($event: any) => (filterValue.value = 'favorites'))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), {
            class: _normalizeClass(["mr-2", filterValue.value === 'favorites' ? 'text-color-button-text' : 'text-white']),
            role: "img",
            icon: _unref(heartOutline)
          }, null, 8, ["class", "icon"]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('timetables.favorite_activities')), 1)
        ]),
        _: 1
      }, 8, ["icon", "class", "fill"])
    ]),
    _createElementVNode("div", {
      id: "timetable",
      class: "timetable pb-4 invisible-scroller",
      style: _normalizeStyle([gridTemplateColumns, gridTemplateRows])
    }, [
      _createVNode(TimeTableHeader, {
        timelineDetails: props.timeTable.timelineDetails
      }, null, 8, ["timelineDetails"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredTracks.value, (track) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: track.id
        }, [
          _createVNode(TimeTableItemTitle, {
            title: track.name,
            iconUrl: track.iconUrl,
            imageUrl: track.imageUrl
          }, null, 8, ["title", "iconUrl", "imageUrl"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(track.activities.sort(
						(a, b) => a.startAt.toMillis() - b.startAt.toMillis(),
					), (activity, idxActivity) => {
            return (_openBlock(), _createBlock(TimeTableItem, {
              activity: activity,
              track: new (_unref(Track))(track),
              "time-table-id": props.timeTable.id,
              "background-color": activity.backgroundColor,
              "background-color-opacity": activity.backgroundColorOpacity || '100',
              "duration-data": _unref(calculateDurationData)(activity, props.timeTable.timelineDetails),
              key: idxActivity,
              time: `${activity.startAt.toFormat('HH:mm')} - ${activity.endAt.toFormat('HH:mm')}`,
              title: activity.name,
              "bar-percent": _unref(calculateProgressPercent)(activity),
              "show-logged-data": _unref(showLoggedData)()
            }, null, 8, ["activity", "track", "time-table-id", "background-color", "background-color-opacity", "duration-data", "time", "title", "bar-percent", "show-logged-data"]))
          }), 128))
        ], 64))
      }), 128))
    ], 4)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex px-[34px]" }
const _hoisted_2 = {
  key: 0,
  class: "mt-8 z-10"
}
const _hoisted_3 = { class: "flex items-center justify-center w-[60px] h-[60px] p-4 rounded-full bg-white/90" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "fixed flex p-4 items-start justify-between bg-white left-2/4 -translate-x-2/4 bottom-28 align-center rounded-3xl w-[360px] z-10" }
const _hoisted_7 = {
  key: 0,
  class: "flex-1"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex flex-[2] flex-col pl-3" }
const _hoisted_10 = { class: "font-sourcesanspro-bold text-base2 text-[#141414]" }
const _hoisted_11 = { class: "font-sourcesanspro-regular text-xs text-[#333333]/60" }

import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonIcon } from '@ionic/vue';
import { GoogleMap, CustomMarker, Marker } from 'vue3-google-map';
import { chevronBackOutline } from 'ionicons/icons';

import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import useMaps from '@/modules/maps/composables/useMaps';
import { ApiMarker, mapCustomOverlay } from '@/shared/interfaces/map';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'MapsDetailPage',
  setup(__props) {

const mapRef = ref();
const showMarkerDetails = ref(false);
const selectedMarker = ref<ApiMarker>();
const route = useRoute();
const mapId = +route.params.id;
const { getMap, getMaps } = useMaps();
const { map } = getMap(mapId);
const { maps } = getMaps();
const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;

// Third pattern: watch for "ready" then do something with the API or map instance
watch(
	() => mapRef.value?.ready,
	(ready) => {
		if (!ready) return;

		if (map.value) {
			const bounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(map.value.boundries.south, map.value.boundries.west),
				new google.maps.LatLng(map.value.boundries.north, map.value.boundries.east),
			);

			mapRef.value.map.setOptions({
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				restriction: {
					latLngBounds: bounds,
					strictBounds: true,
				},
			});

			// Setting overlay
			const image = map.value.overlay!.toString();
			const customOverlay = new mapCustomOverlay();
			const overlay = customOverlay.createMapTextOverlay(bounds, image);
			overlay.setMap(mapRef.value.map);
		}
	},
);

const openMarkerDetails = (markerId: number) => {
	const marker = map.value!.markers?.find((m) => m.id === markerId);
	if (marker) {
		selectedMarker.value = { ...marker };
		showMarkerDetails.value = true;
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(maps) && _unref(maps).length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: "/maps" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "ion-no-padding text-black"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_unref(map))
      ? (_openBlock(), _createBlock(_unref(GoogleMap), {
          key: 1,
          class: "absolute top-0 left-0",
          ref_key: "mapRef",
          ref: mapRef,
          "api-key": _unref(GOOGLE_MAPS_API_KEY),
          "clickable-icons": false,
          mapTypeId: "roadmap",
          disableDefaultUi: true,
          style: {"width":"100%","height":"100%"},
          center: { lat: _unref(map)?.initialPosition.lat, lng: _unref(map)?.initialPosition.lng },
          zoom: 15,
          "zoom-control": true,
          "zoom-control-position": "RIGHT_TOP",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showMarkerDetails.value = false))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(map).markers, (marker, idx) => {
              return (_openBlock(), _createElementBlock("div", { key: idx }, [
                (marker.icon)
                  ? (_openBlock(), _createBlock(_unref(CustomMarker), {
                      key: 0,
                      options: {
						position: { lat: marker.position.lat, lng: marker.position.lng },
						anchorPoint: 'BOTTOM_CENTER',
					}
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "relative drop-shadow-lg",
                          onClick: ($event: any) => (openMarkerDetails(marker.id))
                        }, [
                          _createElementVNode("img", {
                            src: marker.icon
                          }, null, 8, _hoisted_5)
                        ], 8, _hoisted_4)
                      ]),
                      _: 2
                    }, 1032, ["options"]))
                  : (_openBlock(), _createBlock(_unref(Marker), {
                      key: 1,
                      options: { position: { lat: marker.position.lat, lng: marker.position.lng } },
                      onClick: ($event: any) => (openMarkerDetails(marker.id))
                    }, null, 8, ["options", "onClick"]))
              ]))
            }), 128)),
            _withDirectives(_createElementVNode("div", _hoisted_6, [
              (selectedMarker.value?.poi?.image)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("img", {
                      class: "rounded-3xl",
                      src: selectedMarker.value.poi.image
                    }, null, 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(selectedMarker.value?.poi?.name), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(selectedMarker.value?.poi?.description_short), 1)
              ])
            ], 512), [
              [_vShow, showMarkerDetails.value]
            ])
          ]),
          _: 1
        }, 8, ["api-key", "center"]))
      : _createCommentVNode("", true),
    _createVNode(NavigationBar, { "item-active": "maps" })
  ]))
}
}

})
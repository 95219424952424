import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-sourcesanspro-regular text-sm text-center text-white/50 leading-[18px]" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_4 = { class: "flex flex-col justify-between h-full" }
const _hoisted_5 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_6 = { class: "px-[34px] pt-6" }
const _hoisted_7 = { class: "mb-4 font-sourcesanspro-semibold text-white text-lg text-center" }
const _hoisted_8 = { class: "max-w-xs mx-auto mb-8 text-color-white text-base text-center" }
const _hoisted_9 = { class: "w-full" }
const _hoisted_10 = { class: "flex justify-center next-button w-full mb-4 padding-app-left padding-app-right" }

import vue, { ref } from 'vue';
import { IonContent, IonButton, IonIcon } from '@ionic/vue';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';

import ModalOne from '@/shared/components/ui/ModalOne.vue';
import SignInWithEmailForm from '@/modules/auth/components/SignInWithEmailForm.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useModal from '@/shared/composables/useModal';
import useTenant from '@/shared/composables/useTenant';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SignInWithEmailPage',
  setup(__props) {

const { isModalOpen, showHideModal, modalTitle, modalContent } = useModal();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

const isFormValid = ref(false);
const onFormValidation = (formValid: boolean) => {
	isFormValid.value = formValid;
};
const tenantName = window.tenantName;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(ModalOne, {
        title: _unref(modalTitle),
        "is-open": _unref(isModalOpen)
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(modalContent)), 1)
        ]),
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(showHideModal)(false)))
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "is-open"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, { class: "flex px-[34px]" }, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: "/auth/signinhome",
                  class: "flex items-center justify-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h1", _hoisted_7, _toDisplayString(_unref(tenantConfig)?.welcome_title), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(tenantConfig)?.welcome_line), 1),
            _createVNode(SignInWithEmailForm, { onOnFormValidation: onFormValidation })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(IonButton), {
              color: "primary",
              disabled: !isFormValid.value,
              expand: "full",
              form: "signInWithEmailForm",
              shape: "round",
              size: "large",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_in_email_page.button')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
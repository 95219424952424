import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { class: "px-[34px]" }
const _hoisted_4 = { class: "flex flex-col items-start justify-center py-4 font-sourcesanspro-regular" }
const _hoisted_5 = {
  key: 0,
  class: "flex p-4 items-center justify-center"
}
const _hoisted_6 = {
  key: 1,
  class: "flex p-4 items-center justify-center font-sourcesanspro-regular"
}
const _hoisted_7 = { class: "flex grow justify-end" }

import { ref, watch, watchEffect } from 'vue';
import router from '@/router';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonLabel,
	IonRefresher,
	IonRefresherContent,
	IonSpinner,
} from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import { addOutline, chevronBackOutline, trashOutline } from 'ionicons/icons';
import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useAlert from '@/shared/composables/useAlert';
import useRfid from '@/modules/rfid/composables/useRfid';
import useToast from '@/shared/composables/useToast';
import type { RFID } from '@/shared/interfaces/rfid';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'RfidPage',
  setup(__props) {

const isModalOpen = ref(false);
const { disconnectTag, getRfidTags } = useRfid();
const { isLoadingRfidTags, isRefetchingRfidTags, refetchRfidTags, removeRfidTags, rfidTags } = getRfidTags();
const { disconnectTagData, disconnectTagMutate } = disconnectTag();
const { t } = useI18n();
const { presentToast } = useToast();
const { presentAlert } = useAlert();
const mappedRfidTags = ref<any>([]);

// Refresh
const handleRefresh = async (event: any) => {
	removeRfidTags();
	await refetchRfidTags();
	event.target.complete();
};

// Confirm disconnect tag
const confirmDisconnect = (id: number) => {
	presentAlert({
		header: t('general.continue_question'),
		buttons: [
			{
				text: t('general.cancel'),
				role: 'cancel',
			},
			{
				text: t('general.confirm'),
				role: 'confirm',
				handler: async () => {
					await disconnectTagMutate(id);
				},
			},
		],
	});
};

// Watch disconnect status
watch(disconnectTagData, async () => {
	if (disconnectTagData.value && disconnectTagData.value.code === 'success') {
		mappedRfidTags.value = [];
		// Refetch tags
		await refetchRfidTags();
		await presentToast('top', t('components.transaction.rfid_tag_detached_successfully'), 5000);
	}
});

// Watch for rfidTags then map the result
watchEffect(() => {
	if (rfidTags.value && rfidTags.value.length) {
		mappedRfidTags.value = rfidTags.value.map((rfId: RFID) => {
			return {
				id: rfId.id,
				uuid: rfId.uuid,
			};
		});
	} else if (rfidTags.value && rfidTags.value.length === 0) {
		mappedRfidTags.value = [];
	}
});

const scanQr = () => {
	router.push('/rfid/link-bracelet');
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonRefresher), {
        class: "mt-8",
        slot: "fixed",
        onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (handleRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRefresherContent))
        ]),
        _: 1
      }),
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('rfid.title')) + " (" + _toDisplayString(_unref(rfidTags).length) + ") ", 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('rfid.description')), 1),
        _createVNode(AccordionList, {
          title: _unref(t)('rfid.linked_bracelets'),
          headerActionIcon: _unref(addOutline),
          onOnHeaderActionClick: _cache[1] || (_cache[1] = ($event: any) => (scanQr()))
        }, {
          items: _withCtx(() => [
            (_unref(isLoadingRfidTags) || _unref(isRefetchingRfidTags))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_unref(IonSpinner), { name: "dots" })
                ]))
              : _createCommentVNode("", true),
            (!_unref(rfidTags).length && !_unref(isLoadingRfidTags))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(t)('components.transaction.no_devices_found')), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mappedRfidTags.value, (item) => {
              return (_openBlock(), _createBlock(AccordionListItem, {
                key: item.id,
                "key-value": item.id.toString(),
                iconClass: "bg-white",
                "custom-subtitle": _unref(t)('components.transaction.bracelet_rfid'),
                icon: require('@/statics/svg/rfid/bracelet.svg'),
                title: item.uuid.toString()
              }, {
                actions: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(trashOutline),
                      class: "text-white",
                      onClick: ($event: any) => (confirmDisconnect(item.id))
                    }, null, 8, ["icon", "onClick"])
                  ])
                ]),
                _: 2
              }, 1032, ["key-value", "custom-subtitle", "icon", "title"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title", "headerActionIcon"])
      ])
    ]),
    _: 1
  }))
}
}

})
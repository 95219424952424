import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "px-[34px] h-full" }
const _hoisted_3 = ["validation-schema"]
const _hoisted_4 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_5 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_7 = {
  key: 1,
  class: "mt-4 text-center font-sourcesanspro-regular text-white/60"
}
const _hoisted_8 = {
  key: 2,
  class: "flex flex-col justify-center"
}
const _hoisted_9 = { class: "mb-4 text-center font-sourcesanspro-regular text-regular text-white/60" }
const _hoisted_10 = { class: "flex items-center justify-center" }
const _hoisted_11 = { class: "font-sourcesanspro-regular text-white text-[40px] mr-2" }
const _hoisted_12 = { class: "mx-1" }
const _hoisted_13 = {
  key: 0,
  class: "flex items-center justify-center mb-4 font-sourcesanspro-regular text-sm font-color-error"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 0,
  class: "flex flex-col items-center justify-center px-[34px]"
}
const _hoisted_16 = { class: "flex w-full items-center justify-between" }

import vue, { onMounted, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import hexRgb from 'hex-rgb';
import ItemsDetails from '@/shared/components/ui/ItemsDetails.vue';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useProfileRefunds from '@/modules/profile/composables/useProfileRefunds';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import { useRoute } from 'vue-router';
import { Wallet } from '@/shared/interfaces/balance';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'WalletRefundsRequestPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const walletId = route.params.wallet_id?.toString();
const refundDetails: any = ref([]);
const { getRefundInfo, getWalletCurrentBalance, getWallet } = useWallet();
const { userWallet } = getWalletCurrentBalance(walletId, false, true);
const { refundInfo } = getRefundInfo(walletId);
const { amount, iban, schema } = useProfileRefunds(refundInfo);
const nonRefundableValue = ref(0);
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const amountErrorMessage = ref('');

const wallet = ref<Wallet>();
const { exchange } = useUtils();

// useCustomForm
const { isSuccess, isFormValid, onSubmit, onInputChange } = useCustomForm(
	schema,
	`/wallet/${walletId}/refund-request`,
	'post',
	'wallet.refund.requested',
	'wallet.refund.requested_error',
	5000,
);

const onAmountInputChange = (formValid: vue.ComputedRef<boolean>, fieldName: any, event: CustomEvent) => {
	if (event.type !== 'ion-blur') {
		amount.value = event.detail.value;
	}
};

onMounted(() => {
	wallet.value = getWallet(walletId).wallet.value;
});

// Watch refund success
watch(
	isSuccess,
	() => {
		if (isSuccess.value) {
			router.push(`/wallet/refunds/${walletId}`);
		}
	},
	{ immediate: true },
);

watchEffect(() => {
	if (userWallet.value && refundInfo.value) {
		// Reser data
		refundDetails.value = [];

		// Adding balance
		refundDetails.value.push({ title: t('wallet.refund.balance'), totalPrice: +userWallet.value.current_balance });

		// Adding non refundable info
		if (+userWallet.value.current_balance - refundInfo.value.total_amount > 0) {
			nonRefundableValue.value = +userWallet.value.current_balance - refundInfo.value.total_amount;

			refundDetails.value.push({
				title: t('wallet.refund.not_refundable'),
				totalPrice: +userWallet.value.current_balance - refundInfo.value.total_amount,
			});

			if (!refundInfo.value.manual_refund_enabled) {
				refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.onsite')}` });
			}
			refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.vouchers')}` });
			refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.discounts')}` });
			refundDetails.value.push({ title: `- ${t('wallet.refund.not_refundable_items.warranty')}` });
			if (!refundInfo.value.online_cutoff_days) {
				refundDetails.value.push({
					title: t('wallet.refund.not_refundable_items.mollie', {
						online_cutoff_days: refundInfo.value.online_cutoff_days,
					}),
				});
			}
		}

		// Adding more details
		refundDetails.value.push(
			...[
				{ title: t('wallet.refund.amount'), totalPrice: +amount.value },
				{
					title: t('wallet.refund.fee_incl_vat'),
					totalPrice: -(refundInfo.value.cost || 0),
					currency: '&euro;',
				},
			],
		);
	}
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("form", {
            id: "refundsRequestForm",
            class: "flex flex-col h-full",
            "validation-schema": _unref(schema),
            onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
          }, [
            _createVNode(TopBar, null, {
              left: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    to: "/dashboard",
                    class: "flex items-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(chevronBackOutline),
                        class: "text-white"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              center: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('wallet.refund.request.title')), 1)
              ]),
              _: 1
            }),
            (!_unref(userWallet) || !_unref(refundInfo) || !refundDetails.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_unref(IonSpinner), { name: "dots" })
                ]))
              : _createCommentVNode("", true),
            (_unref(userWallet) && +_unref(userWallet).current_balance <= nonRefundableValue.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(t)('wallet.refund.not_eligible')), 1))
              : _createCommentVNode("", true),
            (
							_unref(userWallet) &&
							_unref(refundInfo) &&
							refundDetails.value &&
							+_unref(userWallet).current_balance > nonRefundableValue.value
						)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('wallet.refund.request.description')), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(wallet.value?.currency.prefix), 1),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(TextInput, {
                        disabled: +_unref(userWallet).current_balance <= nonRefundableValue.value,
                        "wrapper-class": "flex flex-wrap justify-left item-center border border-solid focus-within:background-white-15",
                        "input-class": "text-lg",
                        type: "number",
                        step: "any",
                        name: "amount",
                        placeholder: "Amount",
                        value: _unref(amount),
                        onInputChanged: onAmountInputChange,
                        "should-handle-blur": false
                      }, null, 8, ["disabled", "value"])
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "min-w-[25px] font-sourcesanspro-regular text-l2 font-color-text-50" }, null, -1))
                  ]),
                  (amountErrorMessage.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(amountErrorMessage.value), 1))
                    : _createCommentVNode("", true),
                  (_unref(refundInfo)?.manual_refund_enabled && _unref(refundInfo)?.online_amount < +_unref(amount))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createTextVNode(_toDisplayString(_unref(iban)) + " ", 1),
                        _createVNode(TextInput, {
                          "wrapper-class": "flex flex-wrap justify-left item-center mb-3 border border-solid focus-within:background-white-15",
                          value: _unref(iban),
                          label: "IBAN",
                          name: "iban",
                          placeholder: "IBAN",
                          onInputChanged: _unref(onInputChange)
                        }, null, 8, ["value", "onInputChanged"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 40, _hoisted_3)
        ]),
        (_unref(userWallet) && _unref(refundInfo) && refundDetails.value && +_unref(userWallet).current_balance > nonRefundableValue.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(ItemsDetails, {
                "border-class": "border-y border-y-white/5",
                class: "w-full backdrop-blur-xl bg-anykrowd-gray/20 text-white rounded-xl mb-4",
                "text-class": "text-white",
                "text-class-two": "text-white/70",
                title: "Info",
                items: refundDetails.value,
                "total-title": _unref(t)('wallet.refund.received'),
                "total-price": 
						_unref(exchange)(_unref(amount), wallet.value ? wallet.value.currency.exchange_rate : 1) - (_unref(refundInfo)?.cost || 0)
					,
                currency: wallet.value?.currency.prefix
              }, null, 8, ["items", "total-title", "total-price", "currency"]),
              _createVNode(_unref(IonButton), {
                class: "w-full mb-[34px] hover:bg-white hover:rounded-full",
                expand: "full",
                form: "refundsRequestForm",
                size: "large",
                shape: "round",
                disabled: !_unref(isFormValid) || _unref(amount) - _unref(refundInfo).cost < 0,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_16, [
                    _cache[2] || (_cache[2] = _createTextVNode(" Continue ")),
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(arrowForwardOutline),
                      class: "text-dark-blue mr-2"
                    }, null, 8, ["icon"])
                  ])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
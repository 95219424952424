import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_2 = { class: "flex px-6 w-full justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "text-base"
}
const _hoisted_4 = { class: "text-base capitalize" }
const _hoisted_5 = {
  key: 1,
  class: "inline text-xs"
}
const _hoisted_6 = {
  key: 0,
  class: "text-m2"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "flex px-6 py-4 items-center justify-between" }
const _hoisted_9 = {
  key: 0,
  class: "flex flex-col mx-6 mb-6 px-3 py-2 bg-white/5 rounded-md"
}
const _hoisted_10 = { class: "font-sourcesanspro-semibold text-sm2" }
const _hoisted_11 = { class: "font-sourcesanspro-regular text-xs2" }

import { PropType } from 'vue';
import { IonItem, IonList } from '@ionic/vue';

import type { ItemsDetailsItem, ItemsDetailsItemMessage } from '@/shared/interfaces/itemsDetails';
import useUtils from '@/shared/composables/useUtils';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemsDetails',
  props: {
	borderClass: { type: String, required: true },
	class: { type: String, required: true },
	items: {
		type: Array as PropType<ItemsDetailsItem[]>,
		default: function () {
			return [];
		},
	},
	message: {
		type: Object as PropType<ItemsDetailsItemMessage>,
		required: false,
	},
	quantity: { type: Number, default: undefined, required: false },
	currency: { type: String, default: undefined, required: true },
	textClass: { type: String, required: true },
	textClassTwo: { type: String, required: true },
	title: { type: String, required: true },
	totalPrice: { type: Number, required: true },
	totalPriceString: { type: String },
	totalTitle: { type: String, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { convertToCurrency } = useUtils();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonList), {
    class: _normalizeClass(props.class)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([props.textClass, "flex px-6 py-4 items-center"])
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(props.title), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(props.borderClass)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.items as ItemsDetailsItem[], (item, idx) => {
          return (_openBlock(), _createBlock(_unref(IonItem), {
            key: idx,
            class: _normalizeClass([props.textClassTwo, "font-sourcesanspro-semibold ion-no-padding"]),
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  (item.quantity && item.hideQuantity)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.quantity) + " x ", 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.title?.toLowerCase()), 1),
                  (item.price)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("span", {
                          class: _normalizeClass([{ 'line-through': !!item.discount }])
                        }, " (" + _toDisplayString(_unref(convertToCurrency)(item.price)) + ") ", 3)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (!item.hidePrice)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                      _createTextVNode(_toDisplayString(item.totalPriceCurrency) + " ", 1),
                      _createElementVNode("span", {
                        innerHTML: item.currency ?? __props.currency
                      }, null, 8, _hoisted_7),
                      _createTextVNode(" " + _toDisplayString(typeof item.totalPrice === 'number' ? `${_unref(convertToCurrency)(item.totalPrice)}` : ''), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["class"]))
        }), 128))
      ], 2),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", {
          class: _normalizeClass([props.textClass, "font-sourcesanspro-semibold text-l2"])
        }, _toDisplayString(props.totalTitle), 3),
        (!props.totalPriceString)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass([props.textClass, "font-sourcesanspro-semibold text-l2"])
            }, " €" + _toDisplayString(_unref(convertToCurrency)(props.totalPrice)), 3))
          : _createCommentVNode("", true),
        (props.totalPriceString)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass([props.textClass, "font-sourcesanspro-semibold text-l2"])
            }, _toDisplayString(props.totalPriceString), 3))
          : _createCommentVNode("", true)
      ]),
      (props.message && props.message.text)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(props.message.title), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(props.message.text), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mt-6 mb-2 font-sourcesanspro-regular text-base2 text-white" }
const _hoisted_2 = { class: "flex flex-col mb-2" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-base2 text-white" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex p-2 items-center" }
const _hoisted_7 = { class: "flex items-center justify-center" }
const _hoisted_8 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_9 = { class: "flex flex-col justify-center px-[34px]" }
const _hoisted_10 = { class: "flex flex-col items-center justify-center mb-6" }
const _hoisted_11 = { class: "font-sourcesanspro-semibold text-m2" }
const _hoisted_12 = {
  key: 0,
  class: "font-sourcesanspro-regular text-l2 text-white/60"
}
const _hoisted_13 = {
  key: 1,
  class: "font-sourcesanspro-regular text-l2 text-white/60"
}
const _hoisted_14 = {
  key: 0,
  class: "flex items-center justify-center mb-4"
}
const _hoisted_15 = {
  key: 1,
  class: "flex justify-center"
}
const _hoisted_16 = { class: "grid grid-cols-2 gap-4 w-[326px]" }
const _hoisted_17 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "font-sourcesanspro-semibold text-m2 text-center capitalize" }
const _hoisted_20 = { class: "fixed button-container mb-[34px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full" }
const _hoisted_21 = { class: "relative w-full h-full" }
const _hoisted_22 = { class: "flex w-full justify-between items-center font-sourcesanspro-semibold text-m2" }

import { onMounted, onBeforeUnmount, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonInput, IonSpinner } from '@ionic/vue';
import { bagHandleOutline, chevronBackOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useSelfOrdering from '@/modules/self-ordering/components/useSelfOrdering';
import useTableSpotLocation from '@/modules/self-ordering/composables/useTableSpotLocation';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SelfOrderingPage',
  setup(__props) {

const { t } = useI18n();
const locationSearch = ref('');
const route = useRoute();
const selfOrderingSpotId = route.params.id.toString();
const {
	clearLocationAndContinue,
	closeLocationModal,
	getProductCategories,
	getTableSpotByQRCode,
	locationModalOpen,
	tableSpot,
} = useSelfOrdering(selfOrderingSpotId);
const { getLocation, saveLocation } = useTableSpotLocation();
const { isFetchingProductCategories, tableSpotProductCategories } = getProductCategories(selfOrderingSpotId);
const { getCartSize } = useCart();
const { processCheckout } = useCheckout();

// selectLocation
const selectLocation = (locationSelected: string) => {
	saveLocation(locationSelected);
	locationModalOpen.value = false;
};

onMounted(async () => {
	await getTableSpotByQRCode();
});

onBeforeUnmount(() => {
	// Close modal
	document.getElementsByTagName('ion-modal')[0]?.dismiss();
});

// clearSearch
const clearSearch = () => {
	locationSearch.value = '';
};

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(ModalOne, {
        title: _unref(t)('self_ordering.what_is_your_location'),
        "is-open": _unref(locationModalOpen)
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('self_ordering.enter_location')), 1),
          _createVNode(_unref(IonInput), {
            modelValue: locationSearch.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((locationSearch).value = $event)),
            class: "rounded-full bg-red font-sourcesanspro-semibold text-base2 text-white location-searchbar bg-black/10",
            shape: "round",
            placeholder: `${_unref(t)('self_ordering.enter_manual_location_type')} ${_unref(tableSpot)?.manual_location_type}`,
            debounce: 500,
            onIonCancel: _cache[1] || (_cache[1] = ($event: any) => (clearSearch()))
          }, null, 8, ["modelValue", "placeholder"]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-4" }, null, -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('self_ordering.suggestions')), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableSpot)?.manual_location_values
							.split(',')
							.filter((location) =>
								location.toLowerCase().includes(locationSearch.value.toLowerCase()),
							), (location, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                key: idx,
                class: _normalizeClass(["flex flex-col items-start justify-center font-sourcesanspro-regular text-m2 text-white cursor-pointer", [
							{
								'bg-[#514f5a]': _unref(getLocation)() === location,
							},
						]]),
                onClick: ($event: any) => (selectLocation(location))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("div", null, null, 512), [
                    [_directive_dompurify_html, 
									location.replaceAll(
										locationSearch.value,
										`<span class='text-white'><b>${locationSearch.value}</b></span>`,
									)
								]
                  ])
                ])
              ], 10, _hoisted_5))
            }), 128))
          ]),
          _createVNode(_unref(IonButton), {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (selectLocation(locationSearch.value))),
            disabled: !_unref(tableSpot)?.manual_location_values.split(',').includes(locationSearch.value),
            expand: "full",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('self_ordering.continue')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        actions: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(closeLocationModal)()))
              }, null, 8, ["icon"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["title", "is-open"]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(TopBar, null, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(clearLocationAndContinue)()))
            }, [
              _createVNode(_unref(RouterLink), {
                to: "/dashboard",
                class: "flex items-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("h2", _hoisted_11, _toDisplayString(_unref(t)('self_ordering.page_title')), 1),
          (_unref(tableSpot) && _unref(tableSpot).manual_location)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_unref(getLocation)()), 1))
            : _createCommentVNode("", true),
          (_unref(tableSpot) && !_unref(tableSpot).manual_location)
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_unref(tableSpot)?.friendly_id), 1))
            : _createCommentVNode("", true)
        ]),
        (_unref(isFetchingProductCategories))
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (_unref(tableSpotProductCategories))
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableSpotProductCategories), (category, idx) => {
                  return (_openBlock(), _createBlock(_unref(RouterLink), {
                    class: "flex items-center justify-center",
                    key: idx,
                    to: `/self-ordering/cart/${category.id}`
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_17, [
                        (category.image_url)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: category.image_url,
                              class: "w-[56px] h-[56px] invert mb-4"
                            }, null, 8, _hoisted_18))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_19, _toDisplayString(category.name.toLowerCase()), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_unref(IonButton), {
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (
						_unref(processCheckout)(_unref(TransactionType).SELF_ORDER, {
							tableSpot: _unref(tableSpot),
							location: _unref(tableSpot).location ? _unref(tableSpot).location : _unref(tableSpot).manual_location_type,
						})
					), ["prevent"])),
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_22, [
                _createTextVNode(_toDisplayString(_unref(getCartSize)() > 0
								? `${_unref(getCartSize)()} ${_unref(getCartSize)() === 1 ? _unref(t)('self_ordering.item') : _unref(t)('self_ordering.items')}`
								: _unref(t)('self_ordering.empty')) + " ", 1),
                _createVNode(_unref(IonIcon), {
                  icon: _unref(bagHandleOutline),
                  class: "text-dark-blue",
                  slot: "end"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center mb-4"
}
const _hoisted_3 = {
  key: 1,
  class: "flex justify-center mb-4"
}
const _hoisted_4 = {
  key: 2,
  class: "flex justify-center mb-4"
}

import { Capacitor } from '@capacitor/core';
import { isPlatform, loadingController } from '@ionic/vue';
import { logoGoogle, logoApple, logoFacebook } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { SocialProvider } from '@/shared/interfaces/user';
import CustomButton from '@/shared/components/CustomButton.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useTenant from '@/shared/composables/useTenant';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SocialLoginButtons',
  emits: ['onLoginSuccess', 'onOAuthError'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, tenantStyles, anySocialLoginEnabled } = getTenantConfigAndStyles();
const emit = __emit;

// oAuthLogin
const { oAuthLogin } = useAuth();
const login = async (provider: SocialProvider) => {
	if (tenantStyles.value) {
		const loading = await loadingController.create({
			message: t('general.please_wait'),
		});
		await loading.present();
		try {
			const user = await oAuthLogin(provider, tenantStyles.value);
			if (user.value.token) {
				emit('onLoginSuccess');
			}
			await loading.dismiss();
		} catch (e) {
			await loading.dismiss();
		}
	}
};

return (_ctx: any,_cache: any) => {
  return (_unref(tenantConfig) && _unref(tenantStyles))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(tenantConfig)!.enable_apple_login && _unref(Capacitor).isNativePlatform() && _unref(isPlatform)('ios'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(CustomButton, {
                onOnClick: _cache[0] || (_cache[0] = ($event: any) => (login('apple'))),
                text: "auth.continueApple",
                iconLeft: _unref(logoApple)
              }, null, 8, ["iconLeft"])
            ]))
          : _createCommentVNode("", true),
        (_unref(tenantConfig)!.enable_facebook_login)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(CustomButton, {
                onOnClick: _cache[1] || (_cache[1] = ($event: any) => (login('facebook'))),
                text: "auth.continueFacebook",
                iconLeft: _unref(logoFacebook)
              }, null, 8, ["iconLeft"])
            ]))
          : _createCommentVNode("", true),
        (_unref(tenantConfig)!.enable_google_login)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(CustomButton, {
                onOnClick: _cache[2] || (_cache[2] = ($event: any) => (login('google'))),
                text: "auth.continueGoogle",
                iconLeft: _unref(logoGoogle)
              }, null, 8, ["iconLeft"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-4 px-4 py-2 h-[64px] backdrop-blur-xl bg-white/10 border border-white/10 rounded-lg" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex items-center justify-center mr-2" }
const _hoisted_4 = { class: "flex flex-col w-[173px]" }
const _hoisted_5 = { class: "font-sourcesanspro-semibold text-base2" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-xs2 font-color-text-50" }
const _hoisted_7 = { class: "font-sourcesanspro-semibold text-lg24" }

import { IonIcon } from '@ionic/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemStandalone',
  props: {
	icon: { type: String, required: true },
	price: { type: String, required: true },
	subtitle: { type: String, required: true },
	title: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(IonIcon), {
          icon: props.icon,
          class: "w-[22px] h-[22px] p-2 border border-white/10 rounded-full text-white"
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(props.title), 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString(props.subtitle), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(props.price), 1)
  ]))
}
}

})
<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import TopBar from '@/shared/components/TopBar.vue';
import searchUser from '@/modules/guest/composables/useGuestUser';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import GuestWalletBox from '../components/GuestWalletBox.vue';

const { t } = useI18n();
const assetStore = useAssetsStore();

const route = useRoute();
const loginToken = route.params.id.toString();

const { guestUserProfile, isSuccessGuestProfile, userMutate } = searchUser();

onMounted(async () => {
	userMutate({ login_token: loginToken });
});
</script>

<template>
	<ion-content>
		<TopBar class="px-[34px]">
			<template #left>
				<div class="flex items-center justify-start cursor-pointer"></div>
			</template>
			<template #center>
				<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
					{{ t('guest_top_up.title') }}
				</div>
			</template>
		</TopBar>

		<div>
			<div class="px-[34px] icon flex justify-center mb-4">
				<img :src="assetStore.logoUrl" class="max-w-[70%]" />
			</div>
			<GuestWalletBox
				:user-profile="guestUserProfile"
				:login-token="loginToken"
				:is-success-profile="isSuccessGuestProfile"></GuestWalletBox>
		</div>
	</ion-content>
</template>

<style scoped></style>

import { Capacitor } from '@capacitor/core';
import { AppLauncher, OpenURLResult } from '@capacitor/app-launcher';
import Compressor from 'compressorjs';
import { UserProfile } from '../interfaces/user';
import rgbHex from 'rgb-hex';
import hexRgb from 'hex-rgb';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { GroupedTransactions, OverviewTransaction, Transaction } from '../interfaces/wallet';
import { anykrowdApi } from '@/api/anykrowdApi';

// calculateShade
const calculateShade = (rgba: string, shadeFactor = 0.88): string => {
	const rgbaSplitted = rgba.split(',');

	const shaded = rgbaSplitted.map((color, index) => {
		if (index < 3) {
			return (Number(color) * shadeFactor).toFixed();
		}
		return color;
	});
	return shaded.toString();
};

const activateMagicLink = async (token: string): Promise<{ token: string; redirect_url: string }> => {
	const { data } = await anykrowdApi.post(`${window.apiUrl}/magic-link/${token}`, {}, false);
	return data;
};

// calculateTint
const calculateTint = (rgba: string): string => {
	const tintFactor = 0.1;
	const rgbaSplitted = rgba.split(',');

	const tinted = rgbaSplitted.map((color, index) => {
		if (index < 3) {
			return (Number(color) + (255 - Number(color)) * tintFactor).toFixed();
		}
		return color;
	});
	return tinted.toString();
};

// compressImage
const compressImage = (file: Blob, options?: Compressor.Options): Promise<Blob> => {
	return new Promise((resolve) => {
		if (Capacitor.isNativePlatform()) {
			resolve(file);
			return;
		}

		new Compressor(file, {
			quality: options?.quality || 0.6,
			...options,
			// The compression process is asynchronous,
			// which means you have to access the `result` in the `success` hook function.
			success(result) {
				resolve(result);
			},
			error(err) {
				console.log(err.message);
			},
		});
	});
};

// convertBase64ToBlob
const convertBase64ToBlob = (Base64Image: string) => {
	// split into two parts
	const parts = Base64Image.split(';base64,');
	// hold the content type
	const imageType = parts[0].split(':')[1];
	// decode base64 string
	const decodedData = window.atob(parts[1]);
	// create unit8array of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);
	// insert all character code into uint8array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}
	// return blob image after conversion
	return new Blob([uInt8Array], { type: imageType });
};

// convertBlobToBase64
const convertBlobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, _) => {
		const fileReader = new FileReader();
		// eslint-disable-next-line @typescript-eslint/dot-notation
		const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
		const reader = zoneOriginalInstance || fileReader;

		reader.onloadend = () => resolve(reader.result.toString());
		reader.readAsDataURL(blob);
	});
};

// Convert value to currency
const convertToCurrency = (valueToConvert: number, locale = 'en-BE') => {
	return Intl.NumberFormat(locale, {
		style: 'decimal',
		minimumFractionDigits: 2,
	}).format(valueToConvert);
};

const exchange = (amount: number, exchangeRate: number, toFiat = false): number => {
	if (toFiat) {
		return amount / exchangeRate;
	}

	return amount * exchangeRate;
};

// dataURLtoBlob
const dataURLtoBlob = (dataurl: string) => {
	const arr = dataurl.split(',');
	const mime = arr[0].match(/:(.*?);/)![1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
};

// getApiErrorString
const getApiErrorString = (
	error: any,
):
	| {
			error: string;
			errorCode?: string | null;
			status?: string | null;
	  }
	| string[] => {
	if (error.error?.error) {
		if (error.error.error === 'payment_provider_unavailable') {
			return {
				error: 'error.payment_provider_unavailable',
				errorCode: error.error.error_code ? error.error.error_code : 'unknown',
			};
		} else if (error.error.error === 'refund_not_cancellable') {
			return {
				error: 'error.refund_not_cancellable',
				status: error.error.status ? error.error.status : 'unknown',
			};
		}

		return {
			error: `error.${error.error.error}`,
		};
	}

	if (error.error?.errors) {
		return Object.keys(error.error.errors).map((fieldName) => error.error.errors[fieldName]);
	}

	return {
		error: 'error.general',
	};
};

const getCurrentWindowLocationOrigin = (): string => {
	if (Capacitor.isNativePlatform()) {
		const domain = new URL(window.apiUrl).origin;

		return domain;
	}

	return new URL(location.href).origin;
};

// getDataURLFileSize
const getDataURLFileSize = (dataUrl: string) => {
	const head = 'data:image/png;base64,';
	const fileSize = Math.round(((dataUrl.length - head.length) * 3) / 4) / 1024 / 1024;
	return fileSize;
};

const paymentMethodCountries = [
	{ value: 'BE', label: '🇧🇪 Belgium', flag: '🇧🇪' },
	{ value: 'NL', label: '🇳🇱 Netherlands', flag: '🇳🇱' },
	{ value: 'LU', label: '🇱🇺 Luxembourg', flag: '🇱🇺' },
	{ value: 'DE', label: '🇩🇪 Germany', flag: '🇩🇪' },
	{ value: 'FR', label: '🇫🇷 France', flag: '🇫🇷' },
	{ value: 'AT', label: '🇦🇹 Austria', flag: '🇦🇹' },
	{ value: 'DK', label: '🇩🇰 Denmark', flag: '🇩🇰' },
	{ value: 'SE', label: '🇸🇪 Sweden', flag: '🇸🇪' },
	{ value: 'FI', label: '🇫🇮 Finland', flag: '🇫🇮' },
	{ value: 'NO', label: '🇳🇴 Norway', flag: '🇳🇴' },
	{ value: 'CH', label: '🇨🇭 Switzerland', flag: '🇨🇭' },
	{ value: 'GB', label: '🇬🇧 United Kingdom', flag: '🇬🇧' },
	{ value: 'IE', label: '🇮🇪 Ireland', flag: '🇮🇪' },
	{ value: 'PT', label: '🇵🇹 Portugal', flag: '🇵🇹' },
	{ value: 'ES', label: '🇪🇸 Spain', flag: '🇪🇸' },
	{ value: 'IT', label: '🇮🇹 Italy', flag: '🇮🇹' },
	{ value: 'GR', label: '🇬🇷 Greece', flag: '🇬🇷' },
	{ value: 'PL', label: '🇵🇱 Poland', flag: '🇵🇱' },
	{ value: 'CZ', label: '🇨🇿 Czech Republic', flag: '🇨🇿' },
	{ value: 'SK', label: '🇸🇰 Slovakia', flag: '🇸🇰' },
	{ value: 'HU', label: '🇭🇺 Hungary', flag: '🇭🇺' },
	{ value: 'SI', label: '🇸🇮 Slovenia', flag: '🇸🇮' },
	{ value: 'HR', label: '🇭🇷 Croatia', flag: '🇭🇷' },
	{ value: 'RS', label: '🇷🇸 Serbia', flag: '🇷🇸' },
	{ value: 'BG', label: '🇧🇬 Bulgaria', flag: '🇧🇬' },
	{ value: 'RO', label: '🇷🇴 Romania', flag: '🇷🇴' },
	{ value: 'LT', label: '🇱🇹 Lithuania', flag: '🇱🇹' },
	{ value: 'LV', label: '🇱🇻 Latvia', flag: '🇱🇻' },
	{ value: 'EE', label: '🇪🇪 Estonia', flag: '🇪🇪' },
	{ value: 'MT', label: '🇲🇹 Malta', flag: '🇲🇹' },
	{ value: 'MA', label: '🇲🇦 Morocco', flag: '🇲🇦' },
];

// isOffline
const isOffline = (): boolean => {
	return !navigator.onLine;
};

// openURL
const openURL = async (
	url: string,
	options?: { target?: '_self' | '_blank' | '_parent' | '_top' },
): Promise<OpenURLResult | Window | undefined> => {
	if (Capacitor.isNativePlatform()) {
		return await AppLauncher.openUrl({ url });
	}

	if (options?.target === '_blank') {
		window.open(url, options.target);
	}

	window.location.href = url;
};

// dragToScroll
const dragToScroll = (elementId: string) => {
	const scrollContainer = document.getElementById(elementId);
	let isMouseDown = false;
	let startX: number;
	let scrollLeft: number;

	scrollContainer!.addEventListener('mousedown', (e) => {
		isMouseDown = true;
		startX = e.pageX - scrollContainer!.offsetLeft;
		scrollLeft = scrollContainer!.scrollLeft;
	});

	scrollContainer!.addEventListener('mouseleave', () => {
		isMouseDown = false;
	});

	scrollContainer!.addEventListener('mouseup', () => {
		isMouseDown = false;
	});

	scrollContainer!.addEventListener('mousemove', (e) => {
		if (!isMouseDown) return;
		e.preventDefault();
		const x = e.pageX - scrollContainer!.offsetLeft;
		const walk = x - startX;
		scrollContainer!.scrollLeft = scrollLeft - walk;
	});
};

// replaceTopUpGeneralMessageVariables
const replaceTopUpGeneralMessageVariables = (message: string, userProfile: UserProfile) => {
	if (typeof message !== 'string') {
		return message;
	}

	message = message.replace(/:userFirstName/g, userProfile.firstname);
	message = message.replace(/:userLastName/g, userProfile.lastname);
	message = message.replace(/:userFullName/g, userProfile.full_name);
	message = message.replace(/:userEmail/g, userProfile.email);

	return message;
};

const setColors = () => {
	// Primary color
	// Hex
	const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
	const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString()));
	const primaryTint = '#' + rgbHex(calculateTint(primaryRgb.toString()));
	document.documentElement.style.setProperty('--ion-color-primary', window.primary_color);
	primaryRgb.splice(primaryRgb.length - 1, 1);
	// Rgb
	document.documentElement.style.setProperty('--ion-color-primary-rgb', primaryRgb.toString());
	// Shade
	document.documentElement.style.setProperty('--ion-color-primary-shade', primaryShade);
	// Tint
	document.documentElement.style.setProperty('--ion-color-primary-tint', primaryTint);

	// Secondary color
	const secondaryRgb = hexRgb(window.secondary_color, { format: 'array' });
	const secondaryShade = rgbHex(calculateShade(secondaryRgb.toString()));
	const secondaryTint = rgbHex(calculateTint(secondaryRgb.toString()));
	// Hex
	document.documentElement.style.setProperty('--ion-color-secondary', window.secondary_color);
	// Rgb
	secondaryRgb.splice(primaryRgb.length - 1, 1);
	document.documentElement.style.setProperty('--ion-color-secondary-rgb', secondaryRgb.toString());
	// Shade
	document.documentElement.style.setProperty('--ion-color-secondary-shade', secondaryShade);
	// Tint
	document.documentElement.style.setProperty('--ion-color-secondary-tint', secondaryTint);

	// Tertiary color
	const tertiaryRgb = hexRgb(window.tertiary_color, { format: 'array' }).toString();
	const tertiaryShade = rgbHex(calculateShade(tertiaryRgb.toString()));
	const tertiaryTint = rgbHex(calculateTint(tertiaryRgb.toString()));
	// Hex
	document.documentElement.style.setProperty('--ion-color-tertiary', window.tertiary_color);
	// Rgb
	secondaryRgb.splice(tertiaryShade.length - 1, 1);
	document.documentElement.style.setProperty('--ion-color-tertiary-rgb', tertiaryShade.toString());
	// Shade
	document.documentElement.style.setProperty('--ion-color-tertiary-shade', tertiaryShade);
	// Tint
	document.documentElement.style.setProperty('--ion-color-tertiary-tint', tertiaryTint);
};

const transformOembedToIframe = (content: string) => {
	// Replace content
	return content
		.replaceAll('<oembed url=', '<iframe src=')
		.replaceAll('</oembed>', '</iframe>')
		.replaceAll('https://www.youtube.com/watch?v=', 'https://youtube.com/embed/')
		.replaceAll(
			'></frame>',
			' frameborder="0"; scrolling="no"; allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
		);
};

const groupAndSumTransactionsByCurrency = (
	transactions: OverviewTransaction[] | null | undefined,
): GroupedTransactions => {
	if (!Array.isArray(transactions)) {
		return {};
	}

	return transactions.reduce((acc, transaction) => {
		const { amount, currency } = transaction;

		const amountNumber = parseFloat(amount);

		if (currency) {
			const currencyId = currency.id;

			if (!acc[currencyId]) {
				acc[currencyId] = {
					prefix: currency.prefix,
					name: currency.name,
					transactions: [],
					totalAmount: 0,
					type: transaction.type,
					reference: transaction.reference,
					direction: transaction.direction,
				};
			}

			acc[currencyId].transactions.push(transaction);
			acc[currencyId].totalAmount += amountNumber;
		}

		return acc;
	}, {} as GroupedTransactions);
};

type T = ReturnType<typeof useI18n>['t'];
const toRelativeTime = (t: T, date: number) => {
	if (DateTime.fromMillis(date).diffNow().as('seconds') > -59) return t('common.just_now');
	return DateTime.fromMillis(date).toRelative();
};

const useUtils = () => {
	return {
		activateMagicLink,
		calculateShade,
		calculateTint,
		compressImage,
		convertBase64ToBlob,
		convertBlobToBase64,
		convertToCurrency,
		dataURLtoBlob,
		dragToScroll,
		exchange,
		groupAndSumTransactionsByCurrency,
		getApiErrorString,
		getCurrentWindowLocationOrigin,
		getDataURLFileSize,
		isOffline,
		openURL,
		paymentMethodCountries,
		replaceTopUpGeneralMessageVariables,
		setColors,
		transformOembedToIframe,
		toRelativeTime,
	};
};

export default useUtils;

import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "mr-1 leading-none" }

import { PropType, ref } from 'vue';
import type { HorizontalTimePickerItem } from '@/shared/interfaces/horizontalTimePicker';


export default /*@__PURE__*/_defineComponent({
  __name: 'HorizontalTimePicker',
  props: {
	items: {
		type: Array as PropType<HorizontalTimePickerItem[]>,
		default: function () {
			return [];
		},
	},
},
  setup(__props) {

const props = __props;

const activeItem = ref(-1);

const getClass = (enabled: boolean, index: number) => {
	return {
		'text-white/20': !enabled && activeItem.value !== index,
		'text-white': enabled && activeItem.value !== index,
		'text-dark-blue': activeItem.value === index,
	};
};

const getContainerClass = (enabled: boolean, index: number) => {
	return {
		'ml-[34px]': index === 0,
		'border-white/20': !enabled && activeItem.value !== index,
		'border-white/50': enabled && activeItem.value !== index,
		'bg-white': activeItem.value === index,
		'cursor-pointer': enabled,
	};
};

const setActive = (enabled: boolean, index: number) => {
	if (enabled) {
		activeItem.value = index;
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.hour,
      class: _normalizeClass([getContainerClass(item.enabled, index), "flex flex-col justify-center items-center mr-[12px] w-[99px] h-[44px] rounded-full border"]),
      onClick: ($event: any) => (setActive(item.enabled, index))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass([getClass(item.enabled, index), "font-sourcesanspro-semibold text-l2"])
          }, _toDisplayString(item.hour), 3)
        ]),
        _createElementVNode("span", {
          class: _normalizeClass([getClass(item.enabled, index), "font-sourcesanspro-regular text-sm2 leading-none"])
        }, _toDisplayString(item.period), 3)
      ])
    ], 10, _hoisted_1))
  }), 128))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center px-[34px] pt-16" }
const _hoisted_2 = { class: "flex justify-between w-full" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-4 w-full" }
const _hoisted_4 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "flex flex-col items-center justify-center w-[155px] h-[155px] rounded-lg backdrop-blur-3xl bg-white/10" }
const _hoisted_9 = ["src"]

import { ref } from 'vue';
import { RouterLink } from 'vue-router';
import { IonContent, IonFooter, IonIcon } from '@ionic/vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'QuizPage',
  setup(__props) {

const showSearch = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-[20px]" }, null, -1)),
            _createElementVNode("div", null, [
              _createVNode(_unref(IonIcon), {
                icon: require('@/statics/svg/dashboard/anykrowd-logo.svg'),
                class: "w-[100px] h-[100px]"
              }, null, 8, ["icon"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-[20px]" }, null, -1))
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col items-center justify-center mb-6" }, [
            _createElementVNode("span", { class: "font-sourcesanspro-regular text-base text-white/50" }, "Saturday, December 22th"),
            _createElementVNode("h2", { class: "font-sourcesanspro-semibold text-l2" }, "My quizzes")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(RouterLink), { to: "/quiz/questions" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: require('@/statics/svg/quiz/quiz.svg'),
                    class: "w-[56px] h-[56px]"
                  }, null, 8, _hoisted_5),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-m2" }, "Quiz Name 1", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(RouterLink), { to: "/quiz/questions" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: require('@/statics/svg/quiz/quiz.svg'),
                    class: "w-[56px] h-[56px]"
                  }, null, 8, _hoisted_7),
                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-m2" }, "Opening Feedback", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(RouterLink), { to: "/quiz/questions" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("img", {
                    src: require('@/statics/svg/quiz/quiz.svg'),
                    class: "w-[56px] h-[56px]"
                  }, null, 8, _hoisted_9),
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-sourcesanspro-semibold text-m2" }, "Quiz Name 2", -1))
                ])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "quiz" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full h-full items-center justify-center"
}
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col w-full h-full justify-between"
}
const _hoisted_3 = { class: "flex flex-col items-center justify-center" }
const _hoisted_4 = {
  key: 0,
  class: "font-sourcesanspro-semibold text-[40px] mb-4"
}
const _hoisted_5 = { class: "font-sourcesanspro-regular text-m2" }
const _hoisted_6 = {
  key: 0,
  class: "flex mx-[34px] p-4 mb-2 bg-white/10 rounded-lg"
}
const _hoisted_7 = { class: "flex flex-col w-full px-[34px] align-center mb-[34px]" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "flex px-6 py-4 items-center text-dark-blue" }
const _hoisted_10 = { class: "font-sourcesanspro-semibold text-l2" }
const _hoisted_11 = { class: "border-t border-t-slate-200" }
const _hoisted_12 = { class: "flex px-6 py-2 w-full justify-between" }
const _hoisted_13 = { class: "text-base" }
const _hoisted_14 = { class: "flex w-full items-center justify-between" }
const _hoisted_15 = { class: "flex flex-col items-center justify-center" }
const _hoisted_16 = { class: "mb-4 font-sourcesanspro-semibold text-lg" }
const _hoisted_17 = { class: "w-[337px] text-center font-sourcesanspro-regular text-base2 leading-[26px]" }

import { IonButton, IonContent, IonIcon, IonSpinner, IonItem, IonList } from '@ionic/vue';
import { arrowForwardOutline, checkmarkCircle, close } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import ItemsDetails from '@/shared/components/ui/ItemsDetails.vue';
import TopAlert from '@/shared/components/ui/TopAlert.vue';
import useTenant from '@/shared/composables/useTenant';
import useUtils from '@/shared/composables/useUtils';
import useTransaction from '@/shared/composables/useTransaction';
import { TransactionType, TransactionSubtype } from '@/enums/wallet.enum';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import { useSharedStore } from '@/stores/shared/sharedStore';
import useTopUp from '@/modules/top-up/composables/useTopUp';
import { onMounted, watch } from 'vue';
import useCurrencies from '@/shared/composables/useCurrencies';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionPage',
  setup(__props) {

const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { transaction, transactionStatus, goTo } = useTransaction();
const { replaceTopUpGeneralMessageVariables } = useUtils();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const sharedStore = useSharedStore();

// Apologies to posterity.
// the purpose of the abuse of this hook (with undefined) and the watcher is to make sure that, if we did not explicitly select a payment country before topping up,
// we do so now, so that the 'please select payment method' info block disappears from the topup screen
const { persistDefaultCountryIfNoneWasSet } = useTopUp(tenantConfig, undefined as any);
watch(transactionStatus, (value) => {
	if (value.success) {
		persistDefaultCountryIfNoneWasSet();
	}
});

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      (!_unref(transactionStatus) || !_unref(transactionStatus).finished)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_unref(IonSpinner), { name: "dots" })
          ]))
        : _createCommentVNode("", true),
      (_unref(transactionStatus) && _unref(transactionStatus).finished)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["flex w-full", [
				{
					'h-full': _unref(transactionStatus).success,
				},
			]])
          }, [
            (_unref(transactionStatus).success)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(TopAlert, {
                    class: _normalizeClass(["mb-2 pt-[75px] pb-12", [
						{
							'bg-anykrowd-green':
								!_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode ||
								(_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode && !_unref(sharedStore).shouldUsePayconiq),
							'payconiq':
								_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode && _unref(sharedStore).shouldUsePayconiq,
						},
					]])
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(checkmarkCircle),
                          class: "text-white text-[78px] mb-2"
                        }, null, 8, ["icon"]),
                        (_unref(transaction).subtype !== _unref(TransactionSubtype).PACKAGE)
                          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_unref(transaction).totalPriceString), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)(_unref(transactionStatus).subTitle)), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createElementVNode("div", null, [
                    (_unref(userProfile) && _unref(tenantConfig) && _unref(tenantConfig).top_up_general_message)
                      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, null, 512)), [
                          [_directive_dompurify_html, 
							_unref(replaceTopUpGeneralMessageVariables)(_unref(tenantConfig).top_up_general_message, _unref(userProfile))
						]
                        ])
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    (_unref(transaction).type !== _unref(TransactionType).WALLET_MERGE)
                      ? (_openBlock(), _createBlock(ItemsDetails, {
                          key: 0,
                          "border-class": "border-y border-y-slate-200",
                          class: "mb-4 bg-white rounded-xl",
                          "text-class": "text-dark-blue",
                          "text-class-two": "text-dark-blue/70",
                          title: _unref(t)('transaction.order_details'),
                          items: 
							_unref(transaction).items.map((item) => {
								return {
									itemId: item.itemId,
									title: item.description,
									price: item.unitPrice,
									quantity: item.quantity,
									hideQuantity: item.hideQuantity,
									hidePrice: item.hidePrice,
									totalPrice: item.itemPrice,
									discount: item.discount,
									currency: item.currency,
								};
							})
						,
                          "total-title": _unref(transaction).totalDescription,
                          "total-price": _unref(transactionStatus).totalPrice,
                          "total-price-string": _unref(transaction).totalPriceString || '0'
                        }, null, 8, ["title", "items", "total-title", "total-price", "total-price-string"]))
                      : _createCommentVNode("", true),
                    (_unref(transaction).type === _unref(TransactionType).WALLET_MERGE)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_unref(IonList), { class: "mb-4 bg-white rounded-xl" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(t)('transaction.wallet_merged')), 1)
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_unref(IonItem), {
                                  class: "font-sourcesanspro-regular ion-no-padding text-dark-blue/70",
                                  lines: "none"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createElementVNode("div", null, [
                                        _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(transaction).items[0].description), 1)
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(IonButton), {
                      class: "w-full hover:bg-white hover:rounded-full",
                      expand: "full",
                      size: "large",
                      shape: "round",
                      onClick: _unref(goTo)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, [
                          _createTextVNode(_toDisplayString(_unref(t)('transaction.continue')) + " ", 1),
                          _createVNode(_unref(IonIcon), {
                            icon: _unref(arrowForwardOutline),
                            class: "text-dark-blue mr-2"
                          }, null, 8, ["icon"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (!_unref(transactionStatus).success)
              ? (_openBlock(), _createBlock(TopAlert, {
                  key: 1,
                  class: "mb-8 pt-[48px] pb-12 bg-anykrowd-red"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "flex items-center justify-start cursor-pointer",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(goTo) && _unref(goTo)(...args)))
                    }, [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(close),
                        class: "text-xl text-white"
                      }, null, 8, ["icon"])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("h2", _hoisted_16, _toDisplayString(_unref(t)(_unref(transactionStatus).errorTitle)), 1),
                      _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)(_unref(transactionStatus).errorSubTitle)), 1)
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex p-4 items-center justify-between font-sourcesanspro-semibold text-m2 p-4" }

import { IonAccordionGroup, IonIcon } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionList',
  props: {
	class: { type: String },
	headerActionIcon: { type: String },
	title: { type: String, required: true },
},
  emits: ['onHeaderActionClick'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const headerActionClick = (event: MouseEvent) => {
	emit('onHeaderActionClick', event);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAccordionGroup), {
    class: _normalizeClass(["backdrop-blur-xl bg-white/10 rounded-lg", props.class])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(props.title) + " ", 1),
        (props.headerActionIcon)
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: props.headerActionIcon,
              class: "text-white cursor-pointer",
              onClick: headerActionClick
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "h-px bg-white/10 border-0" }, null, -1)),
      _renderSlot(_ctx.$slots, "items")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})
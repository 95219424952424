import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-col mt-12 mb-4 px-[34px] items-center" }
const _hoisted_3 = { class: "flex items-center justify-center mb-4 w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30" }
const _hoisted_4 = { class: "flex items-center mb-4 px-[34px]" }
const _hoisted_5 = { class: "flex w-[100%] mb-8 invisible-scroller overflow-x-auto" }
const _hoisted_6 = { class: "flex flex-1 pb-4" }
const _hoisted_7 = { class: "flex items-center mb-4 px-[34px]" }
const _hoisted_8 = { class: "flex w-[100%] mb-8 invisible-scroller overflow-x-auto" }
const _hoisted_9 = { class: "flex flex-1 pb-4" }
const _hoisted_10 = { class: "mb-8 px-[34px]" }
const _hoisted_11 = { class: "flex items-center mb-4" }
const _hoisted_12 = { class: "fixed button-container px-[34px] bottom-0 w-full" }
const _hoisted_13 = { class: "relative z-10 w-full h-full" }
const _hoisted_14 = { class: "absolute w-full bottom-0 mb-[34px]" }

import EventsItemMemberAdder from '@/modules/events/components/EventsItemMemberAdder.vue';
import router from '@/router';
import HorizontalDatePicker from '@/shared/components/ui/HorizontalDatePicker.vue';
import HorizontalTimePicker from '@/shared/components/ui/HorizontalTimePicker.vue';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import { calendarClearOutline, checkmarkOutline, closeOutline, peopleOutline, timeOutline } from 'ionicons/icons';

// Go back

export default /*@__PURE__*/_defineComponent({
  __name: 'BookingsReservationsBookPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push({ path: link, query: { callbackPage: 'bookings-reservations' } });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(IonIcon), {
              class: "text-[38px] text-white cursor-pointer",
              icon: _unref(closeOutline),
              onClick: goBack
            }, null, 8, ["icon"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(IonIcon), {
            class: "mr-2 text-[24px] text-white cursor-pointer",
            icon: _unref(calendarClearOutline)
          }, null, 8, ["icon"]),
          _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "font-sourcesanspro-semibold text-l2 inline" }, "Select Date", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(HorizontalDatePicker, { items: [
							{ day: '21', weekDay: 'Wed', month: 'Dec', enabled: false },
							{ day: '22', weekDay: 'Thu', month: 'Dec', enabled: true },
							{ day: '23', weekDay: 'Fri', month: 'Dec', enabled: true },
							{ day: '24', weekDay: 'Sat', month: 'Dec', enabled: true },
							{ day: '25', weekDay: 'Sun', month: 'Dec', enabled: true },
							{ day: '26', weekDay: 'Mon', month: 'Dec', enabled: true },
						] })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(IonIcon), {
            class: "mr-2 text-[24px] text-white cursor-pointer",
            icon: _unref(timeOutline)
          }, null, 8, ["icon"]),
          _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "font-sourcesanspro-semibold text-l2 inline" }, "Select Time", -1))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(HorizontalTimePicker, { items: [
							{ hour: '8:45', period: 'AM', enabled: false },
							{ hour: '9:00', period: 'AM', enabled: true },
							{ hour: '9:15', period: 'AM', enabled: true },
							{ hour: '9:30', period: 'AM', enabled: true },
						] })
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_unref(IonIcon), {
              class: "mr-2 text-[24px] text-white cursor-pointer",
              icon: _unref(peopleOutline)
            }, null, 8, ["icon"]),
            _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "font-sourcesanspro-semibold text-l2 inline" }, "Book Members", -1))
          ]),
          _createElementVNode("div", null, [
            _createVNode(EventsItemMemberAdder, {
              class: "w-full",
              icon: _unref(checkmarkOutline),
              subtitle: "regular",
              title: "Mario Gutze",
              member: "Mario Gutze"
            }, null, 8, ["icon"]),
            _createVNode(_unref(IonButton), {
              class: "w-[145px] h-[44px] ion-no-padding",
              color: "primary",
              fill: "outline",
              shape: "round"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base" }, "+ Add New Person", -1)
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_unref(IonButton), {
              class: "w-full",
              expand: "full",
              size: "large",
              shape: "round",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/transaction')))
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("div", { class: "font-sourcesanspro-semibold text-m2 text-center" }, "Pay & Book (€450)", -1)
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
    ]),
    _: 1
  }))
}
}

})
<script setup lang="ts">
import { PropType, ref, watchEffect } from 'vue';
import { IonContent, IonButton, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { addOutline, chevronBackOutline, chevronDownOutline, chevronForwardOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { UserProfile } from '@/shared/interfaces/user';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useTenant from '@/shared/composables/useTenant';
import { captureMessage } from '@/sentry';
import { Wallet } from '@/shared/interfaces/balance';

const props = defineProps({
	userProfile: { type: Object as PropType<UserProfile | null> },
	isSuccessProfile: { type: Boolean, default: false },
	loginToken: { type: String, default: '' },
});

const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const selectedWallet = ref<Wallet | undefined>();

watchEffect(() => {
	const wallets = props.userProfile?.wallets;

	if (wallets && wallets.length && wallets.length > 0) {
		if (!selectedWallet.value) {
			selectedWallet.value = wallets.find((wallet) => {
				return wallet.currency.default;
			});

			if (!selectedWallet.value && wallets.length > 0) {
				selectedWallet.value = wallets[0];
			}
		}
	}
});

const selectWallet = (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
};

const getWalletAtIndex = (index: number) => {
	return props.userProfile?.wallets[index];
};

const currentWalletIndex = () => {
	const index = props.userProfile?.wallets.findIndex((wallet) => wallet === selectedWallet.value);
	if (index === -1) {
		// dirty as f*** but it works
		return -100000;
	}
	return index ?? -100000;
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};
</script>

<template>
	<div>
		<div class="flex">
			<div class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="!!getPreviousWallet()"
					:icon="chevronBackOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectPreviousWallet()"></ion-icon>
			</div>
			<div
				class="flex w-full flex-col justify-between px-3 bg-white rounded-xl mb-2"
				:class="props.userProfile ? 'py-3' : 'pt-3'">
				<div class="flex items-center justify-between mb-4">
					<img
						:src="require('@/statics/svg/rfid/bracelet-with-bg.svg')"
						height="34"
						width="34"
						v-if="props.userProfile" />
					<div v-if="props.userProfile">
						<div class="flex justify-between mb-2">
							<div></div>
							<div v-if="selectedWallet">
								<div
									id="popover-button"
									class="flex items-center justify-center rounded-lg font-sourcesanspro-bold text-anykrowd-dark text-md cursor-pointer">
									<div class="mr-2">{{ selectedWallet.currency.prefix }}</div>
									<div class="mr-2">{{ selectedWallet?.currency.name }}</div>
									<ion-icon
										v-if="userProfile?.wallets && userProfile?.wallets?.length > 1"
										:icon="chevronDownOutline"
										class="text-anykrowd-dark text-md"></ion-icon>
								</div>
								<ion-popover trigger="popover-button" :dismiss-on-select="true">
									<ion-content>
										<ion-list>
											<ion-item
												v-for="wallet in userProfile?.wallets"
												class="flex items-center justify-center ion-no-padding ion-no-margin"
												:key="wallet.id"
												:button="true"
												@click="selectWallet(wallet)"
												:detail="false">
												<div class="flex flex-col px-3 py-2 font-sourcesanspro-regular">
													<div class="mb-1">
														<span>
															{{ wallet.currency.prefix }}
															{{ wallet.current_balance }}
															{{ wallet.currency.name }}
														</span>
													</div>
												</div>
											</ion-item>
										</ion-list>
									</ion-content>
								</ion-popover>
							</div>
						</div>
					</div>
					<div
						v-if="!props.userProfile"
						class="w-full flex justify-center font-sourcesanspro-bold text-anykrowd-dark text-md pt-4">
						{{ t('guest_top_up.not_found') }}
					</div>
				</div>
				<div class="flex items-center mb-4">
					<UserAvatar
						v-if="props.userProfile"
						:container-class="'h-[42px] mr-2'"
						:src="
							props.userProfile.profile_image_url
								? props.userProfile.profile_image_url
								: require('@/statics/img/no_profile_pic.jpg')
						"
						alt="User icon"
						:show-status="false" />
					<div class="flex flex-col">
						<span
							v-if="props.userProfile"
							class="font-sourcesanspro-black text-l2 font-color-dark leading-none">
							{{
								props.userProfile
									? `${props.userProfile.firstname} ${props.userProfile.lastname}`
									: `${t('rfid.guest.wallet')} #${selectedWallet?.id}`
							}}
						</span>
						<div
							v-if="userProfile && userProfile.email"
							class="font-sourcesanspro-regular text-base font-color-dark leading-none break-words">
							{{ userProfile.email }}
						</div>
					</div>
				</div>

				<div class="flex items-center justify-between">
					<div class="flex">
						<ion-spinner
							v-if="!isSuccessProfile && !tenantConfig"
							name="dots"
							class="spinner-black"></ion-spinner>
						<div v-if="selectedWallet && tenantConfig" class="flex items-baseline">
							<div class="mr-1">
								<span class="font-sourcesanspro-black text-lg3 font-color-dark">
									{{ selectedWallet?.currency.prefix }}{{ selectedWallet?.current_balance }}
								</span>
							</div>
							<span class="font-sourcesanspro-regular text-md font-color-dark">{{
								selectedWallet.currency.suffix
							}}</span>
						</div>
					</div>
					<div v-if="selectedWallet && !!selectedWallet!.currency.topupable" class="flex items-center">
						<ion-button
							class="w-[117px] h-[40px] black font-sourcesanspro-semibold text-sm2 ion-no-margin"
							shape="round"
							:routerLink="`/top-up/${selectedWallet?.id}?loginToken=${props.loginToken}`">
							<div class="mr-2"><ion-icon :icon="addOutline"></ion-icon></div>
							{{ t('rfid.guest.add') }}
						</ion-button>
					</div>
				</div>

				<div
					v-if="selectedWallet && selectedWallet.currency?.description"
					class="flex flex-col font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm mb-6 mt-4">
					{{ selectedWallet.currency?.description }}
				</div>
			</div>
			<div class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="!!getNextWallet()"
					:icon="chevronForwardOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectNextWallet()"></ion-icon>
			</div>
		</div>
	</div>
</template>

<style scoped></style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "px-[34px] w-full" }
const _hoisted_3 = { class: "flex items-center justify-start" }
const _hoisted_4 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "text-color-magenta text-base" }
const _hoisted_7 = { class: "relative mb-4" }
const _hoisted_8 = ["validation-schema"]
const _hoisted_9 = { class: "px-[34px] mb-[34px]" }
const _hoisted_10 = { class: "flex w-full items-center justify-center" }

import { watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { useSharedStore } from '@/stores/shared/sharedStore';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePasswordResetStepOnePage',
  setup(__props) {

const { t } = useI18n();
const sharedStore = useSharedStore();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const route = useRoute();
const email = route.query.email ? route.query.email.toString() : '';

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	email: Yup.string().matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email')).required(),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/auth/forgot-password',
	'post',
	'profile.password_reset.messages.check_email',
	'profile.password_reset.errors.error',
	5000,
	{
		checkError: true,
		error: 'This password reset user token is invalid.',
		errorTranslation: 'common.email_error',
	},
);

watch(isSuccess, async () => {
	await router.push('/dashboard');
});

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(TopBar, null, {
            left: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(IonButton), {
                  class: "ion-no-margin ion-no-padding",
                  fill: "clear",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ])
            ]),
            center: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('profile.password_reset.forgot_password')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('profile.password_reset.forgot_password_description')), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("form", {
              id: "resetPasswordForm",
              "validation-schema": _unref(schema),
              onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
            }, [
              _createVNode(TextInput, {
                "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                name: "email",
                type: "text",
                placeholder: "Email",
                value: _unref(email),
                onInputChanged: _unref(onInputChange)
              }, null, 8, ["value", "onInputChanged"])
            ], 40, _hoisted_8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_unref(IonButton), {
            class: "w-full hover:bg-white hover:rounded-full",
            disabled: !_unref(isFormValid),
            expand: "full",
            form: "resetPasswordForm",
            size: "large",
            shape: "round",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('profile.password_reset.forgot_password_submit')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
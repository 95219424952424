import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["validation-schema"]

import vue from 'vue';
import { Form, useForm } from 'vee-validate';
import * as yup from 'yup';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'SmsPhoneHandler',
  props: {
	cellPhoneNumber: { type: String as () => string | null | undefined, default: null },
},
  emits: ['onCellPhoneNumberSent'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const emit = __emit;

// Schema
const validationSchema = yup.object({
	phoneNumber: yup.string().required(),
});

// useForm
const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

// On input change
const onInputChange = async (formValid: vue.ComputedRef<boolean>, phoneNumber: any) => {
	await validateField(phoneNumber.value);
};

// On submit
const onSubmit = handleSubmit(async (formData: any) => {
	emit('onCellPhoneNumberSent', formData.phoneNumber);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args))),
    class: "flex w-full",
    "validation-schema": _unref(validationSchema)
  }, [
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      name: "phoneNumber",
      type: "text",
      label: "Phone Number",
      placeholder: _ctx.$t('auth.sign_up_additional_page.step_six.phone'),
      value: props.cellPhoneNumber,
      onInputChanged: onInputChange
    }, null, 8, ["placeholder", "value"])
  ], 40, _hoisted_1))
}
}

})
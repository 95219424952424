import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["validation-schema"]
const _hoisted_2 = { class: "font-sourcesanspro-semibold text-base text-right" }

import vue, { watch } from 'vue';
import router from '@/router';
import * as yup from 'yup';
import { Form, useForm } from 'vee-validate';
import { mailOutline, lockClosedOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { useSharedStore } from '@/stores/shared/sharedStore';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SignInWithEmailForm',
  emits: ['onFormValidation'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emit = __emit;
const { loginUser, loginData, isSuccessLogin } = useAuth();
const sharedStore = useSharedStore();

// Schema
const validationSchema = yup.object({
	email: yup
		.string()
		.required()
		.label(t('auth.sign_in_email_page.email_label'))
		.matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email')),
	password: yup.string().min(6).label(t('auth.sign_in_email_page.password_label')).required(),
});

const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

// Send form
const onSubmit = handleSubmit(async (formData: any) => {
	await loginUser({
		userData: {
			...formData,
			rememberMe: true,
		},
		message: t('general.please_wait'),
	});
});

// Email login: Listen for change
watch(isSuccessLogin, () => {
	if (isSuccessLogin.value && loginData.value) {
		// Finally, redirect the user if we have a token
		router.push('/profile/additionaldata');
	}
});

// Validating form
const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	emit('onFormValidation', meta.value.valid);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("form", {
    id: "signInWithEmailForm",
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args))),
    "validation-schema": _unref(validationSchema)
  }, [
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      iconLeft: _unref(mailOutline),
      name: "email",
      type: "email",
      placeholder: _ctx.$t('auth.sign_in_email_page.email'),
      onInputChanged: onInputChange
    }, null, 8, ["iconLeft", "placeholder"]),
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      iconLeft: _unref(lockClosedOutline),
      name: "password",
      type: "password",
      placeholder: _ctx.$t('auth.sign_in_email_page.password'),
      onInputChanged: onInputChange
    }, null, 8, ["iconLeft", "placeholder"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/profile/password/reset/request" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_in_email_page.forgot_password')), 1)
        ]),
        _: 1
      })
    ])
  ], 40, _hoisted_1))
}
}

})
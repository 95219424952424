<script setup lang="ts">
import vue, { ref, watchEffect, onMounted } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { Form } from 'vee-validate';
import { IonButton, IonContent, IonIcon, IonList } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Address } from '@/shared/interfaces/wallet';
import { TransactionItem } from '@/shared/interfaces/transaction';
import { TransactionType } from '@/enums/wallet.enum';
import { useCheckout } from '@/shared/composables/useCheckout';
import hexRgb from 'hex-rgb';
import ItemsDetails from '@/shared/components/ui/ItemsDetails.vue';
import rgbHex from 'rgb-hex';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useTenant from '@/shared/composables/useTenant';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';

// Init
const { t } = useI18n();
const emit = defineEmits(['onFormValidation']);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const route = useRoute();
const transactionType = route.query.transactionType;
const checkStock = transactionType === TransactionType.SELF_ORDER ? true : false;
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { convertValutaSync } = useConvertValuta();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const buttonText = ref('');
const formData: any = {
	quantity: 0,
	total: [],
};

// Check user's wallet balance
const { getWallets } = useWallet();
const { refetchWalletsData, wallets } = getWallets();

const {
	items,
	totalItemCount,
	handleCheckout,
	handleCheckoutWithForm,
	totalItemsPrice,
	totalItemsPriceString,
	getTotalPricePerCurrency,
	billingAddressValidationSchema,
} = useCheckout();

const address: Address = {
	country: userProfile.value?.country ?? 'Belgium',
	city: userProfile.value?.city ?? '',
	address_street: userProfile.value?.address_street ?? '',
	address_number: userProfile.value?.address_number ?? '',
	street: userProfile.value?.address_street ?? '',
	number: userProfile.value?.address_number ?? '',
	postal_code: userProfile.value?.postal_code ?? '',
};

// Go back
const goBack = () => {
	router.back();
};

// Validating form
const onInputChange = (formValid: vue.ComputedRef<boolean>) => {
	emit('onFormValidation', formValid);
};

onMounted(async () => {
	await refetchWalletsData();
	const totalPricePerCurrency = getTotalPricePerCurrency();
	if (wallets && wallets.value) {
		const totalCurrenciesNotEnough: any = [];
		wallets.value.filter((wallet) => {
			const matchSubtotalWithCurrentWallet = totalPricePerCurrency.find((subtotal) => {
				return subtotal.currencyName === wallet.currency.name;
			});
			if (matchSubtotalWithCurrentWallet) {
				if (matchSubtotalWithCurrentWallet.total > +wallet.current_balance) {
					totalCurrenciesNotEnough.push({ subtotal: matchSubtotalWithCurrentWallet, wallet });
				}
			}
		});

		if (totalCurrenciesNotEnough.length === 1 && totalCurrenciesNotEnough[0].wallet.currency.topupable) {
			formData.quantity = totalPricePerCurrency.length;
			formData.total = totalPricePerCurrency.map((currency) => {
				return {
					currency_id: currency.currencyId,
					amount: currency.total,
				};
			});
			const currency = totalCurrenciesNotEnough[0];
			const totalToTopUp = Math.abs(currency.wallet.current_balance - currency.subtotal.total);

			buttonText.value = `${t('checkout.top_up')} ${currency.wallet.currency.prefix} ${totalToTopUp} & ${t('checkout.pay')}`;

			if (totalToTopUp > 0) {
				formData.top_up_amount = totalToTopUp;
			}
		} else if (totalCurrenciesNotEnough.length > 1) {
			buttonText.value = 'Not enough balance';
		} else {
			formData.quantity = totalPricePerCurrency.length;
			formData.total = totalPricePerCurrency.map((currency) => {
				return {
					currency_id: currency.currencyId,
					amount: currency.total,
				};
			});
			buttonText.value = t('checkout.pay_now');
		}
	}
});
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col justify-between px-[34px]">
			<div>
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start cursor-pointer" @click="goBack()">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</div>
					</template>

					<template #center>
						<div class="flex grow items-center justify-center w-full">
							<div class="mr-2">
								<span class="font-sourcesanspro-semibold text-l2">{{ t('checkout.title') }}</span>
							</div>
						</div>
					</template>
				</TopBar>

				<div class="flex flex-col items-center justify-center mb-8">
					<h1 class="font-sourcesanspro-semibold text-lg2">{{ t('checkout.title') }}</h1>
					<span class="font-sourcesanspro-regular text-base2 text-white/50">
						{{
							items?.length > 0
								? items?.length > 1
									? `${Number(totalItemCount)} ${t('checkout.items')}`
									: `${Number(totalItemCount)} ${t('checkout.item')}`
								: t('checkout.no_item')
						}}
					</span>
				</div>

				<div class="flex flex-col w-full align-center">
					<ItemsDetails
						v-if="items?.length > 0"
						border-class="border-y border-y-white/5"
						class="backdrop-blur-xl bg-anykrowd-gray/20 text-white rounded-xl mb-4"
						text-class="text-white"
						text-class-two="text-white/70"
						:title="t('checkout.order_details')"
						:items="
							items.map((item: TransactionItem) => {
								return {
									discount: item.discount,
									itemId: item.itemId,
									title: item.description,
									price: item.unitPrice,
									quantity: item.quantity,
									totalPrice: item.itemPrice,
									totalPriceCurrency: item.additionalInfo.product?.currency?.prefix,
								};
							})
						"
						:total-title="
							items?.length > 0
								? Number(totalItemCount) + ` ${t('checkout.items_total')}`
								: t('checkout.empty')
						"
						:total-price-string="totalItemsPriceString || '0'"
						:total-price="totalItemsPrice" />
				</div>
				<ion-list
					v-if="transactionType === TransactionType.SHOP_ORDER"
					class="backdrop-blur-xl bg-anykrowd-gray/20 text-white rounded-xl mb-4">
					<div class="flex px-6 py-4 items-center text-white">
						<span class="font-sourcesanspro-semibold text-l2">{{
							t('checkout.billing_address.title')
						}}</span>
					</div>
					<div class="border-y border-y-white/5 px-6">
						<form
							id="billingAddressForm"
							@submit="handleCheckoutWithForm"
							:validation-schema="billingAddressValidationSchema">
							<TextInput
								wrapper-class="flex flex-wrap justify-left item-center my-2 border border-solid focus-within:background-white-15"
								name="country"
								type="text"
								:value="address.country"
								:placeholder="$t('checkout.billing_address.country')"
								@inputChanged="onInputChange" />
							<TextInput
								wrapper-class="flex flex-wrap justify-left item-center my-2 border border-solid focus-within:background-white-15"
								name="city"
								type="text"
								:value="address.city"
								:placeholder="$t('checkout.billing_address.city')"
								@inputChanged="onInputChange" />
							<TextInput
								wrapper-class="flex flex-wrap justify-left item-center my-2 border border-solid focus-within:background-white-15"
								name="address_street"
								type="text"
								:value="address.address_street"
								:placeholder="$t('checkout.billing_address.street')"
								@inputChanged="onInputChange" />
							<TextInput
								wrapper-class="flex flex-wrap col-md-6 justify-left item-center my-2 border border-solid focus-within:background-white-15"
								name="address_number"
								type="text"
								:value="address.address_number"
								:placeholder="$t('checkout.billing_address.address_number')"
								@inputChanged="onInputChange" />
							<TextInput
								wrapper-class="flex flex-wrap col-md-6 justify-left item-center mb-3 mt-2 border border-solid focus-within:background-white-15"
								name="postal_code"
								type="text"
								:value="address.postal_code"
								:placeholder="$t('checkout.billing_address.postal_code')"
								@inputChanged="onInputChange" />
						</form>
					</div>
				</ion-list>

				<div class="mb-2" v-if="transactionType === TransactionType.PAYMENT">
					<p class="font-sourcesanspro-regular text-sm2 text-center text-white/50">
						{{ t('checkout.check') }}
					</p>
				</div>
			</div>

			<div class="mb-8">
				<ion-button
					v-if="transactionType === TransactionType.SHOP_ORDER"
					:disabled="totalItemsPrice === 0"
					class="w-full"
					expand="full"
					form="billingAddressForm"
					size="large"
					shape="round"
					type="submit">
					<div v-if="userWallet" class="flex w-full items-center justify-between">
						<div class="text-m2" v-if="+totalItemsPrice > +userWallet?.current_balance">
							{{ t('checkout.top_up') }}
							<span v-if="tenantConfig && tenantConfig.custom_valuta_enabled">
								{{ tenantConfig.custom_valuta.prefix }}
								{{
									convertValutaSync(
										tenantConfig,
										(+totalItemsPrice - +userWallet?.current_balance).toFixed(2),
										true,
									)
								}}
							</span>
							<span v-else>{{ `€${(+totalItemsPrice - +userWallet?.current_balance).toFixed(2)}` }}</span>
							& {{ t('checkout.pay') }}
						</div>
						<span class="text-m2" v-else>{{ t('checkout.pay_now') }}</span>
						<div>
							<span v-if="tenantConfig && tenantConfig.custom_valuta_enabled">
								{{ tenantConfig.custom_valuta.prefix }}
								{{ convertValutaSync(tenantConfig, (+totalItemsPrice).toFixed(2) || 0, true) }}
							</span>
							<span v-else>€{{ (+totalItemsPrice).toFixed(2) || 0 }}</span>
						</div>
					</div>
				</ion-button>

				<ion-button
					v-if="transactionType !== TransactionType.SHOP_ORDER"
					:disabled="buttonText === ''"
					class="w-full hover:rounded-full hover:bg-white"
					expand="full"
					size="large"
					shape="round"
					@click="handleCheckout(checkStock, formData)">
					<ion-spinner name="dots" v-if="buttonText === ''"></ion-spinner>
					<div v-else>{{ buttonText }}</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'TopAlert',
  props: {
	class: { type: String, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["px-[34px] rounded-b-[60px]", props.class])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = { class: "px-[34px]" }

import router from '@/router';
import { IonContent, IonFooter, IonIcon, IonSpinner } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';

import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import { useI18n } from 'vue-i18n';
import { ref, watchEffect } from 'vue';
import { WalletTransaction } from '@/shared/interfaces/wallet';
import useWallet from '@/modules/wallet/composables/useWallet';
import TransactionOverview from '@/modules/wallet/components/TransactionOverview.vue';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ShopHistoryPage',
  setup(__props) {

const { getWalletTransactions, getTransactionItemName } = useWallet();
const { isLoading, refetchGetWalletTransactions, removeGetWalletTransactionsResultsFromCache, walletTransactions } =
	getWalletTransactions();
const { t, te } = useI18n();
const mappedWalletTransactions = ref<any>([]);
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

// Refresh
const handleRefresh = async (event: any) => {
	removeGetWalletTransactionsResultsFromCache();
	mappedWalletTransactions.value = [];
	await refetchGetWalletTransactions();
	event.target.complete();
};

// Watch for walletTransactions then map the result
watchEffect(() => {
	if (walletTransactions && walletTransactions.value && walletTransactions.value.data.length) {
		mappedWalletTransactions.value = walletTransactions.value.data.map((transaction: WalletTransaction) => {
			return {
				id: transaction.id,
				title: t(getTransactionItemName(transaction)),
				subtitle: transaction.direction,
				iconClass: 'text-white',
				price: +transaction.amount,
				priceCurrency: '€',
				date: transaction.created_at,
				transactionType: transaction.direction,
			};
		});
	}
});
// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        (!mappedWalletTransactions.value)
          ? (_openBlock(), _createBlock(_unref(IonSpinner), {
              key: 0,
              name: "dots"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(TopBar, { class: "px-[34px]" }, {
                left: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "flex items-center justify-start cursor-pointer",
                    onClick: goBack
                  }, [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(chevronBackOutline),
                      class: "text-white"
                    }, null, 8, ["icon"])
                  ])
                ]),
                center: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('shop.pages.title.bought_history')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(AccordionList, { title: "Last transactions" }, {
                  items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mappedWalletTransactions.value, (item) => {
                      return (_openBlock(), _createBlock(AccordionListItem, {
                        icon: require('@/statics/svg/arrows-left-right.svg'),
                        iconClass: item.iconClass,
                        key: item.id,
                        "key-value": item.id,
                        price: item.price,
                        subtitle: item.subtitle,
                        title: item.title,
                        "transaction-type": item.transactionType
                      }, {
                        actions: _withCtx(() => [
                          (_unref(tenantConfig))
                            ? (_openBlock(), _createBlock(AccordionListItemAction, {
                                key: 0,
                                date: _unref(DateTime).fromISO(item.date).toFormat('dd LLLL yyyy • HH:mm'),
                                price: item.price,
                                priceCurrency: item.priceCurrency,
                                "transaction-type": item.transactionType,
                                "tenant-config": _unref(tenantConfig)
                              }, null, 8, ["date", "price", "priceCurrency", "transaction-type", "tenant-config"]))
                            : _createCommentVNode("", true)
                        ]),
                        content: _withCtx((slotProps) => [
                          (_unref(tenantConfig))
                            ? (_openBlock(), _createBlock(TransactionOverview, {
                                key: 0,
                                "transaction-id": item.id,
                                "should-load": slotProps.shouldLoad,
                                "tenant-config": _unref(tenantConfig)
                              }, null, 8, ["transaction-id", "should-load", "tenant-config"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["icon", "iconClass", "key-value", "price", "subtitle", "title", "transaction-type"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
            ]))
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "shop" })
      ]),
      _: 1
    })
  ], 64))
}
}

})
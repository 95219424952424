import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "grow" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-m2" }
const _hoisted_5 = { class: "font-sourcesanspro-regular text-sm2 text-white/50" }
const _hoisted_6 = { class: "flex items-center justify-center px-4 text-white" }
const _hoisted_7 = { class: "font-sourcesanspro-semibold text-sm2" }

import { IonAvatar, IonButton, IonItem } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'PersonItem',
  props: {
	image: { type: String, required: true },
	location: { type: String, required: true },
	name: { type: String, required: true },
},
  emits: ['onPersonSelected'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const { t } = useI18n();
const emit = __emit;

// selectPerson
const selectPerson = () => {
	emit('onPersonSelected');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonItem), {
    class: "flex justify-between ion-no-padding pb-2 mb-6 border-b border-b-white/5",
    lines: "none"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonAvatar), { slot: "start" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: props.image
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(props.name), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(props.location), 1)
        ])
      ]),
      _createVNode(_unref(IonButton), {
        class: "h-[40px]",
        shape: "round",
        fill: "outline",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectPerson()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('components.person_item.select')), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full justify-between" }
const _hoisted_2 = { class: "flex grow items-center justify-end cursor-pointer" }
const _hoisted_3 = { class: "flex justify-center mt-10 px-[34px]" }
const _hoisted_4 = { class: "flex items-center justify-center flex-col" }
const _hoisted_5 = { class: "flex items-center mb-3 font-color-text-50" }
const _hoisted_6 = { class: "flex flex-col w-full px-[34px] align-center mb-[34px]" }
const _hoisted_7 = { class: "flex items-center justify-center" }
const _hoisted_8 = { class: "flex items-center justify-center text-white" }

import router from '@/router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import {
	chevronBackOutline,
	downloadOutline,
	enterOutline,
	locationOutline,
	lockClosedOutline,
	qrCodeOutline,
	shareSocialOutline,
} from 'ionicons/icons';

// Go back

export default /*@__PURE__*/_defineComponent({
  __name: 'LockersPage',
  setup(__props) {

const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(TopBar, { class: "px-[34px]" }, {
            left: _withCtx(() => [
              _createElementVNode("div", {
                class: "flex items-center justify-start cursor-pointer",
                onClick: goBack
              }, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ])
            ]),
            center: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "flex grow justify-center w-full font-sourcesanspro-semibold text-l2" }, "Locker", -1)
            ])),
            right: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(downloadOutline),
                  class: "mr-2"
                }, null, 8, ["icon"]),
                _createVNode(_unref(IonIcon), { icon: _unref(shareSocialOutline) }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(lockClosedOutline),
                class: "w-[48px] h-[48px] mb-4 text-white"
              }, null, 8, ["icon"]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mb-4" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-m2 font-color-text-50" }, "Locker NO.")
              ], -1)),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-sourcesanspro-light text-[80px]" }, " 2G7A ", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(locationOutline),
                  class: "text-base2 mr-1"
                }, null, 8, ["icon"]),
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-sourcesanspro-regular text-l2" }, "area 2B", -1))
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-[250px] text-center font-sourcesanspro-regular font-color-text-50 text-base2 leading-[26px]" }, " Donec gravida ipsum ex, porttitor sagittis elit pharetra eget. ", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(IonButton), {
            class: "w-full mb-4",
            expand: "full",
            size: "large",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(enterOutline),
                  class: "text-dark-blue mr-2"
                }, null, 8, ["icon"]),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-m2" }, "Open Manual", -1))
              ])
            ]),
            _: 1
          }),
          _createVNode(_unref(IonButton), {
            class: "w-full",
            expand: "full",
            size: "large",
            shape: "round",
            fill: "outline"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(qrCodeOutline),
                  class: "mr-2"
                }, null, 8, ["icon"]),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-m2" }, "Open QR Code", -1))
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col height-inherit" }
const _hoisted_2 = { class: "flex w-full max-w-[532px] items-center justify-between" }

import { computed } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { useForm } from 'vee-validate';
import { arrowForwardOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'FormWizard',
  props: {
	bottomClass: {
		type: String,
	},
	currentStep: {
		type: Number,
		required: true,
	},
	lastStepIndex: {
		type: Number,
		required: true,
	},
	validationSchema: {
		type: Array,
		required: true,
	},
	disableSubmitButton: {
		type: Boolean,
		default: false,
	},
},
  emits: ['onGoNextStep', 'submit'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// if this is the last step
const isLastStep = computed(() => {
	return props.currentStep === props.lastStepIndex;
});

// extracts the indivdual step schema
const currentSchema = computed(() => {
	return props.validationSchema[props.currentStep];
});

const { values, handleSubmit, isFieldValid, meta } = useForm({
	// vee-validate will be aware of computed schema changes
	validationSchema: currentSchema,
	// turn this on so each step values won't get removed when you move back or to the next step
	keepValuesOnUnmount: true,
});

// We are using the "submit" handler to progress to next steps
// and to submit the form if its the last step
const onSubmit = handleSubmit((values) => {
	if (!isLastStep.value) {
		emit('onGoNextStep');

		return;
	}

	// Let the parent know the form was filled across all steps
	emit('submit', values);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {
        values: _unref(values),
        onSubmit: _unref(onSubmit)
      }),
      _createElementVNode("div", {
        class: _normalizeClass(["fixed flex justify-center margin-auto w-full mb-6", props.bottomClass ? props.bottomClass : 'bottom-0 left-0 px-[32px]'])
      }, [
        _createVNode(_unref(IonButton), {
          class: "max-w-[532px]",
          disabled: !_unref(meta).valid || props.disableSubmitButton,
          type: "submit",
          color: "primary",
          size: "large",
          shape: "round"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(isLastStep.value ? "Let's finish" : 'Continue') + " ", 1),
              _createVNode(_unref(IonIcon), {
                icon: _unref(arrowForwardOutline),
                slot: "end"
              }, null, 8, ["icon"])
            ])
          ]),
          _: 1
        }, 8, ["disabled"])
      ], 2)
    ])
  ], 32))
}
}

})
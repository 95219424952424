import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "flex justify-center w-full font-sourcesanspro-semibold text-l2"
}
const _hoisted_3 = {
  key: 1,
  class: "flex items-center justify-center"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { class: "flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller" }
const _hoisted_6 = { class: "flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden ml-[34px]" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src", "onClick"]
const _hoisted_9 = { class: "px-[34px] mb-2" }
const _hoisted_10 = { class: "flex font-sourcesanspro-regular text-xs2 font-color-text-50 mb-4" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "flex items-center" }
const _hoisted_13 = { class: "font-sourcesanspro-regular text-base2 mb-4 font-white" }
const _hoisted_14 = {
  key: 0,
  class: "mb-2 font-sourcesanspro-semibold"
}

import { ref } from 'vue';
import { IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { chevronBackOutline, timeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

import FavoriteButton from '@/shared/components/FavoriteButton.vue';
import FullScreenImage from '@/shared/components/ui/FullScreenImage.vue';
import RelatedContent from '@/shared/components/ui/RelatedContent.vue';
import ShareButton from '@/shared/components/ShareButton.vue';
import TopBar from '@/shared/components/TopBar.vue';
import usePosts from '@/modules/news/composables/usePosts';
import useUtils from '@/shared/composables/useUtils';
import useTenant from '@/shared/composables/useTenant';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'NewsDetailPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const postId = +route.params.id;
const { getPost } = usePosts();
const { isFetchedPost, post } = getPost(postId);
const { transformOembedToIframe } = useUtils();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();

// Image handling
const fullScreenImage = ref('');
const openInFullScreen = ref(false);
const viewFullScreen = (image: string) => {
	fullScreenImage.value = image;
	openInFullScreen.value = true;
};

const onCloseImage = () => {
	openInFullScreen.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      (openInFullScreen.value)
        ? (_openBlock(), _createBlock(FullScreenImage, {
            key: 0,
            fullScreenImage: fullScreenImage.value,
            onOnCloseImage: onCloseImage
          }, null, 8, ["fullScreenImage"]))
        : _createCommentVNode("", true),
      _createVNode(TopBar, { class: "px-[34px]" }, _createSlots({
        left: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: "/dashboard",
              class: "flex items-center justify-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(chevronBackOutline),
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ])
        ]),
        center: _withCtx(() => [
          (_unref(post))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(post).title), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_unref(post))
          ? {
              name: "right",
              fn: _withCtx(() => [
                (_unref(showLoggedData)())
                  ? (_openBlock(), _createBlock(FavoriteButton, {
                      key: 0,
                      id: _unref(post).id,
                      favorite: _unref(post).is_favorite,
                      type: _unref(post).type
                    }, null, 8, ["id", "favorite", "type"]))
                  : _createCommentVNode("", true),
                _createVNode(ShareButton, {
                  id: _unref(post).id,
                  title: _unref(post).title,
                  type: "news"
                }, null, 8, ["id", "title"])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024),
      (!_unref(isFetchedPost))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_unref(IonSpinner), { name: "dots" })
          ]))
        : _createCommentVNode("", true),
      (_unref(post) && _unref(isFetchedPost))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: _unref(post).main_image_url,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (viewFullScreen(_unref(post).main_image_url))),
                  class: "object-fit w-[100%]"
                }, null, 8, _hoisted_7)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(post).images, (image, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: idx,
                  class: "flex items-center min-w-[326px] mr-4 rounded-xl overflow-hidden"
                }, [
                  _createElementVNode("img", {
                    src: image.image_url,
                    onClick: ($event: any) => (viewFullScreen(image.image_url)),
                    class: "object-fit w-[100%]"
                  }, null, 8, _hoisted_8)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(DateTime).fromISO(_unref(post)?.created_at).toFormat('dd MMMM yyyy')), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_unref(IonIcon), {
                    class: "mr-1 text-m2 font-color-text-50",
                    icon: _unref(timeOutline)
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_unref(DateTime).fromISO(_unref(post)?.created_at).toFormat('HH:mm')), 1)
                ])
              ]),
              _withDirectives(_createElementVNode("div", _hoisted_13, null, 512), [
                [_directive_dompurify_html, _unref(transformOembedToIframe)(_unref(post).content)]
              ])
            ]),
            (_unref(post).events.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(RelatedContent, {
                    title: _unref(t)('components.related_content.title_events'),
                    contents: 
						_unref(post).events.map((event) => {
							return {
								'link': `/events/${event.id}`,
								'src': event.main_image_url,
								'title': event.name,
								'subtitle': '',
							};
						})
					
                  }, null, 8, ["title", "contents"])
                ]))
              : _createCommentVNode("", true),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[100px] mb-4" }, " ", -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonAccordionGroup } from '@ionic/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionListTwo',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAccordionGroup), { class: "w-full mb-4 backdrop-blur-xl bg-white/10 rounded-lg" }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full px-[34px]" }
const _hoisted_2 = { class: "flex flex-col mt-12 mb-10 items-center" }
const _hoisted_3 = { class: "font-sourcesanspro-regular text-base2 text-white/50" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center justify-center mb-4"
}
const _hoisted_5 = { key: 1 }

import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import BottomGradient from '@/shared/components/ui/BottomGradient.vue';
import PersonItem from '@/shared/components/ui/PersonItem.vue';
import useFriendsSelect from '@/shared/composables/useFriendsSelect';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'FriendsSelectPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const type = route.query.type?.toString() || '';
const id = route.query.id?.toString() || '';
const eventId = route.query.eventId?.toString();

// Prepare params to query for friends
const params: any = {};
if (type === 'ticket' || type === 'ticket-creation') {
	params.without_ticket_at_event = eventId;
}

// Get friends
const { getFriends, onPersonSelected } = useFriendsSelect(type, id, { eventId });
const { isFetchingFriends, friends } = getFriends(params);

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(IonButton), {
            class: "rounded ion-no-padding ion-no-margin w-[60px] h-[60px] mb-4",
            routerLink: `/events/${_unref(eventId)}`,
            onClick: goBack
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-white cursor-pointer",
                icon: _unref(closeOutline)
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["routerLink"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('friends.select.title')), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-4" }, null, -1)),
        (_unref(isFetchingFriends))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (_unref(friends) && !_unref(isFetchingFriends))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(friends), (friend, idx) => {
                return (_openBlock(), _createBlock(PersonItem, {
                  onOnPersonSelected: ($event: any) => (_unref(onPersonSelected)(friend)),
                  key: idx,
                  name: friend.full_name,
                  location: friend.city,
                  image: friend.profile_image_url
                }, null, 8, ["onOnPersonSelected", "name", "location", "image"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(BottomGradient)
    ]),
    _: 1
  }))
}
}

})
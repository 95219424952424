import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { QueryClient } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';

import { useAuthStore } from '@/stores/auth/authStore';
import type { TenantConfig } from '@/shared/interfaces/tenant';

export const authGuard = async (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext,
	queryClient: QueryClient,
) => {
	// Init
	const comesFromPaymentProvider = to.query.fromMollie;

	// Check if the user is authenticated
	const authStore = useAuthStore();
	const { signInWithSocialLoginQueryParams } = authStore;
	const { user } = storeToRefs(authStore);
	const token = user.value.token;
	const tenantConfig = queryClient.getQueryCache().find(['tenantConfig']);
	const publicMode = (tenantConfig?.state.data as TenantConfig).enable_public_mode;

	// If comes from payment provider
	if (comesFromPaymentProvider == 'true') {
		const orderId = to.query.orderId;
		if (orderId) {
			next({
				path: '/transaction',
				query: {
					orderId,
				},
			});
		} else {
			next({ path: '/transaction' });
		}
	}
	// If access token exists on the url
	else if (to.query.accessToken) {
		// If accessToken is in params, try to login with it
		const signedIn = await signInWithSocialLoginQueryParams(to);

		// If accessToken param or token exist redirect to dashboard
		if (signedIn || token) {
			next('/dashboard');
		} else {
			// Otherwise continue navigation
			next();
		}
	}
	// If not public mode
	else {
		if (publicMode === null) {
			next();
		} else if (publicMode === false) {
			// If accessToken is in params, try to login with it
			const signedIn = await signInWithSocialLoginQueryParams(to);

			// If is not logged in and going to auth
			if (!signedIn && !token && (to.fullPath.includes('auth') || to.fullPath.includes('magic-login'))) {
				// Let it continue
				next();
			}
			// If is not logged in and going to public routes
			else if (
				!signedIn &&
				!token &&
				(to.name === 'activate' ||
					to.name === 'top-up' ||
					to.name === 'transaction-added' ||
					to.name === 'wallet-guest' ||
					to.name === 'wallet-history' ||
					to.name === 'wallet-guest-link-bracelet' ||
					to.name === 'wallet-voucher-refund' ||
					to.name === 'profile-password-reset-request' ||
					to.name === 'profile-refunds-guest-request' ||
					to.name === 'guest-wallet')
			) {
				// Let it continue
				next();
			}
			// If is not logged in, send to auth path
			else if (!signedIn && !token) {
				next('/auth/signuphome');
			}
			// If logged in, let it continue
			else {
				next();
			}
		}
		// If public mode, let it continue
		else {
			next();
		}
	}
};

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_2 = { class: "px-[34px] icon flex justify-center mb-4" }
const _hoisted_3 = ["src"]

import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { IonContent } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import TopBar from '@/shared/components/TopBar.vue';
import searchUser from '@/modules/guest/composables/useGuestUser';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import GuestWalletBox from '../components/GuestWalletBox.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'GuestWalletPage',
  setup(__props) {

const { t } = useI18n();
const assetStore = useAssetsStore();

const route = useRoute();
const loginToken = route.params.id.toString();

const { guestUserProfile, isSuccessGuestProfile, userMutate } = searchUser();

onMounted(async () => {
	userMutate({ login_token: loginToken });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "flex items-center justify-start cursor-pointer" }, null, -1)
        ])),
        center: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('guest_top_up.title')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _unref(assetStore).logoUrl,
            class: "max-w-[70%]"
          }, null, 8, _hoisted_3)
        ]),
        _createVNode(GuestWalletBox, {
          "user-profile": _unref(guestUserProfile),
          "login-token": _unref(loginToken),
          "is-success-profile": _unref(isSuccessGuestProfile)
        }, null, 8, ["user-profile", "login-token", "is-success-profile"])
      ])
    ]),
    _: 1
  }))
}
}

})
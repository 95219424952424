import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full px-[34px]" }
const _hoisted_2 = { class: "flex flex-col mt-12 mb-4 items-center" }
const _hoisted_3 = { class: "flex items-center justify-center mb-4 w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30" }
const _hoisted_4 = { class: "mb-8" }
const _hoisted_5 = { class: "fixed button-container h-[150px] px-[34px] left-1/2 -translate-x-1/2 bottom-0 w-full" }
const _hoisted_6 = { class: "relative z-10 w-full h-full" }
const _hoisted_7 = { class: "absolute w-full bottom-0 mb-[34px]" }
const _hoisted_8 = { class: "flex w-full justify-between items-center font-sourcesanspro-semibold text-l2" }

import { computed } from 'vue';
import router from '@/router';
import { IonContent, IonIcon, IonButton } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { TransactionType } from '@/enums/wallet.enum';
import { useCart } from '@/modules/shop/composables/useCart';
import { useCheckout } from '@/shared/composables/useCheckout';
import hexRgb from 'hex-rgb';
import ItemAdder from '@/shared/components/ui/ItemAdder.vue';
import rgbHex from 'rgb-hex';
import TopAlert from '@/shared/components/ui/TopAlert.vue';
import useUtils from '@/shared/composables/useUtils';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ShopShoppingCartPage',
  setup(__props) {

const { t } = useI18n();
const { cartTotal, cartCount, clearCart, decreaseProductQuantity, getCartItems, increaseProductQuantity } = useCart();
const { processCheckout } = useCheckout();
const { calculateShade, convertToCurrency, paymentMethodCountries, replaceTopUpGeneralMessageVariables } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Clear cart but keep current category items
clearCart(CartCategoriesEnum.shop);

// Compute the total price for products in the cart
const totalPrice = computed(() => cartTotal.value);

// Go back
const goBack = () => {
	router.back();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(IonIcon), {
              class: "text-[38px] text-white cursor-pointer",
              icon: _unref(closeOutline),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (goBack()))
            }, null, 8, ["icon"])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              (!_unref(cartCount))
                ? (_openBlock(), _createBlock(TopAlert, {
                    key: 0,
                    class: _normalizeClass('alert-danger text-center')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('shop.cart.empty')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(getCartItems)(), (cartItem) => {
                    return (_openBlock(), _createBlock(ItemAdder, {
                      onOnDecreaseProductQuantity: _unref(decreaseProductQuantity),
                      onOnIncreaseProductQuantity: _unref(increaseProductQuantity),
                      expandable: false,
                      key: cartItem.product.id,
                      id: cartItem.product.id,
                      productId: cartItem.product.id,
                      class: "mb-4",
                      title: cartItem.product.name,
                      discount: cartItem.product.discount,
                      price: +cartItem.product.price,
                      priceWithDiscount: +cartItem.product.discountedPrice()!,
                      quantity: cartItem.quantity,
                      "can-add-more": true
                    }, null, 8, ["onOnDecreaseProductQuantity", "onOnIncreaseProductQuantity", "id", "productId", "title", "discount", "price", "priceWithDiscount", "quantity"]))
                  }), 128))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(IonButton), {
              class: "w-full h-[60px]",
              expand: "full",
              size: "large",
              shape: "round",
              disabled: !_unref(cartCount),
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(processCheckout)(_unref(TransactionType).SHOP_ORDER)), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", null, _toDisplayString(_unref(t)('shop.cart.checkout')), 1),
                  _createElementVNode("span", null, "€" + _toDisplayString(totalPrice.value.toFixed(2)), 1)
                ])
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}
}

})
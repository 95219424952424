import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-left w-full" }
const _hoisted_2 = { class: "mr-2" }
const _hoisted_3 = { class: "font-sourcesanspro-semibold text-base2" }

import { bagOutline } from 'ionicons/icons';
import { useCart } from '@/modules/shop/composables/useCart';
import { IonIcon } from '@ionic/vue';

import { CartCategoriesEnum } from '@/enums/values.enum';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'ShoppingCartIcon',
  setup(__props) {

const { getCartTotalItemsByCategory } = useCart();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: "/shop/shopping-cart" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, "(" + _toDisplayString(_unref(getCartTotalItemsByCategory)(_unref(CartCategoriesEnum).shop)) + ")", 1)
        ]),
        _createVNode(_unref(IonIcon), {
          icon: _unref(bagOutline),
          class: "text-white"
        }, null, 8, ["icon"])
      ])
    ]),
    _: 1
  }))
}
}

})
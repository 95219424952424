import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex fixed px-2 justify-evenly bg-white left-2/4 -translate-x-2/4 bottom-4 align-center rounded-full w-[90%] min-w-[250px] max-w-[360px] h-[77px] z-10"
}
const _hoisted_2 = { class: "flex flex-col items-center justify-center navigation-item" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col items-center justify-center navigation-item"
}
const _hoisted_4 = {
  key: 1,
  class: "flex flex-col items-center justify-center navigation-item"
}
const _hoisted_5 = {
  key: 2,
  class: "flex flex-col items-center justify-center navigation-item"
}
const _hoisted_6 = {
  key: 3,
  class: "flex flex-col items-center justify-center navigation-item"
}

import { ref } from 'vue';
import { IonIcon } from '@ionic/vue';
import { RouterLink } from 'vue-router';
import { bagOutline, calendarOutline, homeOutline, locationOutline, ticketOutline } from 'ionicons/icons';

import useTenant from '@/shared/composables/useTenant';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationBar',
  props: {
	itemActive: { type: String, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { getTenantConfigAndStyles } = useTenant();
const { isSuccessTenantConfig, tenantConfig } = getTenantConfigAndStyles();
const hash = window.location.hash;

return (_ctx: any,_cache: any) => {
  return (_unref(isSuccessTenantConfig) && _unref(tenantConfig))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(RouterLink), {
            class: "flex flex-col items-center",
            to: "/dashboard"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonIcon), {
                class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'dashboard' && _unref(hash) !== '#news',
							'text-dark-blue/25': props.itemActive !== 'dashboard' || _unref(hash) === '#news',
						},
					], "mb-[5px]"]),
                icon: _unref(homeOutline)
              }, null, 8, ["class", "icon"]),
              _createElementVNode("span", {
                class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'dashboard' && _unref(hash) !== '#news',
							'text-dark-blue/25': props.itemActive !== 'dashboard' || _unref(hash) === '#news',
						},
					], "text-xs leading-none"])
              }, "Home", 2)
            ]),
            _: 1
          })
        ]),
        (!_unref(tenantConfig).hide_maps)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_unref(RouterLink), {
                class: "flex flex-col items-center",
                to: "/maps"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'maps',
							'text-dark-blue/25': props.itemActive !== 'maps',
						},
					], "mb-[5px]"]),
                    icon: _unref(locationOutline)
                  }, null, 8, ["class", "icon"]),
                  _createElementVNode("span", {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'maps',
							'text-dark-blue/25': props.itemActive !== 'maps',
						},
					], "text-xs text-dark-blue leading-none"])
                  }, "Maps", 2)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_unref(tenantConfig).hide_tickets)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_unref(RouterLink), {
                class: "flex flex-col items-center",
                to: "/events"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'events',
							'text-dark-blue/25': props.itemActive !== 'events',
						},
					], "mb-[5px]"]),
                    icon: _unref(ticketOutline)
                  }, null, 8, ["class", "icon"]),
                  _createElementVNode("span", {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'events',
							'text-dark-blue/25': props.itemActive !== 'events',
						},
					], "text-xs text-dark-blue leading-none"])
                  }, "Events", 2)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_unref(tenantConfig).hide_shop)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_unref(RouterLink), {
                class: "flex flex-col items-center",
                to: "/shop"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'shop',
							'text-dark-blue/25': props.itemActive !== 'shop',
						},
					], "mb-[5px]"]),
                    icon: _unref(bagOutline)
                  }, null, 8, ["class", "icon"]),
                  _createElementVNode("span", {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'shop',
							'text-dark-blue/25': props.itemActive !== 'shop',
						},
					], "text-xs text-dark-blue leading-none"])
                  }, "Shop", 2)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (!_unref(tenantConfig).hide_timetables)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_unref(RouterLink), {
                class: "flex flex-col items-center",
                to: "/timetables"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'timetables',
							'text-dark-blue/25': props.itemActive !== 'timetables',
						},
					], "mb-[5px]"]),
                    icon: _unref(calendarOutline)
                  }, null, 8, ["class", "icon"]),
                  _createElementVNode("span", {
                    class: _normalizeClass([[
						{
							'text-dark-blue': props.itemActive === 'timetables',
							'text-dark-blue/25': props.itemActive !== 'timetables',
						},
					], "text-xs text-dark-blue leading-none"])
                  }, "Timetables", 2)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})
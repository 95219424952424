import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex overflow-scroll w-[100%] h-[182px] mb-4 invisible-scroller" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "px-[34px] mb-2" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-between mb-4"
}
const _hoisted_6 = { class: "flex justify-right" }
const _hoisted_7 = {
  key: 1,
  class: "flex items-center mb-4"
}
const _hoisted_8 = { class: "font-sourcesanspro-semibold mb-8" }
const _hoisted_9 = { class: "flex items-center mb-2" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "fixed button-container h-[150px] px-[34px] bottom-0 w-full" }
const _hoisted_12 = { class: "relative z-10 w-full h-full" }
const _hoisted_13 = { class: "absolute w-full bottom-0 mb-[34px]" }
const _hoisted_14 = {
  key: 1,
  class: "flex"
}

import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import TopBar from '@/shared/components/TopBar.vue';
import { checkmarkCircle, chevronBackOutline, heartOutline, shareSocialOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingsReservationsDetailPage',
  setup(__props) {

const route = useRoute();
const bookingReservationId = +route.params.id;

// Go back
const goBack = () => {
	router.back();
};

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(TopBar, { class: "px-[34px]" }, {
        left: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex items-center justify-start cursor-pointer",
            onClick: goBack
          }, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(chevronBackOutline),
              class: "text-white"
            }, null, 8, ["icon"])
          ])
        ]),
        center: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "flex justify-center w-full font-sourcesanspro-semibold text-l2" }, "Bookings Details", -1)
        ])),
        right: _withCtx(() => [
          _createVNode(_unref(IonIcon), {
            class: "mr-1",
            icon: _unref(heartOutline)
          }, null, 8, ["icon"]),
          _createVNode(_unref(IonIcon), {
            class: "mr-1",
            icon: _unref(shareSocialOutline)
          }, null, 8, ["icon"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/statics/svg-delete-after-implement/bookings-reservations-image-limo-1.svg'),
          class: "w-[100%] ml-[34px] mr-4"
        }, null, 8, _hoisted_2),
        _createElementVNode("img", {
          src: require('@/statics/svg-delete-after-implement/bookings-reservations-image-limo-2.svg'),
          class: "w-[100%] h-[182px]"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex mb-4 font-sourcesanspro-semibold text-lg2" }, "Limo Ride", -1)),
        (bookingReservationId == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "29"),
                _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, "December")
              ], -1)),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2" }, "Tuesday"),
                _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, "10:00 PM - End")
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(IonIcon), {
                  src: require('@/statics/svg/events/calendar-add.svg'),
                  class: "h-[54px] w-[54px]"
                }, null, 8, ["src"])
              ])
            ]))
          : _createCommentVNode("", true),
        (bookingReservationId == 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "mr-2" }, [
                _createElementVNode("span", { class: "font-sourcesanspro-semibold text-l2 line-through text-white/30" }, "€79.99")
              ], -1),
              _createElementVNode("span", { class: "font-sourcesanspro-bold text-lg2" }, "€49.99", -1)
            ])))
          : _createCommentVNode("", true),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "mb-4" }, [
          _createElementVNode("div", { class: "font-sourcesanspro-semibold text-l2" }, "About Limo Ride:"),
          _createElementVNode("p", { class: "text-base2 font-sourcesanspro-regular font-color-text-50" }, " Morbi in lectus suscipit, blandit magna sit amet, ornare nulla. Praesent vestibulum lorem nibh, et mattis lorem accumsan eget. Morbi eu mi quis justo tempus tempus. Pellentesque eu orci nibh. Suspendisse consequat enim ut mi rutrum, ac ultrices est rutrum. Donec faucibus velit ac erat fermentum, vitae imperdiet magna semper. ")
        ], -1)),
        _createElementVNode("div", _hoisted_8, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "mb-2 text-l2" }, "What is included", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(checkmarkCircle),
              class: "w-[17px] text-white mr-2"
            }, null, 8, ["icon"]),
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, " Oliver Tree singing is Dec 29th at 10:00pm ", -1))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_unref(IonIcon), {
              icon: _unref(checkmarkCircle),
              class: "w-[17px] text-white mr-2"
            }, null, 8, ["icon"]),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-sourcesanspro-regular text-base font-color-text-50" }, " Meeet and great with Oliver Tree on Dec 30th ", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (bookingReservationId == 1)
              ? (_openBlock(), _createBlock(_unref(IonButton), {
                  key: 0,
                  class: "w-full",
                  expand: "full",
                  size: "large",
                  shape: "round"
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createElementVNode("div", { class: "font-sourcesanspro-semibold text-m2 text-center" }, "Book more", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (bookingReservationId == 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createVNode(_unref(IonButton), {
                    class: "w-full",
                    expand: "full",
                    size: "large",
                    shape: "round",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo('/bookings-reservations/book')))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createElementVNode("div", { class: "font-sourcesanspro-semibold text-m2 text-center" }, "Book Now", -1)
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
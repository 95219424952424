<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { IonIcon, IonButton } from '@ionic/vue';
import { chevronBackOutline, chevronForwardOutline, heartOutline, timeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TimeTableHeader from '@/modules/timetables/components/timetable/TimeTableHeader.vue';
import TimeTableItem from '@/modules/timetables/components/timetable/TimeTableItem.vue';
import TimeTableItemTitle from '@/modules/timetables/components/timetable/TimeTableItemTitle.vue';
import { Timetable } from '@/shared/interfaces/timeTables';
import useTimeTables from '@/modules/timetables/composables/useTimeTables';
import { Track } from '@/shared/interfaces/timeTables';
import useTenant from '@/shared/composables/useTenant';

// Props
const props = defineProps({
	timeTable: { type: Timetable, required: true },
});

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { showLoggedData } = getTenantConfigAndStyles();
const timeTableDate = ref(props.timeTable.timelineDetails[0]);
const days = ref([...new Set(props.timeTable.timelineDetails.map((dateItem) => dateItem?.day))]);
const { calculateDurationData, calculateProgressPercent, scrollToNextDay, scrollToPreviousDay } = useTimeTables();
const timeTableHoursCount = props.timeTable.timelineDetails.length * 4;
const gridTemplateColumns = `grid-template-columns: 50px repeat(${timeTableHoursCount}, 40px);`;
// Grid rows + titles
const gridRowsCount = props.timeTable.tracks.length * 2;
const gridRowsHeights: string[] = [];
for (let i = 0; i < gridRowsCount; i++) {
	const height = i % 2 === 0 ? '34px' : '85px';
	gridRowsHeights.push(height);
}
const gridTemplateRows = `grid-template-rows: 34px ${gridRowsHeights.join(' ')};`;

// onMountedHook
onMounted(() => {
	const div = document.getElementById('timetable');

	// Find label position and update the date if needed
	div!.addEventListener('scroll', () => {
		props.timeTable.timelineDetails.find((date) => {
			if (timeTableDate.value != date) {
				const element = document.querySelector(`#day-${date?.day.toString()}`);
				const position = element!.getBoundingClientRect();
				if (position.x <= 100) {
					timeTableDate.value = date;
				}
			}
		});
	});
});

const filterValue = ref('all');

const filteredTracks = computed(() => {
	if (props.timeTable.tracks) {
		if (filterValue.value === 'favorites') {
			return props.timeTable.tracks.map((track: Track) => ({
				...track,
				activities: track.activities.filter((activity) => activity.isFavorite),
			}));
		} else {
			return props.timeTable.tracks;
		}
	} else {
		return [];
	}
});
</script>

<template>
	<div>
		<div
			v-if="timeTableDate"
			class="flex px-5 py-[13px] justify-between background-white-15 rounded-full font-sourcesanspro-semibold text-base2 mb-5">
			<div class="w-[22px]">
				<ion-button
					v-show="days.findIndex((day) => day === timeTableDate!.day) > 0"
					:icon="chevronBackOutline"
					class="flex h-[22px] transparent ion-no-padding ion-no-margin"
					@click="scrollToPreviousDay(timeTableDate.minus({ day: 1 }))">
					<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
				</ion-button>
			</div>
			{{ timeTableDate?.toFormat('ccc - dd MMM, yy') }}
			<div class="w-[22px]">
				<ion-button
					v-show="days.findIndex((day) => day === timeTableDate!.day) < days.length - 1"
					class="flex h-[22px] transparent ion-no-padding ion-no-margin"
					@click="scrollToNextDay(timeTableDate.plus({ day: 1 }))">
					<ion-icon :icon="chevronForwardOutline" class="text-white"></ion-icon>
				</ion-button>
			</div>
		</div>

		<div class="flex justify-between mb-4">
			<ion-button
				:icon="timeOutline"
				class="flex w-1/2 h-[40px] button-solid rounded-full"
				:class="
					filterValue === 'all'
						? 'hover:bg-white text-color-button-text'
						: 'backdrop-blur bg-white/20 text-white'
				"
				shape="round"
				:fill="filterValue === 'all' ? 'default' : 'clear'"
				@click="filterValue = 'all'">
				<ion-icon
					class="mr-2"
					role="img"
					:icon="timeOutline"
					:class="filterValue === 'all' ? 'text-color-button-text' : 'text-white'"></ion-icon>
				<span class="text-base2">
					{{ t('timetables.all_activities') }}
				</span>
			</ion-button>
			<ion-button
				:icon="heartOutline"
				class="flex w-1/2 h-[40px] button-solid rounded-full"
				:class="
					filterValue === 'favorites'
						? 'hover:bg-white text-color-button-text'
						: 'backdrop-blur bg-white/20 text-white'
				"
				shape="round"
				:fill="filterValue === 'favorites' ? 'default' : 'clear'"
				@click="filterValue = 'favorites'">
				<ion-icon
					class="mr-2"
					:class="filterValue === 'favorites' ? 'text-color-button-text' : 'text-white'"
					role="img"
					:icon="heartOutline"></ion-icon>
				<span class="text-base2">
					{{ t('timetables.favorite_activities') }}
				</span>
			</ion-button>
		</div>

		<div id="timetable" class="timetable pb-4 invisible-scroller" :style="[gridTemplateColumns, gridTemplateRows]">
			<TimeTableHeader :timelineDetails="props.timeTable.timelineDetails" />

			<template v-for="track in filteredTracks" :key="track.id">
				<TimeTableItemTitle :title="track.name" :iconUrl="track.iconUrl" :imageUrl="track.imageUrl" />
				<TimeTableItem
					v-for="(activity, idxActivity) in track.activities.sort(
						(a, b) => a.startAt.toMillis() - b.startAt.toMillis(),
					)"
					:activity="activity"
					:track="new Track(track)"
					:time-table-id="props.timeTable.id"
					:background-color="activity.backgroundColor"
					:background-color-opacity="activity.backgroundColorOpacity || '100'"
					:duration-data="calculateDurationData(activity, props.timeTable.timelineDetails)"
					:key="idxActivity"
					:time="`${activity.startAt.toFormat('HH:mm')} - ${activity.endAt.toFormat('HH:mm')}`"
					:title="activity.name"
					:bar-percent="calculateProgressPercent(activity)"
					:show-logged-data="showLoggedData()" />
			</template>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.timetable {
	display: grid;
	grid-column-gap: 0px;
	grid-row-gap: 10px;
	overflow-x: scroll;
}
</style>

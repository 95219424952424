import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/statics/svg/qr-code/scan-frame.svg'


const _hoisted_1 = { class: "flex flex-col h-full items-center justify-between" }
const _hoisted_2 = { class: "flex flex-col items-center" }
const _hoisted_3 = { class: "flex items-center justify-center w-[60px] h-[60px] mb-[34px] rounded-full bg-white" }

import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { closeOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'QrReaderPage',
  setup(__props) {

const route = useRoute();
const callbackPage = route.query.callbackPage;

const goTo = async () => {
	await router.push({ name: callbackPage as string });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
        _createElementVNode("img", {
          src: _imports_0,
          onClick: goTo
        }),
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-4" }, [
            _createElementVNode("span", { class: "font-sourcesanspro-regular text-base2" }, "Please scan QR code")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(IonIcon), {
              class: "text-[38px] text-black cursor-pointer",
              icon: _unref(closeOutline),
              onClick: goTo
            }, null, 8, ["icon"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})
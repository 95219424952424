import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex grow flex-col items-end" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mr-1" }
const _hoisted_5 = { class: "font-sourcesanspro-regular text-xs font-color-text-50 leading-none whitespace-nowrap" }

import { PropType } from 'vue';

import type { TenantConfig } from '@/shared/interfaces/tenant';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useUtils from '@/shared/composables/useUtils';
import { Currency } from '@/shared/interfaces/balance';
import useCurrencies from '@/shared/composables/useCurrencies';
import { GroupedTransactions } from '@/shared/interfaces/wallet';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionListItemAction',
  props: {
	date: { type: String, required: true },
	hideTransactionSign: { type: Boolean },
	price: { type: Number, required: true },
	priceDetail: { type: Object as PropType<GroupedTransactions[]> },
	currency: { type: Object as PropType<Currency>, required: false },
	transactionType: { type: String },
	paymentType: { type: String },
	tenantConfig: { type: Object as PropType<TenantConfig>, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const { convertValutaSync } = useConvertValuta();
const { convertToCurrency } = useUtils();
const { fiatCurrency } = useCurrencies();

// Get converted price
const convertedPrice = convertValutaSync(props.tenantConfig, props.price, true);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.priceDetail, (transactionGroup, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "font-sourcesanspro-semibold text-sm2 text-nowrap ml-2"
        }, [
          _createElementVNode("span", null, _toDisplayString(transactionGroup?.prefix) + " " + _toDisplayString(_unref(convertToCurrency)(+transactionGroup?.totalAmount)), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["font-sourcesanspro-regular text-base leading-tight", props.transactionType === 'CREDIT' ? 'text-anykrowd-green' : 'text-anykrowd-red'])
    }, [
      (!props.hideTransactionSign)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.transactionType === 'CREDIT' ? '+ ' : '- '), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(fiatCurrency).prefix), 1),
      _createElementVNode("span", null, _toDisplayString(_unref(convertToCurrency)(+_unref(convertedPrice))), 1)
    ], 2),
    _createElementVNode("span", _hoisted_5, _toDisplayString(props.date), 1)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer" }
const _hoisted_3 = { class: "flex w-full flex-col justify-between px-3 py-3 bg-white rounded-xl mb-2" }
const _hoisted_4 = { class: "flex items-center justify-between mb-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex justify-between mb-2" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  id: "popover-button",
  class: "flex items-center justify-center rounded-lg font-sourcesanspro-bold text-anykrowd-dark text-md cursor-pointer"
}
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "mr-2" }
const _hoisted_12 = { class: "flex flex-col px-3 py-2 font-sourcesanspro-regular" }
const _hoisted_13 = { class: "mb-1" }
const _hoisted_14 = { class: "flex items-center mb-4" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = {
  key: 0,
  class: "font-sourcesanspro-black text-l2 font-color-dark leading-none"
}
const _hoisted_17 = {
  key: 1,
  class: "font-sourcesanspro-regular text-base font-color-dark leading-none break-words"
}
const _hoisted_18 = {
  key: 2,
  class: "font-sourcesanspro-regular text-base font-color-dark leading-none"
}
const _hoisted_19 = { class: "flex items-center justify-between" }
const _hoisted_20 = { class: "flex" }
const _hoisted_21 = {
  key: 1,
  class: "flex items-baseline"
}
const _hoisted_22 = { class: "mr-1" }
const _hoisted_23 = { class: "font-sourcesanspro-black text-lg3 font-color-dark" }
const _hoisted_24 = { class: "font-sourcesanspro-regular text-md font-color-dark" }
const _hoisted_25 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_26 = { class: "mr-2" }
const _hoisted_27 = {
  key: 0,
  class: "flex flex-col font-sourcesanspro-semibold text-anykrowd-dark/50 text-sm mb-6 mt-4"
}
const _hoisted_28 = { class: "flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer" }
const _hoisted_29 = {
  key: 0,
  class: "px-[34px]"
}
const _hoisted_30 = { class: "mr-2" }
const _hoisted_31 = {
  key: 1,
  class: "px-[34px]"
}
const _hoisted_32 = {
  key: 2,
  class: "px-[34px]"
}
const _hoisted_33 = { class: "mr-2" }
const _hoisted_34 = {
  key: 3,
  class: "px-[34px]"
}
const _hoisted_35 = {
  key: 4,
  class: "px-[34px] mb-4 mt-2"
}
const _hoisted_36 = { class: "my-6 font-sourcesanspro-regular text-sm2 text-center text-white/60" }
const _hoisted_37 = {
  key: 0,
  class: "flex items-center justify-left p-4"
}
const _hoisted_38 = { class: "font-sourcesanspro-light text-regular text-white/40" }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = {
  key: 0,
  class: "p-4 bg-white/10 font-sourcesanspro-regular text-sm"
}

import { onMounted, PropType, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { IonContent, IonButton, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { addOutline, chevronBackOutline, chevronDownOutline, chevronForwardOutline, linkOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { RfidTag } from '@/shared/interfaces/rfidTag';
import { UserProfile } from '@/shared/interfaces/user';
import useAlert from '@/shared/composables/useAlert';
import UserAvatar from '@/shared/components/user-ui/UserAvatar.vue';
import useTenant from '@/shared/composables/useTenant';
import { captureMessage } from '@/sentry';
import { Wallet } from '@/shared/interfaces/balance';
import useAuth from '@/modules/auth/composables/useAuth';
import { useAuthStore } from '@/stores/auth/authStore';
import { RefundRequestResource } from '@/shared/interfaces/refundRequest';
import { RefundRequestStatus } from '@/shared/interfaces/refundRequest';
import AccordionList from '@/shared/components/ui/accordionlist/AccordionList.vue';
import AccordionListItem from '@/shared/components/ui/accordionlist/AccordionListItem.vue';
import AccordionListItemAction from '@/shared/components/ui/accordionlist/AccordionListItemAction.vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'RfidWalletBox',
  props: {
	rfidTag: { type: Object as PropType<RfidTag> },
	encryptedRfidTag: { type: String, required: true },
	isSuccessRfidTag: { type: Boolean, default: false },
	userProfile: { type: Object as PropType<UserProfile> },
	continueLinking: { type: Boolean, default: false },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();
const { presentAlert } = useAlert();
const selectedWallet = ref<Wallet | undefined>();
const linkingStarted = ref(false);
const { loginUserWithRfid } = useAuth();
const { isAuthenticated } = useAuthStore();

// showConfirmLinkingAlert
const showConfirmLinkingAlert = async () => {
	await presentAlert({
		// cssClass: 'my-custom-class',
		header: t('rfid.guest.linking_confirmation_alert.title'),
		buttons: [
			{
				text: t('rfid.guest.linking_confirmation_alert.cancel'),
				role: 'cancel',
				cssClass: 'secondary',
			},
			{
				text: t('rfid.guest.linking_confirmation_alert.confirm'),
				role: 'ok',
				handler: linkBracelet,
			},
		],
	});
};

watchEffect(() => {
	const wallets = props.rfidTag?.wallets;
	if (wallets && wallets.length && wallets.length > 0) {
		if (!selectedWallet.value) {
			selectedWallet.value = wallets.find((wallet) => {
				return wallet.currency.default;
			});

			if (!selectedWallet.value && wallets.length > 0) {
				selectedWallet.value = wallets[0];
			}
		}
	}
	if (props.continueLinking) {
		startLink();
	}
});

const selectWallet = (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
};

const getWalletAtIndex = (index: number) => {
	return props.rfidTag?.wallets[index];
};

const currentWalletIndex = () => {
	const index = props.rfidTag?.wallets.findIndex((wallet) => wallet === selectedWallet.value);
	if (index === -1) {
		// dirty as f*** but it works
		return -100000;
	}
	return index ?? -100000;
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};

// linkBracelet
const linkBracelet = () => {
	router.push({
		path: '/wallet/wallet-handler/link-bracelet',
		query: {
			amount: selectedWallet.value!.current_balance,
			encryptedRfidTag: props.encryptedRfidTag,
			walletId: selectedWallet.value!.id,
			rfidTag: props.rfidTag?.uuid,
		},
	});
};

// startLink
const startLink = () => {
	if (!props.userProfile || props.userProfile.id === 0) {
		router.push({
			path: '/auth/signuphome',
			query: { callbackPage: 'wallet/wallet-handler', id: props.encryptedRfidTag },
		});
	} else {
		if (!linkingStarted.value) {
			showConfirmLinkingAlert();
			linkingStarted.value = true;
		}
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!!getPreviousWallet())
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: _unref(chevronBackOutline),
              class: "text-white active:bg-white/30 active:rounded-full active:p-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (selectPreviousWallet()))
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: require('@/statics/svg/rfid/bracelet-with-bg.svg'),
            height: "34",
            width: "34"
          }, null, 8, _hoisted_5),
          (props.rfidTag)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", null, null, -1)),
                  (selectedWallet.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(selectedWallet.value.currency.prefix), 1),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(selectedWallet.value?.currency.name), 1),
                          (__props.rfidTag?.wallets && __props.rfidTag?.wallets?.length > 1)
                            ? (_openBlock(), _createBlock(_unref(IonIcon), {
                                key: 0,
                                icon: _unref(chevronDownOutline),
                                class: "text-anykrowd-dark text-md"
                              }, null, 8, ["icon"]))
                            : _createCommentVNode("", true)
                        ]),
                        _createVNode(_unref(IonPopover), {
                          trigger: "popover-button",
                          "dismiss-on-select": true
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonContent), null, {
                              default: _withCtx(() => [
                                _createVNode(_unref(IonList), null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rfidTag?.wallets, (wallet) => {
                                      return (_openBlock(), _createBlock(_unref(IonItem), {
                                        class: "flex items-center justify-center ion-no-padding ion-no-margin",
                                        key: wallet.id,
                                        button: true,
                                        onClick: ($event: any) => (selectWallet(wallet)),
                                        detail: false
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_12, [
                                            _createElementVNode("div", _hoisted_13, [
                                              _createElementVNode("span", null, _toDisplayString(wallet.currency.prefix) + " " + _toDisplayString(wallet.current_balance) + " " + _toDisplayString(wallet.currency.name), 1)
                                            ])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          (props.rfidTag && props.rfidTag.user)
            ? (_openBlock(), _createBlock(UserAvatar, {
                key: 0,
                "container-class": 'h-[42px] mr-2',
                src: 
							props.rfidTag?.user.profile_image_url
								? props.rfidTag?.user.profile_image_url
								: require('@/statics/img/no_profile_pic.jpg')
						,
                alt: "User icon",
                "show-status": false
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_15, [
            (props.rfidTag)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(props.rfidTag.user
									? `${__props.rfidTag?.user.firstname} ${__props.rfidTag?.user.lastname}`
									: `${_unref(t)('rfid.guest.wallet')} #${selectedWallet.value?.id}`), 1))
              : _createCommentVNode("", true),
            (__props.rfidTag?.user && __props.rfidTag?.user.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(__props.rfidTag?.user.email), 1))
              : _createCommentVNode("", true),
            (props.rfidTag)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(`${props.rfidTag?.uuid}`), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            (!__props.isSuccessRfidTag && !!_unref(tenantConfig))
              ? (_openBlock(), _createBlock(_unref(IonSpinner), {
                  key: 0,
                  name: "dots",
                  class: "spinner-black"
                }))
              : _createCommentVNode("", true),
            (selectedWallet.value && _unref(tenantConfig))
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString(selectedWallet.value?.currency.prefix) + _toDisplayString(selectedWallet.value?.current_balance), 1)
                  ]),
                  _createElementVNode("span", _hoisted_24, _toDisplayString(selectedWallet.value.currency.suffix), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (selectedWallet.value && selectedWallet.value!.currency.topupable == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                _createVNode(_unref(IonButton), {
                  class: "w-[117px] h-[40px] black font-sourcesanspro-semibold text-sm2 ion-no-margin",
                  shape: "round",
                  routerLink: `/top-up/${selectedWallet.value?.id}?id=${__props.encryptedRfidTag}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_unref(IonIcon), { icon: _unref(addOutline) }, null, 8, ["icon"])
                    ]),
                    _createTextVNode(" " + _toDisplayString(_unref(t)('rfid.guest.add')), 1)
                  ]),
                  _: 1
                }, 8, ["routerLink"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (selectedWallet.value && selectedWallet.value.currency?.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(selectedWallet.value.currency?.description), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_28, [
        (!!getNextWallet())
          ? (_openBlock(), _createBlock(_unref(IonIcon), {
              key: 0,
              icon: _unref(chevronForwardOutline),
              class: "text-white active:bg-white/30 active:rounded-full active:p-1",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (selectNextWallet()))
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_unref(showLoggedData)() && !props.rfidTag?.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
          (selectedWallet.value)
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 0,
                class: "w-full items-center rounded-box hover:bg-white hover:rounded-xl",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (startLink()))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(linkOutline),
                      class: "text-anykrowd-dark"
                    }, null, 8, ["icon"])
                  ]),
                  _createTextVNode(" " + _toDisplayString(__props.userProfile?.id != 0 ? _unref(t)('rfid.guest.link_wallet_cta') : _unref(t)('rfid.guest.authenticate')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (props.rfidTag && props.rfidTag.user && !props.rfidTag.user.has_external_id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createVNode(_component_router_link, {
            to: _unref(isAuthenticated)() ? '/dashboard' : '/login'
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButton), { class: "w-full items-center rounded-box hover:bg-white hover:rounded-xl" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('rfid.guest.continue_to_dashboard')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true),
    (
				props.encryptedRfidTag &&
				props.rfidTag &&
				props.rfidTag.user &&
				props.rfidTag.user.has_external_id &&
				!_unref(isAuthenticated)()
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createVNode(_unref(IonButton), {
            class: "w-full items-center rounded-box hover:bg-white hover:rounded-xl",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(loginUserWithRfid)(props.encryptedRfidTag, _unref(t)('common.wait'))))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_33, [
                _createVNode(_unref(IonIcon), {
                  icon: _unref(linkOutline),
                  class: "text-anykrowd-dark"
                }, null, 8, ["icon"])
              ]),
              _createTextVNode(" " + _toDisplayString(_unref(t)('rfid.manage')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (
				_unref(tenantConfig)?.enable_full_guest_mode &&
				_unref(tenantConfig).enable_refund &&
				selectedWallet.value?.currency.refundable &&
				props.rfidTag?.is_refundable
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createVNode(_component_router_link, {
            to: `/wallet/refunds/${props.encryptedRfidTag}/guestrequest?rfidTagUuid=${props.rfidTag?.uuid}`
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButton), { class: "w-full items-center rounded-box hover:bg-white hover:rounded-xl" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('rfid.guest.request_full_guest_mode_refund')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true),
    (
				_unref(tenantConfig)?.enable_full_guest_mode &&
				_unref(tenantConfig).enable_refund &&
				props.rfidTag &&
				props.rfidTag.refund_requests &&
				props.rfidTag.refund_requests.length > 0
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(t)('wallet.refund.top_warning_message', { days: 14 })), 1),
          _createVNode(AccordionList, {
            title: _unref(t)('wallet.refund.requests')
          }, {
            items: _withCtx(() => [
              (
							props.rfidTag && props.rfidTag.refund_requests && props.rfidTag.refund_requests.length === 0
						)
                ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                    _createElementVNode("span", _hoisted_38, _toDisplayString(_unref(t)('wallet.refund.no_entries')), 1)
                  ]))
                : _createCommentVNode("", true),
              (props.rfidTag && props.rfidTag.refund_requests)
                ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.rfidTag.refund_requests.map(
								(refund, index) => {
									return {
										date: refund.created_at,
										id: index.toString(),
										refundId: refund.id,
										title: _unref(t)('wallet.refund.item_title', { status: refund.status }),
										iconClass: 'text-white',
										info: refund.info,
										price: refund.fiat_amount,
										customSubtitle: refund.status,
										tag: refund.status,
										transactionType:
											refund.status !== _unref(RefundRequestStatus).FAILED ? 'CREDIT' : 'DEBIT',
									};
								},
							), (item, idx) => {
                      return (_openBlock(), _createBlock(AccordionListItem, {
                        key: idx,
                        "key-value": item.id,
                        icon: require('@/statics/svg/profile/refund.svg'),
                        iconClass: item.iconClass,
                        tag: item.tag,
                        "transaction-type": item.transactionType,
                        title: item.title
                      }, {
                        actions: _withCtx(() => [
                          (_unref(tenantConfig))
                            ? (_openBlock(), _createBlock(AccordionListItemAction, {
                                key: 0,
                                date: _unref(DateTime).fromISO(item.date).toFormat('dd LLLL yyyy • HH:mm'),
                                "hide-transaction-sign": true,
                                price: +item.price,
                                "transaction-type": item.transactionType,
                                "tenant-config": _unref(tenantConfig)
                              }, null, 8, ["date", "price", "transaction-type", "tenant-config"]))
                            : _createCommentVNode("", true)
                        ]),
                        content: _withCtx(() => [
                          (item.info)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(item.info), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["key-value", "icon", "iconClass", "tag", "transaction-type", "title"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})
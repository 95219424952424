import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_3 = { class: "flex items-center justify-center" }

import router from '@/router';
import { useRoute } from 'vue-router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'RfidLinkBraceletPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();

const onScan = async (scannedQrQcode: string) => {
	const urlObj = new URL(scannedQrQcode);
	const pathname = urlObj.pathname;
	const domain = urlObj.hostname;

	if (pathname.includes('activate')) {
		// Push to the route
		await router.push({
			path: pathname,
		});
	}

	if (domain === 'payconiq.com' && scannedQrQcode.includes('R=')) {
		const token = urlObj.searchParams.get('R');
		// Push to the route
		await router.push({
			path: '/activate/' + token,
		});
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: "/rfid",
                class: "flex items-center justify-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    icon: _unref(chevronBackOutline),
                    class: "text-white"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ScanQR, {
            onOnScanQR: onScan,
            "start-camera": true,
            permissionTitle: _unref(t)('scan_qr.camera_permission_title'),
            permissionDescription: _unref(t)('scan_qr.camera_permission_description')
          }, null, 8, ["permissionTitle", "permissionDescription"])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[200px]" }, null, -1))
      ])
    ]),
    _: 1
  }))
}
}

})
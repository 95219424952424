// keep Pinia at the top of the imports
import { pinia } from '@/pinia';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { IonicVue } from '@ionic/vue';
import { VueQueryPlugin, type VueQueryPluginOptions } from '@tanstack/vue-query';
import { persistQueryClient as persistQueryClientCore } from '@tanstack/query-persist-client-core';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '@/firebase';
import { createI18n } from 'vue-i18n';
import messages from '@/statics/i18n/en.json';
import { bootstrapSentry } from '@/sentry';
import VueTheMask from 'vue-the-mask';
import VueRecaptcha, { VueReCaptcha } from 'vue-recaptcha-v3';

import { GesturePlugin } from '@vueuse/gesture';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.scss';

console.log('============ APP (RE)STARTING ===============');
bootstrapSentry();

// TanStack persist configuration
const vueQueryOptions: VueQueryPluginOptions = {
	queryClientConfig: {
		defaultOptions: {
			queries: {
				cacheTime: 1000 * 60 * 60 * 24 * 365,
				retry(failureCount: number, error: any) {
					// Retry logic
					// If error is 403 don't retry again
					// Else, retry up to 3 times
					// This can be overwritten by local configuration on each call
					const retryTimes = 3;
					return error && error.response && error.response.status === 403
						? false
						: failureCount < retryTimes - 1
							? true
							: false;
				},
			},
		},
	},
	clientPersister: (queryClient: any) => {
		return persistQueryClientCore({
			queryClient,
			persister: createSyncStoragePersister({ storage: localStorage }),
		});
	},
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Register service worker
if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('firebase-messaging-sw.js')
		.then((reg) => {
			console.log(`Service Worker Registration (Scope: ${reg.scope})`);
		})
		.catch((error) => {
			const msg = `Service Worker Error (${error})`;
			console.error(msg);
		});
} else {
	// happens when the app isn't served over HTTPS or if the browser doesn't support service workers
	console.warn('Service Worker not available');
}

// Internationalization
const i18n = createI18n({
	legacy: false, // you must set `false`, to use Composition API
	locale: 'en',
	fallbackLocale: 'en',
	messages,
});

const RECAPTCHAS_SITE_KEY = process.env.VUE_APP_RECAPTCHAS_SITE_KEY;

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

const app = createApp(App)
	.use(IonicVue)
	.use(pinia)
	.use(VueQueryPlugin, vueQueryOptions)
	.use(router)
	.use(i18n)
	.use(GesturePlugin)
	.use(VueDOMPurifyHTML, {
		default: {
			ADD_TAGS: ['iframe', 'p', 'br', 'strong'],
			ADD_ATTR: ['url'],
		},
	})
	.use(VueTheMask)
	.use(VueReCaptcha, {
		siteKey: RECAPTCHAS_SITE_KEY as string,
		loaderOptions: {}, // Add appropriate loader options here
	});

router.isReady().then(() => {
	app.mount('#app');
});

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-left item-center border-white-15 mb-2" }
const _hoisted_2 = ["id", "name", "value", "checked"]

import { toRefs } from 'vue';
import { useField } from 'vee-validate';
import { IonCheckbox } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxInput',
  props: {
	id: String,
	class: String,
	containerClass: String,
	name: String,
},
  setup(__props) {

const props = __props;

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
// eslint-disable-next-line vue/no-dupe-keys
const { handleChange, value } = useField(name);

const swapCheckbox = () => {
	handleChange(!value.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex mb-2", props.containerClass]),
      lines: "none"
    }, [
      _createElementVNode("input", {
        id: props.id,
        type: "checkbox",
        class: _normalizeClass(["mr-4", props.class]),
        name: _unref(name),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (swapCheckbox())),
        value: _unref(value),
        checked: _unref(value) === true ? true : false
      }, null, 10, _hoisted_2),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ]))
}
}

})
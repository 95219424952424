import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "font-sourcesanspro-semibold text-base text-dark-blue" }
const _hoisted_3 = { class: "font-sourcesanspro-semibold text-base text-dark-blue" }

import { toRefs, PropType } from 'vue';
import { useField } from 'vee-validate';
import type { QuizOptionItem } from '@/shared/interfaces/quizOption';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioInput',
  props: {
	modelValue: { type: null },
	name: String,
	values: {
		type: Array as PropType<QuizOptionItem[]>,
		default: function () {
			return [];
		},
	},
},
  emits: ['onSelect'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

// we are using toRefs to create reactive references to `name` and `value` props
// this is important because vee-validte needs to know if any of these props change
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const { name } = toRefs(props);
const { value, handleChange } = useField(name);

const selectItem = (event: MouseEvent, radio: QuizOptionItem) => {
	handleChange(radio.name);
	event.preventDefault();
	emit('onSelect', radio.name);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.values, (radio) => {
    return (_openBlock(), _createElementBlock("button", {
      key: radio,
      class: _normalizeClass(["flex justify-between items-center w-full bg-white border border-solid rounded-full mb-4 px-5 normal-case", [
			{
				'bg-white/90': radio.name === _unref(value),
			},
		]]),
      onClick: ($event) => selectItem($event, radio)
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(radio.name), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(radio.option), 1)
    ], 10, _hoisted_1))
  }), 128))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full justify-between" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center font-sourcesanspro-semibold text-lg24 mr-2"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "font-sourcesanspro-semibold text-l2 capitalize" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex justify-between" }
const _hoisted_9 = {
  key: 0,
  class: "flex items-center justify-center mr-2"
}
const _hoisted_10 = { class: "font-sourcesanspro-bold text-sm text-white/30 line-through" }
const _hoisted_11 = {
  key: 1,
  class: "flex items-center justify-center mr-2 px-3 py-[1px] rounded-full bg-anykrowd-discount-green font-sourcesanspro-bold text-xs2"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "font-sourcesanspro-bold text-base2 text-white" }
const _hoisted_15 = { class: "flex items-center justify-between w-[100px]" }
const _hoisted_16 = { class: "font-sourcesanspro-semibold text-lg" }
const _hoisted_17 = {
  key: 0,
  class: "px-5 pb-4 font-sourcesanspro-regular text-sm text-white/50",
  slot: "content"
}
const _hoisted_18 = { class: "mb-4" }
const _hoisted_19 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_20 = { class: "flex w-full items-center justify-start text-white rounded" }
const _hoisted_21 = { class: "font-sourcesanspro-regular text-sm text-[#1C1725]/50 max-w-[90%]" }

import { PropType, ref, watch } from 'vue';
import { IonAccordionGroup, IonAccordion, IonButton, IonIcon } from '@ionic/vue';
import {
	addOutline,
	chevronDownOutline,
	chevronUpOutline,
	createOutline,
	removeOutline,
	trashOutline,
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Discount, DiscountType } from '@/shared/interfaces/discount';
import useConvertValuta from '@/shared/composables/useConvertValuta';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import { Currency } from '@/shared/interfaces/balance';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { Product } from '@/shared/interfaces/tableSpot';
import { GenericProduct } from '@/shared/interfaces/genericProduct';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemAdder',
  props: {
	addMoreError: { type: String, default: '' },
	addMoreErrorDetail: { type: String, default: '' },
	canAddMore: { type: Boolean, default: false },
	canWriteAComment: { type: Boolean, default: false },
	class: { type: String },
	description: { type: String },
	discount: { type: Discount },
	expandable: { type: Boolean, default: true },
	comments: {
		type: Object as PropType<string[]>,
		required: false,
	},
	price: { type: Number, required: true },
	priceWithDiscount: { type: Number },
	currency: { type: Currency, required: true },
	productId: { type: Number, required: true },
	product: {
		type: Object as PropType<EventTicketType | EventComboTicketType | Product | GenericProduct>,
		required: true,
	},
	quantity: { type: Number, required: true },
	title: { type: String, required: true },
},
  emits: [
	'onWriteAComment',
	'onDecreaseProductQuantity',
	'onIncreaseProductQuantity',
	'onDeleteAComment',
],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const { t } = useI18n();
const isOpen = ref(true);
const emit = __emit;
const { convertValutaSync } = useConvertValuta();
const { presentToast } = useToast();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();

// deleteComment
const deleteComment = (commentIndex: number) => {
	emit('onDeleteAComment', props.productId, commentIndex);
};

// writeAComment
const writeAComment = () => {
	emit('onWriteAComment');
};

// decreaseProductQuantity
const decreaseProductQuantity = (event: MouseEvent) => {
	event.stopPropagation();
	emit('onDecreaseProductQuantity', props.product);
};

// increaseProductQuantity
const increaseProductQuantity = (event: MouseEvent) => {
	event.stopPropagation();
	emit('onIncreaseProductQuantity', props.product);
};

// watch
watch(
	() => props.canAddMore,
	async (value, oldValue) => {
		if (!value) {
			await presentToast('top', t(props.addMoreError, { value: props.addMoreErrorDetail }), 5000, 'danger');
		}
	},
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAccordionGroup), {
    class: _normalizeClass(props.class)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonAccordion), {
        class: "backdrop-blur-xl bg-white/10 rounded-lg",
        value: "first"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex flex-col w-full justify-between px-3 py-2 cursor-pointer",
            slot: "header",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (__props.expandable ? (isOpen.value = !isOpen.value) : null))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (__props.expandable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_unref(IonIcon), {
                        icon: isOpen.value ? _unref(chevronDownOutline) : _unref(chevronUpOutline),
                        class: "text-white"
                      }, null, 8, ["icon"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(props.title.toLowerCase()), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", {
                  class: _normalizeClass(["font-sourcesanspro-bold text-lg", [{ 'text-white/30': props.quantity === 0 }]])
                }, _toDisplayString(props.currency?.prefix) + " " + _toDisplayString(((props.discount ? props.priceWithDiscount : props.price)! * props.quantity!).toFixed(2)), 3)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center justify-center", __props.expandable ? 'pl-8' : ''])
              }, [
                (props.discount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, _toDisplayString(props.currency?.prefix) + _toDisplayString(props.price.toFixed(2)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (props.discount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (props.discount.type === _unref(DiscountType).FIXED)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("span", null, _toDisplayString(props.currency?.prefix) + " " + _toDisplayString(props.discount.amount.toFixed(2)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (props.discount.type === _unref(DiscountType).PERCENTAGE)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(props.discount.amount) + " %", 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_14, _toDisplayString(props.currency?.prefix) + " " + _toDisplayString(props.discount ? props.priceWithDiscount?.toFixed(2) : props.price.toFixed(2)), 1)
              ], 2),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(IonButton), {
                    class: "w-[32px] h-[32px] ion-no-padding ion-no-margin",
                    fill: "outline",
                    style: {"z-index":"1000"},
                    onClick: _cache[0] || (_cache[0] = (event) => decreaseProductQuantity(event)),
                    disabled: __props.quantity === 0
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(removeOutline),
                        class: "text-white"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _createElementVNode("span", _hoisted_16, _toDisplayString(props.quantity), 1),
                _createElementVNode("div", null, [
                  _createVNode(_unref(IonButton), {
                    class: _normalizeClass(["w-[32px] h-[32px] ion-no-padding ion-no-margin hover:bg-white hover:rounded", [
									{
										'no-more-items': !__props.canAddMore,
									},
								]]),
                    onClick: _cache[1] || (_cache[1] = (event) => increaseProductQuantity(event)),
                    disabled: !__props.canAddMore
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(addOutline),
                        class: "text-anykrowd-dark"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["class", "disabled"])
                ])
              ])
            ])
          ]),
          (__props.expandable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(props.description), 1),
                (props.canWriteAComment)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createVNode(_unref(IonButton), {
                        disabled: props.quantity === 0,
                        class: "flex justify-left w-full comment",
                        fill: "outline",
                        onClick: writeAComment
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_unref(IonIcon), {
                              icon: _unref(createOutline),
                              class: "mr-2"
                            }, null, 8, ["icon"]),
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-sm" }, "Write a comment...", -1))
                          ])
                        ]),
                        _: 1
                      }, 8, ["disabled"])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.comments, (comment, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: idx,
                    class: "flex items-center justify-between mb-1 px-3 py-2 bg-white rounded-md"
                  }, [
                    _createElementVNode("span", _hoisted_21, _toDisplayString(comment), 1),
                    _createVNode(_unref(IonButton), {
                      class: "w-[30px] h-[30px] ion-no-padding ion-no-margin transparent",
                      onClick: ($event: any) => (deleteComment(idx))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          icon: _unref(trashOutline),
                          class: "text-sm text-anykrowd-dark"
                        }, null, 8, ["icon"])
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full max-w-[325px] mb-6 bookings-reservations-item rounded-md backdrop-blur-3xl" }
const _hoisted_2 = { class: "relative p-3" }
const _hoisted_3 = { class: "bookings-reservations-detail-image relative" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-col items-start px-3" }
const _hoisted_6 = { class: "mb-1" }
const _hoisted_7 = { class: "font-sourcesanspro-semibold text-l2 leading-none" }
const _hoisted_8 = { class: "flex items-center w-full" }
const _hoisted_9 = { class: "mr-1" }
const _hoisted_10 = {
  key: 0,
  class: "font-sourcesanspro-semibold text-sm text-white/40 line-through"
}
const _hoisted_11 = { class: "font-sourcesanspro-bold text-m2 text-white" }
const _hoisted_12 = {
  key: 1,
  class: "flex flex-col w-full"
}
const _hoisted_13 = { class: "font-sourcesanspro-semibold text-base2 text-white leading-none" }
const _hoisted_14 = { class: "flex justify-between mt-2" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = { class: "font-sourcesanspro-regular text-sm2 text-white/50" }
const _hoisted_17 = { class: "flex flex-col mt-10 mb-4 px-4 font-sourcesanspro-regular text-base2 text-white/50" }
const _hoisted_18 = { class: "flex justify-center mb-4 px-4" }

import router from '@/router';
import LabelTopLeft from '@/shared/components/ui/LabelTopLeft.vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { timeOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingsReservationsItem',
  props: {
	accepted: { type: Boolean, default: false },
	description: { type: String, required: true },
	hour: { type: String, required: true },
	image: { type: String, required: true },
	link: { type: String, required: true },
	location: { type: String, required: true },
	price: { type: Number, required: true },
	priceCurrency: { type: String, required: true },
	priceOriginal: { type: Number },
	time: { type: String, required: true },
	title: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: props.image,
          class: "w-full rounded-md"
        }, null, 8, _hoisted_4),
        (props.accepted)
          ? (_openBlock(), _createBlock(LabelTopLeft, {
              key: 0,
              class: "border-l-anykrowd-item-added-green",
              "label-container-class": "left-0 top-3",
              label: "Accepted"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(props.title), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (!props.accepted)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_9, [
                (props.priceOriginal)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(props.priceCurrency) + _toDisplayString(props.priceOriginal), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("span", _hoisted_11, _toDisplayString(props.priceCurrency) + _toDisplayString(props.price), 1)
            ], 64))
          : _createCommentVNode("", true),
        (props.accepted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, _toDisplayString(props.hour), 1),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_unref(IonIcon), {
                    class: "mr-1",
                    icon: _unref(timeOutline)
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(props.time), 1)
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex items-center font-sourcesanspro-semibold text-sm text-white/30" }, "ID 45", -1))
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_17, _toDisplayString(props.description), 1),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(_unref(IonButton), {
        class: "h-[48px]",
        color: "primary",
        fill: "outline",
        shape: "round",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (goTo(props.link)))
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "font-sourcesanspro-semibold text-base2" }, "View Details", -1)
        ])),
        _: 1
      }),
      (!props.accepted)
        ? (_openBlock(), _createBlock(_unref(IonButton), {
            key: 0,
            class: "w-[145px] h-[48px]",
            color: "primary",
            fill: "outline",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "font-sourcesanspro-semibold text-base2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (goTo('/events/tickets')))
              }, "Book")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (props.accepted)
        ? (_openBlock(), _createBlock(_unref(IonButton), {
            key: 1,
            class: "w-[145px] h-[48px]",
            shape: "round"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "font-sourcesanspro-semibold text-base2",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (goTo('/events/tickets')))
              }, "Ticket")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})
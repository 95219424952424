import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex items-center justify-center mr-2" }
const _hoisted_3 = { class: "flex flex-col justify-center w-full" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-sm text-white/50" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { class: "mr-1" }
const _hoisted_7 = { class: "font-sourcesanspro-semibolditalic text-m2" }
const _hoisted_8 = {
  key: 0,
  class: "font-sourcesanspro-semibolditalic text-sm2 text-white/50"
}
const _hoisted_9 = { class: "flex items-center font-sourcesanspro-semibold text-lg24" }
const _hoisted_10 = { class: "w-full" }
const _hoisted_11 = ["id", "validation-schema"]
const _hoisted_12 = { class: "relative mb-4" }
const _hoisted_13 = { class: "flex items-start" }
const _hoisted_14 = { class: "flex grow items-center mr-2" }
const _hoisted_15 = {
  key: 0,
  class: "flex grow items-center justify-between h-[55px] mr-2 px-4 py-2 rounded-full border border-anykrowd-gray/20 background-anykrowd-gray mb-4"
}
const _hoisted_16 = { class: "flex" }
const _hoisted_17 = { class: "font-sourcesanspro-semibold text-base2 text-white" }

import vue, { PropType, ref } from 'vue';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { IonAccordionGroup, IonAccordion, IonButton, IonIcon } from '@ionic/vue';
import {
	checkmarkOutline,
	chevronForwardOutline,
	chevronDownOutline,
	closeOutline,
	ticketOutline,
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Cart } from '@/shared/interfaces/cart';
import { useSharedStore } from '@/stores/shared/sharedStore';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import { useCart } from '@/modules/shop/composables/useCart';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsItemMemberAdder',
  props: {
	shouldBlockChanges: { type: Boolean, default: false },
	cartItem: { type: Object as PropType<Cart>, required: true },
	cartItemProductId: { type: Number, required: true },
	cartItemIndex: { type: Number, required: true },
	eventId: { type: Number, required: true },
},
  setup(__props) {

const props = __props;

// Init
const { t } = useI18n();
const isOpen = ref(false);
const isFormValid = ref(false);
const sharedStore = useSharedStore();
const { updateCartAdditionalInfoByIndex } = useCart();

// Schema
const validationSchema = yup.object({
	memberEmail: yup
		.string()
		.required()
		.label(t('events.tickets.add_members.email_label'))
		.matches(sharedStore.EMAIL_REGX, t('events.tickets.add_members.errors.email')),
	memberFullName: yup.string().label(t('events.tickets.add_members.full_name_label')).required(),
});

const { meta, setFieldValue, validate, validateField, values, resetForm } = useForm({
	validationSchema,
});

// Remove member
const removeMember = () => {
	updateCartAdditionalInfoByIndex(props.cartItemIndex, {
		...props.cartItem.additionalInfo,
		name: null,
		email: null,
		user_id: null,
	});
	resetForm();
};

// Validating form
const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	isFormValid.value = meta.value.valid;
};

// Handle submit
const onSubmit = (event: Event) => {
	validate();
	event.preventDefault();
	updateCartAdditionalInfoByIndex(props.cartItemIndex, {
		...props.cartItem.additionalInfo,
		name: values.memberFullName,
		email: values.memberEmail,
		user_id: null,
	});
	isFormValid.value = meta.value.valid;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonAccordionGroup), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonAccordion), {
        class: "mb-4 items-start justify-between backdrop-blur-3xl bg-[#3f3e4a] rounded-lg",
        value: "first"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "flex w-full justify-between h-[70px] px-4 py-2 cursor-pointer",
            slot: "header",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_unref(IonIcon), {
                  class: _normalizeClass([
								!props.cartItem.additionalInfo.name ? 'text-white' : 'text-anykrowd-ticket-check-green'
							, "w-[22px] h-[22px] py-2 rounded-full"]),
                  icon: !props.cartItem.additionalInfo.name ? _unref(ticketOutline) : _unref(checkmarkOutline)
                }, null, 8, ["class", "icon"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(__props.cartItem?.product.name) + ":", 1),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(props.cartItem.additionalInfo.name), 1)
                  ]),
                  (!props.cartItem.additionalInfo.name)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(_unref(t)('components.events_item_member_adder.empty')) + ")", 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_unref(IonIcon), {
                icon: isOpen.value ? _unref(chevronDownOutline) : _unref(chevronForwardOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass([[{ 'flex': props.cartItem.additionalInfo.name }], "ion-padding border-t border-t-white/10 font-sourcesanspro-regular text-base2 font-color-text-50"]),
            slot: "content"
          }, [
            _withDirectives(_createElementVNode("div", _hoisted_10, [
              _createElementVNode("form", {
                id: `form-${__props.cartItemIndex}`,
                onSubmit: _cache[1] || (_cache[1] = (event) => onSubmit(event)),
                "validation-schema": _unref(validationSchema),
                class: "w-full"
              }, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(TextInput, {
                        "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                        name: "memberFullName",
                        value: props.cartItem.additionalInfo.name,
                        type: "text",
                        placeholder: _unref(t)('components.events_item_member_adder.memberFullName'),
                        onInputChanged: onInputChange
                      }, null, 8, ["value", "placeholder"])
                    ])
                  ])
                ]),
                _createVNode(TextInput, {
                  "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
                  name: "memberEmail",
                  value: props.cartItem.additionalInfo.email,
                  type: "email",
                  placeholder: _unref(t)('components.events_item_member_adder.memberEmail'),
                  onInputChanged: onInputChange
                }, null, 8, ["value", "placeholder"]),
                _createVNode(_unref(IonButton), {
                  type: "submit",
                  disabled: !isFormValid.value,
                  shape: "round",
                  class: "hover:rounded-full hover:bg-white"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('components.events_item_member_adder.save')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 40, _hoisted_11)
            ], 512), [
              [_vShow, !props.cartItem.additionalInfo.name || !props.cartItem.additionalInfo.email]
            ]),
            (props.cartItem.additionalInfo.name && props.cartItem.additionalInfo.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_unref(IonIcon), {
                      class: "cursor-pointer mr-2",
                      icon: _unref(ticketOutline),
                      slot: "end"
                    }, null, 8, ["icon"]),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(props.cartItem.additionalInfo.name), 1)
                  ]),
                  (!__props.shouldBlockChanges)
                    ? (_openBlock(), _createBlock(_unref(IonButton), {
                        key: 0,
                        class: "transparent ion-no-margin ion-no-padding",
                        shape: "round",
                        onClick: removeMember
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonIcon), {
                            class: "cursor-pointer",
                            icon: _unref(closeOutline)
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})
<script setup lang="ts">
import vue from 'vue';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { callOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useToast from '@/shared/composables/useToast';
import { useReCaptcha } from 'vue-recaptcha-v3';
import useLoading from '@/shared/composables/useLoading';

const { t } = useI18n();
const emit = defineEmits(['onFormValidation']);
const { verifyPhone } = useAuth();
const { presentToast } = useToast();
const { presentLoading } = useLoading();
const recaptcha = useReCaptcha();
const executeRecaptcha = recaptcha?.executeRecaptcha;

const phoneRegExp = /^\+[1-9]\d{7,14}$/;

const validationSchema = yup.object({
	phone_number: yup
		.string()
		.matches(phoneRegExp, t('auth.sign_in_phone_page.errors.phone'))
		.required(t('auth.sign_in_phone_page.required')),
});

const { handleSubmit, meta, validateField } = useForm({
	validationSchema: validationSchema,
});

const onSubmit = handleSubmit(async (formData: any) => {
	const loading = await presentLoading(t('general.please_wait'));

	try {
		let recaptchaToken = '';

		if (executeRecaptcha) {
			recaptchaToken = await executeRecaptcha('login_action');
		}

		await verifyPhone({
			userData: {
				...formData,
			},
			message: t('general.please_wait'),
			recaptchaToken: recaptchaToken,
		});

		await loading.dismiss();
	} catch (error: any) {
		await loading.dismiss();

		await presentToast(
			'top',
			error.response?.data?.message ? error.response.data.message.toString() : error.toString(),
			5000,
			'danger',
		);
	}
});

const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	await validateField(fieldName.value);
	emit('onFormValidation', meta.value.valid);
};
</script>

<template>
	<form id="signInWithPhoneForm" @submit="onSubmit" :validation-schema="validationSchema">
		<TextInput
			wrapper-class="flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15"
			:iconLeft="callOutline"
			name="phone_number"
			type="tel"
			:placeholder="$t('auth.sign_in_phone_page.phone')"
			@inputChanged="onInputChange" />
	</form>
</template>

<style lang="scss" scoped></style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed flex top-0 left-0 items-center justify-center w-[100%] h-[100%] bg-black overflow-scroll max-w-none z-20" }
const _hoisted_2 = ["src"]

import { ref } from 'vue';
import { useSpring, useMotionProperties } from '@vueuse/motion';
import { IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

// Propes
type MotionPropertiesCustom = {
	cursor: string;
	x: number;
	y: number;
};

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'FullScreenImage',
  props: {
	fullScreenImage: { type: String },
},
  emits: ['onCloseImage'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Type
const imageRef = ref(); // target image to apply the pinch gesture
const accumulated = ref(0);
const emit = __emit;

// Bind to the element or component reference
// and init style properties that will be animated.
const { motionProperties } = useMotionProperties(imageRef, {
	cursor: 'grab',
	x: 0,
	y: 0,
});

const { set } = useSpring(motionProperties as MotionPropertiesCustom, {
	duration: 10,
});

const pinchHandler = ({ movement: [d, a] }: { movement: [number, number] }) => {
	accumulated.value += d / 30000;
	accumulated.value = accumulated.value <= 1 ? 1 : accumulated.value * 1.0;

	set({ scale: accumulated.value, offset: 1 });
};

const closeImage = () => {
	emit('onCloseImage');
};

return (_ctx: any,_cache: any) => {
  const _directive_pinch = _resolveDirective("pinch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex items-center justify-start cursor-pointer",
      onClick: closeImage
    }, [
      _createVNode(_unref(IonIcon), {
        class: "fixed top-8 left-8 text-[38px] text-white cursor-pointer z-20",
        icon: _unref(closeOutline)
      }, null, 8, ["icon"])
    ]),
    _withDirectives(_createElementVNode("img", {
      src: props.fullScreenImage,
      alt: "",
      class: "max-w-[100%] scale-100 z-10",
      ref_key: "imageRef",
      ref: imageRef
    }, null, 8, _hoisted_2), [
      [_directive_pinch, pinchHandler]
    ])
  ]))
}
}

})
<script setup lang="ts">
import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, closeOutline, mailOutline, callOutline } from 'ionicons/icons';

import CustomButton from '@/shared/components/CustomButton.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import SignInSignUpAlert from '@/shared/components/SignInSignUpAlert.vue';
import SocialLoginButtons from '@/modules/auth/components/SocialLoginButtons.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init
const { getTenantConfigAndStyles } = useTenant();
const assetStore = useAssetsStore();
const { isSuccessTenantConfig, tenantConfig, anySocialLoginEnabled } = getTenantConfigAndStyles();

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

const onLoginSuccess = () => {
	router.push('/profile/additionaldata');
};

const goTo = async (link: string) => {
	await router.push(link);
};
</script>

<template>
	<ion-content>
		<ModalThree :is-open="modalState">
			<div class="flex w-full items-center justify-center mb-4">
				<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4">
					<ion-icon
						class="text-[38px] text-black cursor-pointer"
						:icon="closeOutline"
						@click="setModal(false)"></ion-icon>
				</div>
			</div>
			<h1 class="font-sourcesanspro-semibold text-lg">
				{{ modalType === 'tos' ? 'Terms and conditions' : 'Privacy Policy' }}
			</h1>
			<div
				v-dompurify-html="
					modalType === 'tos' ? tenantConfig!.terms_and_conditions : tenantConfig!.privacy_policy
				"
				class="text-anykrowd-dark"></div>
		</ModalThree>

		<div class="flex flex-col h-full justify-between">
			<TopBar v-if="tenantConfig && tenantConfig.enable_public_mode" class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer">
						<router-link to="/dashboard" class="flex items-center justify-center">
							<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
						</router-link>
					</div>
				</template>
			</TopBar>
			<div class="px-[34px] pt-14">
				<div class="icon flex justify-center mb-4">
					<img :src="assetStore.logoUrl" class="max-w-[70%]" />
				</div>
				<h1 class="mb-4 font-sourcesanspro-semibold text-white text-lg text-center">
					{{ tenantConfig?.welcome_title }}
				</h1>
				<div class="max-w-xs mx-auto mb-8 text-white text-base text-center">
					{{ tenantConfig?.welcome_line }}
				</div>
				<div class="flex justify-center mb-4">
					<CustomButton
						@on-click="goTo('/auth/signinwithemail')"
						text="auth.sign_in_home_page.logInEmail"
						:iconLeft="mailOutline" />
				</div>
				<div v-if="tenantConfig?.enable_phone_login" class="flex justify-center mb-8">
					<CustomButton
						@on-click="goTo('/auth/signinwithphone')"
						text="auth.sign_in_home_page.logInPhone"
						:iconLeft="callOutline" />
				</div>
				<div class="separator mb-8" v-if="anySocialLoginEnabled()">
					<h5>
						<span class="font-sourcesanspro-regular text-base text-white">{{
							$t(`auth.sign_in_home_page.orSignUpWith`)
						}}</span>
					</h5>
				</div>
				<SocialLoginButtons @on-login-success="onLoginSuccess"></SocialLoginButtons>

				<p class="max-w-s mx-auto mt-4 mb-8 px-9 font-sourcesanspro-regular text-white text-sm text-center">
					{{ $t('auth.sign_in_home_page.terms_continue') }}
					<a
						v-if="isSuccessTenantConfig"
						@click="setModal(true, 'tos')"
						target="_blank"
						class="text-white underline">
						{{ $t('auth.sign_in_home_page.terms_of_use') }}
					</a>

					{{ $t('auth.sign_in_home_page.terms_and_confirm') }}
					<a
						v-if="isSuccessTenantConfig"
						@click="setModal(true, 'pp')"
						target="_blank"
						class="text-white underline">
						{{ $t('auth.sign_in_home_page.terms_privacy_policy') }}
					</a>
				</p>
			</div>

			<div class="w-full max-w-none">
				<SignInSignUpAlert
					buttonText="components.sign_up.buttonText"
					text="components.sign_up.text"
					link="/auth/signuphome" />
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
